import React from 'react'
import Badge from './badge'
import StaticMap from './maps/static-map'
import useClassnames from 'hooks/use-classnames'
import useDimensions from 'hooks/use-dimensions'
import {ClockTypeBadge, AccountTime} from 'util/formatters'
import {DateTime} from 'luxon'
import {MapEmptyState} from './empty-state'
import {ClockType} from '@scavatec/timeclock-lib/database/models/common'

type TransactionCardProps = {
  transaction: MappedTransaction
  onClick?: OnClickEvent
  showName?: boolean
}
export const TransactionCard: React.FC<TransactionCardProps> = ({
  transaction,
  onClick,
  showName = true,
}) => {
  const classes = useClassnames({
    'transaction-card': true,
    invalid: transaction.isInvalid === '1',
    card: true,
  })
  const date = DateTime.fromFormat(transaction.date, 'yyyy-LL-dd HH:mm:ss')
  // TODO check
  const type =
    parseInt(transaction.type.toString(), 10) === ClockType.In ? 'in' : 'out'
  const {
    ref,
    dimensions: {width},
  } = useDimensions()
  return (
    <div className={classes} ref={ref} onClick={onClick}>
      {transaction.location.valid ? (
        <StaticMap
          width={width}
          height={150}
          zoom={13}
          markers={[
            {
              lat: transaction.location.lat,
              long: transaction.location.long,
              color: type === 'in' ? '0x03C03C' : '0xED4C67',
              label: type === 'in' ? 'I' : 'O',
            },
          ]}
        />
      ) : (
        <MapEmptyState />
      )}
      <div className="card-body">
        {showName && <span className="employee">{transaction.employee}</span>}
        <div className="row">
          <span className="date">
            <AccountTime
              value={transaction.dateUTC}
              baseFormat={DateTime.DATE_MED}
            />
          </span>
          <span className="job">{transaction.job}</span>
        </div>
        <div className="row">
          <ClockTypeBadge type={parseInt(transaction.type.toString(), 10)} />
          <span className="time">
            <AccountTime
              value={transaction.dateUTC}
              baseFormat={DateTime.TIME_SIMPLE}
            />
          </span>
        </div>
      </div>
    </div>
  )
}
export default TransactionCard
