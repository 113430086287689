import {useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import React, {ComponentProps, useMemo, useState} from 'react'
import Forms from './forms'
import Popover from './popover'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import {CustomPayPeriod, PayPeriod, formatDay} from '@scavatec/timeclock-lib/pay-period'
import {MaterialIcon} from './icon'
import {mdiChevronLeft, mdiChevronRight, mdiClockOutline} from '@mdi/js'
import {DropdownMenu} from './dropdown-menu'
import {MenuItem} from './dropdown-menu'
import * as Menu from './menu'
import analytics from '@scavatec/timeclock-lib/analytics'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
dayjs.extend(localizedFormat)
dayjs.extend(quarterOfYear)

type ChevronButtonProps = {
  icon: ComponentProps<typeof MaterialIcon>['path']
  onClick: () => void
}
export const ChevronButton = ({icon, onClick}: ChevronButtonProps) => {
  return (
    <div className="chevron-button" onClick={onClick}>
      <MaterialIcon path={icon} size={1} />
    </div>
  )
}

const LABEL_FORMAT = 'll'

const formatPayPeriod = (p: PayPeriod | CustomPayPeriod) => {
  return `${!p[0] ? '' : dayjs(p[0]).format(LABEL_FORMAT)} - ${!p[1] ? '' : dayjs(p[1]).format(LABEL_FORMAT)}`
}

type PayPeriodPickerProps = {
  onNextClick?: () => void
  onPrevClick?: () => void
}
export const PayPeriodPicker: React.FC<PayPeriodPickerProps> = ({onNextClick, onPrevClick}) => {
  const [open, setOpen] = useState(false)
  const {rawReport, actions, payPeriod} = useReportContext()
  const {custom, active} = payPeriod
  const timezone = useOrganizationSetting('account.timezone')
  const displayPeriod = custom[0] !== null || custom[1] !== null ? custom : active
  const period = useMemo(() => {
    if (!displayPeriod[0] || !displayPeriod[1]) {
      return {}
    }
    return {
      period: {
        from: dayjs(displayPeriod[0]).toDate(),
        to: dayjs(displayPeriod[1]).toDate(),
      },
      start: dayjs(displayPeriod[0]).toDate(),
      end: dayjs(displayPeriod[1]).toDate(),
    }
  }, [displayPeriod])
  return (
    <div className="pay-period">
      <Popover
        open={open}
        onRequestClose={() => setOpen(false)}
        backdrop
        placement="bottom-end"
        renderTrigger={({ref}) => {
          return (
            <div className="input-bar" ref={ref}>
              <QuickRange />
              <ChevronButton
                icon={mdiChevronLeft}
                onClick={() => {
                  onPrevClick && onPrevClick()
                  actions.prevPeriod()
                  analytics.event('w.af.pay-period.previous')
                }}
              />
              <span onClick={() => setOpen(!open)}>{formatPayPeriod(displayPeriod)}</span>
              <ChevronButton
                icon={mdiChevronRight}
                onClick={() => {
                  onNextClick && onNextClick()
                  actions.nextPeriod()
                  analytics.event('w.af.pay-period.next')
                }}
              />
              {/* <ChevronButton
                icon="calendar"
                onClick={() => {
                  actions.setLatest()
                }}
              /> */}
            </div>
          )
        }}
        renderContent={({ref, style}) => {
          return (
            <div className="popover pay-period-popover" ref={ref} style={style}>
              <div className="row">
                <div className="col">
                  <Forms.Calendar
                    label="Period Start"
                    error=""
                    value={displayPeriod[0] ?? ''}
                    modifiers={{
                      ...period,
                    }}
                    disabled={{
                      after: dayjs(displayPeriod[1]).toDate(),
                    }}
                    onValueChange={(v: string) => {
                      const d = dayjs.tz(v, timezone.value).startOf('day')
                      const p = [formatDay(d), custom[1]] as PayPeriod
                      actions.setCustomPeriod(p)
                      if (p[1] && p[0]) {
                        analytics.event('w.af.pay-period.set-custom', {
                          start: p[0],
                          end: p[1],
                        })
                      }
                    }}
                  />
                </div>
                <div className="col">
                  <Forms.Calendar
                    label="Period End"
                    error=""
                    value={displayPeriod[1] ?? ''}
                    modifiers={{
                      ...period,
                    }}
                    disabled={{
                      before: dayjs(displayPeriod[0]).toDate(),
                    }}
                    onValueChange={(v: string) => {
                      const d = dayjs.tz(v, timezone.value).startOf('day')
                      const p = [custom[0], formatDay(d)] as PayPeriod
                      actions.setCustomPeriod(p)
                      if (p[1] && p[0]) {
                        analytics.event('w.af.pay-period.set-custom', {
                          start: p[0],
                          end: p[1],
                        })
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}
export default PayPeriodPicker

const event = (type: string, period: PayPeriod) => {
  analytics.event('w.af.quick-range-select', {
    type,
    start: period[0],
    end: period[1],
    days: dayjs(period[1]).diff(dayjs(period[0]), 'day') + 1,
  })
}

const QuickRange = () => {
  const {actions} = useReportContext()
  const [open, setOpen] = useState(false)
  return (
    <Popover
      renderTrigger={({ref}) => (
        <div ref={ref}>
          <ChevronButton icon={mdiClockOutline} onClick={() => setOpen(!open)} />
        </div>
      )}
      open={open}
      onRequestClose={() => setOpen(false)}
      backdrop
      placement="bottom-end"
      renderContent={({ref, style}) => (
        <div className="popover popover-menu" style={style} ref={ref}>
          <Menu.Menu>
            <Menu.MenuItem
              label="Today"
              onClick={() => {
                const p = [formatDay(dayjs()), formatDay(dayjs())] as PayPeriod
                event('today', p)
                actions.setCustomPeriod(p)
                setOpen(false)
              }}
            />
            <Menu.MenuItem
              label="Yesterday"
              onClick={() => {
                const d = dayjs().subtract(1, 'day')
                const p = [formatDay(d), formatDay(d)] as PayPeriod
                event('yesterday', p)
                actions.setCustomPeriod(p)
                setOpen(false)
              }}
            />
            <Menu.MenuItem
              label="This Week"
              onClick={() => {
                const d = dayjs()
                const p = [formatDay(d.startOf('week')), formatDay(d.endOf('week'))] as PayPeriod
                event('this_week', p)
                actions.setCustomPeriod(p)
                setOpen(false)
              }}
            />
            <Menu.MenuItem
              label="Last Week"
              onClick={() => {
                const d = dayjs().subtract(1, 'week')
                const p = [formatDay(d.startOf('week')), formatDay(d.endOf('week'))] as PayPeriod
                event('last_week', p)
                actions.setCustomPeriod(p)
                setOpen(false)
              }}
            />
            <Menu.MenuItem
              label="This Month"
              onClick={() => {
                const d = dayjs()
                const p = [formatDay(d.startOf('month')), formatDay(d.endOf('month'))] as PayPeriod
                event('this_month', p)
                actions.setCustomPeriod(p)
                setOpen(false)
              }}
            />
            <Menu.MenuItem
              label="Last Month"
              onClick={() => {
                const d = dayjs().subtract(1, 'month')
                const p = [formatDay(d.startOf('month')), formatDay(d.endOf('month'))] as PayPeriod
                event('last_month', p)
                actions.setCustomPeriod(p)
                setOpen(false)
              }}
            />
            <Menu.MenuItem
              label="This Quarter"
              onClick={() => {
                const d = dayjs()
                const p = [formatDay(d.startOf('quarter')), formatDay(d.endOf('quarter'))] as PayPeriod
                event('this_quarter', p)
                actions.setCustomPeriod(p)
                setOpen(false)
              }}
            />
            <Menu.MenuItem
              label="Last Quarter"
              onClick={() => {
                const d = dayjs().subtract(1, 'quarter')
                const p = [formatDay(d.startOf('quarter')), formatDay(d.endOf('quarter'))] as PayPeriod
                event('this_quarter', p)
                actions.setCustomPeriod(p)
                setOpen(false)
              }}
            />
            <Menu.MenuItem
              label="Year to Date"
              onClick={() => {
                const p = [formatDay(dayjs().startOf('year')), formatDay(dayjs().endOf('day'))] as PayPeriod
                event('year_to_date', p)
                actions.setCustomPeriod(p)
                setOpen(false)
              }}
            />
            <Menu.MenuItem
              label="This Year"
              onClick={() => {
                const d = dayjs()
                const p = [formatDay(d.startOf('year')), formatDay(d.endOf('year'))] as PayPeriod
                event('this_quarter', p)
                actions.setCustomPeriod(p)
                setOpen(false)
              }}
            />
            <Menu.MenuItem
              label="Last Year"
              onClick={() => {
                const d = dayjs().subtract(1, 'year')
                const p = [formatDay(d.startOf('year')), formatDay(d.endOf('year'))] as PayPeriod
                event('this_quarter', p)
                actions.setCustomPeriod(p)
                setOpen(false)
              }}
            />
          </Menu.Menu>
        </div>
      )}
    />
  )
}
