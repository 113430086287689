import {mergeDeepRight} from 'rambda'
import pkg from '../../package.json'

const env = process.env.REACT_APP_APP_ENV === 'production' ? 'production' : 'dev'

const baseConfig = {
  version: pkg.version,
  env: env,
  dev: env !== 'production',
  production: env === 'production',
  api: {
    version: '1.9',
    legacyHost: process.env.REACT_APP_API_LEGACY_HOST,
    host: process.env.REACT_APP_API_HOST,
    integrationHost: process.env.REACT_APP_INTEGRATION_HOST,
  },
  features: {
    tvMode: true,
    customAttributes: false,
    reportingEngine: false,
  },
  mobile: {
    latestVersion: '3.0.1',
  },
  services: {
    logrocket: {
      key: process.env.REACT_APP_LOGROCKET_KEY,
    },
    amplitude: {
      key: process.env.REACT_APP_AMPLITUDE_KEY,
    },
    mixpanel: {
      key: process.env.REACT_APP_MIXPANEL_KEY,
    },
    stripe: {
      key: process.env.REACT_APP_STRIPE_KEY,
      prices: {
        monthly: 'usd-monthly',
        yearly: 'usd-yearly',
      },
    },
    pusher: {
      key: process.env.REACT_APP_PUSHER_KEY,
      cluster: 'us3',
    },
  },
}

type EnvConfig = typeof baseConfig

const dev: EnvConfig = mergeDeepRight(baseConfig, {
  api: {
    legacyHost: 'https://legacy-api.dev.mytoolr.com/1.1',
    host: 'https://api.dev.mytoolr.com',
  },
  env: 'dev',
  dev: true,
  production: false,
  features: {
    tvMode: true,
  },
  services: {
    logrocket: {
      key: 'w9fktt/web-app-dev',
    },
    amplitude: {
      key: 'e0eccdfc34f40ce3f6f464ed66301ecf',
    },
    mixpanel: {
      key: '36386593de6adbe32fa9c72fd2579b80',
    },
    stripe: {
      key: 'pk_0FN9lujnbsINim79VjTVAZkCa55Ho',
    },
    pusher: {
      key: '295c5489c6bba9b7462c',
    },
  },
})

const prodAWS: EnvConfig = mergeDeepRight(baseConfig, {
  api: {
    legacyHost: 'https://legacy-api-prod.mytoolr.com/1.1',
    host: 'https://api-prod.mytoolr.com',
  },
})

const local = {
  ...baseConfig,
  env: 'local',
}

const makeConfig = () => {
  switch (window.location.host) {
    case 'legacy.dev.mytoolr.com':
    case 'app.dev.mytoolr.com':
      return dev
    case 'app-prod.mytoolr.com':
      return prodAWS
  }
  return baseConfig
}

const config = makeConfig()
export default config
