import {useState} from 'react'

export const useAPI = (legacy = true) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [response, setResponse] = useState(null)

  const request = async (p: Promise<any>, onError = (e: any) => {}): Promise<any> => {
    setLoading(true)
    setError('')
    setResponse(null)
    try {
      const res = await p
      setResponse(res)
      setLoading(false)
      return res
    } catch (e: any) {
      setLoading(false)
      onError(e)
      setError(e.message || e)
      throw e
    }
  }
  const clear = () => {
    setError('')
    setLoading(false)
    setResponse(null)
  }
  const requestAsync = async (func: any) => {
    setLoading(true)
    setError('')
    setResponse(null)
    const res = await func()
    setResponse(res)
    setLoading(false)
  }
  return {
    clear,
    loading,
    error,
    success: response !== null && response !== undefined,
    response,
    request,
    requestAsync,
  }
}

export default useAPI
