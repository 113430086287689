import React from 'react'
import StaticMap from './maps/static-map'
import useClassnames from 'hooks/use-classnames'
import {PreferredTime, AccountTime} from 'util/formatters'
import useDimensions from 'hooks/use-dimensions'
import {DateTime} from 'luxon'
import {MapEmptyState} from './empty-state'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {parseLocation} from '@scavatec/timeclock-lib/contexts/report-context'
import {ReportInterval} from '@scavatec/timeclock-lib/api/report'
import {employeeName} from '@scavatec/timeclock-lib/formatters'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'

type IntervalCardProps = {
  interval: ReportInterval
  onClick?: (i: ReportInterval) => void
  showEmployee?: boolean
  showJob?: boolean
}
export const IntervalCard: React.FC<IntervalCardProps> = ({interval, onClick, showEmployee = true, showJob = true}) => {
  const classes = useClassnames({
    card: true,
    // invalid: interval.invalid,
    invalid: false,
    'report-card': true,
    'no-hover': true,
  })
  const timezone = useOrganizationSetting('account.timezone')
  const inDate = DateTime.fromISO(interval.start).setZone(timezone.value)
  const outDate = interval.end !== null ? DateTime.fromISO(interval.end).setZone(timezone.value) : inDate
  const date =
    inDate.day === outDate.day
      ? `${inDate.toLocaleString(DateTime.DATE_MED)}`
      : `${inDate.toLocaleString(DateTime.DATE_MED)} - ${outDate.toLocaleString(DateTime.DATE_MED)}`
  const {
    ref,
    dimensions: {width},
  } = useDimensions()
  // const locStart = parseLocation(interval.in)
  // const locEnd = parseLocation(interval.out)
  const locations: any[] = []
  // if (locStart.valid) {
  //   locations.push({
  //     lat: locStart.lat,
  //     long: locStart.long,
  //     color: '0x03C03C',
  //     label: '1',
  //   })
  // }
  // if (locEnd.valid) {
  //   locations.push({
  //     lat: locEnd.lat,
  //     long: locEnd.long,
  //     color: '0xED4C67',
  //     label: '2',
  //   })
  // }
  return (
    <div
      className={classes}
      ref={ref}
      onClick={() => {
        onClick && onClick(interval)
      }}
    >
      {locations.length > 0 ? (
        <StaticMap width={width} height={150} zoom={13} markers={locations} />
      ) : (
        <MapEmptyState />
      )}
      <div className="card-body">
        {showEmployee && <span className="employee">missing</span>}
        <div className="row">
          <span className="date">{date}</span>
          <span className="job">missing</span>
        </div>
        <div className="row">
          <span className="time">
            <AccountTime value={interval.start} baseFormat={DateTime.TIME_SIMPLE} />
            {' - '}
            {interval.end === null ? 'Now' : <AccountTime value={interval.end} baseFormat={DateTime.TIME_SIMPLE} />}
          </span>
          <span className="duration">
            <PreferredTime seconds={interval.duration} />
          </span>
        </div>
      </div>
    </div>
  )
}

type IntervalCardLegacyProps = {
  interval: any
  onClick?: (i: ReportIntervalLegacy) => void
  showEmployee?: boolean
  showJob?: boolean
}
export const IntervalCardLegacy: React.FC<IntervalCardLegacyProps> = ({
  interval,
  onClick,
  showEmployee = true,
  showJob = true,
}) => {
  const classes = useClassnames({
    card: true,
    invalid: interval.invalid,
    'report-card': true,
    'no-hover': true,
  })
  const timezone = useOrganizationSetting('account.timezone')
  const inDate = DateTime.fromISO(interval.dIn).setZone(timezone.value)
  const outDate = DateTime.fromISO(interval.dOut).setZone(timezone.value)
  const date =
    inDate.day === outDate.day
      ? `${inDate.toLocaleString(DateTime.DATE_MED)}`
      : `${inDate.toLocaleString(DateTime.DATE_MED)} - ${outDate.toLocaleString(DateTime.DATE_MED)}`
  const {
    ref,
    dimensions: {width},
  } = useDimensions()
  const locStart = parseLocation(interval.in)
  const locEnd = parseLocation(interval.out)
  const locations = []
  if (locStart.valid) {
    locations.push({
      lat: locStart.lat,
      long: locStart.long,
      color: '0x03C03C',
      label: '1',
    })
  }
  if (locEnd.valid) {
    locations.push({
      lat: locEnd.lat,
      long: locEnd.long,
      color: '0xED4C67',
      label: '2',
    })
  }
  return (
    <div
      className={classes}
      ref={ref}
      onClick={() => {
        onClick && onClick(interval)
      }}
    >
      {locations.length > 0 ? (
        <StaticMap width={width} height={150} zoom={13} markers={locations} />
      ) : (
        <MapEmptyState />
      )}
      <div className="card-body">
        {showEmployee && <span className="employee">{interval.employee}</span>}
        <div className="row">
          <span className="date">{date}</span>
          <span className="job">{interval.job}</span>
        </div>
        <div className="row">
          <span className="time">
            <AccountTime value={interval.dIn} baseFormat={DateTime.TIME_SIMPLE} />
            {' - '}
            {interval.out?.faux ? 'Now' : <AccountTime value={interval.dOut} baseFormat={DateTime.TIME_SIMPLE} />}
          </span>
          <span className="duration">
            <PreferredTime seconds={interval.totalSeconds} />
          </span>
        </div>
      </div>
    </div>
  )
}
export default IntervalCardLegacy
