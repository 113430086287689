import analytics from '@scavatec/timeclock-lib/analytics'
import {useEmployeeListQuery} from '@scavatec/timeclock-lib/api/employee'
import {createEmployee, EmployeeDoc} from '@scavatec/timeclock-lib/database/models/employee'
import {useEmployees} from '@scavatec/timeclock-lib/state/entities'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import useDataModal from 'hooks/use-data-modal'
import useFilterSet from 'hooks/use-filter-set'
import useFuse from 'hooks/use-fuse'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useNavigate} from 'react-router'
import Button from 'ui/button'
import EmployeeCard from 'ui/employee'
import EmptyState from 'ui/empty-state'
import EmployeeTable from 'ui/tables/employee-table'
import {EmployeeListFilters, EmployeeListToolbar} from 'ui/toolbars/employee-list-toolbar'

const fuseOptions = {
  threshold: 0.1,
  shouldSort: true,
  keys: ['firstName', 'lastName'],
}

type EmployeesProps = {}
export const Employees: React.FC<EmployeesProps> = ({children}) => {
  const modal = useDataModal<EmployeeDoc>(() => createEmployee({}))
  const navigate = useNavigate()
  const query = useEmployeeListQuery()
  const preferredView = useOrganizationSetting('account.preferred_view')
  const filterSet = useFilterSet<EmployeeListFilters>({
    view: preferredView.value,
    status: 'all',
    search: '',
    showInactive: false,
  })
  useEmployeeListQuery()
  const employees = useEmployees()
  useEffect(() => {
    query.refetch()
    analytics.event('w.g.1.0-view')
  }, [])

  const {status, showInactive, search} = filterSet.filters
  let filtered = useMemo(() => {
    return employees.filter((e) => {
      if (!showInactive && !e.isActive) {
        return false
      }
      if (status !== 'all') {
        return e.statusString() === status
      }
      return true
    })
  }, [employees, status, showInactive])
  filtered = useFuse(filtered, search, fuseOptions)

  const employeeClicked = useCallback(
    (e: EmployeeDoc) => {
      analytics.event('w.g.1.0-click-tile')
      navigate('/employees/' + e.uuid)
    },
    [modal],
  )

  return (
    <div className="page employees">
      <EmployeeListToolbar modal={modal} filterSet={filterSet} />
      <div className="page-content">
        {employees.length <= 0 && (
          <EmptyState
            title="No People"
            description="No People have been added to your account yet. To add your first Person, click the Add Person button in the top right."
          >
            <Button
              label="Add Person"
              color="clock-in"
              icon="plus"
              onClick={() => {
                analytics.event('w.g.1.0-click-add-employee-empty')
                navigate('/employees/new')
              }}
            />
          </EmptyState>
        )}
        {employees.length > 0 && filtered.length <= 0 && (
          <EmptyState
            title="No People Found"
            description="No People were found with the selected filters. Change the filters above or create a new Person by clicking the 'Add Person' button in the top right."
          >
            <Button
              label="Add Person"
              color="clock-in"
              icon="plus"
              onClick={() => {
                analytics.event('w.g.1.0-click-add-employee-empty')
                navigate('/employees/new')
              }}
            />
          </EmptyState>
        )}
        {preferredView.value === 'list' ? (
          <div className="employee-table">
            <EmployeeTable analyticsId="w.g.1.0" onRowClick={employeeClicked} data={filtered} />
          </div>
        ) : (
          <div className="cards employee-cards">
            {filtered.map((e) => {
              return <EmployeeCard key={e.uuid} employee={e} onClick={() => employeeClicked(e)} />
            })}
          </div>
        )}
      </div>
    </div>
  )
}
export default Employees
