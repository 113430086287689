import React, {useMemo} from 'react'
import {CellProps} from 'react-table'
import Table, {TableRowClick} from 'ui/table'
import {Currency, PreferredTime, AccountTime} from 'util/formatters'
import EmptyState from 'ui/empty-state'
import IntervalCard from 'ui/interval'
import {FilterSet} from '../../hooks/use-filter-set'
import useFuse from '../../hooks/use-fuse'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useReportContext, transformAllIntervals} from '@scavatec/timeclock-lib/contexts/report-context'
import {ColumnSizes} from 'ui/tables/common'
import {useAtom} from 'jotai'
import {reportSearchAtom} from './report-state'
import Button from 'ui/button'
import {useNavigate} from 'react-router'
import {useActiveReport} from '@scavatec/timeclock-lib/api/report'
import {NONE_UUID} from '@scavatec/timeclock-lib/database/models/job'

const analyticsId = 'w.c.1.0-intervals'
type IntervalsProps = {}
const Intervals: React.FC<IntervalsProps> = (
  {
    /* onRowClick, */
    /* data, */
  },
) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Start',
        accessor: 'start',
        Cell: ({cell: {value}}: CellProps<any>) => <AccountTime value={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'End',
        accessor: 'end',
        Cell: ({cell: {value}}: CellProps<any>) => <AccountTime value={value} />,
        width: ColumnSizes.time,
      },
    ],
    [],
  )
  const {intervals} = useActiveReport()
  const navigate = useNavigate()

  // Apply filters
  const [search] = useAtom(reportSearchAtom)
  const totals = useFuse(intervals, search, {
    threshold: 0.1,
    shouldSort: true,
    keys: ['name', 'job', 'dIn', 'dOut'],
  })

  if (totals.length <= 0) {
    return (
      <EmptyState
        title="No Intervals in Range"
        description="No intervals were found in the selected date range. Try changing the dates or add a new time by clicking 'Add Time'."
      >
        <Button
          label="Need Help?"
          color="clock-in"
          onClick={() => {
            navigate('/help')
          }}
        />
      </EmptyState>
    )
  }
  return (
    <Table
      columns={columns}
      data={totals}
      analyticsId={analyticsId}
      noHover
      initialSortBy={[{id: 'dOut', desc: true}]}
      onRowClick={() => null}
      getRowProps={(row) => {
        return {
          className: row.original.invalid ? 'invalid' : '',
        }
      }}
    />
  )
}
export default Intervals
