import React from 'react'
import {RefObject} from 'react'
import useClassnames from '../../hooks/use-classnames'
import Badge from '../badge'
import Icon from '../icon'
import {LabelErrorRow} from './common'

type CheckboxInputProps = {
  value: boolean
  name: string
  disabled?: boolean
  onFocus?: () => void
  onBlur?: () => void
}
export const CheckboxInput = React.forwardRef<
  HTMLInputElement,
  CheckboxInputProps
>(function Checkbox(props, ref) {
  const {onFocus = () => null, disabled, value, name, ...rest} = props
  const classes = useClassnames({})
  return (
    <div className="input checkbox">
      <label htmlFor={name}>
        <input
          type="checkbox"
          checked={value}
          className="checkbox-input"
          disabled={disabled}
          readOnly
          ref={ref}
        />
        <div className="checkbox-control">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-hidden="true"
            focusable="false">
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="3"
              d="M1.73 12.91l6.37 6.37L22.79 4.59"
            />
          </svg>
        </div>
      </label>
    </div>
  )
})

export default CheckboxInput
