import analytics from '@scavatec/timeclock-lib/analytics'
import {updateLegacy} from '@scavatec/timeclock-lib/api/settings'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {userAtom} from '@scavatec/timeclock-lib/state/environment'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import {capitalize, snakeToCamel} from '@scavatec/timeclock-lib/util/string'
import {useAtom} from 'jotai'
import React, {useEffect, useMemo} from 'react'
import * as yup from 'yup'
import useAPI from '../../hooks/use-api'
import Forms from '../forms'
import Setting from '../settings'
const schema = yup.object().shape({
  paid: yup.boolean().required(),
  rule: yup.string().required(),
})
type BreakFormData = yup.InferType<typeof schema>
type BreakSettingsProps = {}
export const BreakSettings = (p: BreakSettingsProps) => {
  const [currentUser] = useAtom(userAtom)
  const breakRule = useOrganizationSetting('reporting.breaks.ruleset')
  const ruleOptions = useMemo(() => {
    return Object.keys(breakRules).map((k) => {
      const label = k.split('_').map(capitalize).join(' ')
      return {
        value: k,
        label: label,
      }
    })
  }, [])
  useEffect(() => {
    analytics.event('w.e.4.0-view')
  }, [])
  return (
    <div className="page-content">
      <div className="setting-row">
        <div className="setting-col">
          <div className="setting-group">
            <h6>Options</h6>
            <Setting.Setting
              label="Breaks Are Paid"
              name="reporting.breaks.time_is_paid"
              description="Determines whether break time is subtracted from paid time when generating reports."
              onValueChanged={(v) => {
                updateLegacy(currentUser.organization, 'mealBreaksPaid', v)
                analytics.event('w.e.4.0-change-breaks-paid', {
                  breaks_paid: v,
                })
              }}
              renderInput={(props) => <Forms.Switch {...props} label="Breaks Are Paid" />}
            />
            <Setting.Setting
              label="Ruleset"
              name="reporting.breaks.ruleset"
              onValueChanged={(v) => {
                updateLegacy(currentUser.organization, 'mealBreakRule', ruleToLegacy(v))
                analytics.event('w.e.4.0-select-ruleset', {
                  ruleset: v,
                })
              }}
              renderInput={(props) => <Forms.Select options={ruleOptions} {...props} />}
            />
          </div>
        </div>
        <div className="setting-col">
          <div className="setting-group">
            <h6>Details</h6>
            <div style={{padding: '0 20px 20px 20px'}}>
              <small style={{marginBottom: 10, display: 'block'}}>
                Automatic breaks do not alter transactional data and can be toggled off and on at any time.
              </small>
              <label>Selected Rule Details</label>
              <span
                dangerouslySetInnerHTML={{
                  __html: breakRules[breakRule.value ?? 'none'] ?? breakRules['none'],
                }}
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div className="row"></div>
    </div>
  )
}

type BreakRuleDetails = {
  [s: string]: string
}
const breakRules: BreakRuleDetails = {
  none: 'Select a ruleset from the dropdown to enable breaks for your account.',
  california:
    '<ul><li>&frac12 hour after 5 hour work periods.</li><li>A second &frac12 hour break for work periods of 10 hours or more.</li></ul>',
  colorado: '<ul><li>&frac12 hour if work shift exceeds 5 consecutive hours.</li></ul>',
  connecticut:
    '<ul><li>&frac12 hour after first 2 hours and before last 2 hours for employees who work 7&frac12 consecutive hours or more.</li></ul>',
  delaware:
    '<ul><li>&frac12 hour after first 2 hours and before last 2 hours for employees who work 7&frac12 consecutive hours or more.</li></ul>',
  illinois:
    '<ul><li>At least 20 minutes, no later than 5 hours after the start of the work period, to employees who work 7&frac12 continuous hours or more.</li></ul>',
  kentucky: '<ul><li>&frac12 hour between 3rd and 5th hour of work</li></ul>',
  maine: '<ul><li>30 minutes after 6 consecutive hours.</li></ul>',
  maryland:
    '<ul><li>15 minute break for 4-6 hour shifts</li><li>30 minute break for 6-8 hour shifts</li><li>Additional 15 minute break for every 4 hours after 8 hours</li></ul>',
  nebraska: '<ul><li>&frac12 hour for each 8 hour shift</li></ul>',
  nevada: '<ul><li>&frac12 hour for an 8 hour shift</li></ul>',
  new_hampshire: '<ul><li>&frac12 hour after 5 consecutive hours</li></ul>',
  new_york:
    '<ul><li>30-minute lunch break between 11:00 a.m. and 2:00 p.m. for shifts six hours or longer that extend over that period</li><li>An additional 45-minute meal break for all shifts of more than six hours starting between 1:00 p.m. and 6:00 a.m.</li><li>An additional 20-minute meal break between 5:00 p.m. and 7:00 p.m. for workdays that extend from before 11:00 a.m. to after 7:00 p.m.</li></ul>',
  new_york_factories:
    '<ul><li>60-minute lunch break between 11:00 a.m. and 2:00 p.m.</li><li>An additional 60-minute meal break for all shifts of more than six hours starting between 1:00 p.m. and 6:00 a.m. and lasting more than six hours.</li><li>An additional 20-minute meal break between 5:00 p.m. and 7:00 p.m. for workdays that extend from before 11:00 a.m. to after 7:00 p.m.</li></ul>',
  north_dakota: '<ul><li>&frac12 hour, on each shift exceeding 5 hours.</li></ul>',
  oregon: '<ul><li>&frac12 hour for each work period of 6 to 8 hours.</li></ul>',
  rhode_island:
    '<ul><li>20 minute break within a 6 hour shift.</li><li>30 minute break within an 8 hour shift.</li></ul>',
  tennessee: '<ul><li>20 minute break for employees scheduled to work 6 consecutive hours or more.</li></ul>',
  washington: '<ul><li>&frac12 hour, if work period is more than 5 consecutive hours.</li></ul>',
  west_virginia: '<ul><li>20 minutes for employees who work 6 consecutive hours or more.</li></ul>',
  guam: '<ul><li>&frac12 hour, after 5 hour shift.</li></ul>',
  puerto_rico: '<ul><li>1 hour break after 5 hour shift.</li><li>Additional 1 hour after 10 hours.</li></ul>',
  // Legacy break rules
  // formerly hourafterfive
  legacy_one_hour_break_after_five_hour_shift: '<ul><li>1 hour for each shift of 5 hours or more.</li></ul>',
  // formerly massachusetts
  legacy_massachusetts: '<ul><li>&frac12 hour, if work is for more than 6 hours.</li></ul>',
}

const ruleToLegacy = (newRule: string) => {
  let rule = newRule
  if (rule === 'legacy_one_hour_break_after_five_hour_shift') {
    return 'HourAfterFive'
  } else if (rule === 'legacy_massachusetts') {
    return 'Massachusetts'
  }
  rule = snakeToCamel(rule)
  return rule.charAt(0).toUpperCase() + rule.slice(1)
}
