import {mdiClose} from '@mdi/js'
import analytics from '@scavatec/timeclock-lib/analytics'
import {useDeviceDeleteMutation, useDeviceListQuery} from '@scavatec/timeclock-lib/api/device'
import {RemoteDevice} from '@scavatec/timeclock-lib/api/types'
import {DataModal} from 'hooks/use-data-modal'
import React from 'react'
import Button, {MaterialIconButton} from '../button'
import {RoundedErrorCallout} from '../callout'
import Modal from './modal'

const SCREEN_ID = 'legacy-device-modal'

type LegacyDeviceModalProps = {
  modal: DataModal<RemoteDevice | null>
  onCancel?: () => void
  onDelete?: () => void
}

const LegacyDeviceModal: React.FC<LegacyDeviceModalProps> = ({modal, ...props}) => {
  const deviceQuery = useDeviceListQuery()
  const deleteMutation = useDeviceDeleteMutation({
    onMutate: () => {
      analytics.event(`${SCREEN_ID}-submit-delete`)
    },
    onSuccess: () => {
      analytics.event(`${SCREEN_ID}-delete-success`)
      props.onDelete?.()
      modal.close()
      deviceQuery.refetch()
    },
  })
  return (
    <Modal.Wrapper className="subscribe-modal" open={modal.isOpen}>
      <Modal.Header>
        <h1>Unsupported Device</h1>
        <MaterialIconButton
          path={mdiClose}
          size={1}
          onClick={() => {
            analytics.event(`${SCREEN_ID}-click-close`)
            modal.close()
          }}
        />
      </Modal.Header>
      {deleteMutation.isError && (
        <div className="callout-container">
          <RoundedErrorCallout message={deleteMutation.error + ''} />
        </div>
      )}
      <Modal.Body>
        <p>
          This device, <strong>{`${modal.data?.name ?? ''}`}</strong>, is using a version of the Time Clock application
          that is no longer supported. Please remove it from your account and install the latest version, "Toolr: Time
          Clock" from either the App Store or Google Play Store.
        </p>
        <p>
          Once deleted your device will no longer sync and any unsynced data will be lost. Legacy device support will be
          completely removed March 31st 2023.
        </p>
        <ul>
          <li>
            <a href="https://apps.apple.com/us/app/toolr-time-clock-calculator/id1186041453">
              Apple App Store - iOS 11+
            </a>
          </li>
          <li>
            <a href="https://play.google.com/store/apps/details?id=com.scavatec.TimeClock_Sync&hl=en_US&gl=US">
              Google Play Store - Android 4.4+
            </a>
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button
          mode="secondary"
          label="Cancel"
          disabled={deleteMutation.isLoading}
          onClick={() => {
            analytics.event(`${SCREEN_ID}-click-cancel`)
            modal.close()
          }}
        />
        <Button
          type="submit"
          disabled={deleteMutation.isLoading}
          loading={deleteMutation.isLoading}
          color="red"
          label="Remove Device"
          onClick={() => {
            if (!modal.data) {
              return
            }
            deleteMutation.mutate(modal.data.id)
          }}
        />
      </Modal.Footer>
    </Modal.Wrapper>
  )
}

export default LegacyDeviceModal
