import React from 'react'
import Crew from '@scavatec/timeclock-lib/database/models/crew'
import Icon from './icon'

type CrewCardProps = {
  crew: Crew
  onClick?: OnClickEvent
}
export const CrewCard: React.FC<CrewCardProps> = ({crew, onClick}) => {
  return (
    <div className="card crew-card" onClick={onClick}>
      <div className="card-header">
        <span className="name">{crew.name}</span>
      </div>
      <div className="card-body">
        <div className="crew-stats">
          <div>
            <Icon icon="users" size={32} />
            <span>{crew.employees.length}</span>
          </div>
          <div>
            <Icon icon="construction" size={32} />
            <span>{crew.jobs.length}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CrewCard
