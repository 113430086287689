import React from 'react'
import StaticMap from './maps/static-map'
import useClassnames from 'hooks/use-classnames'
import useDimensions from 'hooks/use-dimensions'
import {ClockTypeBadge, AccountTime, TransactionTypeBadge} from 'util/formatters'
import {DateTime} from 'luxon'
import {MapEmptyState} from './empty-state'
import {ReportTimeEntry} from '@scavatec/timeclock-lib/api/report'
import {TransactionType} from '@scavatec/timeclock-lib/database/models/transaction'
import {employeeName} from '@scavatec/timeclock-lib/formatters'

type TimeEntryCardProps = {
  transaction: ReportTimeEntry
  onClick?: () => void
  showName?: boolean
}
export const TimeEntryCard: React.FC<TimeEntryCardProps> = ({transaction, onClick, showName = true}) => {
  const classes = useClassnames({
    'transaction-card': true,
    // invalid: transaction.valid === '1',
    card: true,
  })
  const date = DateTime.fromFormat(transaction.dateUtc, 'yyyy-LL-dd HH:mm:ss')
  // TODO check
  const type = transaction.type === TransactionType.In ? 'in' : 'out'
  const {
    ref,
    dimensions: {width},
  } = useDimensions()
  return (
    <div className={classes} ref={ref} onClick={onClick}>
      {transaction.latitude < 200 ? (
        <StaticMap
          width={width}
          height={150}
          zoom={13}
          markers={[
            {
              lat: transaction.latitude,
              long: transaction.longitude,
              color: type === 'in' ? '0x03C03C' : '0xED4C67',
              label: type === 'in' ? 'I' : 'O',
            },
          ]}
        />
      ) : (
        <MapEmptyState />
      )}
      <div className="card-body">
        {showName && <span className="employee">{employeeName(transaction.employee)}</span>}
        <div className="row">
          <span className="date">
            <AccountTime value={transaction.dateUtc} baseFormat={DateTime.DATE_MED} />
          </span>
          <span className="job">{transaction.job?.name ?? '<none>'}</span>
        </div>
        <div className="row">
          <TransactionTypeBadge type={transaction.type} />
          <span className="time">
            <AccountTime value={transaction.dateUtc} baseFormat={DateTime.TIME_SIMPLE} />
          </span>
        </div>
      </div>
    </div>
  )
}
