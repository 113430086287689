import {mdiClose} from '@mdi/js'
import analytics from '@scavatec/timeclock-lib/analytics'
import React from 'react'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {ModalHookType} from '../../hooks/use-modal'
import Button, {MaterialIconButton} from '../button'
import Modal from './modal'

const SCREEN_ID = 'w.af.1.0'

type AdvancedFeatureModalProps = {
  modal: ModalHookType
  children: React.ReactNode | React.ReactNode[]
  actionLabel: string
  onCloseClick?: () => void
  onActionClick: () => void
}
export const AdvancedFeatureModal: React.FC<AdvancedFeatureModalProps> = ({
  modal,
  actionLabel,
  children,
  onCloseClick,
  onActionClick,
}) => {
  const navigate = useNavigate()
  useEffect(() => {
    analytics.event(`${SCREEN_ID}-view`)
  }, [])
  return (
    <Modal.Wrapper open={modal.isOpen} className="advanced-feature">
      <Modal.Header>
        {onCloseClick && (
          <MaterialIconButton
            path={mdiClose}
            size={1}
            onClick={() => {
              analytics.event(`${SCREEN_ID}-click-close`)
              onCloseClick()
            }}
          />
        )}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button
          mode="outline"
          label="More info"
          onClick={() => {
            analytics.event(`${SCREEN_ID}-click-more-info`)
            window.open(
              'https://www.mytoolr.com/post/toolr-plus-new-features-better-support-more-value',
              '_blank',
            )
          }}
        />
        <Button
          label={actionLabel}
          onClick={() => {
            analytics.event(`${SCREEN_ID}-click-action`, {action: actionLabel})
            onActionClick()
          }}
        />
      </Modal.Footer>
    </Modal.Wrapper>
  )
}
export default AdvancedFeatureModal
