import React, {ReactNode, useEffect, useState} from 'react'

type Props<T> = {
  open: boolean
  className?: string
  children: ReactNode
  onClose?: () => void
  onOpen?: () => void
}
const Modal = <T extends {}>({onClose, onOpen, className = '', open, children}: Props<T>) => {
  const [triggerClose, setTriggerClose] = useState(true)
  useEffect(() => {
    if (!open && typeof onClose === 'function' && triggerClose) {
      onClose()
      setTriggerClose(false)
    } else if (triggerClose && open) {
      setTriggerClose(true)
      onOpen?.()
    }
  }, [open, onClose, triggerClose, setTriggerClose])
  if (!open) {
    return null
  }
  return (
    <div className={`modal-container ${className}`}>
      <div className="modal">{children}</div>
    </div>
  )
}

type BodyProps = {}
const Body: React.FC<BodyProps> = ({children}) => {
  return <div className="modal-body">{children}</div>
}

type HeaderProps = {}
const Header: React.FC<HeaderProps> = ({children}) => {
  return <div className="modal-header">{children}</div>
}

type FooterProps = {}
const Footer: React.FC<FooterProps> = ({children}) => {
  return <div className="modal-footer">{children}</div>
}

export default {
  Wrapper: Modal,
  Body,
  Footer,
  Header,
}
