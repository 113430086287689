import React, {useContext, useEffect, useState} from 'react'
import {animated, config, useTransition} from 'react-spring'
import Icon from 'ui/icon'

export interface State {}
const defaultState: State = {}
type Context = {
  addItem: (item: Partial<Item>, duration: number) => void
}
export const ToastContext = React.createContext<Context | undefined>(undefined)
export const useToastContext = () => {
  const c = useContext(ToastContext)
  if (!c) {
    throw new Error('data context not in provider')
  }
  return c
}

type ProviderProps = {}
function* incrementalGenerator() {
  let n = 0
  while (true) {
    yield n
    n++
    if (n > 500) {
      n = 0
    }
  }
}
const idGen = incrementalGenerator()
const spring = {...config.default, precision: 0.1}
type Item = {
  key: number
  title: string
  message?: string
  icon?: string
  color?: string
}

type TransitionProps = {
  height: number | string
  transform: string
  opacity: number
}
const Provider: React.FC<ProviderProps> = ({children}) => {
  const [items, set] = useState<Item[]>([])
  const addItem = (item: any, duration = 2000) => {
    const key = idGen.next().value
    set((items) => [...items, {...item, key}])
    setTimeout(() => {
      set((items) => items.filter((i) => i.key !== key))
    }, duration)
  }
  const clear = (key: number) =>
    set((items) => items.filter((i) => i.key !== key))
  // useful for debugging
  // useEffect(() => {
  //   setInterval(() => {
  //     addItem({title: 'testing', icon: 'save'}, 3000)
  //   }, 1000)
  // }, [])
  const transitions = useTransition<Item, TransitionProps>(items, {
    keys: (item: any) => item.key,
    from: {height: 0, transform: 'translate3d(40px,0,0)', opacity: 0},
    enter: {height: 'auto', transform: 'translate3d(0px,0,0)', opacity: 1},
    leave: (item: Item) => async (next: Function, cancel: Function) => {
      await next({opacity: 0, transform: 'translate3d(-40px,0,0)'})
      await next({height: 0}, true)
    },
    config: spring,
  })

  const context = {
    addItem,
  }

  return (
    <ToastContext.Provider value={context}>
      {children}
      <div className="toaster">
        {transitions((props, item) => (
          <animated.div
            style={{...props, backgroundColor: item?.color ?? 'black'}}
            onClick={() => clear(item.key)}>
            {item?.icon && item.icon.length > 0 && (
              <Icon icon={item.icon} size={18} />
            )}
            <strong>{item.title}</strong>
            {item.message && item.message.length > 0 && <p>{item.message}</p>}
          </animated.div>
        ))}
      </div>
    </ToastContext.Provider>
  )
}
export default Provider
