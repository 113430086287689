import React, {useMemo} from 'react'
import {CellProps} from 'react-table'
import Table, {TableRowClick} from 'ui/table'
import {Currency, PreferredTime} from 'util/formatters'
import EmptyState from 'ui/empty-state'
import {navigate} from 'hookrouter'
import {useReportContext, transformJobTotals} from '@scavatec/timeclock-lib/contexts/report-context'
import {ColumnSizes} from 'ui/tables/common'
import {useNavigate} from 'react-router-dom'
import useFuse from 'hooks/use-fuse'
import {useAtom} from 'jotai'
import {reportSearchAtom} from './report-state'
import Button from 'ui/button'
import {useActiveReport} from '@scavatec/timeclock-lib/api/report'
import {breakColumns, jobColumns, overtimeColumns, timeColumns} from './column-definitions'
import {NONE_UUID} from '@scavatec/timeclock-lib/database/models/job'

const analyticsId = 'w.c.1.0-jobs'
type JobTableProps = {}
const JobTotals: React.FC<JobTableProps> = ({}) => {
  const navigate = useNavigate()
  const columns = useMemo(() => [...jobColumns(), ...timeColumns(), ...breakColumns(), ...overtimeColumns()], [])
  const {jobTotals} = useActiveReport()
  const [search] = useAtom(reportSearchAtom)
  const totals = useFuse(jobTotals, search, {
    threshold: 0.1,
    shouldSort: true,
    keys: ['task'],
  })
  if (totals.length <= 0) {
    return (
      <EmptyState
        title="No Totals in Range"
        description="No totals were found in the selected date range. Try changing the dates or add a new time by clicking 'Add Time'."
      >
        <Button
          label="Need Help?"
          color="clock-in"
          onClick={() => {
            navigate('/help')
          }}
        />
      </EmptyState>
    )
  }
  return (
    <Table
      columns={columns}
      data={totals}
      analyticsId={analyticsId}
      initialSortBy={[{id: 'task', desc: false}]}
      getRowProps={(row) => {
        return {
          className: row.original.isInvalid ? 'invalid' : '',
        }
      }}
      onRowClick={(r) => {
        if (!r.taskUUID || r.taskUUID.length <= 0) {
          return
        }
        navigate(`/jobs/${r.taskUUID}`)
      }}
    />
  )
}
export default JobTotals
