import React from 'react'
import {Control, RegisterOptions, useController} from 'react-hook-form'
import useClassnames from '../../hooks/use-classnames'
import {InputLabelRow, FieldError} from './common'
import PhoneNumberInput from 'react-phone-number-input'

type TextInputProps = {
  name: string
  label: string
  placeholder?: string
  control: Control<any>
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
} & React.HTMLProps<HTMLInputElement>
export const PhoneInput = ({name, control, rules, placeholder, label, style, ...rest}: TextInputProps) => {
  const {
    field,
    fieldState: {error},
  } = useController<any>({name, control, defaultValue: '', rules})
  const classes = useClassnames({
    error: error != undefined,
  })
  return (
    <div className={`form-input phone ${classes}`} style={style}>
      <InputLabelRow label={label} error={error} />
      <PhoneNumberInput
        placeholder={placeholder}
        defaultCountry="US"
        {...field}
        onChange={(v) => {
          field.onChange(v ?? '')
        }}
      />
      <FieldError error={error} label={label} />
    </div>
  )
}

export default PhoneInput
