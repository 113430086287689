import analytics, {sanitizeProperties} from '@scavatec/timeclock-lib/analytics'
import {APIValidationError} from '@scavatec/timeclock-lib/api/client'
import {attributesToForm} from '@scavatec/timeclock-lib/api/form-builder'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import Employee, {createEmployee} from '@scavatec/timeclock-lib/database/models/employee'
import useDatabase from '@scavatec/timeclock-lib/hooks/use-database'
import {CustomUserForm} from 'custom-forms'
import {employeesAtom, useEmployee, useEmployees} from '@scavatec/timeclock-lib/state/entities'
import {useCustomForm} from '@scavatec/timeclock-lib/state/form-builder'
import {useToastContext} from 'contexts/toast-context'
import React, {useCallback, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router'
import Button from 'ui/button'
import {ConfirmationButton} from 'ui/confirmation-button'
import EmptyState from 'ui/empty-state'
import * as Forms from 'ui/forms'
import EmployeeForm from 'ui/forms/employee-form'
import {SettingDivider} from 'ui/settings'
import Toolbar from 'ui/toolbar'
import {useEmployeeDeleteMutation, useEmployeeUpdateMutation} from '@scavatec/timeclock-lib/api/employee'
import {EmployeeUpdatePayload} from '@scavatec/timeclock-lib/api/types'
import {ErrorCallout} from 'ui/callout'
import {useAtom} from 'jotai'
import env from 'util/env'

const screenID = 'w.g.3.1'

type EmployeeEditProps = {
  employee: Employee
}
export const EmployeeEdit: React.FC<EmployeeEditProps> = ({employee}) => {
  const navigate = useNavigate()
  const {actions} = useReportContext()
  const {addItem} = useToastContext()
  const {database} = useDatabase()
  const form = useCustomForm('employeeUpdate', {enabled: env.features.customAttributes})
  const {control, handleSubmit, clearErrors, reset, setError, watch} = useForm<EmployeeUpdatePayload>({
    reValidateMode: 'onBlur',
    defaultValues: {
      ...employee.toForm(),
      options: {
        newPin: '',
        resetPin: false,
      },
      // customForm: employee?.toForm()?.attributes ?? {},
    },
  })
  useEffect(() => {
    if (form && employee) {
      reset({
        ...employee.toForm(),
        options: {
          newPin: '',
          resetPin: false,
        },
        relationships: {
          customForm: {
            data: attributesToForm(form, employee.attributes),
          },
        },
      })
    }
  }, [employee, form])
  useEffect(() => {
    analytics.event(`${screenID}-view`)
  }, [])
  const updateMutation = useEmployeeUpdateMutation(employee.uuid, {
    onMutate: (values) => {
      analytics.event(`${screenID}-submit-update`, sanitizeProperties(values))
    },
    onSuccess: async (res) => {
      await employee?.userUpdate(res as any)
      addItem(
        {
          title: `Updated employee ${employee?.formattedName()}`,
          icon: 'save',
        },
        4000,
      )
      navigate('/employees')
    },
    onError: (error) => {
      if (error instanceof APIValidationError) {
        Object.keys(error.errors).forEach((key) =>
          setError(`${key}` as any, {
            type: 'validation',
            message: error.errors[key],
          }),
        )
      }
    },
  })
  const update = useCallback(
    (values: EmployeeUpdatePayload) => {
      updateMutation.mutate(values)
    },
    [updateMutation, form],
  )
  const deleteMutation = useEmployeeDeleteMutation({
    onMutate: () => {
      analytics.event('w.g.3.1-submit-delete')
    },
    onSuccess: () => {
      if (!employee) {
        return
      }
      database.action(async () => {
        await employee.destroyPermanently()
      })
      addItem(
        {
          title: `Employee ${employee.formattedName()} deleted`,
          icon: 'trash',
        },
        4000,
      )
      actions.refresh()
      navigate('/employees')
    },
  })
  return (
    <div className="page employee-edit">
      <Toolbar.Bars>
        <Toolbar.Bar>
          <Toolbar.Group>
            <Button
              label="Cancel"
              mode="secondary"
              disabled={updateMutation.isLoading}
              icon="close"
              onClick={() => {
                analytics.event(`${screenID}-click-cancel`)
                navigate('/employees')
              }}
            />
          </Toolbar.Group>
          <Toolbar.Group align="right">
            <ConfirmationButton
              title="Confirm Delete"
              description="Are you sure you want to remove this employee? All transactions will be removed. You may change the employee to inactive instead to keep their data available."
              renderButton={(onClick) => {
                return (
                  <Button
                    color="danger"
                    label="Delete"
                    icon="trash"
                    disabled={updateMutation.isLoading}
                    loading={deleteMutation.isLoading}
                    onClick={onClick}
                  />
                )
              }}
              onConfirm={() => deleteMutation.mutate(employee.uuid)}
            />
            <Button
              disabled={updateMutation.isLoading}
              loading={updateMutation.isLoading}
              label="Update"
              icon={'pencil'}
              onClick={() => {
                handleSubmit(update)()
              }}
            />
          </Toolbar.Group>
        </Toolbar.Bar>
      </Toolbar.Bars>
      <div className="page-content">
        <Forms.Form className="employee-form" onSubmit={handleSubmit(update)}>
          {updateMutation.isError && <ErrorCallout error={updateMutation.error} />}
          {deleteMutation.isError && <ErrorCallout error={deleteMutation.error} />}
          <EmployeeForm control={control as any} hasExistingPin={employee.pin.length > 0} mode="update" />
          <div className="row-spacer" />
          <CustomUserForm control={control as any} prefix="relationships.customForm.data" formName="employeeUpdate">
            <SettingDivider label="Custom Properties" />
          </CustomUserForm>
        </Forms.Form>
      </div>
    </div>
  )
}

type EmployeeEditPageProps = {}
export const EmployeeEditPage: React.FC<EmployeeEditPageProps> = ({}) => {
  const {uuid} = useParams()
  const employee = useEmployee(uuid ?? '')
  useEffect(() => {
    analytics.event(`${screenID}-view`)
  }, [])
  if (!employee) {
    return (
      <div className="page employee-edit">
        <div className="page-content">
          <EmptyState
            title="Employee not found"
            description="The employee attempting to be edited could no longer be found."
          />
        </div>
      </div>
    )
  }
  return <EmployeeEdit employee={employee} />
}
export default EmployeeEditPage
