import analytics from '@scavatec/timeclock-lib/analytics'
import * as authClient from '@scavatec/timeclock-lib/api/auth'
import {APIError, setAuthToken} from '@scavatec/timeclock-lib/api/client'
import {useSubscriptionAccessQuery} from '@scavatec/timeclock-lib/api/subscription'
import {useAuthContext} from '@scavatec/timeclock-lib/contexts/auth-context'
import {currentUserAtom, userAtom} from '@scavatec/timeclock-lib/state/environment'
import {useMutation} from '@tanstack/react-query'
import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth'
import useAPI from 'hooks/use-api'
import useDataModal from 'hooks/use-data-modal'
import useForm from 'hooks/use-form'
import useModal from 'hooks/use-modal'
import {useAtom} from 'jotai'
import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {createSearchParams, useSearchParams} from 'react-router-dom'
import Button from 'ui/button'
import Callout, {ErrorCallout} from 'ui/callout'
import * as Forms from 'ui/forms'
import Logo from 'ui/logo'
import ForgotPasswordModal from 'ui/modals/forgot-password'
import ResetPasswordModal from 'ui/modals/reset-password'
import {SettingDivider} from 'ui/settings'
import firebase from 'util/firebase'
import * as yup from 'yup'
import {firebaseAtom} from './state'

type LoginValues = {
  username: string
  password: string
}
type LoginProps = {}
export const Login: React.FC<LoginProps> = ({children}) => {
  const [, setUser] = useAtom(userAtom)
  const {dispatch} = useAuthContext()
  const navigate = useNavigate()
  const [params, setSearchParams] = useSearchParams()
  const loginRequest = useAPI()
  const accessQuery = useSubscriptionAccessQuery()
  const [firebaseData, setFirebaseData] = useAtom(firebaseAtom)
  const [firebaseLoading, setFirebaseLoading] = useState(false)
  const [flash, setFlash] = useState<undefined | {message: string; type: string}>()
  const [isLegacy, setIsLegacy] = useState(false)
  const [successPath, setSuccessPath] = useState('/employees')
  useEffect(() => {
    analytics.event('w.a.1.0-view')
    const path = window.location.pathname
    if (path !== '/login') {
      setSuccessPath(path)
    }
  }, [])
  useEffect(() => {
    const email = params.get('email')
    const token = params.get('token')
    const flash = params.get('flash')
    const flashType = params.get('flash_type')
    if (flash) {
      setFlash({
        message: decodeURIComponent(flash),
        type: flashType ?? 'info',
      })
      setSearchParams({})
    }
    if (email && token) {
      resetPasswordModal.open({email, token})
      setSearchParams({})
    }
  }, [params])
  const [, setCurrentUser] = useAtom(currentUserAtom)
  const {handleSubmit, getInputProps, setValue} = useForm<LoginValues>(
    {
      username: '',
      password: '',
    },
    yup.object().shape({
      username: yup.string().required(),
      password: yup.string().required(),
    }),
  )
  useEffect(() => {
    const legacy = params.get('legacy')
    if (legacy && legacy.length > 0) {
      setIsLegacy(true)
      setValue('username')(legacy)
      analytics.event('legacy-redirect-detected', {
        org_id: legacy,
      })
    }
  }, [params])
  const processLogin = (res: authClient.LoginResponse) => {
    analytics.setUserProperties(
      {
        organization_uuid: res.organization.id,
        organization_id: res.user.username,
        email: res.user?.email ?? '',
      },
      res.organization.id,
    )
    setAuthToken(res.token)
    setCurrentUser(res.user)
    setUser({
      uuid: res.user.id,
      organization: res.organization.id,
      token: res.token,
    })
    dispatch({
      type: 'login-user',
      token: res.token,
      user: {
        email: res.user.email,
        uuid: res.user.id,
        username: res.user.username,
      },
      org: {
        name: res.organization.name,
        uuid: res.organization.id,
      },
    })
    const access = res?.accessList
    accessQuery.refetch()
    if (!access?.isTrial && !access?.isSubscribed) {
      const msg = encodeURIComponent(
        'Your account does not have an active subscription. You must subscribe to continue using Time Clock.',
      )
      navigate(`/settings?flash=${msg}`)
    } else {
      navigate(successPath, {replace: true})
    }
  }
  const onSubmit = async (values: LoginValues) => {
    analytics.event('w.a.1.0-submit-login', {
      org_id: values.username,
      password: values.password.length > 0,
    })
    try {
      const res = await loginRequest.request(authClient.login(values))
      if (res) {
        processLogin(res)
      }
    } catch (e) {}
  }
  const firebaseMutation = useMutation(authClient.loginFirebase, {
    onSuccess: (res) => {
      processLogin(res)
    },
    onError: (err) => {
      if (err instanceof APIError) {
        navigate({
          pathname: '/create',
          search: `?${createSearchParams({
            mode: 'firebase',
            flash: 'No account found with this Google Account',
            email: firebaseData.email,
            name: firebaseData.name,
          })}`,
        })
      }
    },
  })
  const attemptFirebaseLogin = useCallback(async () => {
    setFirebaseLoading(true)
    const provider = new GoogleAuthProvider()
    firebase.auth.useDeviceLanguage()
    try {
      const res = await signInWithPopup(firebase.auth, provider)
      const credential = GoogleAuthProvider.credentialFromResult(res)
      if (!credential) {
        return
      }
      const user = res.user
      const token = await user.getIdToken()
      setFirebaseData({
        name: user.displayName ?? '',
        email: user.email ?? '',
        token: token ?? '',
      })
      firebaseMutation.mutate(token)
      setFirebaseLoading(false)
    } catch (e) {
      setFirebaseLoading(false)
    }
  }, [setFirebaseLoading])
  const forgotPasswordModal = useModal()
  const resetPasswordModal = useDataModal(() => ({
    email: '',
    token: '',
  }))
  return (
    <div className="auth-container">
      <ForgotPasswordModal modal={forgotPasswordModal} />
      <ResetPasswordModal modal={resetPasswordModal} />
      <div className="login">
        <div className="">
          <Logo width={200} size="medium" forceTheme="light" />
        </div>
        {firebaseMutation.isError && <ErrorCallout error={firebaseMutation.error as any} />}
        {loginRequest.error.length > 0 && <Callout type="error" title="Login Failed" message={loginRequest.error} />}
        {flash && <Callout type={flash.type as any} message={flash.message} />}
        {isLegacy && (
          <Callout
            type="warning"
            title="Legacy Customer"
            message="Welcome! We detected you are coming from a legacy version of our application. Login using your existing username and password and please update your bookmarks."
          />
        )}
        <h1>Login</h1>
        <Forms.Form
          onSubmit={() => {
            handleSubmit(onSubmit)
          }}
        >
          <Button
            loading={firebaseLoading || firebaseMutation.isLoading}
            disabled={firebaseLoading || firebaseMutation.isLoading}
            color="blue"
            icon="google"
            className="google-auth"
            label="Use Google Account"
            onClick={attemptFirebaseLogin}
          />
          <SettingDivider label="or" />
          <Forms.TextInput
            label="Organization ID"
            placeholder="Your Organization Name"
            {...getInputProps('username')}
          />
          <Forms.TextInput label="Password" type="password" placeholder="Password" {...getInputProps('password')} />
          <Button type="submit" loading={loginRequest.loading} icon="enter" label="Login" />
        </Forms.Form>
        <div
          className="link"
          style={{marginBottom: 20}}
          onClick={() => {
            analytics.event('w.a.1.0-click-find-account-reset-password')
            forgotPasswordModal.open()
          }}
        >
          Find Account / Reset Password
        </div>
        <div
          className="link"
          onClick={() => {
            analytics.event('w.a.1.0-click-create-account')
            navigate('/create')
          }}
        >
          Don't have an account? Create one now.
        </div>
      </div>
    </div>
  )
}
export default Login
