import analytics from '@scavatec/timeclock-lib/analytics'
import {resetPassword} from '@scavatec/timeclock-lib/api/auth'
import {APIValidationError} from '@scavatec/timeclock-lib/api/client'
import {DataModal} from 'hooks/use-data-modal'
import React from 'react'
import {useMutation} from '@tanstack/react-query'
import * as yup from 'yup'
import useAPI from '../../hooks/use-api'
import useForm from '../../hooks/use-form'
import {ModalHookType} from '../../hooks/use-modal'
import Button from '../button'
import {ErrorCallout, RoundedErrorCallout} from '../callout'
import * as Forms from '../forms'
import Modal from './modal'

const schema = yup.object().shape({
  password: yup.string().required(),
})
export type FormData = yup.InferType<typeof schema>
type ResetPasswordModalProps = {
  modal: DataModal<{email: string; token: string}>
  onSave?: (values: any) => void
  onDelete?: (values: any) => void
}
export const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({modal, onSave, onDelete}) => {
  const {handleSubmit, getInputProps, setErrors} = useForm<FormData>({password: ''}, schema)
  const {loading, error, request, response} = useAPI()
  const {email = '', token = ''} = modal.data ?? {}

  const mutation = useMutation<any, any, string>(
    (password) => {
      return resetPassword(email, password, token)
    },
    {
      onError: (error) => {
        if (error instanceof APIValidationError) {
          setErrors(error.errors)
        }
      },
    },
  )

  const onSubmit = async (values: FormData) => {
    analytics.event('w.a.1.3-submit-reset-password')
    mutation.mutate(values.password)
  }

  return (
    <Modal.Wrapper className="reset-password" open={modal.isOpen}>
      <Forms.Form onSubmit={() => handleSubmit(onSubmit)}>
        <Modal.Header>
          <h1>Reset Password</h1>
        </Modal.Header>
        <div className="callout-container">
          {mutation.error && <RoundedErrorCallout error={mutation.error} omitKeys={['password']} />}
        </div>
        <Modal.Body>
          {mutation.isSuccess ? (
            <>
              <h5>
                Your password was successfully reset for all organizations associated with this email. You may now log
                in using your Organization ID and new password.
              </h5>
            </>
          ) : (
            <>
              <h5>Enter your password and upon submit, we will reset the password for all associated Organizations.</h5>
              <Forms.Row>
                <Forms.TextInput
                  label="Password"
                  placeholder="Password"
                  type="password"
                  {...getInputProps('password')}
                />
              </Forms.Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {mutation.isSuccess ? (
            <Button
              disabled={loading}
              loading={loading}
              label="Continue"
              onClick={() => {
                analytics.event('w.a.1.4-click-continue')
                modal.close()
              }}
            />
          ) : (
            <>
              <Button
                color="red"
                mode="secondary"
                label="Cancel"
                disabled={loading}
                onClick={() => {
                  analytics.event('w.a.1.3-click-cancel')
                  modal.close()
                }}
              />
              <Button type="submit" disabled={loading} loading={loading} label="Reset Password" />
            </>
          )}
        </Modal.Footer>
      </Forms.Form>
    </Modal.Wrapper>
  )
}
export default ResetPasswordModal
