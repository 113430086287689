import * as client from '@scavatec/timeclock-lib/api/settings'
import {SettingKey, SettingValue} from '@scavatec/timeclock-lib/api/types'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {Settings} from '@scavatec/timeclock-lib/database/models/setting'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import React, {useCallback, useState} from 'react'
import ActivityIndicator from './activity-indicator'

type SettingDividerProps = {
  label: string
}
export const SettingDivider: React.FC<SettingDividerProps> = ({label}) => {
  return (
    <div className="setting-divider">
      <div className="line"></div>
      <span>{label}</span>
      <div className="line"></div>
    </div>
  )
}

type SettingRenderProps = {
  value: any
  error: string
  label: string
  onValueChange: (v: any) => void
}
type SettingProps = {
  label: string
  name: SettingKey
  parse?: (v: any) => any
  encode?: (v: any) => any
  onValueChanged?: (v: any) => void
  description?: string
  renderInput: (props: SettingRenderProps) => React.ReactNode
}
export const Setting: React.FC<SettingProps> = ({
  label,
  name,
  parse,
  encode,
  description = '',
  onValueChanged,
  renderInput,
}) => {
  const setting = useOrganizationSetting(name)
  const update = useCallback(
    (v) => {
      const value = encode ? encode(v) : v
      onValueChanged && onValueChanged(value)
      setting.update(value)
    },
    [onValueChanged, parse, setting.updateQuery],
  )
  return (
    <div className="setting">
      {setting.updateQuery.isLoading && <ActivityIndicator />}
      {renderInput({
        value: parse ? parse(setting.value) : setting.value,
        label,
        error: '',
        onValueChange: (d: SettingValue) => update(d),
      })}
      {description.length > 0 && <span className="description">{description ?? setting.definition.description}</span>}
    </div>
  )
}
export default {
  Divider: SettingDivider,
  Setting,
}
