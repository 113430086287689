import React, { useEffect, useState } from 'react'
import { useReportContext } from '@scavatec/timeclock-lib/contexts/report-context'
import {
  extendTrialStatus,
  requestExtension,
  useExtendTrialMutation,
  useSubscriptionAccessQuery,
  useSubscriptionStatusQuery,
  useTrialExtensionStatusQuery,
} from '@scavatec/timeclock-lib/api/subscription'
import analytics from '@scavatec/timeclock-lib/analytics'
import Button from './button'
import useRequest from '@scavatec/timeclock-lib/hooks/use-request'
import { useDataContext } from '@scavatec/timeclock-lib/contexts/data-context'
import {
} from '@scavatec/timeclock-lib/state/subscription'
import { useQueryClient } from '@tanstack/react-query'

const SCREEN_ID = 'w.e.2.0'

export const TrialExtension = () => {
  const [duration, setDuration] = useState(-1)
  const { actions } = useReportContext()
  const subscriptionQuery = useSubscriptionStatusQuery()
  const accessQuery = useSubscriptionAccessQuery()
  const trialStatusQuery = useTrialExtensionStatusQuery({
    onSuccess: (res) => {
      if (res?.isAvailableToExtend) {
        setDuration(res?.trialExtensionDuration ?? 1)
        analytics.event(`${SCREEN_ID}-show-extend-trial`)
      } else {
        setDuration(-1)
      }
    }
  })
  const extendMutation = useExtendTrialMutation({
    onSuccess: (data) => {
      subscriptionQuery.refetch()
      accessQuery.refetch()
      actions.refresh()
      refresh()
    }
  })
  const request = useRequest()
  const refresh = async () => {
    trialStatusQuery.refetch()
  }

  useEffect(() => {
    refresh()
  }, [])
  if (duration <= 0) {
    return null
  }
  return (
    <div className="trial-extension">
      <span>
        If you would like more time to explore our app, you’re welcome to Extend
        Your Trial one time.
      </span>
      <span>{`This will extend your trial by ${duration} days.`}</span>
      <Button
        label="Extend Trial"
        color="green"
        loading={request.loading}
        onClick={async () => {
          analytics.event(`${SCREEN_ID}-submit-extend-trial`)
          extendMutation.mutate(undefined)
          try {
          } catch (e) { }
        }}
      />
    </div>
  )
}

export default TrialExtension
