import React, {useEffect, useState} from 'react'
import {hhmmFromSeconds, hhmmToSeconds} from 'util/formatters'
import {FormError} from './common'
import TimePicker from 'rc-time-picker'
import moment from 'moment'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'

type TimeInputDropdownProps = {
  label: string
  value: string
  error: string
  placeholder?: string
  minimal?: boolean
  onValueChange?: (v: string) => void
  customFormat?: (v: number) => number
  onBlur?: () => void
  type?: 'text' | 'password' | 'email' | 'tel'
}
export const TimeInput: React.FC<TimeInputDropdownProps> = ({
  error,
  onValueChange = () => null,
  children,
  customFormat,
  minimal = false,
  value = '',
  label,
  ...rest
}) => {
  const clockType = useOrganizationSetting('account.clock_type')
  return (
    <div className={`form-input text ${minimal ? 'minimal' : ''}`}>
      {error !== undefined && <FormError error={error} />}
      <label>{label}</label>
      <TimePicker
        value={moment(value)}
        use12Hours={clockType.value === '12_hour'}
        allowEmpty={false}
        showSecond={false}
        onChange={(v) => {
          if (v) {
            onValueChange(v.format())
          }
        }}
      />
    </div>
  )
}

// <input
//   type="time"
//   value={hhmmFromSeconds(value)}
//   onChange={(e) => {
//     onValueChange(hhmmToSeconds(e.currentTarget.value))
//   }}
//   {...rest}
// />

const maskBlocks = {
  HH: {
    mask: '0[0]',
    placeholderChar: 'H',
  },
  D: {
    mask: '0[0][0]',
  },
  P: {
    mask: '00',
  },
  MM: {
    mask: '#0',
    placeholderChar: 'M',
    definitions: {
      '#': /[0-5]/,
    },
  },
}
type TimeInputTextProps = {
  label: string
  value: number
  error: string
  onValueChange: (v: number) => void
}
export const TimeInputText = ({value, label, error, onValueChange}: TimeInputTextProps) => {
  const [stringValue, setStringValue] = useState(hhmmFromSeconds(value))
  useEffect(() => {
    setStringValue(hhmmFromSeconds(value))
  }, [value])
  return (
    <div className={`form-input text duration`}>
      <div className="label-row">
        {error !== undefined && <FormError error={error} />}
        <label>{label}</label>
      </div>
      <div className="time-inputs">
        <input
          placeholder="HH:MM"
          onBlur={(e) => {
            const v = e.currentTarget.value
            onValueChange(hhmmToSeconds(v.length > 0 ? v : '0:00'))
          }}
          onChange={(e) => {
            const v = e.currentTarget.value
            setStringValue(v)
          }}
          value={stringValue}
        />
      </div>
    </div>
  )
}
