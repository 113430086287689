import React, {useMemo} from 'react'
import {CellProps} from 'react-table'
import Table, {TableRowClick} from 'ui/table'
import {Currency, PreferredTime} from 'util/formatters'
import EmptyState from 'ui/empty-state'
import {navigate} from 'hookrouter'
import {useReportContext, transformJobTotals} from '@scavatec/timeclock-lib/contexts/report-context'
import {ColumnSizes} from 'ui/tables/common'
import {useNavigate} from 'react-router-dom'
import useFuse from 'hooks/use-fuse'
import {useAtom} from 'jotai'
import {reportSearchAtom} from '../reports/report-state'
import Button from 'ui/button'

const analyticsId = 'w.c.1.0-jobs'
type JobTableProps = {}
const JobTotals: React.FC<JobTableProps> = ({}) => {
  const navigate = useNavigate()
  const columns = useMemo(
    () => [
      {
        Header: 'Job / Acct',
        accessor: 'task',
        width: ColumnSizes.name,
      },
      {
        Header: 'Time',
        accessor: 'time',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Paid Time',
        accessor: 'paidTime',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Regular Time',
        accessor: (row: any) => row.paidTime - row.overtime,
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time + 20,
      },
      {
        Header: 'Overtime',
        accessor: 'overtime',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Breaks',
        accessor: 'breakTime',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Pay',
        accessor: 'pay',
        Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value} />,
        width: ColumnSizes.currency,
      },
      {
        Header: 'Tips',
        accessor: 'tips',
        Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value} />,
        width: ColumnSizes.currency,
      },
    ],
    [],
  )
  const {rawReport} = useReportContext()
  const items = useMemo(() => {
    if (rawReport) {
      return transformJobTotals(rawReport.dateRangeTotals.jobs)
    }
    return []
  }, [rawReport])
  const [search] = useAtom(reportSearchAtom)
  const totals = useFuse(items, search, {
    threshold: 0.1,
    shouldSort: true,
    keys: ['task'],
  })
  if (totals.length <= 0) {
    return (
      <EmptyState
        title="No Totals in Range"
        description="No totals were found in the selected date range. Try changing the dates or add a new time by clicking 'Add Time'."
      >
        <Button
          label="Need Help?"
          color="clock-in"
          onClick={() => {
            navigate('/help')
          }}
        />
      </EmptyState>
    )
  }
  return (
    <Table
      columns={columns}
      data={totals}
      analyticsId={analyticsId}
      initialSortBy={[{id: 'task', desc: false}]}
      getRowProps={(row) => {
        return {
          className: row.original.isInvalid ? 'invalid' : '',
        }
      }}
      onRowClick={(r) => {
        if (!r.taskUUID || r.taskUUID.length <= 0) {
          return
        }
        navigate(`/jobs/${r.taskUUID}`)
      }}
    />
  )
}
export default JobTotals
