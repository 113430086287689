import {mdiTrashCanOutline} from '@mdi/js'
import analytics from '@scavatec/timeclock-lib/analytics'
import * as api from '@scavatec/timeclock-lib/api/conversations'
import {ConversationMessage} from '@scavatec/timeclock-lib/api/types'
import {useAuthContext} from '@scavatec/timeclock-lib/contexts/auth-context'
import {useEmployee} from '@scavatec/timeclock-lib/state/entities'
import {environmentAtom} from '@scavatec/timeclock-lib/state/environment'
import {useAtom} from 'jotai'
import {DateTime} from 'luxon'
import React, {useEffect, useRef, useState} from 'react'
import {useQueryClient} from '@tanstack/react-query'
import {Spinner} from 'ui/activity-indicator'
import AlertDialog from 'ui/alert-dialog'
import Avatar from 'ui/avatar'
import {MaterialIcon} from 'ui/icon'

type MessageProps = {
  message: ConversationMessage
  employeeUUID: string
  refetch: () => void
}
const Message = ({message, employeeUUID, refetch}: MessageProps) => {
  const {
    state: {user},
  } = useAuthContext()
  const [showDelete, setShowDelete] = useState(false)
  const deleteMutation = api.useDeleteMessageMutation()
  return (
    <div className="message">
      <AlertDialog
        title="Confirm Delete"
        analyticsId="w.n.1.1"
        message={`Are you sure you want to delete the message:`}
        open={showDelete}
        loading={deleteMutation.isLoading}
        onDismiss={() => setShowDelete(false)}
        onConfirm={async () => {
          await deleteMutation.mutateAsync({
            conversationId: message.conversationId,
            messageId: message.id,
          })
          setShowDelete(false)
          refetch()
        }}
      >
        <pre>{message.text}</pre>
      </AlertDialog>
      <div className="header">
        <Avatar text={user.username} />
        <span className="from">{user.username}</span>

        <span className="timestamp">{DateTime.fromISO(message.createdAt).toLocaleString(DateTime.DATETIME_SHORT)}</span>
        <div className="actions">
          <span className={`status ${message.status}`}>{message.status ?? 'Unseen'}</span>
          <div
            className="delete-button"
            onClick={() => {
              analytics.event('w.n.1.0-click-delete')
              setShowDelete(true)
            }}
          >
            <MaterialIcon path={mdiTrashCanOutline} size={0.7} />
          </div>
        </div>
      </div>
      <pre className="text">{message.text}</pre>
    </div>
  )
}

type ConversationMessagesProps = {
  employeeUUID: string
}
export const ConversationMessages = ({employeeUUID}: ConversationMessagesProps) => {
  const [env] = useAtom(environmentAtom)
  const {data: messages = [], isLoading, isFetching, refetch} = api.useEmployeeMessagesQuery(employeeUUID, {
    staleTime: 60000,
  })
  const {
    state: {user},
  } = useAuthContext()
  const ref = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  })
  const employee = useEmployee(employeeUUID)
  if (!employee) {
    return null
  }
  return (
    <div className="message-container" ref={ref}>
      <div className="messages">
        {isLoading && <Spinner className="floating" />}
        {!isLoading && !messages.length && (
          <span className="info-message">
            This is the very beginning of your direct message history with{' '}
            <span className="employee-name">{employee.formattedName()}</span> use the form below to send your first
            message!
          </span>
        )}
        {messages.map((m) => (
          <Message key={m.id} message={m} employeeUUID={employeeUUID} refetch={refetch} />
        ))}
        {!isLoading && isFetching && <Spinner contained className="bottom-center small" />}
      </div>
    </div>
  )
}
