import React from 'react'
import Badge from './badge'
import Job, {JobDoc} from '@scavatec/timeclock-lib/database/models/job'
import {EntityStatusBadge} from 'util/formatters'

type JobCardProps = {
  job: Job | JobDoc
  onClick?: OnClickEvent
}
export const JobCard: React.FC<JobCardProps> = ({job, onClick}) => {
  return (
    <div className={`card job-card ${!job.isActive ? 'inactive' : ''}`} onClick={onClick}>
      <div className="card-header">
        <div className="row">
          <span className="name">{job.name}</span>
          <span className="identifier">
            <span className="label">ID</span>
            {job.identifier}
          </span>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <EntityStatusBadge isActive={job.isActive} />
        </div>
      </div>
    </div>
  )
}
export default JobCard
