import React from 'react'
import {Employee, EmployeeDoc} from '@scavatec/timeclock-lib/database/models/employee'
import {ClockType as TimeEntryClockType} from '@scavatec/timeclock-lib/database/models/common'
import Badge from 'ui/badge'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {TransactionType} from '@scavatec/timeclock-lib/database/models/transaction'
import {DateTime} from 'luxon'
import {ClockType, HoursFormat, RemoteEmployee} from '@scavatec/timeclock-lib/api/types'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import {currencyMap} from '@scavatec/timeclock-lib/formatters'

type NameFormat = 'last-first' | 'first-last'
export const employeeName = (e: Employee | EmployeeDoc | RemoteEmployee, format = 'last-first') => {
  const delim = ', '
  const showDelim = e.lastName.length && e.firstName.length
  let res = e.lastName + (showDelim ? delim : '') + e.firstName
  return res
}

type StringCurrencyProps = {
  value: string
  suffix?: string
  forceCurrency?: string
}
export const StringCurrency: React.FC<CurrencyProps> = ({value, suffix = '', forceCurrency}) => {
  const currencySetting = useOrganizationSetting('account.currency')
  const currency = forceCurrency ? forceCurrency : currencySetting.value.toUpperCase()
  const symbol = currencyMap[currency] ?? '$'
  return <span>{`${symbol}${value}${suffix}`}</span>
}

type CurrencyProps = {
  value: number
  suffix?: string
  forceCurrency?: string
}
export const Currency: React.FC<CurrencyProps> = ({value, suffix = '', forceCurrency}) => {
  const currencySetting = useOrganizationSetting('account.currency')
  const currency = forceCurrency ? forceCurrency : currencySetting.value.toUpperCase()
  const symbol = currencyMap[currency] ?? '$'
  return <span>{`${symbol}${value.toFixed(2)}${suffix}`}</span>
}
type IntegerCurrencyProps = {} & CurrencyProps
export const IntegerCurrency: React.FC<IntegerCurrencyProps> = ({value, ...props}) => {
  return <Currency {...props} value={value / 100} />
}

type AccountTimeProps = {
  value: string | null
  baseFormat?: any
}
export const AccountTime: React.FC<AccountTimeProps> = ({value, baseFormat = DateTime.DATETIME_MED}) => {
  const timezone = useOrganizationSetting('account.timezone')
  const clockType = useOrganizationSetting('account.clock_type')
  const zone = timezone.value.length ? timezone.value : 'America/Los_Angeles'
  if (value === null) {
    return <span></span>
  }
  const dt = DateTime.fromISO(value)
  return (
    <span>
      {dt
        .toLocal()
        .setZone(zone)
        .toLocaleString({
          ...baseFormat,
          hourCycle: clockType.value === ClockType.HOUR_24 ? 'h23' : 'h12',
          // hour12: settings.clockType !== '24-hour',
        })}
    </span>
  )
}

type ClockTypeProps = {
  type: TimeEntryClockType
}
export const ClockTypeString: React.FC<ClockTypeProps> = ({type}) => {
  return <span>{type === TimeEntryClockType.In ? 'In' : 'Out'}</span>
}
type ClockTypeBadgeProps = {
  type: TimeEntryClockType
}
export const ClockTypeBadge: React.FC<ClockTypeBadgeProps> = ({type}) => {
  return (
    <Badge
      outline
      label={type === TimeEntryClockType.In ? 'In' : 'Out'}
      type={type === TimeEntryClockType.In ? 'clock-in' : 'clock-out'}
    />
  )
}

type TransactionTypeBadgeProps = {
  type: TransactionType | TimeEntryClockType | string
}
export const TransactionTypeBadge: React.FC<TransactionTypeBadgeProps> = ({type}) => {
  return (
    <Badge
      outline
      label={type === TransactionType.In || type === TimeEntryClockType.In ? 'In' : 'Out'}
      type={type === TransactionType.In || type === TimeEntryClockType.In ? 'clock-in' : 'clock-out'}
    />
  )
}

type EntityStatusBadgeProps = {
  isActive: boolean
}
export const EntityStatusBadge: React.FC<EntityStatusBadgeProps> = ({isActive}) => {
  return <Badge outline label={isActive ? 'Active' : 'Inactive'} type={isActive ? 'active' : 'inactive'} />
}

type PlatformBadgeProps = {
  platform: 'android' | 'ios' | string
}
export const PlatformBadge: React.FC<PlatformBadgeProps> = ({platform}) => {
  return (
    <Badge
      outline
      label={platform.toLowerCase() === 'ios' ? 'Apple' : 'Android'}
      type={platform.toLowerCase() === 'ios' ? 'apple' : 'android'}
    />
  )
}

type PreferredTimeProps = {
  seconds: number
}
export const PreferredTime: React.FC<PreferredTimeProps> = ({seconds}) => {
  const hoursFormat = useOrganizationSetting('account.hours_format')
  return <span>{hoursFormat.value === HoursFormat.HH_MM ? hhmmFromSeconds(seconds) : decimalFromSeconds(seconds)}</span>
}

export const timeComponentsToSeconds = (hours: number, minutes: number, seconds: number = 0) =>
  hours * 3600 + minutes * 60 + seconds
export function timeComponentsFromSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.ceil((seconds % 3600) / 60)
  return {
    hours,
    minutes,
  }
}
export function hhmmFromSeconds(seconds: number): string {
  if (seconds <= 0) {
    return '0:00'
  }
  const {minutes, hours} = timeComponentsFromSeconds(seconds)
  let minStr = minutes.toString()
  if (minStr.length === 1) {
    minStr = '0' + minutes
  }
  return hours + ':' + minStr
}

export function hhmmToSeconds(str: string): number {
  const split = str.split(':')
  switch (split.length) {
    case 1: {
      const hours = parseInt(split[0], 10)
      if (!isNaN(hours)) {
        return hours * 60 * 60
      }
      break
    }
    case 2: {
      const hours = parseInt(split[0], 10)
      const minutes = parseInt(split[1].length === 1 ? split[1] + '0' : split[1], 10)
      if (!isNaN(hours) && !isNaN(minutes)) {
        return hours * 60 * 60 + minutes * 60
      }
      break
    }
    default:
      return NaN
  }
  return NaN
}

// Convert seconds to decimal string with a fixed length of 2.
// This is done so that the decimal totals summed match for reports
export function decimalFromSeconds(seconds: number): string {
  if (seconds <= 0) {
    return '0.00'
  }
  return (seconds / 3600).toFixed(2)
}

export const secondsAsHours = (seconds: number) => {
  return parseFloat(decimalFromSeconds(seconds))
}

export const decimalToSeconds = (decimal: number) => decimal * 60 * 60
