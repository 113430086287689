import analytics from '@scavatec/timeclock-lib/analytics'
import {useDeviceListQuery} from '@scavatec/timeclock-lib/api/device'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import React from 'react'
import {useNavigate} from 'react-router'
import {FilterSet} from '../../hooks/use-filter-set'
import {ButtonGroup} from '../button'
import {TextInput} from '../forms'
import Toolbar from '../toolbar'

export type DeviceListFilters = {
  view: 'cards' | 'list' | string
  showInactive: boolean
  search: string
}
type DeviceListToolbarProps = {
  filterSet: FilterSet<DeviceListFilters>
}
export const DeviceListToolbar: React.FC<DeviceListToolbarProps> = ({filterSet}) => {
  const navigate = useNavigate()
  const preferredView = useOrganizationSetting('account.preferred_view')
  const deviceListQuery = useDeviceListQuery()
  return (
    <div className="toolbars">
      <Toolbar.Bar>
        <Toolbar.Group>
          <TextInput
            label="Search"
            placeholder="Search"
            onFocus={() => analytics.event('w.i.1.0-focus-search')}
            {...filterSet.getInputProps('search')}
            minimal
          />
        </Toolbar.Group>
        <Toolbar.Group align="right">
          <Toolbar.Button
            icon="reload"
            label="Refresh"
            color="blue"
            mode="secondary"
            loading={deviceListQuery.isFetching}
            onClick={() => {
              analytics.event('w.i.1.0-submit-refresh')
              deviceListQuery.refetch()
            }}
          />
          <Toolbar.Button
            icon="plus"
            label="Add Device"
            onClick={() => {
              analytics.event('w.i.1.0-click-add-device')
              navigate('/devices/new')
            }}
          />
        </Toolbar.Group>
      </Toolbar.Bar>
      <Toolbar.Bar small>
        <Toolbar.Group></Toolbar.Group>
        <Toolbar.Group></Toolbar.Group>
        <Toolbar.Group align="right">
          <Toolbar.Item label="View">
            <ButtonGroup
              color="blue"
              value={preferredView.value}
              error=""
              options={[
                {label: 'Cards', icon: 'grid-alt', value: 'cards'},
                {label: 'List', icon: 'list', value: 'list'},
              ]}
              onValueChange={(v) => {
                analytics.event('w.i.1.0-change-view', {
                  view: v,
                })
                preferredView.update(v)
              }}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar.Bar>
    </div>
  )
}
export default DeviceListToolbar
