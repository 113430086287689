import analytics from '@scavatec/timeclock-lib/analytics'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import {useAtom} from 'jotai'
import React, {useCallback} from 'react'
import {useNavigate, useParams} from 'react-router'
import TimeEntriesTable from 'ui/tables/time-entries-table'
import TimeEntriesTableLegacy from 'ui/tables/time-entries-table-legacy'
import {employeeListViewAtom, employeeSearchAtom} from './employee-state'

const screenID = 'w.g.2.0-daily-totals'

type EmployeeTimeEntriesProps = {}
const EmployeeTimeEntries: React.FC<EmployeeTimeEntriesProps> = ({}) => {
  const {uuid = ''} = useParams()
  const [search] = useAtom(employeeSearchAtom)
  const [view] = useAtom(employeeListViewAtom)
  const navigate = useNavigate()
  const onClick = useCallback((t: Transaction) => {
    analytics.event(`${screenID}-click-tile`)
    navigate(`/transactions/${t.transactionUUID}`)
  }, [])
  const reportEngineSetting = useOrganizationSetting('reporting.engine_version')
  return reportEngineSetting.value < 2 ? (
    <TimeEntriesTableLegacy
      search={search}
      view={view}
      employee={uuid}
      showJob
      hideColumns={['employee']}
      analyticsId={screenID}
      onRowClick={onClick}
    />
  ) : (
    <TimeEntriesTable
      search={search}
      view={view}
      employee={uuid}
      showJob
      hideColumns={['employee']}
      analyticsId={screenID}
      onRowClick={onClick}
    />
  )
}
export default EmployeeTimeEntries
