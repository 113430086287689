import React from 'react'
import logoUrl from '../img/toolr_time_clock_logo_noslug_white_v1.svg'
import logoDarkUrl from '../img/toolr_time_clock_logo_noslug_dark_v1.svg'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'

type LogoProps = {
  width: string | number
  dark?: boolean
  forceTheme?: 'auto' | 'light' | 'dark'
  size?: 'small' | 'medium'
}
export const Logo: React.FC<LogoProps> = ({width, size = 'small', dark, forceTheme = 'auto'}) => {
  const themeSetting = useOrganizationSetting('account.theme')
  return (
    <div className={`logo ${size}`}>
      <img
        alt="time clock logo"
        className="logo"
        src={themeSetting.value !== 'dark' ? logoUrl : logoDarkUrl}
        width={width}
      />
    </div>
  )
}
export default Logo
