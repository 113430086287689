import React, { useMemo } from 'react'
import Table, { TableRowClick } from '../table'
import Crew from '@scavatec/timeclock-lib/database/models/crew'
import { ColumnSizes } from './common'

type CrewTableProps = {
  data: Crew[]
  onRowClick: TableRowClick
  analyticsId: string
}
const CrewTable: React.FC<CrewTableProps> = ({ onRowClick, analyticsId, data }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: ColumnSizes.name
      },
      {
        Header: 'Employees',
        accessor: (document: Crew) => document.employees.length,
        width: 100
      },
      {
        Header: 'Jobs',
        accessor: (document: Crew) => document.jobs.length,
        width: 100
      },
    ],
    [],
  )
  return <Table
    columns={columns}
    data={data}
    analyticsId={analyticsId}
    initialSortBy={[{id: 'name', desc: false}]}
    onRowClick={onRowClick} />
}
export default CrewTable
