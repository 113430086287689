import React from 'react'
import Toolbar from '../toolbar'
import {navigate} from 'hookrouter'
import PayPeriodPicker from '../pay-period'
import {Currency, PreferredTime} from 'util/formatters'
import {useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import {TimezonePicker} from '../pickers/timezone-picker'
import {ButtonGroup} from '../button'
import {FilterSet} from '../../hooks/use-filter-set'
import {EmployeesReportFilters} from './report-toolbar'
import analytics from '@scavatec/timeclock-lib/analytics'
import {useNavigate} from 'react-router'

type ReportDetailsToolbarProps = {
  pageId?: string
  title: string
  uuid: string
  type: 'Employee' | 'Job'
  typeLabel: 'Person' | 'Job'
  setView?: (v: string) => void
  total: Partial<EmployeeTotal> | any
  transactionParams?: {[s: string]: any}
  filterSet: FilterSet<EmployeesReportFilters>
}
export const ReportDetailsToolbar: React.FC<ReportDetailsToolbarProps> = ({
  title,
  uuid,
  type,
  typeLabel,
  setView,
  transactionParams = {},
  filterSet,
  pageId = '',
  total = {
    time: 0,
    paidTime: 0,
    breakTime: 0,
    overtime: 0,
    pay: 0,
    tips: 0,
  },
}) => {
  const {actions, loading} = useReportContext()
  const navigate = useNavigate()
  return (
    <div className="toolbars">
      <Toolbar.Bar>
        <Toolbar.Group>
          <Toolbar.Item label={typeLabel}>
            <h1>{title}</h1>
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group align="right">
          <Toolbar.Button
            icon="reload"
            label="Refresh"
            mode="secondary"
            color="blue"
            loading={loading}
            onClick={() => {
              analytics.event(`${pageId}-submit-refresh`)
              actions.refresh()
            }}
          />
          <Toolbar.Button
            label={`Edit ${typeLabel}`}
            icon="pencil"
            mode="secondary"
            onClick={() => {
              analytics.event(`${pageId}-click-edit`)
              navigate(`/${type.toLowerCase()}s/${uuid}/edit`)
            }}
          />
          <Toolbar.Button
            icon="plus"
            label="Add Time"
            onClick={() => {
              analytics.event(`${pageId}-click-add-time`)
              navigate('/transactions/new', {state: transactionParams})
            }}
          />
        </Toolbar.Group>
      </Toolbar.Bar>
      <Toolbar.Divider type="horizontal"></Toolbar.Divider>
      <Toolbar.Bar small>
        <Toolbar.Group>
          <Toolbar.Item label="Gross Pay">
            <h1>
              <Currency value={total.pay} />
            </h1>
          </Toolbar.Item>
          <Toolbar.Divider type="vertical"></Toolbar.Divider>
          <Toolbar.Item label="Time">
            <h4>
              <PreferredTime seconds={total.time} />
            </h4>
          </Toolbar.Item>
          <Toolbar.Item label="Breaks">
            <h4>
              <PreferredTime seconds={total.breakTime} />
            </h4>
          </Toolbar.Item>
          <Toolbar.Item label="Overtime">
            <h4>
              <PreferredTime seconds={total.overtime} />
            </h4>
          </Toolbar.Item>
          <Toolbar.Item label="Tips">
            <h4>
              <Currency value={total.tips} />
            </h4>
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group></Toolbar.Group>
        <Toolbar.Group align="right">
          <Toolbar.Item label="Report Timezone">
            <TimezonePicker onConfirm={() => null} />
          </Toolbar.Item>
          <Toolbar.Item label="View">
            <ButtonGroup
              color="blue"
              {...filterSet.getInputProps('view')}
              options={[
                {label: 'Cards', icon: 'grid-alt', value: 'cards'},
                {label: 'List', icon: 'list', value: 'list'},
              ]}
              onValueChange={(v) => {
                analytics.event(`${pageId}-change-view`, {
                  view: v,
                })
                setView && setView(v)
                filterSet.setValue('view')(v)
              }}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar.Bar>
    </div>
  )
}
export default ReportDetailsToolbar
