import React, {useMemo, useCallback, useState, useEffect, useLayoutEffect, useRef} from 'react'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import Callout from '../callout'
import useDimensions from '../../hooks/use-dimensions'
import mapgl, {LngLatLike} from 'maplibre-gl'
import {mdiMarkerCancel} from '@mdi/js'
import {path} from 'rambda'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'

type MapMarker = {
  lat: number
  long: number
  color: string
  label: string
}
type InteractiveMapProps = {
  markers?: MapMarker[]
  zoom?: number
}

const MAPTILER_ACCESS_TOKEN = 'FlqYsFeGT8o2h3Rg8Ldb'

const mapStyles = {
  light: `https://api.maptiler.com/maps/streets/style.json?key=${MAPTILER_ACCESS_TOKEN}`,
  dark: `https://api.maptiler.com/maps/fb426cd2-049d-4a88-9a92-9e8bd6558acc/style.json?key=${MAPTILER_ACCESS_TOKEN}`,
}

export const InteractiveMap: React.FC<InteractiveMapProps> = ({zoom = 12, markers = []}) => {
  const {
    ref,
    dimensions: {width},
  } = useDimensions()
  const theme = useOrganizationSetting('account.theme')
  const mapRef = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState(null)
  const center: LngLatLike = useMemo(() => {
    const center = markers.reduce(
      (center, marker) => {
        return [center[0] + marker.lat, center[1] + marker.long]
      },
      [0, 0],
    )
    return [center[1] / markers.length, center[0] / markers.length]
  }, [markers])
  useEffect(() => {
    // Initialize our map
    if (!mapRef.current) {
      return
    }
    const m = new mapgl.Map({
      container: mapRef.current,
      style: path(theme.value, mapStyles) ?? mapStyles.light,
      center,
      zoom: 12, // starting zoom
    })
    m.on('load', () => {
      // m.resize()
    })
    const nav = new mapgl.NavigationControl()
    m.addControl(nav)
    for (const mark of markers) {
      const popup = new mapgl.Popup({
        closeButton: false,
      })
        .setText(mark.label)
        .addTo(m)
      const marker = new mapgl.Marker({
        color: mark.color,
      })
        .setLngLat([mark.long, mark.lat])
        .addTo(m)
        .setPopup(popup)
      // m.resize()
    }
  }, [mapRef])
  if (markers.length == 0) {
    return <Callout type="error" message="No transactions available for display" title="Unable to load map" />
  }
  return (
    <div className="map-container">
      {/* <div className="map interactive" ref={ref}> */}
      <div id="map" ref={mapRef} style={{width: '100%', height: 600}}></div>
      {/* </div> */}
      <div className="instructions">
        <span>
          <strong>Left Click</strong> - Pan
        </span>
        <span>
          <strong>Right Click</strong> - Rotate
        </span>
        <span>
          <strong>Scroll Wheel</strong> - Zoom
        </span>
      </div>
    </div>
  )
}
export default InteractiveMap
