import React from 'react'
import {Control, RegisterOptions, useController} from 'react-hook-form'
import useClassnames from '../../hooks/use-classnames'
import {FieldError, InputLabelRow} from './common'

type SwitchInputProps = {
  name: string
  label: string
  control: Control<any>
  disabledMessage?: string
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  disabled?: boolean
} & React.HTMLProps<HTMLInputElement>
export const SwitchInput = ({
  name,
  control,
  disabled,
  rules,
  label,
  style,
  disabledMessage = '',
  ...rest
}: SwitchInputProps) => {
  const {
    field,
    fieldState: {error},
  } = useController<any>({name, control, defaultValue: false, rules})
  const classes = useClassnames({
    error: error != undefined,
  })
  return (
    <div className="form-input switch">
      <InputLabelRow label={label} error={error} />
      <div className="switch-container" style={{marginTop: 10}}>
        <input
          id={name}
          type="checkbox"
          className="switch-input"
          disabled={disabled}
          {...rest}
          {...field}
          checked={field.value}
        />
        <label htmlFor={name}></label>
      </div>
      <FieldError error={error} label={label} />
      {disabled && disabledMessage.length > 0 && <span>{disabledMessage}</span>}
    </div>
  )
}

export default SwitchInput
