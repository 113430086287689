import {CustomForm, FormBlock, FormBlockProps, useCustomFormsQuery} from '@scavatec/timeclock-lib/api/form-builder'
import {useCustomForm} from '@scavatec/timeclock-lib/state/form-builder'
import React, {useEffect, useMemo} from 'react'
import {Control, useFieldArray, useForm} from 'react-hook-form'
import SelectInput from 'ui2/inputs/select-input'
import SwitchInput from 'ui2/inputs/switch-input'
import HiddenInput from 'ui2/inputs/hidden-input'
import TextInput from 'ui2/inputs/text-input'
import env from 'util/env'
const mapBlock = (index: number, block: FormBlock, control: Control<any>, prefix: string) => {
  switch (block.blockType) {
    case 'markdown':
      const mdProps = block.props as FormBlockProps['markdown']
      return null
    // <Markdown
    //   style={{
    //     body: {
    //       fontFamily: 'Lato-Regular',
    //       fontStyle: 'normal',
    //       fontWeight: '400',
    //     },
    //   }}>
    //   {mdProps.content}
    // </Markdown>
    case 'field':
      const props = block.props as FormBlockProps['field']
      const name = `${prefix}.value`
      const common = {
        name,
        control,
        label: props.label,
      }
      switch (props.type) {
        case 'text_input':
          return <TextInput {...common} />
        case 'checkbox_input':
          return <SwitchInput {...common} />
        case 'single_select_input':
          const {options = []} = props
          return (
            <SelectInput
              {...common}
              name={`${prefix}.dictionaryId`}
              placeholder=""
              options={options.map((o) => ({
                label: o.value,
                value: o.id.toString(),
              }))}
            />
          )
      }
  }
  return null
}

type BlockProps = {
  block: FormBlock
  index: number
} & RenderProps
export const Block = ({index, block, control, prefix}: BlockProps) => {
  const blockNode = useMemo(() => {
    const p = `${prefix}.relationships.blocks.data.${index}`
    const b = mapBlock(index, block, control, p)
    return (
      <>
        <HiddenInput control={control as any} name={`${p}.id`} defaultValue={block.id} />
        {b}
      </>
    )
  }, [block])
  return blockNode
}

type RenderProps = {
  control: Control<any>
  prefix: string
}

type FormRendererProps = {
  form: CustomForm
} & RenderProps
export const FormRenderer = ({form, ...props}: FormRendererProps) => {
  if (!form) {
    return null
  }
  return (
    <div>
      <div className="grid">
        <HiddenInput control={props.control} name={`${props.prefix}.id`} defaultValue={form.id} />
        {form.blocks.map((b, i) => (
          <Block key={i} index={i} block={b} {...props} />
        ))}
      </div>
    </div>
  )
}

type CustomClockFormProps = {} & RenderProps
export const CustomClockForm = ({...props}: CustomClockFormProps) => {
  const query = useCustomFormsQuery({
    enabled: env.features.customAttributes,
  })
  useEffect(() => {
    query.refetch()
  }, [])
  if (query.data && query.data.length > 0) {
    return <FormRenderer form={query.data[0]} {...props} />
  }
  return null
}

type CustomUserFormProps = {
  formName: string
  children?: React.ReactNode
} & RenderProps
export const CustomUserForm = ({formName, children, ...props}: CustomUserFormProps) => {
  // const form = useCustomForm(formName, {
  //   enabled: env.features.customAttributes,
  // })
  // if (form) {
  //   return (
  //     <>
  //       {children}
  //       <FormRenderer form={form} {...props} />
  //     </>
  //   )
  // }
  return null
}
