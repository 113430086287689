import React, {useCallback, useState} from 'react'
import Popover from '../popover'
import Button from '../button'
import {useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import {client} from '@scavatec/timeclock-lib/api/client'
import {getDownloadUrl, getCombinedDownloadURL, getPDFUrl} from '@scavatec/timeclock-lib/api/report'
import fileDownload from 'js-file-download'
import useRequest from '../../hooks/use-request'
import * as Menu from '../menu'
import analytics from '@scavatec/timeclock-lib/analytics'
import {userAtom} from '@scavatec/timeclock-lib/state/environment'
import {useAtom} from 'jotai'
import {PayPeriod} from '@scavatec/timeclock-lib/pay-period'

const downloadBlob = async (route: string, filename: string) => {
  const blob = await client.downloadBlob(route)
  fileDownload(blob, filename)
  return true
}

const downloadPDF = async (route: string, filename: string) => {
  const pdfData = await client.downloadPDF(route)
  fileDownload(pdfData as any, filename)
  return true
}

export const ReportDownloader = () => {
  const [open, setOpen] = useState(false)
  const downloadRequest = useRequest()
  const {payPeriod} = useReportContext()
  const [user] = useAtom(userAtom)
  const downloadTotals = useCallback(
    (period: PayPeriod) => {
      return downloadBlob(getCombinedDownloadURL(user.organization, period), `totals.xlsx`)
    },
    [user.organization],
  )
  const downloadReport = useCallback(
    (period: PayPeriod, type: string) => {
      return downloadBlob(getDownloadUrl(user.organization, period, type), `${type}.xlsx`)
    },
    [user.organization],
  )
  const downloadPrintablePDF = useCallback(
    (period: PayPeriod) => {
      return downloadPDF(getPDFUrl(user.organization, period), 'employee-sheets.pdf')
    },
    [user.organization],
  )
  return (
    <Popover
      renderTrigger={({ref}) => (
        <div ref={ref}>
          <Button
            mode="secondary"
            loading={downloadRequest.loading}
            label="Download Report"
            size="small"
            iconRight="chevron-down"
            iconSize={10}
            onClick={() => setOpen(!open)}
          />
        </div>
      )}
      open={open}
      onRequestClose={() => setOpen(false)}
      backdrop
      placement="bottom-start"
      renderContent={({ref, style}) => (
        <div className="popover popover-menu" style={style} ref={ref}>
          <Menu.Menu>
            <Menu.MenuItem
              label="Combined Spreadsheet"
              onClick={() => {
                analytics.event('w.z.2.0-click-combined-spreadsheets')
                downloadRequest.request(downloadTotals(payPeriod.legacy))
                setOpen(false)
              }}
            >
              <small>XLSX</small>
            </Menu.MenuItem>
            <Menu.MenuItem
              label="Printable Time Sheets"
              onClick={() => {
                analytics.event('w.z.2.0-click-printable-time-sheets')
                downloadRequest.request(downloadPrintablePDF(payPeriod.legacy))
                setOpen(false)
              }}
            >
              <small>PDF</small>
            </Menu.MenuItem>
            <Menu.MenuItem
              label="Employee Totals"
              onClick={() => {
                analytics.event('w.z.2.0-click-employee-totals')
                downloadRequest.request(downloadReport(payPeriod.legacy, 'employees'))
                setOpen(false)
              }}
            >
              <small>XLSX</small>
            </Menu.MenuItem>
            <Menu.MenuItem
              label="Job Totals"
              onClick={() => {
                analytics.event('w.z.2.0-click-job-totals')
                downloadRequest.request(downloadReport(payPeriod.legacy, 'jobs'))
                setOpen(false)
              }}
            >
              <small>XLSX</small>
            </Menu.MenuItem>
            <Menu.MenuItem
              label="Transactions"
              onClick={() => {
                analytics.event('w.z.2.0-click-transactions')
                downloadRequest.request(downloadReport(payPeriod.legacy, 'transactions'))
                setOpen(false)
              }}
            >
              <small>XLSX</small>
            </Menu.MenuItem>
            <Menu.MenuItem
              label="Daily Totals"
              onClick={() => {
                analytics.event('w.z.2.0-click-daily-totals')
                downloadRequest.request(downloadReport(payPeriod.legacy, 'daily-totals'))
                setOpen(false)
              }}
            >
              <small>XLSX</small>
            </Menu.MenuItem>
            <Menu.MenuItem
              label="Intervals"
              onClick={() => {
                analytics.event('w.z.2.0-click-intervals')
                downloadRequest.request(downloadReport(payPeriod.legacy, 'intervals'))
                setOpen(false)
              }}
            >
              <small>XLSX</small>
            </Menu.MenuItem>
          </Menu.Menu>
        </div>
      )}
    />
  )
}

export default ReportDownloader
