import analytics from '@scavatec/timeclock-lib/analytics'
import {APIValidationError} from '@scavatec/timeclock-lib/api/client'
import * as jobClient from '@scavatec/timeclock-lib/api/job'
import {JobCreatePayload} from '@scavatec/timeclock-lib/api/types'
import {applyCreate, transformJob} from '@scavatec/timeclock-lib/database/models/job'
import useDatabase from '@scavatec/timeclock-lib/hooks/use-database'
import {useToastContext} from 'contexts/toast-context'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router'
import Button from 'ui/button'
import {ErrorCallout} from 'ui/callout'
import {Form} from 'ui/forms'
import JobForm from 'ui/forms/job-form'
import Toolbar from 'ui/toolbar'

const pageId = 'w.h.3.0'

type JobNewProps = {}
export const JobNew: React.FC<JobNewProps> = ({children}) => {
  const navigate = useNavigate()
  const {addItem} = useToastContext()
  const {database, collections} = useDatabase()
  const {reset, control, setError, handleSubmit} = useForm<JobCreatePayload>({
    defaultValues: {
      name: '',
      note: '',
      isActive: true,
      identifier: '',
    },
  })
  useEffect(() => {
    analytics.event(`${pageId}-view`)
  }, [])
  const mutation = jobClient.useJobCreateMutation({
    onError: (error) => {
      if (error instanceof APIValidationError) {
        Object.keys(error.errors).forEach((key) =>
          setError(`${key}` as any, {
            type: 'validation',
            message: error.errors[key],
          }),
        )
      }
    },
    onSuccess: (data) => {
      database.action(async () => {
        await collections.jobs.create(applyCreate(transformJob.fromApi(data)))
      })
      addItem(
        {
          title: `Created job ${data.name}`,
          icon: 'save',
        },
        4000,
      )
    },
  })

  const saveAddAnother = async (values: JobCreatePayload) => {
    try {
      analytics.event(`${pageId}-submit-create`, {
        add_another: true,
        name: values.name.length > 0,
        note: values.note && values.note.length > 0,
      })
      await mutation.mutateAsync(values)
      reset()
    } catch (e) {}
  }

  const save = async (values: JobCreatePayload) => {
    try {
      analytics.event(`${pageId}-submit-create`, {
        add_another: false,
        name: values.name.length > 0,
        note: values.note && values.note.length > 0,
      })
      await mutation.mutateAsync(values)
      navigate('/jobs')
    } catch (e) {}
  }
  return (
    <div className="page job-edit">
      <Toolbar.Bars>
        <Toolbar.Bar>
          <Toolbar.Group>
            <Button
              label="Cancel"
              disabled={mutation.isLoading}
              mode="secondary"
              icon="close"
              onClick={() => {
                analytics.event(`${pageId}-click-cancel`)
                navigate('/jobs')
              }}
            />
          </Toolbar.Group>
          <Toolbar.Group align="right">
            <Button
              disabled={mutation.isLoading}
              loading={mutation.isLoading}
              label="Save & Add Another"
              mode="secondary"
              icon="plus"
              onClick={() => handleSubmit(saveAddAnother)()}
            />
            <Button
              disabled={mutation.isLoading}
              loading={mutation.isLoading}
              label={'Save'}
              icon={'plus'}
              onClick={() => handleSubmit(save)()}
            />
          </Toolbar.Group>
        </Toolbar.Bar>
      </Toolbar.Bars>
      <div className="page-content">
        {mutation.isError && <ErrorCallout error={mutation.error} />}
        <div className="split-header">
          <h1>{`New Job`}</h1>
        </div>
        <Form onSubmit={handleSubmit(save)}>
          <JobForm pageId={pageId} mode="create" control={control as any} />
        </Form>
      </div>
    </div>
  )
}
export default JobNew
