import analytics from '@scavatec/timeclock-lib/analytics'
import {updateLegacy} from '@scavatec/timeclock-lib/api/settings'
import {useAuthContext} from '@scavatec/timeclock-lib/contexts/auth-context'
import {useEffect} from 'react'
import useModal from '../../hooks/use-modal'
import Button from '../button'
import Forms from '../forms'
import {TimeInputText} from '../inputs/time-input'
import PasswordModal from '../modals/password-modal'
import UserEmailModal from '../modals/user-email-modal'
import Setting from '../settings'
import {ClockType, HoursFormat} from '@scavatec/timeclock-lib/api/types'
import {useAtom} from 'jotai'
import {userAtom} from '@scavatec/timeclock-lib/state/environment'
import {useIdentityQuery} from '@scavatec/timeclock-lib/api/auth'
import {useResendActivationMutation} from '@scavatec/timeclock-lib/api/user'
import {useToastContext} from 'contexts/toast-context'

type AccountSettingsProps = {}
export const AccountSettings = (p: AccountSettingsProps) => {
  const {
    state: {user},
  } = useAuthContext()
  const passwordModal = useModal()
  const emailModal = useModal()
  const [currentUser] = useAtom(userAtom)
  const identityQuery = useIdentityQuery()
  useEffect(() => {
    analytics.event('w.e.1.0-view')
  }, [])
  const isEmailVerified = identityQuery.data?.user.emailVerifiedAt != null

  const {addItem} = useToastContext()
  const resendMutation = useResendActivationMutation({
    onSuccess: () => {
      addItem(
        {
          title: 'Activation email sent',
          message: 'Check your email for a link to activate your email address',
        },
        5000,
      )
    },
    onError: (error) => {
      addItem(
        {
          title: 'Unable to send',
          message: error?.message ?? 'Unknown problem',
          color: 'red',
        },
        3000,
      )
    },
  })
  return (
    <div className="page-content">
      <PasswordModal modal={passwordModal} />
      <UserEmailModal modal={emailModal} />
      <div className="setting-row">
        <div className="setting-col">
          <div className="setting-group">
            <h6>User Settings</h6>
            <div className="setting">
              <div className="form-input">
                <label>{`Email: ${identityQuery.data?.user.email ?? ''}`}</label>
                {!isEmailVerified && (
                  <div className="row justify-between align-center">
                    <small>Pending verification link click, check your inbox.</small>
                    <Button
                      size="small"
                      label="Resend"
                      mode="outline"
                      color="blue"
                      onClick={() => resendMutation.mutate()}
                      loading={resendMutation.isLoading}
                    />
                  </div>
                )}
                <Button
                  label="Change Email"
                  onClick={() => {
                    analytics.event('w.e.1.0-click-change-email')
                    emailModal.open()
                  }}
                />
                <span className="description">
                  Primary account email used for password resets and admin contact. We will send an email to confirm any
                  email changes.
                </span>
              </div>
            </div>
            {/* {settings.email.length > 0 && (
            <span className="user-email-pending">
              Change to:
              <span className="email">{settings.email}</span>
              pending validation link click, check your inbox.
            </span>
          )} */}
            <div className="setting">
              <div className="form-input">
                <label>Password</label>
                <Button
                  label="Change Password"
                  onClick={() => {
                    analytics.event('w.e.1.0-click-change-password')
                    passwordModal.open()
                  }}
                />
              </div>
            </div>
          </div>

          <div className="setting-group">
            <h6>Notifications</h6>
            <Setting.Setting
              name="clock_notifier.duration"
              label="Forgot To Clock Out"
              description="Send an email (to the account admin) when an employee has remained clocked in longer than the specified duration. Set to 0:00 to disable."
              onValueChanged={(v) => {
                analytics.event('w.e.1.0-change-forgot-clock-out-notification', {
                  forgot_clock_out_notification: v,
                })
              }}
              renderInput={(props) => <TimeInputText {...props} />}
            />
          </div>
        </div>
        <div className="setting-col">
          <div className="setting-group">
            <h6>Web Apperance</h6>
            <Setting.Setting
              label="Theme"
              name="account.theme"
              description="Theme for the web application."
              onValueChanged={(v) => {
                analytics.event('w.e.1.0-select-theme', {
                  theme: v,
                })
              }}
              renderInput={(props) => (
                <Forms.Select
                  {...props}
                  options={[
                    {value: 'system', label: 'Default'},
                    {value: 'light', label: 'Light'},
                    {value: 'dark', label: 'Dark'},
                  ]}
                />
              )}
            />
          </div>
          <div className="setting-group">
            <h6>Data Formatting</h6>
            <Setting.Setting
              label="Clock Type"
              name="account.clock_type"
              description="Preferred clock format."
              onValueChanged={(v) => {
                updateLegacy(currentUser.organization, 'Clock Type', v === ClockType.HOUR_24 ? '24-hour' : '12-Hour')
                analytics.event('w.e.1.0-select-clock-type', {
                  clock_type: v,
                })
              }}
              renderInput={(props) => (
                <Forms.Select
                  {...props}
                  options={[
                    {value: ClockType.HOUR_24, label: '24 Hour'},
                    {
                      value: ClockType.HOUR_12,
                      label: '12 Hour (AM / PM)',
                    },
                  ]}
                />
              )}
            />

            <Setting.Setting
              label="Hours Format"
              name="account.hours_format"
              description="Preferred hours format. Decimal will be rounded to two places."
              onValueChanged={(v) => {
                updateLegacy(currentUser.organization, 'Hours Format', v === HoursFormat.DECIMAL ? 'Decimal' : 'HH:MM')
                analytics.event('w.e.1.0-select-hours-format', {
                  hours_format: v,
                })
              }}
              renderInput={(props) => (
                <Forms.Select
                  {...props}
                  options={[
                    {value: HoursFormat.HH_MM, label: 'HH:MM'},
                    {value: HoursFormat.DECIMAL, label: 'Decimal'},
                  ]}
                />
              )}
            />

            <Setting.Setting
              label="Currency"
              name="account.currency"
              description="Currency prefix displayed before monetary values."
              onValueChanged={(v) => {
                updateLegacy(currentUser.organization, 'Currency', v)
                analytics.event('w.e.1.0-select-currency', {
                  currency: v,
                })
              }}
              renderInput={(props) => (
                <Forms.Select
                  {...props}
                  options={[
                    {value: 'usd', label: 'Dollar'},
                    {value: 'eur', label: 'Euro'},
                    {value: 'gbp', label: 'Pound Sterling'},
                    {value: 'inr', label: 'Rupee'},
                    {value: 'jpy', label: 'Yen / Renminbi'},
                  ]}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
