import {initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'
import {getAnalytics} from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyCbkBIvrtGlWOHTOxvOHTEkX-I4KWrrgMY',
  authDomain: 'timeclock-sync.firebaseapp.com',
  databaseURL: 'https://timeclock-sync.firebaseio.com',
  projectId: 'timeclock-sync',
  storageBucket: 'timeclock-sync.appspot.com',
  messagingSenderId: '213650632426',
  appId: '1:213650632426:web:0f61ff414147f4427a3193',
  measurementId: 'G-681B8384JT',
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)

export const firebase = {
  analytics,
  auth,
}

export default firebase
