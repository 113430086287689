import React, {useMemo} from 'react'
import NumberFormat from 'react-number-format'
import ReactSelect from 'react-select'
import {path} from 'rambda'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import DayPicker from 'react-day-picker'
import PhoneNumberInput from 'react-phone-number-input'
// import {format, formatISO, startOfDay, parseISO} from 'date-fns'
import {DateTime} from 'luxon'
import {FormError, LabelErrorRow} from './inputs/common'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import useClassnames from 'hooks/use-classnames'
import {useState} from 'react'
import {useEffect} from 'react'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'

type FormProps = {
  onSubmit: () => void
  className?: string
}
export const Form: React.FC<FormProps> = ({className = '', children, onSubmit}) => {
  return (
    <form
      className={className}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      {children}
    </form>
  )
}

type OnChangeText = (v: string) => void
type Props = {
  label: string
  value: string | boolean | number
  error: string
  placeholder?: string
  minimal?: boolean
  onValueChange?: OnChangeText
  disabled?: boolean
  onFocus?: () => void
  onBlur?: () => void
  type?: 'text' | 'password' | 'email' | 'tel'
}
export const TextInput: React.FC<Props> = ({
  error,
  onValueChange = () => null,
  children,
  minimal = false,
  value = '',
  label,
  onFocus = () => null,
  ...rest
}) => {
  const classes = useClassnames({error: error.length > 0})
  return (
    <div className={`form-input text ${minimal ? 'minimal' : ''}`}>
      <LabelErrorRow label={label} error={error} />
      <input
        type="text"
        className={classes}
        onFocus={onFocus}
        value={value.toString()}
        {...rest}
        onChange={(e) => {
          onValueChange(e.currentTarget.value)
        }}
      />
    </div>
  )
}

type PhoneInputProps = {
  label: string
  value: string
  error: string
  placeholder?: string
  minimal?: boolean
  onValueChange?: OnChangeText
}
export const PhoneInput: React.FC<PhoneInputProps> = ({
  error,
  onValueChange = () => null,
  children,
  minimal = false,
  placeholder,
  value = '',
  label,
  ...rest
}) => {
  const [v, setV] = useState<undefined | string>(value)
  useEffect(() => {
    if (!v) return
    onValueChange(v)
  }, [v])
  return (
    <div className={`form-input phone ${minimal ? 'minimal' : ''}`}>
      <LabelErrorRow label={label} error={error} />
      <PhoneNumberInput placeholder={placeholder} value={value} defaultCountry="US" onChange={setV} />
    </div>
  )
}

type IntegerInputProps = {
  label: string
  value: number
  error: string
  placeholder?: string
  onValueChange: (v: number) => void
  onBlur?: () => void
  type?: 'text' | 'password'
}

export const IntegerInput: React.FC<IntegerInputProps> = ({
  error,
  onValueChange,
  children,
  value = '',
  label,
  ...rest
}) => {
  return (
    <div className="form-input number">
      <LabelErrorRow label={label} error={error} />
      <NumberFormat
        type="tel"
        value={value}
        decimalScale={0}
        maxLength={8}
        allowLeadingZeros={true}
        prefix=""
        {...rest}
        onValueChange={(values) => {
          onValueChange(values.floatValue ?? 0)
        }}
      />
    </div>
  )
}

type PinInputProps = {
  label: string
  value: number
  error: string
  placeholder?: string
  onValueChange: (v: string) => void
  onBlur?: () => void
  type?: 'text' | 'password'
}

export const PinInput: React.FC<PinInputProps> = ({onValueChange, children, value, ...rest}) => {
  return (
    <div className="form-input number">
      <TextInput
        type="tel"
        value={value.toString()}
        onValueChange={(v) => {
          onValueChange(v.replace(/\D/g, ''))
        }}
        {...rest}
      />
    </div>
  )
}

type FloatInputProps = {
  label: string
  value: number
  error: string
  placeholder?: string
  onValueChange: (v: number) => void
  onBlur?: () => void
  type?: 'text' | 'password'
  prefix?: string
}

export const FloatInput: React.FC<FloatInputProps> = ({
  error,
  onValueChange,
  children,
  value = '',
  prefix = '$',
  label,
  ...rest
}) => {
  return (
    <div className="form-input number">
      <LabelErrorRow label={label} error={error} />
      <NumberFormat
        type="tel"
        value={value}
        decimalScale={2}
        prefix={prefix}
        {...rest}
        onValueChange={(values) => {
          onValueChange(values.floatValue ?? 0.0)
        }}
      />
    </div>
  )
}

type DatePickerProps = {
  label: string
  value: string
  error: string
  placeholder?: string
  onValueChange: OnChangeText
}
export const DatePicker: React.FC<DatePickerProps> = ({
  value = '',
  onValueChange,
  placeholder = '',
  children,
  error,
  label,
}) => {
  return (
    <div className="form-input date-picker">
      <LabelErrorRow label={label} error={error} />
      <DayPickerInput
        value={value}
        parseDate={(d) => DateTime.fromISO(d).toJSDate()}
        formatDate={(d) => {
          return DateTime.fromJSDate(d).toSQLDate()
        }}
        onDayChange={(day) => {
          onValueChange(DateTime.fromJSDate(day).toISO())
        }}
      />
    </div>
  )
}

type TextAreaProps = {
  label: string
  value: string
  error: string
  rows?: number
  placeholder?: string
  onValueChange: OnChangeText
  style?: any
}
export const TextArea: React.FC<TextAreaProps> = ({
  value = '',
  onValueChange,
  placeholder = '',
  children,
  error,
  rows = 3,
  label,
  style,
}) => {
  return (
    <div className="form-input textarea" style={style}>
      <LabelErrorRow label={label} error={error} />
      <textarea
        value={value.toString()}
        placeholder={placeholder}
        rows={rows}
        onChange={(e) => onValueChange(e.currentTarget.value)}
      ></textarea>
    </div>
  )
}

type SwitchProps = {
  name?: string
  label: string
  value: boolean
  error: string
  onValueChange: (v: boolean) => void
  disabled?: boolean
  disabledMessage?: string
}
export const Switch: React.FC<SwitchProps> = ({
  value,
  name = '',
  onValueChange,
  children,
  label,
  disabled,
  disabledMessage = '',
}) => {
  const inputName = name.length > 0 ? name : label
  return (
    <div className="form-input switch">
      <div className="input-row">
        {label.length > 0 && <label>{label}</label>}
        <div className="switch-container">
          <input
            id={inputName}
            type="checkbox"
            name={inputName}
            className="switch-input"
            disabled={disabled}
            checked={value || false}
            onChange={(e) => {
              onValueChange(e.target.checked)
            }}
          />
          <label htmlFor={inputName}></label>
        </div>
      </div>
      {disabled && disabledMessage.length > 0 && <span>{disabledMessage}</span>}
    </div>
  )
}

type RowProps = {
  className?: string
}
export const Row: React.FC<RowProps> = ({className = '', children}) => {
  return <div className={`form-row ${className}`}>{children}</div>
}

type ColProps = {
  className?: string
}
export const Col: React.FC<ColProps> = ({className = '', children}) => {
  return <div className={`form-col ${className}`}>{children}</div>
}

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 3,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 14,
    width: 14,
  },
})
const selectStyles = {
  option: (styles: any, {data}: any) => {
    return {
      ...styles,
      // backgroundColor: '#fff',
      // borderBottom: '1px solid rgba(0,0,0,0.1)',
      color: data.color,
      // ...dot(data.color),
      ':hover': {
        // backgroundColor: '#ccc',
      },
    }
  },
  control: (styles: any) => ({
    ...styles,
    background: '#E3E8EC',
    border: 'none',
    borderRadius: 8,
  }),
  menu: (styles: any) => ({
    ...styles,
    // background: '#fff',
  }),
  singleValue: (styles: any, {data}: any) => {
    return {
      ...styles,
      color: data.color,
      // ...dot(data.color),
    }
  },
  multiValue: (styles: any, {data}: any) => {
    return {
      ...styles,
      background: '#2c2f33',
    }
  },
  multiValueLabel: (styles: any, {data}: any) => {
    return {
      ...styles,
      color: '#fff',
    }
  },
}

export type SelectOption = {
  label: string
  value: string
  color?: string
}
type GroupedOptions = {
  label: string
  options: SelectOption[]
}[]
type SelectProps = {
  options: SelectOption[] | GroupedOptions
  value: string
  label: string
  placeholder?: string
  error?: string
  onValueChange: (v: string) => void
}

const selectGroupLabel = (data: any) => (
  <div className="select-group-label">
    <span>{data.label}</span>
    <span>{data.options.length}</span>
  </div>
)

export const Select: React.FC<SelectProps> = ({label, options, value, error = '', onValueChange, ...props}) => {
  const v = useMemo(() => {
    if ((options.length > 0 && options[0].hasOwnProperty('options')) ?? undefined) {
      const opts = options as GroupedOptions
      return opts.reduce<SelectOption | undefined>((acc, sub) => {
        if (!acc) {
          return sub.options.find((o) => o.value === value)
        }
        return acc
      }, undefined)
    } else {
      const opts = options as SelectOption[]
      return opts.find((o) => o.value === value)
    }
  }, [value, options])
  const classes = useClassnames({
    error: error.length > 0,
  })
  const theme = useOrganizationSetting('account.theme')
  return (
    <div className="form-input select">
      <LabelErrorRow label={label} error={error} />
      <ReactSelect
        value={v}
        theme={(base) => ({
          ...base,
          colors: {
            ...base.colors,
            primary25: theme.value !== 'dark' ? base.colors.primary25 : '#828282',
            neutral0: theme.value !== 'dark' ? base.colors.neutral0 : '#323232',
            neutral10: 'green',
          },
        })}
        styles={selectStyles}
        options={options}
        classNamePrefix="select"
        className={classes}
        {...props}
        formatGroupLabel={selectGroupLabel}
        onChange={(v) => {
          onValueChange(path(['value'], v) ?? '')
        }}
      />
    </div>
  )
}

type MultiSelectProps = Partial<JSX.LibraryManagedAttributes<typeof ReactSelect, ReactSelect['props']>> & {
  options: SelectOption[]
  value: string[]
  label: string
  error: string
  onValueChange: (v: string[]) => void
}
export const MultiSelect: React.FC<MultiSelectProps> = ({label, value, options, error, onValueChange, ...props}) => {
  const v = useMemo(() => options.filter((o) => value.find((s) => s === o.value)), [value, options])
  return (
    <div className="form-input select">
      <LabelErrorRow label={label} error={error} />
      <ReactSelect
        value={v as any}
        styles={selectStyles}
        options={options as any}
        isMulti={true as any}
        {...props}
        onChange={(newValue) => {
          if (Array.isArray(newValue)) {
            const vals = newValue as SelectOption[]
            onValueChange(vals.map((v) => v.value))
          }
        }}
      />
    </div>
  )
}

type CalendarProps = {
  value: string
  label: string
  error?: string
  disabled?: any
  modifiers?: any
  className?: string
  onValueChange: (v: string) => void
}

export const Calendar: React.FC<CalendarProps> = ({
  label,
  value = '',
  error = '',
  disabled = {},
  onValueChange,
  className = '',
  ...props
}) => {
  const v = value.length > 0 ? value : DateTime.local().toISO()
  return (
    <div className={`form-input calendar ${className}`}>
      <label>{label}</label>
      <DayPicker
        selectedDays={[DateTime.fromISO(v).toJSDate()]}
        disabledDays={disabled}
        initialMonth={DateTime.fromISO(v).toJSDate()}
        onDayClick={(v) => {
          const d = DateTime.fromJSDate(v)
          onValueChange(d.toISO())
        }}
        {...props}
      />
    </div>
  )
}
export default {
  Form,
  FloatInput,
  TextInput,
  TextArea,
  Select,
  MultiSelect,
  DatePicker,
  Row,
  Switch,
  Col,
  Calendar,
}
