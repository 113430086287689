import React, {useMemo} from 'react'
import {CellProps} from 'react-table'
import Table, {TableRowClick} from '../table'
import {Currency, PreferredTime} from 'util/formatters'
import EmptyState from '../empty-state'
import {DailyTotalCard} from '../daily-total'
import {FilterSet} from '../../hooks/use-filter-set'
import {EmployeesReportFilters} from '../toolbars/report-toolbar'
import useFuse from '../../hooks/use-fuse'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useReportContext, transformAllDailyTotals} from '@scavatec/timeclock-lib/contexts/report-context'
import {ColumnSizes} from './common'
import {ReportDailyTotal, useActiveReport} from '@scavatec/timeclock-lib/api/report'
import {breakColumns, employeeColumns, overtimeColumns, timeColumns} from 'pages/reports/column-definitions'
import {NONE_UUID} from '@scavatec/timeclock-lib/database/models/job'

type DailyTotalsTable = {
  employee?: string
  job?: string
  showJob?: boolean
  showEmployee?: boolean
  view?: string
  onClick?: (dt: ReportDailyTotal) => void
  analyticsId: string
  search?: string
  hideColumns?: string[]
}
const DailyTotalsTable: React.FC<DailyTotalsTable> = ({
  hideColumns = [],
  employee = '',
  job = '',
  analyticsId = '',
  showJob = true,
  showEmployee = true,
  view = 'cards',
  search = '',
  onClick,
}) => {
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Day',
          accessor: 'day',
          width: ColumnSizes.date,
        },
        ...employeeColumns(),
        ...timeColumns(),
        ...breakColumns(),
        ...overtimeColumns(),
      ].filter((c) => !hideColumns.includes(c.accessor)),
    [hideColumns],
  )
  const {employeeDailyTotals} = useActiveReport()
  let totals = useMemo(() => {
    return employeeDailyTotals.filter((dt) => dt.employee.id === employee)
  }, [])

  // Apply filters
  totals = useFuse(totals, search, {
    threshold: 0.1,
    shouldSort: true,
    keys: ['employee', 'task', 'day'],
  })

  if (totals.length <= 0) {
    return (
      <EmptyState
        title="No Daily Totals in Range"
        description="No daily totals were found in the selected date range. Try changing the dates or add a new time by clicking 'Add Time'."
      />
    )
  }
  if (view === 'list') {
    return (
      <Table
        columns={columns}
        analyticsId={analyticsId}
        data={totals}
        noHover
        initialSortBy={[{id: 'day', desc: true}]}
        onRowClick={() => null}
        getRowProps={(row) => {
          return {
            className: row.original.invalid ? 'invalid' : '',
          }
        }}
      />
    )
  }
  return (
    <div className="cards">
      {totals.map((dt, i) => (
        <DailyTotalCard showName={showEmployee} dailytotal={dt} key={i} onClick={onClick} />
      ))}
    </div>
  )
}
export default DailyTotalsTable
