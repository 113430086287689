import analytics from '@scavatec/timeclock-lib/analytics'
import {useState} from 'react'
type TrackingOptions = {
  name: string
  context?: {
    [s: string]: any
  }
}
type FieldErrors = {
  [s: string]: string
}
export const useRequest = (tracking: TrackingOptions | null = null) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [errors, setErrors] = useState<FieldErrors>({})
  const [response, setResponse] = useState(null)
  const [signal, setSignal] = useState(new AbortController())

  const request = async (p: Promise<any>, throwError = false): Promise<any> => {
    setLoading(true)
    setError('')
    signal.abort()
    setSignal(new AbortController())
    setResponse(null)
    if (tracking) {
      analytics.event(tracking.name, {
        action: 'request',
      })
    }
    try {
      const res = await p
      setResponse(res)
      setLoading(false)
      if (tracking) {
        analytics.event(tracking.name, {
          action: 'success',
        })
      }
      return res
    } catch (e: any) {
      setLoading(false)
      setError(e.message ?? e)
      setErrors(e.errors ?? {})
      if (tracking) {
        analytics.event(tracking.name, {
          action: 'error',
          error: e.message,
        })
      }
      if (throwError) {
        throw e
      }
      return null
    }
  }
  const clear = () => {
    setError('')
    setErrors({})
    setLoading(false)
    setResponse(null)
  }
  return {
    clear,
    loading,
    error,
    errors,
    success: response !== null && response !== undefined,
    response,
    signal,
    request,
  }
}
export type RequestHook = ReturnType<typeof useRequest>
export default useRequest
