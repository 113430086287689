import {mdiMagnify, mdiSort, mdiSortAlphabeticalDescending, mdiSortClockDescendingOutline} from '@mdi/js'
import analytics from '@scavatec/timeclock-lib/analytics'
import {useFeatureFlags} from '@scavatec/timeclock-lib/state/subscription'
import useModal from 'hooks/use-modal'
import {useAtom} from 'jotai'
import React, {useCallback, useEffect, useState} from 'react'
import {Outlet, useNavigate, useParams} from 'react-router'
import AlertDialog from 'ui/alert-dialog'
import Button from 'ui/button'
import {DropdownMenu, MenuItem} from 'ui/dropdown-menu'
import {MaterialIcon} from 'ui/icon'
import AdvancedFeatureModal from 'ui/modals/advanced-feature-modal'
import Conversation, {ComposeConversation} from './conversation'
import {ConversationList} from './conversation-list'
import {
  conversationItemsAtom,
  conversationSearchAtom,
  conversationSortAtom,
  defaultConversationAtom,
  fetchConversationsAtom,
} from './messaging-state'

type SortMenuProps = {
  analyticsId: string
}
const SortMenu = ({analyticsId}: SortMenuProps) => {
  const [sort, setSort] = useAtom(conversationSortAtom)
  return (
    <DropdownMenu
      renderTrigger={(toggle) => {
        return (
          <div className="filter-menu" onClick={toggle}>
            <MaterialIcon path={mdiSort} size={0.8} />
          </div>
        )
      }}
    >
      {(setOpen) => {
        return (
          <>
            <MenuItem
              icon={mdiSortClockDescendingOutline}
              onClick={() => {
                analytics.event(`${analyticsId}-click-sort`, {
                  sort_by: 'latest',
                })
                setSort('latest')
                setOpen(false)
              }}
            >
              Recent
            </MenuItem>
            <MenuItem
              icon={mdiSortAlphabeticalDescending}
              onClick={() => {
                analytics.event(`${analyticsId}-click-sort`, {
                  sort_by: 'firstLast',
                })
                setSort('firstLast')
                setOpen(false)
              }}
            >
              {'Alphabetical (First > Last)'}
            </MenuItem>
            <MenuItem
              icon={mdiSortAlphabeticalDescending}
              onClick={() => {
                analytics.event(`${analyticsId}-click-sort`, {
                  sort_by: 'lastFirst',
                })
                setSort('lastFirst')
                setOpen(false)
              }}
            >
              {'Alphabetical (Last > First)'}
            </MenuItem>
            <MenuItem
              icon={mdiSortClockDescendingOutline}
              onClick={() => {
                analytics.event(`${analyticsId}-click-sort`, {
                  sort_by: 'lastClock',
                })
                setSort('lastClock')
                setOpen(false)
              }}
            >
              Last clock
            </MenuItem>
          </>
        )
      }}
    </DropdownMenu>
  )
}

type ComposeMessageProps = {}
export const ComposeMessage = ({}: ComposeMessageProps) => {
  const [search, setSearch] = useAtom(conversationSearchAtom)
  const [showCancel, setShowCancel] = useState(false)
  const navigate = useNavigate()
  const params = useParams()
  const conversationId = params.uuid ?? ''
  const [items] = useAtom(conversationItemsAtom)
  const [group, setGroup] = useState<string[]>([])
  useEffect(() => {
    analytics.event('w.n.2.0-view')
  }, [])
  const toggleSelection = useCallback(
    (uuid: string) => {
      if (group.includes(uuid)) {
        setGroup(group.filter((g) => g !== uuid))
      } else {
        setGroup([...group, uuid])
      }
    },
    [group, setGroup],
  )
  return (
    <>
      <div className="sidebar">
        <div className="controls">
          <div className="row">
            <div className="search-input">
              <MaterialIcon path={mdiMagnify} size={0.8} />
              <input
                type="text"
                value={search}
                onFocus={() => analytics.event(`w.n.2.0-click-search`)}
                onChange={(e) => setSearch(e.currentTarget.value)}
                placeholder="Search By Name, Email or ID"
              />
            </div>
            <SortMenu analyticsId="w.n.2.0" />
          </div>
          <div className="row button-row">
            <Button
              label="Cancel"
              color="red"
              className="cancel-button"
              onClick={() => {
                analytics.event('w.n.2.0-click-cancel')
                setShowCancel(true)
              }}
            />
            <Button
              label="Select All"
              mode="secondary"
              className="small"
              onClick={() => {
                analytics.event('w.n.2.0-click-select-all')
                setGroup(items.map((i) => i.employee.uuid))
              }}
              disabled={group.length === items.length}
            />
            <Button
              label="None"
              mode="secondary"
              className="small"
              onClick={() => {
                analytics.event('w.n.2.0-click-none')
                setGroup([])
              }}
              disabled={group.length === 0}
            />
          </div>
        </div>
        <ConversationList
          active={conversationId}
          compose
          group={group}
          analyticsId="w.n.2.0"
          toggleSelection={toggleSelection}
        />
      </div>
      <AlertDialog
        title="Confirm Cancel"
        analyticsId="w.n.2.1"
        message="Are you sure you want to cancel this message?"
        open={showCancel}
        onDismiss={() => setShowCancel(false)}
        onConfirm={() => navigate('/messaging')}
      />
      <div className="content">
        <ComposeConversation group={group} toggleSelection={toggleSelection} />
      </div>
    </>
  )
}

type ConversationViewProps = {}
export const ConversationView = ({}: ConversationViewProps) => {
  const [search, setSearch] = useAtom(conversationSearchAtom)
  const {uuid: employeeUUID = ''} = useParams()
  const navigate = useNavigate()
  const [redirectUUID] = useAtom(defaultConversationAtom)
  const [, reloadConversations] = useAtom(fetchConversationsAtom)
  useEffect(() => {
    analytics.event('w.n.1.0-view')
    reloadConversations()
  }, [])
  useEffect(() => {
    // Handle navigation to the first conversation once loaded and not already looking at a conversation
    if (employeeUUID === '' && redirectUUID !== null) {
      navigate(`/messaging/${redirectUUID}`)
    }
  }, [redirectUUID, employeeUUID])
  return (
    <>
      <div className="sidebar">
        <div className="controls">
          <div className="row">
            <div className="search-input">
              <MaterialIcon path={mdiMagnify} size={0.8} />
              <input
                type="text"
                value={search}
                onFocus={() => analytics.event(`w.n.1.0-click-search`)}
                onChange={(e) => setSearch(e.currentTarget.value)}
                placeholder="Search By Name, Email or ID"
              />
            </div>
            <SortMenu analyticsId="w.n.1.0" />
          </div>
          <div className="row button-row">
            <Button
              label="New Message"
              className="compose-button"
              onClick={() => {
                analytics.event('w.n.1.0-click-new-message')
                navigate('/messaging/new')
              }}
            />
          </div>
        </div>
        <ConversationList active={employeeUUID} analyticsId="w.n.1.0" />
      </div>
      <div className="content">{employeeUUID.length >= 1 && <Conversation employeeUUID={employeeUUID} />}</div>
    </>
  )
}

type MessagingProps = {}
export const Messaging: React.FC<MessagingProps> = ({}) => {
  const advancedModal = useModal(true)
  const navigate = useNavigate()
  const flags = useFeatureFlags()
  return (
    <div className="page messaging">
      {!flags.isTrialOrPlusTier && (
        <AdvancedFeatureModal modal={advancedModal} actionLabel="Upgrade" onActionClick={() => navigate('/settings')}>
          <h1>You discovered an Advanced feature!</h1>
          <p>
            This feature is part of Toolr Plus, select below to upgrade for only an additional $2.50/mo per active
            worker.{' '}
          </p>
        </AdvancedFeatureModal>
      )}
      <Outlet />
    </div>
  )
}

export default Messaging
