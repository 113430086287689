import React, {useMemo} from 'react'
import {CellProps} from 'react-table'
import {Currency, PreferredTime} from 'util/formatters'
import {navigate} from 'hookrouter'
import useFuse from '../../hooks/use-fuse'
import {useReportContext, transformEmployeeTotals} from '@scavatec/timeclock-lib/contexts/report-context'
import {ColumnSizes} from 'ui/tables/common'
import EmptyState from 'ui/empty-state'
import Table from 'ui/table'
import {useNavigate} from 'react-router-dom'
import {useAtom} from 'jotai'
import {reportSearchAtom} from '../reports/report-state'
import Button from 'ui/button'

const analyticsId = 'w.c.1.0-employees'

type EmployeeTotalsProps = {}

const EmployeeTotals: React.FC<EmployeeTotalsProps> = ({}) => {
  const navigate = useNavigate()
  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'ID',
        accessor: 'id',
        width: ColumnSizes.identifier,
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 'auto',
      },
      {
        Header: 'Time',
        accessor: 'time',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Paid Time',
        accessor: 'paidTime',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Regular Time',
        accessor: (row: any) => row.paidTime - row.overtime,
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time + 20,
      },
      {
        Header: 'Overtime',
        accessor: 'overtime',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Breaks',
        accessor: 'breakTime',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Pay',
        accessor: 'pay',
        Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value} />,
        width: ColumnSizes.currency,
      },
      {
        Header: 'Tips',
        accessor: 'tips',
        Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value} />,
        width: ColumnSizes.currency,
      },
    ]
    return cols
  }, [])
  const {rawReport} = useReportContext()

  // Memo the totals
  let totals = useMemo(() => {
    if (rawReport) {
      return transformEmployeeTotals(rawReport.dateRangeTotals.employees)
    }
    return []
  }, [rawReport])

  // Apply filters
  const [search] = useAtom(reportSearchAtom)
  totals = useFuse(totals, search, {
    threshold: 0.1,
    shouldSort: true,
    keys: ['first', 'last'],
  })
  if (totals.length <= 0) {
    return (
      <EmptyState
        title="No Totals in Range"
        description="No totals were found in the selected date range. Try changing the dates or add a new time by clicking 'Add Time'."
      >
        <Button
          label="Need Help?"
          color="clock-in"
          onClick={() => {
            navigate('/help')
          }}
        />
      </EmptyState>
    )
  }
  return (
    <Table
      columns={columns}
      data={totals}
      initialSortBy={[{id: 'name', desc: false}]}
      getRowProps={(row) => {
        return {
          className: row.original.isInvalid ? 'invalid' : '',
        }
      }}
      onRowClick={(r) => navigate(`/employees/${r.employeeUUID}`)}
    />
  )
}
export default EmployeeTotals
