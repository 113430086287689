import {client} from '@scavatec/timeclock-lib/api/client'
import {createDatabase, schema} from '@scavatec/timeclock-lib/database/database'
import analytics from '@scavatec/timeclock-lib/analytics'
import pkg from '../package.json'
import env from 'util/env'
import mixpanel from 'mixpanel-browser'
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs'
import LogRocket from 'logrocket'

export const bootstrap = () => {
  // Configure the time clock api client(s)
  LogRocket.init(env.services.logrocket.key)
  client.setHost(env.api.host)
  client.setDefaultHeaders({
    'App-Version': pkg.version,
    'App-Platform': 'web',
    'Api-Version': env.api.version,
  })

  // Configure our analytics and user tracking libraries
  mixpanel.init(env.services.mixpanel.key)
  if (env.dev) {
    mixpanel.set_config({debug: true})
  }
  analytics.resetHandlers()
  analytics.registerHandler({
    setUserID: (id) => {
      mixpanel.identify(id)
      LogRocket.identify(id)
    },
    event: (event, props) => mixpanel.track(event, props),
    reset: () => mixpanel.reset(),
    setUserProperties: (props, userId, existingIdFallback) => {
      mixpanel.people.set(props)
      if (userId && userId.length > 0) {
        LogRocket.identify(userId)
        mixpanel.identify(userId)
      } else if (existingIdFallback) {
        mixpanel.identify(mixpanel.get_distinct_id())
      }
    },
  })

  // Initialize our client side database (watermelondb)
  const adapter = new LokiJSAdapter({
    schema,
    useWebWorker: false,
    useIncrementalIndexedDB: true,
  } as any)

  const database = createDatabase(adapter)
  return database
}

export default bootstrap
