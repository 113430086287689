import analytics from '@scavatec/timeclock-lib/analytics'
import * as subscriptionClient from '@scavatec/timeclock-lib/api/subscription'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import React, {useEffect, useState} from 'react'
import {CardElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js'
import * as yup from 'yup'
import useAPI from '../../hooks/use-api'
import useForm from '../../hooks/use-form'
import {ModalHookType} from '../../hooks/use-modal'
import Button, {MaterialIconButton} from '../button'
import Callout, {ErrorCallout, MinimalCallout, RoundedErrorCallout} from '../callout'
import * as Forms from '../forms'
import Modal from './modal'
import {mdiCheck, mdiClose} from '@mdi/js'
import colors from '@scavatec/timeclock-lib/theme/colors'
import Icon from '@mdi/react'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'

const SCREEN_ID = 'w.e.2.3'

const schema = yup.object().shape({
  name: yup.string().required('please enter the name on the card'),
})
export type FormData = yup.InferType<typeof schema>
type ChangeCardModalProps = {
  modal: ModalHookType
  onSave?: (values: any) => void
  onDelete?: (values: any) => void
}

const ChangeCardForm: React.FC<any> = ({onSuccess, modal, request, ...props}) => {
  const stripe = useStripe()
  const elements = useElements()
  const theme = useOrganizationSetting('account.theme')
  const subscriptionQuery = subscriptionClient.useSubscriptionStatusQuery()
  const [stripeError, setStripeError] = useState('')
  const changeCardMutation = subscriptionClient.useChangeCardMutation({
    onSuccess: () => {
      subscriptionQuery.refetch()
      onSuccess()
    },
  })
  const onSubmit = async (values: FormData) => {
    analytics.event(`${SCREEN_ID}-submit-change`)
    if (!stripe || !elements) {
      return
    }
    try {
      const ele = elements.getElement('card')
      if (!ele) {
        return
      }
      const {paymentMethod, error} = await stripe.createPaymentMethod({
        type: 'card',
        card: ele,
        billing_details: {name: values.name},
      })
      if (!paymentMethod) {
        setStripeError(error?.message ?? 'Unable to create payment method with stripe')
        return
      }
      changeCardMutation.mutate({
        stripePaymentIntentTokenId: paymentMethod.id,
        organizationName: values.name,
      })
    } catch (e) {}
  }
  const {handleSubmit, getInputProps} = useForm<FormData>({name: ''}, schema)
  return (
    <>
      {stripeError && (
        <div className="callout-container">
          <RoundedErrorCallout message={stripeError} />
        </div>
      )}
      {changeCardMutation.isError && (
        <div className="callout-container">
          <RoundedErrorCallout message={changeCardMutation.error + ''} />
        </div>
      )}
      <Forms.Form onSubmit={() => handleSubmit(onSubmit)}>
        <Modal.Body>
          <Forms.Row>
            <Forms.TextInput label="Card Holder Name" placeholder="John Doe" {...getInputProps('name')} />
          </Forms.Row>
          <span className="form-label">Card Info</span>
          <div className="card-form">
            <CardElement
              options={{
                style: {
                  base: {
                    // padding: 5,
                    color: theme.value === 'light' ? '#000' : '#D5D5D5',
                    '::placeholder': {
                      color: '#828282',
                      fontSize: '1.1rem',
                    },
                    ':focus': {
                      // boxShadow: '0 0 0 2px red',
                    },
                  },
                },
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            mode="secondary"
            label="Cancel"
            disabled={changeCardMutation.isLoading}
            onClick={() => {
              analytics.event(`${SCREEN_ID}-click-cancel`)
              modal.close()
            }}
          />
          <Button
            type="submit"
            disabled={changeCardMutation.isLoading}
            loading={changeCardMutation.isLoading}
            label="Change Card"
            icon="credit-cards"
          />
        </Modal.Footer>
      </Forms.Form>
    </>
  )
}

export const ChangeCardModal: React.FC<ChangeCardModalProps> = ({modal, onSave, onDelete}) => {
  const request = useAPI()
  useEffect(() => {
    if (modal.isOpen) {
      request.clear()
      setSuccess(false)
    }
  }, [modal.isOpen])
  const [success, setSuccess] = useState(false)
  return (
    <Modal.Wrapper className="subscribe-modal" open={modal.isOpen}>
      <Modal.Header>
        <h1>Change Card</h1>
        <MaterialIconButton
          path={mdiClose}
          size={1}
          onClick={() => {
            analytics.event(`${SCREEN_ID}-click-close`)
            modal.close()
          }}
        />
      </Modal.Header>
      {!success && <ChangeCardForm modal={modal} request={request} onSuccess={() => setSuccess(true)} />}
      {success && (
        <>
          <Modal.Body>
            <div className="subscription-success">
              <Icon size={1} color={colors.green} path={mdiCheck} />
              Your card has been updated.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button label="Close" onClick={() => modal.close()} />
          </Modal.Footer>
        </>
      )}
    </Modal.Wrapper>
  )
}
export default ChangeCardModal
