import React from 'react'
import {Control} from 'react-hook-form'
import SwitchInput from 'ui2/inputs/switch-input'
import TextAreaInput from 'ui2/inputs/text-area-input'
import TextInput from 'ui2/inputs/text-input'
import * as Forms from '../forms'

type JobFormProps = {
  pageId?: string
  mode: 'create' | 'update'
  control: Control<any>
}
export const JobForm: React.FC<JobFormProps> = ({pageId = '', control, mode}) => {
  return (
    <>
      <Forms.Row>
        <TextInput label="Name*" placeholder="Managers" name="name" control={control as any} />
        <TextInput label="ID" placeholder="1234" name="identifier" control={control as any} />
        <SwitchInput label="Active" name="isActive" control={control as any} />
      </Forms.Row>
      <Forms.Row>
        <TextAreaInput label="Note" placeholder="Job details" name="note" control={control as any} />
      </Forms.Row>
    </>
  )
}
export default JobForm
