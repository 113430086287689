import {yupResolver} from '@hookform/resolvers/yup'
import analytics from '@scavatec/timeclock-lib/analytics'
import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import * as yup from 'yup'
import {FilterSet} from '../../hooks/use-filter-set'
import Button from '../button'
import * as Forms from '../forms'
import RangeInput from '../inputs/range-input'
import SwitchInput from '../inputs/switch-input'
import {TVModeFilters} from '../toolbars/tv-mode-toolbar'
import Modal from './modal'

const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
const toSnakedObject = <T extends StringKeyed<any>>(b: T) => {
  return Object.keys(b).reduce<StringKeyed<string>>((acc, key) => {
    acc[camelToSnakeCase(key)] = b[key]
    return acc
  }, {})
}

const schema = yup.object().shape({
  scrollSpeed: yup.number().required(),
  scrollStride: yup.number().required(),
  density: yup.number().required(),
  showLastJob: yup.boolean(),
  showLastTime: yup.boolean(),
  showEmployeeNote: yup.boolean(),
  showStatus: yup.boolean(),
  showEmployeeStatus: yup.boolean(),
})

type BoardOptionsModalProps = {
  renderTrigger: (open: () => void) => React.ReactNode
  filterSet: FilterSet<TVModeFilters>
}
type FormData = yup.InferType<typeof schema>
export const BoardOptionsModal: React.FC<BoardOptionsModalProps> = ({renderTrigger, filterSet}) => {
  const [open, setOpen] = useState(false)
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      scrollSpeed: filterSet.filters.scrollSpeed,
      scrollStride: filterSet.filters.scrollStride,
      density: filterSet.filters.density,
      showLastJob: true,
      showLastTime: true,
      showEmployeeNote: true,
      showStatus: true,
      showEmployeeStatus: true,
    },
  })
  const speed = watch('scrollSpeed')
  const stride = watch('scrollStride')
  const density = watch('density')

  const close = () => {
    setOpen(false)
    analytics.event('w.k.1.1-close')
  }

  const onSubmit = async (values: FormData) => {
    analytics.event('w.k.1.1-save', toSnakedObject(values))
    filterSet.setValues(values)
    close()
  }

  return (
    <>
      {renderTrigger(() => {
        reset(filterSet.filters)
        analytics.event('w.k.1.1-view')
        setOpen(true)
      })}
      <Modal.Wrapper open={open}>
        <Forms.Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <h1>In-Out Board Options</h1>
          </Modal.Header>
          <Modal.Body>
            <Forms.Row>
              <Forms.Col>
                <h2>Display</h2>
                <Forms.Row>
                  <RangeInput
                    label="Cards Per Row"
                    description="How many cards to display per row"
                    error=""
                    {...register('density')}
                    min="1"
                    max="10"
                    step="1"
                    showValue={`${density} cards`}
                  />
                </Forms.Row>
                <h2>Auto Scrolling</h2>
                <Forms.Row>
                  <RangeInput
                    label="Speed"
                    description="How often the page will be scrolled to the next step"
                    error=""
                    {...register('scrollSpeed')}
                    min="5000"
                    max="60000"
                    step="1000"
                    showValue={`${speed / 1000} seconds`}
                  />
                </Forms.Row>
                <Forms.Row>
                  <RangeInput
                    label="Stride"
                    description="How far the page will scroll on each step"
                    error=""
                    {...register('scrollStride')}
                    min="100"
                    max="2000"
                    step="50"
                    showValue={`${stride} px`}
                  />
                </Forms.Row>
              </Forms.Col>
              <Forms.Col>
                <h2>Card Fields</h2>
                <SwitchInput label="Employee Note" error="" {...register('showEmployeeNote')} />
                {/* <SwitchInput
                  label="Last Clock Job"
                  error=""
                  name="showLastJob"
                  ref={register}
                /> */}
                <SwitchInput label="Last Clock Time" error="" {...register('showLastTime')} />
                <SwitchInput label="Employee Status" error="" {...register('showEmployeeStatus')} />
                <SwitchInput label="Clock Status" error="" {...register('showStatus')} />
              </Forms.Col>
            </Forms.Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="group"></div>
            <Button color="secondary" label="Cancel" icon="close" onClick={() => close()} />
            <Button label="Save" icon="save" type="submit" />
          </Modal.Footer>
        </Forms.Form>
      </Modal.Wrapper>
    </>
  )
}
export default BoardOptionsModal
