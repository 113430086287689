import {mdiClose} from '@mdi/js'
import analytics from '@scavatec/timeclock-lib/analytics'
import {useDeviceDeleteMutation, useDeviceListQuery} from '@scavatec/timeclock-lib/api/device'
import {RemoteDevice} from '@scavatec/timeclock-lib/api/types'
import {DataModal} from 'hooks/use-data-modal'
import React from 'react'
import {ErrorCallout} from 'ui/callout'
import {ConfirmationButton} from 'ui/confirmation-button'
import Button, {MaterialIconButton} from '../button'
import Modal from './modal'

const SCREEN_ID = 'device-upgrade-modal'

type DeviceUpgradeModalProps = {
  modal: DataModal<RemoteDevice | null>
  onCancel?: () => void
  onDelete?: () => void
}

const DeviceUpgradeModal: React.FC<DeviceUpgradeModalProps> = ({modal, ...props}) => {
  const deviceList = useDeviceListQuery()
  const deleteMutation = useDeviceDeleteMutation()
  return (
    <Modal.Wrapper
      className="device-upgrade-modal"
      open={modal.isOpen}
      onOpen={() => analytics.event(`${SCREEN_ID}-open`)}
    >
      <Modal.Header>
        <h1>Update Device</h1>
        <MaterialIconButton
          path={mdiClose}
          size={1}
          onClick={() => {
            analytics.event(`${SCREEN_ID}-click-close`)
            modal.close()
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <p>
          This device, <strong>{`${modal.data?.name ?? ''}`}</strong>, is using a version of the Time Clock application
          that is no longer supported and cannot be managed through the web application. Please update it to the latest
          version via the device's app store.
        </p>
        <ul>
          <li>
            <a href="https://apps.apple.com/us/app/toolr-time-clock-calculator/id1186041453">
              Apple App Store - iOS 11+
            </a>
          </li>
          <li>
            <a href="https://play.google.com/store/apps/details?id=com.scavatec.TimeClock_Sync&hl=en_US&gl=US">
              Google Play Store - Android 4.4+
            </a>
          </li>
        </ul>
        {deleteMutation.isError && <ErrorCallout error={deleteMutation.error} />}
      </Modal.Body>
      <Modal.Footer>
        <ConfirmationButton
          description="This will delete the device and all associated data, any unsynced data will be deleted. This action cannot be undone."
          placement="top"
          renderButton={(onClick) => (
            <Button
              mode="primary"
              color="red"
              label="Delete Device"
              onClick={onClick}
              loading={deleteMutation.isLoading}
            />
          )}
          onConfirm={async () => {
            try {
              await deleteMutation.mutateAsync(modal.data?.id ?? '')
              deviceList.refetch()
              modal.close()
            } catch (e) {}
          }}
          title="Delete Device?"
        />
        <Button
          mode="secondary"
          label="Close"
          onClick={() => {
            analytics.event(`${SCREEN_ID}-click-cancel`)
            modal.close()
          }}
        />
      </Modal.Footer>
    </Modal.Wrapper>
  )
}

export default DeviceUpgradeModal
