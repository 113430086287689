import React from 'react'
import * as yup from 'yup'
import useForm from '../../hooks/use-form'
import {ModalHookType} from '../../hooks/use-modal'
import useRequest from '../../hooks/use-request'
import Button from '../button'
import Callout from '../callout'
import * as Forms from '../forms'
import Modal from './modal'

const schema = yup.object().shape({
  totals: yup.array().of(yup.string()),
  intervals: yup.array().of(yup.string()),
  dailyTotals: yup.array().of(yup.string()),
})

const totalOptions = [
  {value: 'id', label: 'ID'},
  {value: 'name', label: 'Employee Name'},
  {value: 'time', label: 'Time'},
  {value: 'paidTime', label: 'Paid Time'},
  {value: 'overtime', label: 'Overtime'},
  {value: 'breakTime', label: 'Breaks'},
  {value: 'pay', label: 'Pay'},
  {value: 'tips', label: 'Tips'},
]

const dailyTotalOptions = [
  {value: 'day', label: 'Day'},
  {value: 'employee', label: 'Employee'},
  {value: 'task', label: 'Job'},
  {value: 'total', label: 'Time'},
  {value: 'paid', label: 'Paid Time'},
  {value: 'overtime', label: 'Overtime'},
  {value: 'break', label: 'Breaks'},
  {value: 'pay', label: 'Pay'},
  {value: 'tips', label: 'Tips'},
]

const intervalOptions = [
  {value: 'name', label: 'Employee'},
  {value: 'dIn', label: 'In'},
  {value: 'dOut', label: 'Out'},
  {value: 'job', label: 'Job'},
  {value: 'totalSeconds', label: 'Duration'},
  {value: 'payRate', label: 'Rate'},
  {value: 'tips', label: 'Tips'},
]
const defaults = {
  totals: [],
  dailyTotals: [],
  intervals: [],
}

type ReportSettingsModalProps = {
  modal: ModalHookType
  initialValues?: ReportSettingsFormData
  onSave: (v: ReportSettingsFormData) => void
}
type ReportSettingsFormData = yup.InferType<typeof schema>
export const ReportSettingsModal: React.FC<ReportSettingsModalProps> = ({
  modal,
  initialValues = defaults,
  onSave,
}) => {
  const {handleSubmit, setValues, values, getInputProps} = useForm<
    ReportSettingsFormData
  >(initialValues, schema)
  const request = useRequest()

  const onSubmit = async (values: ReportSettingsFormData) => {
    onSave(values)
  }

  return (
    <Modal.Wrapper className="side-modal" open={modal.isOpen}>
      <Forms.Form onSubmit={() => handleSubmit(onSubmit)}>
        <Modal.Header>
          <h1>View Options</h1>
        </Modal.Header>
        <Modal.Body>
          {request.error.length > 0 && (
            <Callout
              type="error"
              title="Unable to change password"
              message={request.error}
            />
          )}
          <Forms.Row>
            <Forms.MultiSelect
              label="Employee Total Columns"
              options={totalOptions}
              {...getInputProps('totals')}
            />
          </Forms.Row>
          <Forms.Row>
            <Forms.MultiSelect
              label="Daily Total Columns"
              options={dailyTotalOptions}
              {...getInputProps('dailyTotals')}
            />
          </Forms.Row>
          <Forms.Row>
            <Forms.MultiSelect
              label="Interval Columns"
              options={intervalOptions}
              {...getInputProps('intervals')}
            />
          </Forms.Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="group"></div>
          <Button
            color="secondary"
            label="Close"
            disabled={request.loading}
            icon="close"
            onClick={() => modal.close()}
          />
          <Button
            type="submit"
            disabled={request.loading}
            loading={request.loading}
            label="Save"
            icon="save"
          />
        </Modal.Footer>
      </Forms.Form>
    </Modal.Wrapper>
  )
}
export default ReportSettingsModal
