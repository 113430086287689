import analytics from '@scavatec/timeclock-lib/analytics'
import * as api from '@scavatec/timeclock-lib/api/conversations'
import React from 'react'
import {useForm} from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import {Spinner} from 'ui/activity-indicator'

const MAX_LENGTH = 1000

type MessageComposerProps = {
  onSend?: (s: string) => void
  group: ConversationGroup
  analyticsId: string
}

type MessageFormData = {
  message: string
}
export const MessageComposer: React.FC<MessageComposerProps> = ({group, onSend, analyticsId}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: {errors},
  } = useForm<MessageFormData>()
  const mutation = api.usePostMessageMutation({
    onSuccess: () => {
      setValue('message', '')
      onSend && onSend(message)
    },
  })
  const message = watch('message') ?? ''
  const disableSend = !message.length
  const onSubmit = ({message}: MessageFormData) => {
    analytics.event(`${analyticsId}-submit-send`)
    mutation.mutate({
      group,
      message,
    })
  }

  return (
    <div className="message-composer">
      <form onSubmit={handleSubmit(onSubmit)}>
        {errors.message && <span>This field is required</span>}
        <TextareaAutosize
          defaultValue=""
          rows={1}
          maxRows={6}
          maxLength={MAX_LENGTH}
          disabled={mutation.isLoading}
          placeholder="Start a new message"
          onFocus={() => analytics.event(`${analyticsId}-click-message-input`)}
          {...register('message')}
        />
        <div className="controls">
          <div className="">
            <span>{`${MAX_LENGTH - message.length} characters left`}</span>
            {mutation.isLoading && <Spinner className="tiny" />}
          </div>
          <input
            type="submit"
            disabled={disableSend || mutation.isLoading}
            className={`${disableSend || mutation.isLoading ? 'disabled' : ''}`}
            value={group.length > 1 ? 'Send Separately' : 'Send Message'}
          />
        </div>
      </form>
    </div>
  )
}
