import analytics from '@scavatec/timeclock-lib/analytics'
import {useActiveReport} from '@scavatec/timeclock-lib/api/report'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {transformTransactions, useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import {ClockType} from '@scavatec/timeclock-lib/database/models/common'
import {TransactionType} from '@scavatec/timeclock-lib/database/models/transaction'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import colors from '@scavatec/timeclock-lib/theme/colors'
import {DateTime} from 'luxon'
import React, {useCallback, useMemo} from 'react'
import {useNavigate, useParams} from 'react-router'
import Icon from 'ui/icon'
import InteractiveMap from 'ui/maps/interactive-map'
import {AccountTime} from 'util/formatters'

const screenID = 'w.g.2.0-daily-totals'

const TimeEntryMapLegacy = () => {
  const {uuid = ''} = useParams()
  const {rawReport} = useReportContext()
  const {
    employees: {items: employees},
  } = useDataContext()
  const navigate = useNavigate()
  const onClick = useCallback(() => {
    analytics.event(`${screenID}-click-tile`)
    navigate(`/employees/${uuid}/transactions`)
  }, [])
  const transactions = useMemo(() => {
    if (!rawReport) {
      return []
    }
    let transactions = rawReport.transactions
    if (uuid.length > 0) {
      transactions = transactions.filter((t) => t.employeeUUID === uuid)
    }
    return transformTransactions(transactions, employees)
  }, [uuid, rawReport, employees])
  const markers = useMemo(() => {
    return transactions.reduce<any[]>((acc, t, index) => {
      if (t.location.valid) {
        acc.push({
          lat: t.location.lat,
          long: t.location.long,
          color: t.type === ClockType.In ? colors.green : colors.red,
          label: `${index + 1}. ${t.date}`,
        })
      }
      return acc
    }, [])
  }, [transactions])
  return (
    <div className="transaction-map">
      <InteractiveMap markers={markers} />
      <ul className="list">
        {transactions.map((t, i) => {
          return (
            <li key={t.transactionUUID}>
              <span
                className="identifier"
                style={{
                  color: t.type === ClockType.In ? colors.green : colors.red,
                }}
              >
                {i + 1}{' '}
              </span>
              <AccountTime value={t.dateUTC} />
              {<Icon icon={t.location.valid ? 'map-marker' : 'location_off'} size={16} />}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const TimeEntryMap = () => {
  const {uuid = ''} = useParams()
  const {timeEntries} = useActiveReport()
  const navigate = useNavigate()
  const onClick = useCallback(() => {
    analytics.event(`${screenID}-click-tile`)
    navigate(`/employees/${uuid}/transactions`)
  }, [])
  const transactions = useMemo(() => {
    if (uuid.length > 0) {
      return timeEntries.filter((t) => t.employee.id === uuid)
    }
    return timeEntries
  }, [uuid, timeEntries])
  const markers = useMemo(() => {
    return transactions.reduce<any[]>((acc, t, index) => {
      if (t.latitude < 200) {
        acc.push({
          lat: t.latitude,
          long: t.longitude,
          color: t.type === TransactionType.In ? colors.green : colors.red,
          label: `${index + 1}. ${DateTime.fromISO(t.dateUtc)
            .setZone(t.timezone)
            .toLocaleString(DateTime.DATETIME_MED)}`,
        })
      }
      return acc
    }, [])
  }, [transactions])
  return (
    <div className="transaction-map">
      <InteractiveMap markers={markers} />
      <ul className="list">
        {transactions.map((t, i) => {
          return (
            <li key={t.id}>
              <span
                className="identifier"
                style={{
                  color: t.type === TransactionType.In ? colors.green : colors.red,
                }}
              >
                {i + 1}{' '}
              </span>
              <AccountTime value={t.dateUtc} />
              {<Icon icon={t.latitude < 200 ? 'map-marker' : 'location_off'} size={16} />}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

type EmployeeMapProps = {}
const EmployeeMap: React.FC<EmployeeMapProps> = ({}) => {
  const reportEngineSetting = useOrganizationSetting('reporting.engine_version')
  return reportEngineSetting.value < 2 ? <TimeEntryMapLegacy /> : <TimeEntryMap />
}
export default EmployeeMap
