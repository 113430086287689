import analytics from '@scavatec/timeclock-lib/analytics'
import {useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import React, {useEffect, useMemo} from 'react'
import useFilterSet from 'hooks/use-filter-set'
import useModal from 'hooks/use-modal'
import ReportSettingsModal from 'ui/modals/report-settings-modal'
import ReportToolbar, {EmployeesReportFilters} from 'ui/toolbars/report-toolbar'
import {Outlet} from 'react-router'
import {NavLink, useNavigate} from 'react-router-dom'
import {useActiveReport, useReport, useReportQuery} from '@scavatec/timeclock-lib/api/report'
import {Interval} from 'luxon'
import {InfoCallout, MinimalCallout, RoundedInfoCallout} from 'ui/callout'
import Button from 'ui/button'
import {atomWithStorage, createJSONStorage} from 'jotai/utils'
import {useAtom} from 'jotai'
import {useFeatureFlags} from '@scavatec/timeclock-lib/state/subscription'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'

const storage = createJSONStorage(() => localStorage)
const hideMessageAtom = atomWithStorage('report-upgrade-hidden', false, storage)

type ReportIndexProps = {}
export const ReportIndex: React.FC<ReportIndexProps> = ({children}) => {
  const report = useActiveReport()
  const navigate = useNavigate()
  const [hidden, setHidden] = useAtom(hideMessageAtom)
  const flags = useFeatureFlags()
  const settingModal = useModal()
  const reportEngineSetting = useOrganizationSetting('reporting.engine_version')
  useEffect(() => {
    analytics.event('w.c.1.0-view')
  }, [])
  const filterSet = useFilterSet<EmployeesReportFilters>({
    view: 'table',
    search: '',
    columns: {
      totals: ['id', 'name', 'time', 'paidTime', 'overtime', 'breakTime', 'pay', 'tips'],
      dailyTotals: ['day', 'employee', 'task', 'total', 'paid', 'overtime', 'break', 'pay', 'tips'],
      intervals: ['name', 'dIn', 'dOut', 'job', 'totalSeconds', 'payRate', 'tips'],
    },
  })
  return (
    <div className="page employees-report">
      <ReportToolbar pageId="w.c.1.0" timezone={''} settingModal={settingModal} filterSet={filterSet} />
      <div className="page-content">
        {!hidden && flags.allowLegacyReports && (
          <div className="upgrade-banner">
            <div>
              <h3>New Reporting Engine</h3>
              <p>You are using the latest version of the report engine.</p>
            </div>
            <div className="row">
              <Button color="blue" label="Hide Message" mode="secondary" onClick={() => setHidden(true)} />
              <Button
                color="blue"
                label="Use Previous Version"
                onClick={() => {
                  reportEngineSetting.update(1)
                  navigate('/reports')
                }}
              />
            </div>
          </div>
        )}
        <div className="tab-menu">
          <NavLink end to="/report/" onClick={() => analytics.event('w.c.1.0-click-employees-tab')}>
            Employees
          </NavLink>
          <NavLink to="/report/jobs" onClick={() => analytics.event('w.c.1.0-click-jobs-tab')}>
            Jobs
          </NavLink>
          <NavLink to="/report/daily" onClick={() => analytics.event('w.c.1.0-click-daily-totals-tab')}>
            Daily Totals
          </NavLink>
          <NavLink to="/report/interval" onClick={() => analytics.event('w.c.1.0-click-intervals-tab')}>
            Intervals
          </NavLink>
          <NavLink to="/report/transactions" onClick={() => analytics.event('w.c.1.0-click-transactions-tab')}>
            Time Entries
          </NavLink>
          <div className="spacer"></div>
          <div className="metadata"></div>
        </div>
        <ReportSettingsModal
          modal={settingModal}
          initialValues={filterSet.filters.columns}
          onSave={(v) => {
            filterSet.setValue('columns')(v)
            settingModal.close()
          }}
        />
        <Outlet />
      </div>
    </div>
  )
}
export default ReportIndex
