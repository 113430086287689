import * as api from '@scavatec/timeclock-lib/api/conversations'
import {Conversation} from '@scavatec/timeclock-lib/api/types'
import Employee from '@scavatec/timeclock-lib/database/models/employee'
import {activeEmployeesAtom} from '@scavatec/timeclock-lib/state/entities'
import {environmentAtom} from '@scavatec/timeclock-lib/state/environment'
import {atom} from 'jotai'
import {matchSorter} from 'match-sorter'

// Atom for list of organization conversations
export const conversationsAtom = atom<Conversation[]>([])
export const conversationsStateAtom = atom<ConversationNetState, Partial<ConversationNetState>>(
  {
    timestamp: 0,
    loading: false,
    error: '',
  },
  (get, set, update) => {
    set(conversationsStateAtom, {
      ...get(conversationsStateAtom),
      ...update,
    })
  },
)
export const fetchConversationsAtom = atom(
  (get) => get(conversationsAtom),
  (get, set, update) => {
    const refresh = async () => {
      const env = get(environmentAtom)
      set(conversationsStateAtom, {
        loading: true,
        error: '',
      })
      try {
        const res = await api.index(env.user.organization)
        set(conversationsAtom, res)
        set(conversationsStateAtom, {
          timestamp: Date.now(),
          loading: false,
        })
      } catch (e) {
        set(conversationsStateAtom, {
          timestamp: Date.now(),
          loading: false,
          error: 'Failed to fetch',
        })
      }
    }
    refresh()
  },
)
// fetchConversationsAtom.onMount = (set) => {
//   set()
// }

// Atoms used for the conversation list
export type ConversationItem = {
  employee: Employee
  lastMessageAt: string
}
type SearchItem = {item: ConversationItem}
const conversationSort = {
  latest: (a: SearchItem, b: SearchItem) =>
    b.item.lastMessageAt.localeCompare(a.item.lastMessageAt) ||
    a.item.employee.firstName.localeCompare(b.item.employee.firstName) ||
    a.item.employee.lastName.localeCompare(b.item.employee.lastName),
  firstLast: (a: SearchItem, b: SearchItem) =>
    a.item.employee.firstName.localeCompare(b.item.employee.firstName) ||
    a.item.employee.lastName.localeCompare(b.item.employee.lastName),
  lastFirst: (a: SearchItem, b: SearchItem) =>
    a.item.employee.lastName.localeCompare(b.item.employee.lastName) ||
    a.item.employee.firstName.localeCompare(b.item.employee.firstName),
  lastClock: (a: SearchItem, b: SearchItem) => {
    const lastA = a.item.employee.getLastClock()?.dateUtcRounded ?? '2222-05-12T00:07:00.000000Z'
    const lastB = b.item.employee.getLastClock()?.dateUtcRounded ?? '2222-05-12T00:07:00.000000Z'
    return (
      lastA.localeCompare(lastB) ||
      a.item.employee.firstName.localeCompare(b.item.employee.firstName) ||
      a.item.employee.lastName.localeCompare(b.item.employee.lastName)
    )
  },
}
export const conversationSearchAtom = atom('')
export const conversationSortAtom = atom<keyof typeof conversationSort>('latest')
export const conversationItemsAtom = atom((get) => {
  const employees = get(activeEmployeesAtom)
  const conversations = get(conversationsAtom)
  const search = get(conversationSearchAtom)
  const sortType = get(conversationSortAtom)
  const items = employees.map((e) => {
    // PERF this may need to be sped up by keying the array by the empolyee uuid to O(1) lookup
    const convo = conversations.find((c) => {
      return c.group.includes(e.uuid)
    })
    return {
      employee: e,
      lastMessageAt: convo?.lastMessageAt ?? '',
    }
  })
  return matchSorter(items, search, {
    keys: [
      (item) => item.employee.firstName,
      (item) => item.employee.lastName,
      (item) => item.employee.email,
      (item) => item.employee.identifier,
    ],
    sorter: (items) => items.sort(conversationSort[sortType]),
  })
})

// Atom used to redirect to the top item of the conversation list. Returns null until the conversation data is loaded
// or errors out.
export const defaultConversationAtom = atom((get) => {
  const items = get(conversationItemsAtom)
  const {timestamp, error} = get(conversationsStateAtom)
  if (!items.length || (timestamp <= 0 && error === '')) {
    return null
  }
  return items[0].employee.uuid
})
