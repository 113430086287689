import React from 'react'
import MDIcon from '@mdi/react'
export const MaterialIcon = MDIcon

type Props = {
  icon: string
  size?: number
}
const Icon: React.FC<Props> = ({icon, size = 24}) => {
  return <i className={`icon lni-${icon}`} style={{fontSize: size}}></i>
}

export default Icon
