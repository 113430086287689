import React, {useMemo} from 'react'
import {CellProps} from 'react-table'
import Table, {TableRowClick} from '../table'
import {Currency, PreferredTime, AccountTime} from 'util/formatters'
import EmptyState from '../empty-state'
import {IntervalCard} from 'ui/interval'
import {FilterSet} from '../../hooks/use-filter-set'
import {EmployeesReportFilters} from '../toolbars/report-toolbar'
import useFuse from '../../hooks/use-fuse'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useReportContext, transformAllIntervals} from '@scavatec/timeclock-lib/contexts/report-context'
import {ColumnSizes} from './common'
import {ReportInterval, useActiveReport} from '@scavatec/timeclock-lib/api/report'
import {NONE_UUID} from '@scavatec/timeclock-lib/database/models/job'

type IntervalsTableProps = {
  employee?: string
  job?: string
  view?: string
  showEmployee?: boolean
  showJob?: boolean
  onClick?: (interval: ReportInterval) => void
  search?: string
  analyticsId: string
  hideColumns?: string[]
}
const IntervalsTable: React.FC<IntervalsTableProps> = ({
  search = '',
  employee = '',
  job = '',
  analyticsId = '',
  view = 'cards',
  showJob = true,
  showEmployee = true,
  hideColumns = [],
  onClick,
}) => {
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Start',
          accessor: 'start',
          Cell: ({cell: {value}}: CellProps<any>) => <AccountTime value={value} />,
          width: ColumnSizes.time,
        },
        {
          Header: 'End',
          accessor: 'end',
          Cell: ({cell: {value}}: CellProps<any>) => <AccountTime value={value} />,
          width: ColumnSizes.time,
        },
        {
          Header: 'Duration',
          accessor: 'duration',
          Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value ?? 0} />,
          width: ColumnSizes.time,
        },
      ].filter((c) => !hideColumns.includes(c.accessor)),
    [hideColumns],
  )
  const {intervals} = useActiveReport()
  let totals = useMemo(() => {
    return intervals
  }, [intervals])

  // Apply filters
  totals = useFuse(totals, search, {
    threshold: 0.1,
    shouldSort: true,
    keys: ['name', 'job', 'dIn', 'dOut'],
  })

  if (totals.length <= 0) {
    return (
      <EmptyState
        title="No Intervals in Range"
        description="No intervals were found in the selected date range. Try changing the dates or add a new time by clicking 'Add Time'."
      />
    )
  }
  if (view === 'list') {
    return (
      <Table
        columns={columns}
        data={totals}
        analyticsId={analyticsId}
        noHover
        initialSortBy={[{id: 'dOut', desc: true}]}
        onRowClick={() => null}
        getRowProps={(row) => {
          return {
            className: row.original.invalid ? 'invalid' : '',
          }
        }}
      />
    )
  }
  return (
    <div className="cards">
      {totals.map((t, i) => (
        <IntervalCard
          showEmployee={showEmployee}
          showJob={showJob}
          key={i}
          interval={t}
          onClick={() => {
            onClick && onClick(t)
          }}
        />
      ))}
    </div>
  )
}
export default IntervalsTable
