import React, {useMemo} from 'react'

type MapMarker = {
  lat: number
  long: number
  color: string
  label: string
}
type StaticMapProps = {
  markers: MapMarker[]
  zoom: number
  width: number
  height: number
}
const BASE_URL = 'https://maps.googleapis.com/maps/api/staticmap?'
const KEY = 'AIzaSyDDbPZKYXOS5mRu3DmZ_AUT13liqSx19e0'
const makeMarker = (l: MapMarker) => {
  return `markers=color:${l.color}|label:${l.label}|${l.lat},${l.long}`
}
export const StaticMap: React.FC<StaticMapProps> = ({width, height, zoom, markers}) => {
  const img = useMemo(() => {
    if (width <= 0) {
      return null
    }
    let url = `${BASE_URL}key=${KEY}&size=${Math.ceil(width)}x${height}&zoom=${zoom}`
    url = markers.reduce((acc, marker) => {
      return acc + '&' + makeMarker(marker)
    }, url)
    return <img className="static-map" src={url} />
  }, [width])
  return <div style={{width, height}}>{img}</div>
}
export default StaticMap
