import analytics from '@scavatec/timeclock-lib/analytics'
import {useJobListQuery} from '@scavatec/timeclock-lib/api/job'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import React from 'react'
import {useNavigate} from 'react-router'
import {FilterSet} from '../../hooks/use-filter-set'
import {ButtonGroup} from '../button'
import {Switch, TextInput} from '../forms'
import Toolbar from '../toolbar'

export type JobListFilters = {
  view: 'cards' | 'list' | string
  showInactive: boolean
  search: string
}
type JobListToolbarProps = {
  filterSet: FilterSet<JobListFilters>
}
export const JobListToolbar: React.FC<JobListToolbarProps> = ({filterSet}) => {
  const navigate = useNavigate()
  const preferredView = useOrganizationSetting('account.preferred_view')
  const query = useJobListQuery({})
  return (
    <div className="toolbars">
      <Toolbar.Bar>
        <Toolbar.Group>
          <TextInput
            label="Search"
            placeholder="Search"
            onFocus={() => analytics.event('w.h.1.0-focus-search')}
            {...filterSet.getInputProps('search')}
            minimal
          />
        </Toolbar.Group>
        <Toolbar.Group align="right">
          <Toolbar.Button
            icon="reload"
            label="Refresh"
            color="blue"
            mode="secondary"
            loading={query.isFetching}
            onClick={() => {
              analytics.event('w.h.1.0-submit-refresh')
              query.refetch()
            }}
          />
          <Toolbar.Button
            icon="plus"
            label="Add Job"
            color="clock-in"
            onClick={() => {
              analytics.event('w.h.1.0-click-add-job')
              navigate('/jobs/new')
            }}
          />
        </Toolbar.Group>
      </Toolbar.Bar>
      <Toolbar.Bar small>
        <Toolbar.Group>
          <Toolbar.Item label="Show Inactive">
            <Switch
              name="inactive"
              label=""
              {...filterSet.getInputProps('showInactive')}
              onValueChange={(v) => {
                analytics.event('w.h.1.0-change-hide-inactive', {
                  hide_inactive: v,
                })
                filterSet.setValue('showInactive')(v)
              }}
            />
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group></Toolbar.Group>
        <Toolbar.Group align="right">
          <Toolbar.Item label="View">
            <ButtonGroup
              color="blue"
              value={preferredView.value}
              error=""
              options={[
                {label: 'Cards', icon: 'grid-alt', value: 'cards'},
                {label: 'List', icon: 'list', value: 'list'},
              ]}
              onValueChange={(v) => {
                analytics.event('w.h.1.0-change-view', {
                  view: v,
                })
                preferredView.update(v)
              }}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar.Bar>
    </div>
  )
}
export default JobListToolbar
