import analytics from '@scavatec/timeclock-lib/analytics'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import Employee, {createEmployee, EmployeeDoc} from '@scavatec/timeclock-lib/database/models/employee'
import {DateTime} from 'luxon'
import {sort as rSort} from 'rambda'
import React, {RefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useToastContext} from 'contexts/toast-context'
import {AccountTime, TransactionTypeBadge} from 'util/formatters'
import useDataModal from 'hooks/use-data-modal'
import useFilterSet, {FilterSet} from 'hooks/use-filter-set'
import useFuse from 'hooks/use-fuse'
import EmptyState from 'ui/empty-state'
import {TVModeFilters, TVModeToolbar} from 'ui/toolbars/tv-mode-toolbar'
import AdvancedFeatureModal from 'ui/modals/advanced-feature-modal'
import {useNavigate} from 'react-router-dom'
import useModal from 'hooks/use-modal'
import {subscriptionAccessAtom, useFeatureFlags} from '@scavatec/timeclock-lib/state/subscription'
import env from 'util/env'
import {useAtom} from 'jotai'
import Button from 'ui/button'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'

const fuseOptions = {
  threshold: 0.1,
  shouldSort: true,
  keys: ['firstName', 'lastName', 'identifier'],
}

const sortEmployeeName = (a: Employee, b: Employee) => {
  return a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)
}

const sortClockStatus = (a: Employee, b: Employee) => {
  const statusA = a.getClockStatus()
  const statusB = b.getClockStatus()
  if (statusA !== statusB) {
    return statusA === 'clock_in' ? -1 : 1
  }
  return 0
}

const sortLastActivity = (a: Employee, b: Employee) => {
  const strA = a.getLastClock()?.dateUtc
  const strB = b.getLastClock()?.dateUtc
  const dtA = strA ? DateTime.fromISO(strA) : DateTime.local()
  const dtB = strB ? DateTime.fromISO(strB) : DateTime.local()
  return dtB.toMillis() - dtA.toMillis()
}

// TODO check
const sortMap: StringKeyed<any> = {
  name: sortEmployeeName,
  status: (a: Employee, b: Employee) => {
    return sortClockStatus(a, b) || sortEmployeeName(a, b)
  },
  activity: (a: Employee, b: Employee) => {
    return sortLastActivity(a, b) || sortEmployeeName(a, b)
  },
}

const useAutoScroll = (filterSet: FilterSet<TVModeFilters>, ref: RefObject<HTMLDivElement>) => {
  const [progress, setProgress] = useState(0)
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)
  const toast = useToastContext()
  const handleScroll = useCallback(() => {
    if (!ref.current) {
      return
    }
    const offset = ref.current.offsetTop
    const windowHeight = window.innerHeight
    const contentHeight = ref.current.clientHeight
    const maxScroll = Math.max(contentHeight - windowHeight, 0) + offset
    const current = window.scrollY
    let newOffset = Math.min(current + filterSet.filters.scrollStride, maxScroll)
    if (current === maxScroll) {
      newOffset = offset
    }
    window.scrollTo({
      top: newOffset,
      behavior: 'smooth',
    })
    setProgress(0)
    if (filterSet.filters.autoScroll) {
      const timer = setTimeout(handleScroll, filterSet.filters.scrollSpeed)
      setTimer(timer)
      setTimeout(() => setProgress(50), 50)
    }
  }, [ref, progress, setProgress, setTimer, filterSet])
  const startAutoScroll = useCallback(() => {
    const timer = setTimeout(handleScroll, filterSet.filters.scrollSpeed)
    setTimer(timer)
    setProgress(0)
    setTimeout(() => setProgress(50), 50)
    toast.addItem(
      {
        title: 'Auto Scrolling Enabled',
        message: 'Press any key while window is focused to cancel',
      },
      10000,
    )
  }, [handleScroll, setTimer, setProgress])
  const stopAutoScroll = useCallback(() => {
    if (timer) {
      clearInterval(timer)
    }
    setTimer(null)
    filterSet.setValue('autoScroll')(false)
  }, [timer, setTimer])
  useEffect(() => {
    const handleCancelScroll = (e: any) => {
      if (filterSet.filters.autoScroll) {
        toast.addItem(
          {
            title: 'Auto Scroll Canceled',
          },
          5000,
        )
        stopAutoScroll()
      }
    }
    window.addEventListener('mousewheel', handleCancelScroll)
    window.addEventListener('keypress', handleCancelScroll)
    return () => {
      window.removeEventListener('mousewheel', handleCancelScroll)
      window.removeEventListener('keypress', handleCancelScroll)
    }
  }, [stopAutoScroll, filterSet])
  return {
    progress,
    start: startAutoScroll,
    stop: stopAutoScroll,
  }
}

type TVModeProps = {}
export const TVMode: React.FC<TVModeProps> = ({children}) => {
  const modal = useDataModal<EmployeeDoc>(() => createEmployee({}))
  const {
    employees: {items: employees, requestSync, syncIfStale},
  } = useDataContext()
  const preferredView = useOrganizationSetting('account.preferred_view')
  useEffect(() => {
    const interval = setInterval(() => requestSync(), 60 * 1000 * 5)
    return () => clearInterval(interval)
  }, [requestSync])
  const filterSet = useFilterSet<TVModeFilters>({
    view: preferredView.value,
    status: 'all',
    sort: 'name',
    search: '',
    density: 2,
    scrollSpeed: 5000,
    scrollStride: 500,
    showInactive: false,
    autoScroll: false,
    showEmployeeNote: true,
    showStatus: true,
    showLastJob: true,
    showLastTime: true,
    showEmployeeStatus: true,
  })
  const containerRef = useRef<HTMLDivElement>(null)
  const scrollState = useAutoScroll(filterSet, containerRef)
  useEffect(() => {
    analytics.event('w.k.1.0-view')
    syncIfStale()
  }, [])

  useEffect(() => {
    if (filterSet.filters.autoScroll) {
      // Start auto scroll
      scrollState.start()
      return () => scrollState.stop()
    } else if (!filterSet.filters.autoScroll) {
      scrollState.stop()
    }
  }, [filterSet.filters.autoScroll])

  useEffect(() => {
    const t = setTimeout(() => {
      if (filterSet.filters.search.length > 0) {
        analytics.event('w.k.1.0-search', {search: filterSet.filters.search})
      }
    }, 400)
    return () => clearTimeout(t)
  }, [filterSet.filters.search])

  const {status, showInactive, search, sort} = filterSet.filters
  let filtered = useMemo(() => {
    return employees.filter((e) => {
      if (!showInactive && !e.isActive) {
        return false
      }
      if (status !== 'all') {
        return e.statusString() === status
      }
      return true
    })
  }, [employees, status, showInactive])
  filtered = useFuse(filtered, search, fuseOptions)
  const sorted = useMemo((): Employee[] => {
    return rSort(sortMap[sort], filtered) as Employee[]
  }, [sort, filtered])
  const advancedModal = useModal(true)
  const navigate = useNavigate()
  const flags = useFeatureFlags()
  return (
    <div className="page tv-mode">
      {filterSet.filters.autoScroll && (
        <div className="progress-bar">
          <div
            style={
              scrollState.progress >= 0.1
                ? {
                    transition: `width ${filterSet.filters.scrollSpeed}ms linear`,
                  }
                : {}
            }
            className={`progress ${scrollState.progress >= 0.1 ? 'enabled' : ''}`}
          ></div>
        </div>
      )}
      <TVModeToolbar modal={modal} filterSet={filterSet} />
      <div className="page-content">
        {!flags.isTrialOrPlusTier && (
          <AdvancedFeatureModal
            modal={advancedModal}
            onCloseClick={() => {
              navigate(-1)
            }}
            actionLabel="Upgrade"
            onActionClick={() => navigate('/settings')}
          >
            <h1>You discovered an Advanced feature!</h1>
            <p>
              This feature is part of Toolr Plus, select below to upgrade for only an additional $2.50/mo per active
              worker.{' '}
            </p>
          </AdvancedFeatureModal>
        )}
        {employees.length <= 0 && (
          <EmptyState title="No People" description="No people have been added to your account.">
            <Button
              label="Add Person"
              color="clock-in"
              icon="plus"
              onClick={() => {
                analytics.event('w.g.1.0-click-add-employee-empty')
                navigate('/employees/new')
              }}
            />
          </EmptyState>
        )}
        {employees.length > 0 && filtered.length <= 0 && (
          <EmptyState
            title="No People Found"
            description="
              No people were found with the selected filters. Change the filters
              above or create a new person by clicking the 'Add Person' button in the
              top right."
          >
            <Button
              label="Add Person"
              color="clock-in"
              icon="plus"
              onClick={() => {
                analytics.event('w.g.1.0-click-add-employee-empty')
                navigate('/employees/new')
              }}
            />
          </EmptyState>
        )}
        <div className="tv-cards" ref={containerRef}>
          {sorted.map((e) => (
            <TVCard key={e.id} employee={e} columnCount={filterSet.filters.density} filters={filterSet.filters} />
          ))}
        </div>
      </div>
    </div>
  )
}
export default TVMode

const TVCard = ({
  employee,
  columnCount,
  filters,
}: {
  employee: Employee
  columnCount: number
  filters: TVModeFilters
}) => {
  const clockStatus = employee.getClockStatus()
  const lastClock = employee.getLastClock()
  const status = employee.getPropertyValue('status', '')
  return (
    <div style={{width: `${100 / columnCount}%`}}>
      <div className="tv-card">
        <div className="card-header">
          <h1>{employee.formattedName()}</h1>
          {filters.showStatus && <TransactionTypeBadge type={clockStatus} />}
        </div>
        {filters.showLastTime && (
          <div className="card-date">
            {lastClock && lastClock.dateUtcRounded.length > 0 ? (
              <AccountTime value={lastClock.dateUtcRounded} />
            ) : (
              'No Recorded Activity'
            )}
          </div>
        )}
        {filters.showEmployeeStatus && status.length > 0 && <span className="employee-status">{status}</span>}
        <div className="card-body">{filters.showEmployeeNote && <div className="note">{employee.note}</div>}</div>
      </div>
    </div>
  )
}
