import analytics from '@scavatec/timeclock-lib/analytics'
import {useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import {
  PayPeriod,
  PeriodControl,
  PeriodType,
  periodContains,
  periodControlFromSettings,
} from '@scavatec/timeclock-lib/pay-period'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import {DateTime, Interval} from 'luxon'
import DayPicker, {Modifiers} from 'react-day-picker'
import {useEffect, useMemo, useState} from 'react'
import Forms from '../forms'
import Setting from '../settings'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import {MaterialIcon} from 'ui/icon'
import {mdiCalendarToday, mdiStar} from '@mdi/js'
import tzPlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'
import {updateLegacy} from '@scavatec/timeclock-lib/api/settings'
import {currentUserAtom, userAtom} from '@scavatec/timeclock-lib/state/environment'
import {useAtom} from 'jotai'
import {capitalize} from '@scavatec/timeclock-lib/util/string'
dayjs.extend(utcPlugin)
dayjs.extend(tzPlugin)
dayjs.extend(advancedFormat)

const helpString = (type: string) => {
  switch (type) {
    case 'semi-monthly':
      return 'Semi-Monthly period type will generate pay periods based on two configurable days of the month. Periods will always run from the 1st date to the end of the day before the 2nd date. The following period is from the 2nd date to the day before the 1st date, which may fall on the next month.'
  }
}

const usePeriodPreview = (customStart: string | null = null) => {
  const timezone = useOrganizationSetting('account.timezone')
  const firstDate = useOrganizationSetting('pay_period.first_day')
  const secondDate = useOrganizationSetting('pay_period.second_day')
  const periodType = useOrganizationSetting('pay_period.type')
  const biWeeklyDate = useOrganizationSetting('pay_period.bi_weekly_start_date')
  const weekStart = useOrganizationSetting('reporting.workweek.start_day')
  const monthlyOffset = useOrganizationSetting('pay_period.monthly.start_day')
  const [periods, setPeriods] = useState<PayPeriod[]>([])
  const [modifiers, setModifiers] = useState<Modifiers | any>({})
  useEffect(() => {
    const start = customStart ? dayjs(customStart).toISOString() : dayjs().toISOString()
    const control = periodControlFromSettings(
      {
        firstDate: firstDate.value,
        secondDate: secondDate.value,
        weekStart: weekStart.value,
        biWeeklyStartDate: biWeeklyDate.value,
        type: periodType.value,
        min: '2018-01-1',
        timezone: timezone.value,
        monthlyOffset: monthlyOffset.value,
      },
      start,
    )
    if (!control) {
      return
    }
    const n = 6
    const periods = []
    for (let i = 0; i < n / 2 + 1; i++) {
      control.next()
    }
    for (let i = 0; i < n + 1; i++) {
      periods.push(control.prev())
    }
    if (!periods.length) {
      return
    }
    setPeriods(periods)
    setModifiers(
      periods.reduce((acc, p, i) => {
        const start = dayjs.tz(p[0]).toDate()
        const end = dayjs.tz(p[1]).toDate()
        return {
          ...acc,
          [`period p${i}`]: {
            from: start,
            to: end,
          },
          [`start p${i}`]: start,
          [`end p${i}`]: end,
        }
      }, {}),
    )
  }, [
    firstDate.value,
    secondDate.value,
    weekStart.value,
    biWeeklyDate.value,
    periodType.value,
    timezone.value,
    monthlyOffset.value,
  ])
  return {modifiers, periods, timezone: timezone.value}
}

type ReportSettingsProps = {}
export const ReportSettings = (p: ReportSettingsProps) => {
  const periodType = useOrganizationSetting('pay_period.type')
  const [currentUser] = useAtom(userAtom)
  useEffect(() => {
    analytics.event('w.e.3.0-view')
  }, [])
  const dayPickerOptions = useMemo(() => {
    return Array.from({length: 28}, (_, i) => ({value: `${i + 1}`, label: `${i + 1}`}))
  }, [])
  const {modifiers, periods, timezone} = usePeriodPreview()
  const sortedPeriods = useMemo(() => [...periods].reverse(), [periods])
  return (
    <div className="page-content">
      <div className="setting-row">
        <div className="setting-col">
          <div className="setting-group">
            <h6>Pay Period</h6>
            <Setting.Setting
              label="Pay Period Type"
              name="pay_period.type"
              description="Interval at which employees are paid"
              onValueChanged={(v) => {
                analytics.event('w.e.3.0-select-period-type', {
                  period_type: v,
                })
              }}
              renderInput={(props) => (
                <Forms.Select
                  {...props}
                  options={[
                    {value: PeriodType.DAILY, label: 'Daily'},
                    {value: PeriodType.WEEKLY, label: 'Weekly'},
                    {value: PeriodType.BI_WEEKLY, label: 'Biweekly'},
                    {value: PeriodType.SEMI_MONTHLY, label: 'Semimonthly'},
                    {value: PeriodType.MONTHLY, label: 'Monthly'},
                  ]}
                />
              )}
            />

            {periodType.value === PeriodType.BI_WEEKLY && (
              <div className="col">
                <Setting.Setting
                  label="Bi Weekly Starting Date"
                  name="pay_period.bi_weekly_start_date"
                  description="The initial bi-weekly period start date from which all periods will be derived."
                  onValueChanged={(v) => {
                    analytics.event('w.e.3.0-select-second-date', {
                      second_date: v,
                    })
                  }}
                  renderInput={(props) => <Forms.Calendar {...props} />}
                />
              </div>
            )}

            {periodType.value === PeriodType.SEMI_MONTHLY && (
              <Setting.Setting
                label="First Date"
                name="pay_period.first_day"
                description="The starting day of the first pay period in the month. This will be used to derive all pay periods."
                onValueChanged={(v) => {
                  analytics.event('w.e.3.0-select-first-date', {
                    first_date: v,
                  })
                }}
                parse={(v) => v.toString()}
                encode={(v) => parseInt(v)}
                renderInput={(props) => <Forms.Select {...props} options={dayPickerOptions} />}
              />
            )}

            {periodType.value === PeriodType.SEMI_MONTHLY && (
              <Setting.Setting
                label="Second Date"
                name="pay_period.second_day"
                description="The starting day of the second period of the month."
                onValueChanged={(v) => {
                  analytics.event('w.e.3.0-select-second-date', {
                    first_date: v,
                  })
                }}
                parse={(v) => v.toString()}
                encode={(v) => parseInt(v)}
                renderInput={(props) => <Forms.Select {...props} options={dayPickerOptions} />}
              />
            )}

            {periodType.value === PeriodType.MONTHLY && (
              <Setting.Setting
                label="Monthly Starting Day"
                name="pay_period.monthly.start_day"
                description="The day of the month used as the period starting date."
                onValueChanged={(v) => {
                  analytics.event('w.e.3.0-select-second-date', {
                    first_date: v,
                  })
                }}
                parse={(v) => v.toString()}
                encode={(v) => parseInt(v)}
                renderInput={(props) => <Forms.Select {...props} options={dayPickerOptions} />}
              />
            )}

            <div className="period-preview">
              <label>Preview</label>
              <div className="calendar">
                <DayPicker showOutsideDays modifiers={modifiers} />
              </div>
              <div className="periods">
                <ul>
                  {sortedPeriods.map((p, i) => {
                    const p0 = dayjs.tz(p[0], timezone)
                    const p1 = dayjs.tz(p[1], timezone)
                    const active = periodContains(p, dayjs())
                    return (
                      <li key={i} className={`p${i} ${active ? 'active' : ''}`}>
                        {active && (
                          <div className="icon">
                            <MaterialIcon path={mdiStar} size={0.8} />
                          </div>
                        )}
                        <div>
                          <span className="month">{`${p0.format('MMM')}`}</span>
                          <span>{`${p0.format('Do')}`}</span>
                        </div>
                        <div className="divider">{`to`}</div>
                        <div>
                          <span className="month">{`${p1.format('MMM')}`}</span>
                          <span>{`${p1.format('Do')}`}</span>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="help-text">{helpString(periodType.value)}</div>
        {/* <div className="col">
          <Setting.Setting
            label="Hide Inactive Employees"
            name=""
            onValueChanged={(v) => {
              analytics.event('w.e.3.0-change-hide-inactive-employees', {
                ignore_inactive: v,
              })
            }}
            renderInput={(props) => <Forms.Switch {...props} />}
          />
        </div> */}
        <div className="setting-col">
          <div className="setting-group">
            <h6>Options</h6>
            <Setting.Setting
              label="Workweek Start"
              name="reporting.workweek.start_day"
              description="Which day of the week is considered the start of the workweek. Used for overtime calculation."
              onValueChanged={(v) => {
                updateLegacy(currentUser.organization, 'weekStart', capitalize(v))
                analytics.event('w.e.3.0-select-week-start', {
                  week_start: v,
                })
              }}
              renderInput={(props) => (
                <Forms.Select
                  {...props}
                  options={[
                    {value: 'sunday', label: 'Sunday'},
                    {value: 'monday', label: 'Monday'},
                    {value: 'tuesday', label: 'Tuesday'},
                    {value: 'wednesday', label: 'Wednesday'},
                    {value: 'thursday', label: 'Thursday'},
                    {value: 'friday', label: 'Friday'},
                    {value: 'saturday', label: 'Saturday'},
                  ]}
                />
              )}
            />
            <Setting.Setting
              label="Time Rounding"
              name="clocking.time_rounding"
              description="How times should be rounded when an employee clocks in or out. This option will not retroactively apply to existing time entries."
              onValueChanged={(v) => {
                analytics.event('w.e.3.0-select-time-rounding', {
                  time_rounding: v,
                })
              }}
              renderInput={(props) => (
                <Forms.Select
                  {...props}
                  options={[
                    {value: 'none', label: 'None'},
                    {value: 'round_1_min', label: 'Truncate to 1 minute'},
                    {value: 'round_5_min', label: 'Nearest 5 minutes'},
                    {value: 'round_10_min', label: 'Nearest 10 minutes'},
                    {value: 'round_15_min', label: 'Nearest 15 minutes'},
                    {value: 'round_30_min', label: 'Nearest 30 minutes'},
                    {value: 'round_60_min', label: 'Nearest hour'},
                    {value: 'up_down_5_min', label: 'Up @ in / Down @ out 5 minutes'},
                    {value: 'up_down_10_min', label: 'Up @ in / Down @ out 10 minutes'},
                    {value: 'up_down_15_min', label: 'Up @ in / Down @ out 15 minutes'},
                    {value: 'up_down_30_min', label: 'Up @ in / Down @ out 30 minutes'},
                    {value: 'up_down_60_min', label: 'Up @ in / Down @ out 1 hour'},
                  ]}
                />
              )}
            />
            <Setting.Setting
              label="Show Inactive Employees"
              name="reporting.legacy_show_inactive_employees"
              description="When enabled, inactive employees will be shown on reports."
              onValueChanged={(v) => {
                updateLegacy(currentUser.organization, 'Ignore Inactive', !v)
                analytics.event('w.e.3.0-change-hide-inactive', {
                  value: v,
                })
              }}
              renderInput={(props) => <Forms.Switch {...props} label="Show Inactive Employees" />}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
