import { useMemo } from 'react'

type ClassMap = {
  [s: string]: boolean
}
export const useClassnames = (classMap: ClassMap) => {
  return useMemo(() => {
    const classes = Object.keys(classMap).reduce<string[]>((acc, name) => {
      if (classMap[name]) {
        acc.push(name)
      }
      return acc
    }, [])
    return classes.join(' ')
  }, [classMap])
}

export default useClassnames
