import React from 'react'

type RegistrationCodeProps = {
  code: string
}
export const RegistrationCode: React.FC<RegistrationCodeProps> = ({code}) => {
  const split = code.split('')
  return (
    <div className="registration-code">
      {split.map((s, i) => (
        <span key={i}>{s}</span>
      ))}
    </div>
  )
}
export default RegistrationCode
