import React, {useMemo} from 'react'
import Table, {TableRowClick} from '../table'
import {DateTime} from 'luxon'
import RegistrationCode from '../registration-code'
import {isLatestMobileVersion} from 'util/versions'
import {ColumnSizes} from './common'
import {PlatformBadge} from 'util/formatters'
import env from 'util/env'
import {MaterialIcon} from 'ui/icon'
import {mdiAlert} from '@mdi/js'
import {isLegacyDevice} from 'hooks/use-has-legacy-devices'
import {RemoteDevice} from '@scavatec/timeclock-lib/api/types'

type DeviceTableProps = {
  data: RemoteDevice[]
  onRowClick: TableRowClick
  analyticsId: string
}
const DeviceTable: React.FC<DeviceTableProps> = ({onRowClick, analyticsId, data}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: ColumnSizes.name,
      },
      {
        Header: 'App',
        accessor: (d: RemoteDevice) => ({
          appName: d.appName,
          appVersion: d.appVersion,
          code: d.registrationCode,
        }),
        Cell: ({
          cell: {
            value: {appName, appVersion, code},
          },
        }: any) => {
          if (code) {
            return (
              <div className="table-reg-column">
                <span className="message">Awaiting code entry on this device to activate</span>
                <RegistrationCode code={code} />
              </div>
            )
          }
          if (isLegacyDevice(appName ?? '', appVersion ?? '')) {
            return (
              <div className="legacy-device" title="This version of the mobile application is no longer supported.">
                <MaterialIcon path={mdiAlert} size={1} />
                {appName}
              </div>
            )
          }
          return appName === 'TimeClock Sync' ? 'Time Clock Sync' : appName
        },
        width: ColumnSizes.name,
      },
      {
        Header: 'Last Synced',
        accessor: 'lastHeardFromAt',
        Cell: ({cell: {value}}: any) => {
          if (!value) {
            return 'Never'
          }
          return DateTime.fromISO(value).toRelative()
        },
        width: ColumnSizes.date,
      },
      {
        Header: 'App Version',
        accessor: 'appVersion',
        Cell: ({cell: {value}}: any) => {
          if (value && !isLatestMobileVersion(value, env.mobile.latestVersion)) {
            return `${value} (Update Available)`
          }
          return value
        },
        width: 180,
      },
      {
        Header: 'Platform',
        accessor: 'osName',
        Cell: ({cell: {value}}: any) => {
          return value && <PlatformBadge platform={value} />
        },
      },
    ],
    [],
  )
  return (
    <Table
      columns={columns}
      data={data}
      analyticsId={analyticsId}
      initialSortBy={[{id: 'name', desc: false}]}
      onRowClick={onRowClick}
    />
  )
}
export default DeviceTable
