import React, {useMemo, useState} from 'react'
import {CellProps} from 'react-table'
import {Currency, AccountTime, ClockTypeBadge, TransactionTypeBadge, StringCurrency} from 'util/formatters'
import Table, {TableRowClick} from '../table'
import TransactionCard from 'ui/transaction'
import EmptyState from '../empty-state'
import {FilterSet} from '../../hooks/use-filter-set'
import {EmployeesReportFilters} from '../toolbars/report-toolbar'
import useFuse from '../../hooks/use-fuse'
import Popover from '../popover'
import Button from '../button'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useReportContext, transformTransactions} from '@scavatec/timeclock-lib/contexts/report-context'
import {ColumnSizes} from './common'
import {useActiveReport} from '@scavatec/timeclock-lib/api/report'
import {employeeName} from '@scavatec/timeclock-lib/formatters'
import {TimeEntryCard} from 'ui/time-entry'
import {NONE_UUID} from '@scavatec/timeclock-lib/database/models/job'

type TimeEntriesTableProps = {
  onRowClick: TableRowClick
  employee?: string
  job?: string
  view?: string
  search?: string
  showEmployee?: boolean
  showJob?: boolean
  analyticsId: string
  hideColumns?: string[]
}

const NotePopover = ({note}: {note: string}) => {
  const [open, setOpen] = useState(false)
  return (
    <Popover
      open={open}
      onRequestClose={() => setOpen(false)}
      backdrop
      placement="left"
      renderTrigger={({ref}) => {
        return (
          <div className="note-popover-trigger" ref={ref}>
            <Button
              size="small"
              label="View Note"
              onClick={(e) => {
                e.stopPropagation()
                setOpen(!open)
              }}
            />
          </div>
        )
      }}
      renderContent={({ref, style}) => {
        return (
          <div className="popover note-popover" onClick={(e) => e.stopPropagation()} ref={ref} style={style}>
            <h3>Note</h3>
            {note}
          </div>
        )
      }}
    />
  )
}
const TimeEntriesTable: React.FC<TimeEntriesTableProps> = ({
  onRowClick,
  search = '',
  employee = '',
  job = '',
  view = 'cards',
  showEmployee = true,
  showJob = true,
  analyticsId,
  hideColumns = [],
}) => {
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Date Time',
          accessor: 'dateUtc',
          Cell: ({cell: {value}}: CellProps<any>) => <AccountTime value={value} />,
          width: ColumnSizes.datetime,
        },
        {
          Header: 'Timezone',
          accessor: 'timezone',
          width: ColumnSizes.datetime,
        },
        {
          Header: 'Type',
          accessor: 'type',
          Cell: ({cell: {value}}: CellProps<any>) => <TransactionTypeBadge type={value} />,
          width: ColumnSizes.clockType,
        },
        {
          Header: 'First Name',
          accessor: 'employee.firstName',
          width: 'auto',
        },
        {
          Header: 'Last Name',
          accessor: 'employee.lastName',
          width: 'auto',
        },
        {
          Header: 'Name',
          accessor: 'employee.name',
          Cell: ({row}: CellProps<any>) => employeeName(row.original.employee),
          width: 'auto',
        },
        {
          Header: 'Job',
          accessor: 'job',
          Cell: ({cell: {value}}: CellProps<any>) => (!value ? '<none>' : value.name),
          width: ColumnSizes.name,
        },
        {
          Header: 'Device',
          accessor: 'deviceId',
        },
        {
          Header: 'Pay Rate',
          accessor: 'payRate',
          Cell: ({cell: {value}}: CellProps<any>) => <StringCurrency value={value} />,
          width: ColumnSizes.currency,
        },
        {
          Header: 'Tips',
          accessor: 'tips',
          Cell: ({cell: {value}}: CellProps<any>) => <StringCurrency value={value} />,
          width: ColumnSizes.currency,
        },
        {
          Header: 'Note',
          accessor: 'note',
          Cell: ({cell: {value}}: CellProps<any>) => value && <NotePopover note={value} />,
        },
      ].filter((c) => !hideColumns.includes(c.accessor)),
    [hideColumns],
  )
  const {timeEntries} = useActiveReport()
  let totals = useMemo(() => {
    return timeEntries.filter((t) => t.employee.id === employee)
  }, [timeEntries])

  // Apply filters
  totals = useFuse(totals, search, {
    threshold: 0.1,
    shouldSort: true,
    keys: ['name', 'job', 'device', 'date'],
  })

  if (totals.length <= 0) {
    return (
      <EmptyState
        title="No Time Entries in Range"
        description="No time entries were found in the selected date range. Try changing the dates or add a new time by clicking 'Add Time'."
      />
    )
  }
  if (view === 'list') {
    return (
      <Table
        columns={columns}
        data={totals}
        onRowClick={onRowClick}
        analyticsId={analyticsId}
        initialSortBy={[{id: 'dateUTC', desc: true}]}
        getRowProps={(row) => {
          return {
            className: row.original.isInvalid === '1' ? 'invalid' : '',
          }
        }}
      />
    )
  }
  return (
    <div className="cards">
      {totals.map((t) => (
        <TimeEntryCard transaction={t} onClick={() => onRowClick(t)} showName={showEmployee} key={t.id} />
      ))}
    </div>
  )
}
export default TimeEntriesTable
