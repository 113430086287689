import {mdiCheck, mdiClose} from '@mdi/js'
import Icon from '@mdi/react'
import analytics from '@scavatec/timeclock-lib/analytics'
import * as subscriptionClient from '@scavatec/timeclock-lib/api/subscription'
import {useAuthContext} from '@scavatec/timeclock-lib/contexts/auth-context'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {subscriptionStatusAtom} from '@scavatec/timeclock-lib/state/subscription'
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js'
import {DataModal} from 'hooks/use-data-modal'
import {useAtom} from 'jotai'
import {DateTime} from 'luxon'
import React, {useState} from 'react'
import {useMutation} from '@tanstack/react-query'
import * as yup from 'yup'
import useForm from '../../hooks/use-form'
import Button, {MaterialIconButton} from '../button'
import Callout, {ErrorCallout, InfoCallout, RoundedErrorCallout, RoundedInfoCallout} from '../callout'
import * as Forms from '../forms'
import {isMonthlyPrice, Pricing} from '../pricing'
import Modal from './modal'
import colors from '@scavatec/timeclock-lib/theme/colors'

const SCREEN_ID = 'w.e.2.7'

const schema = yup.object().shape({
  coupon: yup.string(),
})
export type FormData = yup.InferType<typeof schema>

const ChangePlanForm: React.FC<any> = ({modal, ...props}) => {
  const {
    state: {user},
  } = useAuthContext()
  const plan = modal.data
  const query = subscriptionClient.useSubscriptionStatusQuery()
  const [subscription] = useAtom(subscriptionStatusAtom)
  const subscribeMutation = subscriptionClient.useSubscribeMutation({
    onMutate: () => {
      analytics.event(`${SCREEN_ID}-submit-change-plan`)
    },
    onSuccess: () => {
      query.refetch()
    },
  })
  let firstBillDate = DateTime.fromISO(subscription.trialPeriodEnd ?? '')
  const onSubmit = (data: FormData) => {
    subscribeMutation.mutate({
      organizationName: user.username,
      couponCode: data.coupon,
      subscriptionPriceName: plan.price,
      subscriptionProductName: plan.product,
    })
  }
  const {handleSubmit, getInputProps} = useForm<FormData>({coupon: ''}, schema)
  return (
    <>
      <Modal.Header>
        <h1>Change Plan - {plan.product === subscriptionClient.STARTER_TIER_NAME ? 'Starter' : 'Plus'}</h1>
        <MaterialIconButton
          path={mdiClose}
          size={1}
          onClick={() => {
            analytics.event(`${subscribeMutation.isSuccess ? 'w.e.2.7' : SCREEN_ID}-click-close`)
            modal.close()
          }}
        />
      </Modal.Header>
      <div className="callout-container">
        {subscribeMutation.isError && <RoundedErrorCallout message={subscribeMutation.error + ''} />}
        {subscription.productName === 'plus' && plan.product !== 'plus' && (
          <RoundedInfoCallout message="Note! By swapping to a Starter plan, you will lose access to Plus plan features." />
        )}
      </div>
      <Forms.Form onSubmit={() => handleSubmit(onSubmit)}>
        <Modal.Body>
          {subscribeMutation.isSuccess && (
            <div className="subscription-success">
              <Icon size={1} color={colors.green} path={mdiCheck} />
              Your plan has been updated.
            </div>
          )}
          {!subscribeMutation.isSuccess && (
            <>
              <p>
                You are changing from{' '}
                <span className="capitalize">
                  {subscription.productName} {isMonthlyPrice(subscription.priceName ?? '') ? 'monthly' : 'yearly'}
                </span>{' '}
                to{' '}
                <span className="capitalize">
                  {plan.product} {isMonthlyPrice(plan.price ?? '') ? 'monthly' : 'yearly'}
                </span>{' '}
                plan.
              </p>
              <Pricing
                product={plan.product}
                price={plan.price}
                activeEmployeeCount={subscription.activeEmployeeCount ?? 0}
              />
              <div className="first-bill">
                <p>This change will be applied once confirmed below.</p>
              </div>
              <Forms.Row>
                <Forms.TextInput label="Coupon Code (optional)" placeholder="CODE15" {...getInputProps('coupon')} />
              </Forms.Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!subscribeMutation.isSuccess && (
            <>
              <Button
                mode="secondary"
                label="Cancel"
                disabled={subscribeMutation.isLoading}
                onClick={() => {
                  analytics.event('w.a.1.1-click-cancel')
                  modal.close()
                }}
              />
              <Button
                type="submit"
                disabled={subscribeMutation.isLoading}
                loading={subscribeMutation.isLoading}
                label="Change Plan"
              />
            </>
          )}
          {subscribeMutation.isSuccess && (
            <>
              <Button
                label="Close"
                disabled={subscribeMutation.isLoading}
                onClick={() => {
                  analytics.event(`w.e.2.7-click-close`)
                  modal.close()
                }}
              />
            </>
          )}
        </Modal.Footer>
      </Forms.Form>
    </>
  )
}

type ChangePlanModalProps = {
  modal: DataModal<{product: string; price: string}>
  onSave?: (values: any) => void
  onDelete?: (values: any) => void
  onClose?: () => void
}

export const ChangePlanModal: React.FC<ChangePlanModalProps> = ({modal, onSave, onDelete, onClose = () => null}) => {
  return (
    <Modal.Wrapper className="subscribe-modal" open={modal.isOpen}>
      <ChangePlanForm modal={modal} />
    </Modal.Wrapper>
  )
}
export default ChangePlanModal
