import analytics from '@scavatec/timeclock-lib/analytics'
import {useEmployeeListQuery} from '@scavatec/timeclock-lib/api/employee'
import {EmployeeDoc} from '@scavatec/timeclock-lib/database/models/employee'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import React, {useState} from 'react'
import {useNavigate} from 'react-router'
import env from 'util/env'
import {DataModal} from '../../hooks/use-data-modal'
import {FilterSet} from '../../hooks/use-filter-set'
import Badge from '../badge'
import Button, {ButtonGroup} from '../button'
import {Switch, TextInput} from '../forms'
import Toolbar from '../toolbar'

export type EmployeeListFilters = {
  view: 'cards' | 'list' | string
  showInactive: boolean
  status: 'in' | 'out' | 'all'
  search: string
}
type EmployeeListToolbarProps = {
  filterSet: FilterSet<EmployeeListFilters>
  modal: DataModal<EmployeeDoc>
}
export const EmployeeListToolbar: React.FC<EmployeeListToolbarProps> = ({filterSet, modal}) => {
  const navigate = useNavigate()
  const preferredView = useOrganizationSetting('account.preferred_view')
  const query = useEmployeeListQuery()
  return (
    <div className="toolbars">
      <Toolbar.Bar>
        <Toolbar.Group>
          <TextInput
            label="Search"
            placeholder="Search"
            onFocus={() => analytics.event('w.g.1.0-focus-search')}
            {...filterSet.getInputProps('search')}
            minimal
          />
        </Toolbar.Group>
        <Toolbar.Group align="right">
          <Toolbar.Button
            icon="reload"
            label="Refresh"
            color="blue"
            mode="secondary"
            loading={query.isFetching}
            onClick={() => {
              analytics.event('w.g.1.0-submit-refresh')
              query.refetch()
            }}
          />
          <Toolbar.Button
            icon="plus"
            label="Add Person"
            color="clock-in"
            onClick={() => {
              analytics.event('w.g.1.0-click-add-employee')
              navigate('/employees/new')
            }}
          />
        </Toolbar.Group>
      </Toolbar.Bar>
      <Toolbar.Bar small>
        <Toolbar.Group>
          <Toolbar.Item label="Status">
            <ButtonGroup
              {...filterSet.getInputProps('status')}
              options={[
                {label: 'All', icon: 'clipboard', value: 'all'},
                {label: 'In', icon: 'download', value: 'in'},
                {label: 'Out', icon: 'upload', value: 'out'},
              ]}
              onValueChange={(v) => {
                analytics.event('w.g.1.0-change-status-filter', {
                  status: v,
                })
                filterSet.setValue('status')(v)
              }}
            />
          </Toolbar.Item>
          <Toolbar.Item label="Show Inactive">
            <Switch
              name="inactive"
              label=""
              {...filterSet.getInputProps('showInactive')}
              onValueChange={(v) => {
                analytics.event('w.g.1.0-change-hide-inactive', {
                  hide_inactive: v,
                })
                filterSet.setValue('showInactive')(v)
              }}
            />
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group></Toolbar.Group>
        <Toolbar.Group align="right">
          <Toolbar.Item label="View">
            <div className="row">
              <ButtonGroup
                color="blue"
                value={preferredView.value}
                error={''}
                options={[
                  {label: 'Cards', icon: 'grid-alt', value: 'cards'},
                  {label: 'List', icon: 'list', value: 'list'},
                ]}
                onValueChange={(v) => {
                  analytics.event('w.g.1.0-change-view', {
                    view: v,
                  })
                  preferredView.update(v)
                }}
              />
              {env.features.tvMode && <TVButton navigate={navigate} />}
            </div>
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar.Bar>
    </div>
  )
}

const TVButton = ({navigate}: any) => {
  const [open, setOpen] = useState(false)
  return (
    // <Popover
    //   isOpen={open}
    //   positions={['bottom', 'right']}
    //   align="end"
    //   content={
    //     <div className={`tooltip ${settings.theme}`}>
    //       <h1>In Out Board</h1>
    //       <p>Status board display designed for large always on screens.</p>
    //       <img src="./img/tv-mode-preview.png" alt="In-Out board Preview" />
    //     </div>
    //   }>
    // </Popover>
    <div className="tv-mode-button" onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <Button
        icon="display"
        size="small"
        label="in-out board"
        mode="secondary"
        onClick={() => {
          analytics.event(`w.g.1.0-click-in-out-board`)
          navigate('/in-out-board')
        }}
      >
        <Badge label="Beta" type="beta" />
      </Button>
    </div>
  )
}
export default EmployeeListToolbar
