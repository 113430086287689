import {useState} from 'react'

export type ModalHookType = {
  open: () => void
  close: () => void
  isOpen: boolean
}
export const useModal = (
  initial = false,
  onClose: () => void = () => null,
): ModalHookType => {
  const [isOpen, setOpen] = useState(initial)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
    onClose()
  }
  return {
    open: openModal,
    close: closeModal,
    isOpen,
  }
}

export default useModal
