import analytics from '@scavatec/timeclock-lib/analytics'
import * as deviceClient from '@scavatec/timeclock-lib/api/device'
import {useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import useDatabase from '@scavatec/timeclock-lib/hooks/use-database'
import {useDevice} from '@scavatec/timeclock-lib/state/entities'
import {userAtom} from '@scavatec/timeclock-lib/state/environment'
import {settingsAtom, transformRemoteSetting, useDeviceSetting} from '@scavatec/timeclock-lib/state/settings'
import {useAtom} from 'jotai'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router'
import Button from 'ui/button'
import {ErrorCallout} from 'ui/callout'
import {ConfirmationButton} from 'ui/confirmation-button'
import Forms from 'ui/forms'
import Toolbar from 'ui/toolbar'
import {useToastContext} from '../../contexts/toast-context'
import EmptyState from '../../ui/empty-state'
import DeviceForm, {updateDeviceSetting} from '../../ui/forms/device-form'
import {hashLocalPassword} from '@scavatec/timeclock-lib/util/local-password'
import {defaultDeviceData, DeviceFormData, deviceValidationResolver} from '@scavatec/timeclock-lib/forms/device'
import {validationErrorsToHookForm} from '@scavatec/timeclock-lib/forms/errors'

const SCREEN_ID = 'w.i.2.1'

type DeviceEditProps = {}
export const DeviceEdit: React.FC<DeviceEditProps> = ({}) => {
  const navigate = useNavigate()
  const {uuid = ''} = useParams()
  const {addItem} = useToastContext()
  const [, settingsDispatch] = useAtom(settingsAtom)
  const [user] = useAtom(userAtom)
  const device = useDevice(uuid ?? '')
  const deviceListQuery = deviceClient.useDeviceListQuery()
  const deleteMutation = deviceClient.useDeviceDeleteMutation()
  const modeSetting = useDeviceSetting('device.clocking.employee_mode', uuid)
  const jobSetting = useDeviceSetting('device.clocking.jobs.enabled', uuid)
  const requireJobSetting = useDeviceSetting('device.clocking.jobs.require_selection', uuid)
  const notesSetting = useDeviceSetting('device.clocking.notes.enabled', uuid)
  const employeeReportSetting = useDeviceSetting('device.clocking.employee_report.enabled', uuid)
  const pinSetting = useDeviceSetting('device.clocking.require_pin', uuid)
  const pinChangingSetting = useDeviceSetting('device.clocking.pin_changing.enabled', uuid)
  const tipsSetting = useDeviceSetting('device.clocking.tips.enabled', uuid)
  const tipsAtInSetting = useDeviceSetting('device.clocking.tips.entry_at_clock_in', uuid)
  const accountManagementSetting = useDeviceSetting('device.admin.account_management', uuid)
  const reportSetting = useDeviceSetting('device.reporting.enabled', uuid)
  const reportEditingSetting = useDeviceSetting('device.reporting.allow_editing', uuid)
  const reportPasswordSetting = useDeviceSetting('device.reporting.require_admin_password', uuid)
  const employeeStatusSetting = useDeviceSetting('device.clocking.employee_status_message.enabled', uuid)
  const crewSetting = useDeviceSetting('device.clocking.crews', uuid)
  const avatarSetting = useDeviceSetting('device.clock_list.show_avatar', uuid)
  const resourceScoreSetting = useDeviceSetting('device.resource_score.enabled', uuid)
  const toastSetting = useDeviceSetting('device.clocking.toast_notification.enabled', uuid)
  const singleEmployeeSetting = useDeviceSetting('device.clocking.single_employee', uuid)
  const passwordSetting = useDeviceSetting('device.admin.local_password', uuid)

  const handleDelete = async () => {
    if (!uuid) return
    try {
      await deleteMutation.mutateAsync(uuid)
    } catch (e) {}
    navigate('/devices')
    deviceListQuery.refetch()
  }
  useEffect(() => {
    analytics.event(`${SCREEN_ID}-view`)
  }, [])

  const form = useForm<DeviceFormData>({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    resolver: deviceValidationResolver,
    defaultValues: {
      ...defaultDeviceData,
      data: {
        ...defaultDeviceData,
        name: device?.name ?? '',
        note: device?.note ?? '',
      },
      settings: {
        jobs: jobSetting.value,
        requireJobSelection: jobSetting.value && requireJobSetting.value,
        notes: notesSetting.value,
        selfServe: employeeReportSetting.value,
        requirePin: pinSetting.value,
        allowPinChange: pinSetting.value && pinChangingSetting.value,
        tips: tipsSetting.value,
        tipsAtIn: tipsSetting.value && tipsAtInSetting.value,
        accountManagement: accountManagementSetting.value,
        reports: reportSetting.value,
        reportsPassword: (passwordSetting.value ?? '').length > 0 && reportPasswordSetting.value,
        reportEditing: reportSetting.value && reportEditingSetting.value,
        statusMessage: employeeStatusSetting.value,
        employeeMode: modeSetting.value as any,
        crews: crewSetting.value,
        avatars: avatarSetting.value,
        resourceScore: resourceScoreSetting.value,
        toastNotification: toastSetting.value,
        singleEmployee: singleEmployeeSetting.value ?? '',
        newPassword: '',
        hasPassword: (passwordSetting.value ?? '').length > 0 ?? false,
        usePassword: (passwordSetting.value ?? '').length > 0 ?? false,
      },
    },
  })
  const {
    control,
    watch,
    formState: {errors, isSubmitting},
    reset,
    setError,
    handleSubmit,
  } = form
  const mutation = deviceClient.useDeviceUpdateMutation(uuid ?? '', {
    onError: (err) => validationErrorsToHookForm(setError as any, err, 'data.'),
  })
  const values = watch()

  const onSubmit = async (v: DeviceFormData) => {
    try {
      const res = await mutation.mutateAsync({
        name: v.data.name,
        note: v.data.note,
      })
      try {
        const settingUpdates = [
          updateDeviceSetting('device.clocking.employee_mode', v.settings.employeeMode),
          updateDeviceSetting('device.clocking.jobs.enabled', v.settings.jobs),
          updateDeviceSetting('device.clocking.jobs.require_selection', v.settings.requireJobSelection),
          updateDeviceSetting('device.clocking.notes.enabled', v.settings.notes),
          updateDeviceSetting('device.clocking.employee_report.enabled', v.settings.selfServe),
          updateDeviceSetting('device.clocking.require_pin', v.settings.requirePin),
          updateDeviceSetting('device.clocking.pin_changing.enabled', v.settings.allowPinChange),
          updateDeviceSetting('device.clocking.tips.enabled', v.settings.tips),
          updateDeviceSetting('device.clocking.tips.entry_at_clock_in', v.settings.tipsAtIn),
          updateDeviceSetting('device.admin.account_management', v.settings.accountManagement),
          updateDeviceSetting('device.reporting.enabled', v.settings.reports),
          updateDeviceSetting('device.reporting.allow_editing', v.settings.reportEditing),
          updateDeviceSetting('device.reporting.require_admin_password', v.settings.reportsPassword),
          updateDeviceSetting('device.clocking.employee_status_message.enabled', v.settings.statusMessage),
          updateDeviceSetting('device.clocking.crews', v.settings.crews),
          updateDeviceSetting('device.clock_list.show_avatar', v.settings.avatars),
          updateDeviceSetting('device.resource_score.enabled', v.settings.resourceScore),
          updateDeviceSetting('device.clocking.toast_notification.enabled', v.settings.toastNotification),
          updateDeviceSetting('device.clocking.single_employee', v.settings.singleEmployee),
        ]
        if (!v.settings.usePassword && v.settings.hasPassword) {
          // Clear the existing password when use password is turned off, and there is an existing password set
          settingUpdates.push(updateDeviceSetting('device.admin.local_password', ''))
        }
        if (v.settings.usePassword && v.settings.newPassword.length > 0) {
          settingUpdates.push(
            updateDeviceSetting(
              'device.admin.local_password',
              hashLocalPassword(user.organization, v.settings.newPassword),
            ),
          )
        }
        await Promise.all(
          settingUpdates.map((fn) =>
            fn(user.organization, res.id).then((res) => {
              // Update the setting locally
              settingsDispatch({type: 'update', payload: transformRemoteSetting(res)})
            }),
          ),
        )
      } catch (e) {}
      navigate('/devices')
      deviceListQuery.refetch()
    } catch (e) {}
  }

  if (!device) {
    return (
      <div className="page device-edit">
        <div className="page-content">
          <EmptyState
            title="Device not found"
            description="The device attempting to be edited could no longer be found."
          />
        </div>
      </div>
    )
  }

  return (
    <div className="page device-edit">
      <Forms.Form onSubmit={handleSubmit(onSubmit)}>
        <Toolbar.Bars>
          <Toolbar.Bar>
            <Toolbar.Group>
              <Button
                label="Cancel"
                disabled={isSubmitting}
                mode="secondary"
                icon="close"
                onClick={() => {
                  analytics.event(`${SCREEN_ID}-click-cancel`)
                  navigate('/devices')
                }}
              />
            </Toolbar.Group>
            <Toolbar.Group align="right">
              <ConfirmationButton
                title="Confirm Delete"
                description="Are you sure you want to delete this device?"
                renderButton={(onClick) => {
                  return (
                    <Button
                      color="danger"
                      label="Delete"
                      icon="trash"
                      disabled={deleteMutation.isLoading}
                      loading={deleteMutation.isLoading}
                      onClick={onClick}
                    />
                  )
                }}
                onConfirm={handleDelete}
              />
              <Button type="submit" disabled={isSubmitting} loading={isSubmitting} label={'Update'} icon={'pencil'} />
            </Toolbar.Group>
          </Toolbar.Bar>
        </Toolbar.Bars>

        <div className="page-content">
          {mutation.isError && <ErrorCallout error={mutation.error} />}
          <DeviceForm pageId={SCREEN_ID} form={form} isEdit />
        </div>
      </Forms.Form>
    </div>
  )
}
export default DeviceEdit
