import * as userClient from '@scavatec/timeclock-lib/api/user'
import {useAuthContext} from '@scavatec/timeclock-lib/contexts/auth-context'
import React from 'react'
import {useForm} from 'react-hook-form'
import TextInput from 'ui2/inputs/text-input'
import {ModalHookType} from '../../hooks/use-modal'
import Button from '../button'
import Callout, {ErrorCallout} from '../callout'
import * as Forms from '../forms'
import Modal from './modal'

type UserEmailModalProps = {
  modal: ModalHookType
}
type UserEmailFormData = {email: string}
export const UserEmailModal: React.FC<UserEmailModalProps> = ({modal}) => {
  const {handleSubmit, control, watch, reset} = useForm({defaultValues: {email: ''}})
  const {
    state: {user},
  } = useAuthContext()

  const mutation = userClient.useUpdateEmailMutation()
  const onSubmit = async (values: UserEmailFormData) => {
    mutation.mutate(values.email)
  }

  return (
    <Modal.Wrapper
      className="user-email"
      open={modal.isOpen}
      onOpen={() => {
        mutation.reset()
        reset()
      }}
    >
      <Forms.Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <h1>Change Email</h1>
        </Modal.Header>
        {!mutation.isSuccess && (
          <Modal.Body>
            {mutation.isError && <ErrorCallout error={mutation.error} />}
            <Forms.Row>
              <p>
                We will send a validation link to your new email address. Your email will be updated once you click the
                link.
              </p>
            </Forms.Row>
            <Forms.Row>
              <TextInput label="New Email" control={control as any} rules={{required: true}} name="email" />
            </Forms.Row>
          </Modal.Body>
        )}
        {mutation.isSuccess && (
          <Modal.Body>
            <Callout
              type="success"
              title="Verification Email Sent"
              message={`An email has been sent to: ${watch('email')}`}
            />
          </Modal.Body>
        )}
        <Modal.Footer>
          <div className="group"></div>
          <Button
            color="secondary"
            disabled={mutation.isLoading}
            label={mutation.isSuccess ? 'Close' : 'Cancel'}
            icon="close"
            onClick={() => modal.close()}
          />
          {!mutation.isSuccess && (
            <Button type="submit" disabled={mutation.isLoading} loading={mutation.isLoading} label="Save" icon="save" />
          )}
        </Modal.Footer>
      </Forms.Form>
    </Modal.Wrapper>
  )
}
export default UserEmailModal
