import {Control, RegisterOptions, useController} from 'react-hook-form'

type CheckboxInputProps = {
  name: string
  placeholder?: string
  disabled?: boolean
  control: Control<any>
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}
export const CheckboxInput = ({name, control, rules, disabled = false}: CheckboxInputProps) => {
  const {
    field: {ref, onChange, value, onBlur: controllerOnBlur, ...inputProps},
    fieldState: {error},
  } = useController<any>({name, control, defaultValue: false, rules})
  return (
    <div
      className="input checkbox"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onChange(!value)
      }}
    >
      <label htmlFor={name}>
        <input type="checkbox" checked={value} className="checkbox-input" disabled={disabled} readOnly />
        <div className="checkbox-control">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
            <path fill="none" stroke="currentColor" strokeWidth="3" d="M1.73 12.91l6.37 6.37L22.79 4.59" />
          </svg>
        </div>
      </label>
    </div>
  )
}

export default CheckboxInput
