import analytics from '@scavatec/timeclock-lib/analytics'
import * as client from '@scavatec/timeclock-lib/api/settings'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {CrewDoc} from '@scavatec/timeclock-lib/database/models/crew'
import {crewsAtom} from '@scavatec/timeclock-lib/state/entities'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import useFilterSet from 'hooks/use-filter-set'
import useFuse from 'hooks/use-fuse'
import {useAtom} from 'jotai'
import React, {useEffect} from 'react'
import {useNavigate} from 'react-router'
import Button from 'ui/button'
import CrewCard from 'ui/crew'
import EmptyState from 'ui/empty-state'
import CrewTable from 'ui/tables/crew-table'
import CrewListToolbar, {CrewListFilters} from 'ui/toolbars/crew-list-toolbar'

const fuseOptions = {
  threshold: 0.1,
  shouldSort: true,
  keys: ['name'],
}

type CrewListProps = {}
export const CrewList: React.FC<CrewListProps> = ({children}) => {
  const navigate = useNavigate()
  const {
    crews: {syncIfStale},
  } = useDataContext()
  const preferredView = useOrganizationSetting('account.preferred_view')
  const filterSet = useFilterSet<CrewListFilters>({
    search: '',
    view: preferredView.value,
  })
  const [crews] = useAtom(crewsAtom)
  useEffect(() => {
    analytics.event('w.j.1.0-view')
    syncIfStale()
  }, [])
  const {search, view} = filterSet.filters
  const filtered = useFuse(crews, search, fuseOptions)
  return (
    <div className="page crews">
      <CrewListToolbar filterSet={filterSet} />
      <div className="page-content">
        {crews.length <= 0 && (
          <EmptyState
            title="No Crews"
            description="No Crews have been added to your account yet. To add your first Crew, click the Add Crew button in the top right."
          >
            <Button
              label="Add Crew"
              color="clock-in"
              icon="plus"
              onClick={() => {
                analytics.event('w.j.1.0-click-add-crew-empty')
                navigate('/crews/new')
              }}
            />
          </EmptyState>
        )}
        {crews.length > 0 && filtered.length <= 0 && (
          <EmptyState
            title="No Crews Found"
            description="
              No crews were found with the selected filters. Change the filters
              above or create a new crew by clicking the 'Add Crew' button in the
              top right."
          >
            <Button
              label="Add Crew"
              color="clock-in"
              icon="plus"
              onClick={() => {
                analytics.event('w.j.1.0-click-add-crew-empty')
                navigate('/crews/new')
              }}
            />
          </EmptyState>
        )}
        {preferredView.value === 'list' ? (
          <CrewTable
            data={filtered}
            analyticsId="w.j.1.0"
            onRowClick={(crewDocument: CrewDoc) => {
              analytics.event('w.j.1.0-click-tile')
              navigate(`/crews/${crewDocument.uuid}`)
            }}
          />
        ) : (
          <div className="cards crew-cards">
            {filtered.map((crewDocument) => {
              return (
                <CrewCard
                  key={crewDocument.uuid}
                  crew={crewDocument}
                  onClick={() => {
                    analytics.event('w.j.1.0-click-tile')
                    navigate(`/crews/${crewDocument.uuid}`)
                  }}
                />
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
export default CrewList
