import React from 'react'
import Toolbar from '../toolbar'
import {TextInput} from '../forms'
import {ButtonGroup} from '../button'
import useAPI from '../../hooks/use-api'
import {FilterSet} from '../../hooks/use-filter-set'
import {navigate} from 'hookrouter'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import analytics from '@scavatec/timeclock-lib/analytics'
import {useNavigate} from 'react-router'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'

export type CrewListFilters = {
  view: 'cards' | 'list' | string
  search: string
}
type CrewListToolbarProps = {
  filterSet: FilterSet<CrewListFilters>
}
export const CrewListToolbar: React.FC<CrewListToolbarProps> = ({filterSet}) => {
  const preferredView = useOrganizationSetting('account.preferred_view')
  const navigate = useNavigate()
  const refreshRequest = useAPI()
  const {
    crews: {requestSync},
  } = useDataContext()
  return (
    <div className="toolbars">
      <Toolbar.Bar>
        <Toolbar.Group>
          <TextInput
            label="Search"
            placeholder="Search"
            onFocus={() => analytics.event('w.j.1.0-focus-search')}
            {...filterSet.getInputProps('search')}
            minimal
          />
        </Toolbar.Group>
        <Toolbar.Group align="right">
          <Toolbar.Button
            icon="reload"
            label="Refresh"
            color="blue"
            mode="secondary"
            loading={refreshRequest.loading}
            onClick={() => {
              analytics.event('w.j.1.0-submit-refresh')
              refreshRequest.requestAsync(requestSync)
            }}
          />
          <Toolbar.Button
            icon="plus"
            label="Add Crew"
            onClick={() => {
              analytics.event('w.j.1.0-click-add-crew')
              navigate('/crews/new')
            }}
          />
        </Toolbar.Group>
      </Toolbar.Bar>
      <Toolbar.Bar small>
        <Toolbar.Group></Toolbar.Group>
        <Toolbar.Group></Toolbar.Group>
        <Toolbar.Group align="right">
          <Toolbar.Item label="View">
            <ButtonGroup
              color="blue"
              value={preferredView.value}
              error=""
              options={[
                {label: 'Cards', icon: 'grid-alt', value: 'cards'},
                {label: 'List', icon: 'list', value: 'list'},
              ]}
              onValueChange={(v) => {
                analytics.event('w.j.1.0-change-view', {
                  view: v,
                })
                preferredView.update(v)
              }}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar.Bar>
    </div>
  )
}
export default CrewListToolbar
