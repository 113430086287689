import analytics from '@scavatec/timeclock-lib/analytics'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import {useAtom} from 'jotai'
import React, {useCallback} from 'react'
import {useNavigate, useParams} from 'react-router'
import IntervalsTable from 'ui/tables/intervals-table'
import IntervalsTableLegacy from 'ui/tables/intervals-table-legacy'
import {jobListViewAtom, jobSearchAtom} from './job-state'

const screenID = 'w.g.2.0-daily-totals'

type JobIntervals = {}
const JobIntervals: React.FC<JobIntervals> = ({}) => {
  const {uuid = ''} = useParams()
  const [search] = useAtom(jobSearchAtom)
  const [view] = useAtom(jobListViewAtom)
  const navigate = useNavigate()
  const onClick = useCallback(() => {
    analytics.event(`${screenID}-click-tile`)
    navigate(`/employees/${uuid}/transactions`)
  }, [])

  const reportEngineSetting = useOrganizationSetting('reporting.engine_version')
  return reportEngineSetting.value < 2 ? (
    <IntervalsTableLegacy
      search={search}
      hideColumns={['job']}
      view={view}
      job={uuid}
      showEmployee
      analyticsId={screenID}
      onClick={onClick}
    />
  ) : (
    <IntervalsTable
      search={search}
      hideColumns={['job']}
      view={view}
      job={uuid}
      showEmployee
      analyticsId={screenID}
      onClick={onClick}
    />
  )
}
export default JobIntervals
