import {mdiInformationOutline} from '@mdi/js'
import {STARTER_TIER_NAME} from '@scavatec/timeclock-lib/api/subscription'
import {activeEmployeesAtom} from '@scavatec/timeclock-lib/state/entities'
import {useAtom} from 'jotai'
import React, {useState} from 'react'
import {usePopper} from 'react-popper'
import env from 'util/env'
import {MaterialIcon} from './icon'
import colors from '@scavatec/timeclock-lib/theme/colors'

export const isMonthlyPrice = (price: string) => {
  return price.includes('monthly')
}
export const getPrice = (product: string, price: string) => {
  if (product === STARTER_TIER_NAME) {
    return isMonthlyPrice(price) ? '2.50' : '25.00'
  }
  return isMonthlyPrice(price) ? '5.00' : '50.00'
}
export const getEmployeePrice = (product: string, price: string) => {
  if (product === STARTER_TIER_NAME) {
    return isMonthlyPrice(price) ? '2.50' : '2.08'
  }
  return isMonthlyPrice(price) ? '5.00' : '4.17'
}

export const getPeriodEmployeePrice = (product: string, price: string) => {
  if (product === STARTER_TIER_NAME) {
    return isMonthlyPrice(price) ? '2.50' : '25.00'
  }
  return isMonthlyPrice(price) ? '5.00' : '50.00'
}
export const getBaseFee = (product: string, price: string) => {
  if (product === STARTER_TIER_NAME) {
    return isMonthlyPrice(price) ? '10.00' : '100.00'
  }
  return isMonthlyPrice(price) ? '20.00' : '200.00'
}

export const Pricing = ({
  product = STARTER_TIER_NAME,
  price = 'usd-monthly',
  activeEmployeeCount = 0,
}: {
  product?: string
  price?: string
  activeEmployeeCount?: number
}) => {
  const count = activeEmployeeCount
  const period = isMonthlyPrice(price) ? 'monthly' : 'yearly'
  const baseFee = parseFloat(getBaseFee(product, price))
  const employeeTotal =
    parseFloat(getPeriodEmployeePrice(product, price)) * count
  return (
    <div className="pricing">
      <h6>
        Pricing - {product === STARTER_TIER_NAME ? 'Starter' : 'Plus'}
        {' / '}
        {isMonthlyPrice(price) ? 'Monthly' : 'Yearly'}
      </h6>
      <h5>
        {`Based on your current Active Employee count, your anticipated ${period} bill will be:`}
      </h5>
      <div className="line-item">
        <div className="row">
          <div>Base Fee</div>
          <div className="price">{`$${baseFee}`}</div>
        </div>
      </div>
      <div className="line-item">
        <div className="row">
          <div>
            <strong>{count}</strong> Active Employees
            <HelpPopover text="Your actual bill will reflect the number of Active Employees you have during that period. You can mark employees inactive anytime in your admin portal." />
          </div>
          {count <= 5 ? (
            <div className="price">
              <div className="strike">{`$${employeeTotal}`}</div>$0
            </div>
          ) : (
            <div className="price">{`$${employeeTotal}`}</div>
          )}
        </div>
        {count <= 5 ? (
          <div className="description">
            No charge for less than 5 employees!
          </div>
        ) : (
          <div className="description">{`$${getPeriodEmployeePrice(
            product,
            price,
          )}/${
            period === 'monthly' ? 'month' : 'year'
          } x ${count} employees`}</div>
        )}
      </div>
      <div className="line-item total">
        <div className="row">
          <div>
            <strong>{`Total ${period}`}</strong>
          </div>
          <div className="price">{`$${
            count <= 5 ? baseFee : employeeTotal + baseFee
          }`}</div>
        </div>
      </div>
    </div>
  )
}
export default () => null

type HelpPopoverProps = {
  text: string
}
const HelpPopover = ({text}: HelpPopoverProps) => {
  const [open, setOpen] = useState(false)
  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  )
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const {styles, attributes, update} = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom',
      modifiers: [
        {name: 'arrow', options: {element: arrowElement}},
        {name: 'offset', options: {offset: [0, 8]}},
        {name: 'preventOverflow', options: {padding: 20}},
      ],
    },
  )

  const setVisibility = (b: boolean) => {
    setOpen(b)
    update && update()
  }

  return (
    <div className="popover-container">
      <div
        ref={setReferenceElement}
        className="trigger"
        onMouseOver={() => setVisibility(true)}
        onMouseOut={() => setVisibility(false)}>
        <MaterialIcon
          path={mdiInformationOutline}
          size={0.7}
          color={colors.primary}
        />
      </div>
      <div
        ref={setPopperElement}
        className="popover-content"
        style={styles.popper}
        data-open={open}
        {...attributes.popper}>
        {text}
        <div className="arrow" ref={setArrowElement} style={styles.arrow} />
      </div>
    </div>
  )
}
