import React from 'react'
import {FieldError} from 'react-hook-form'
import useClassnames from '../../hooks/use-classnames'

export const FormError: React.FC<{
  label?: string
  error?: string | FieldError
}> = ({label = '', error}) => {
  const errorString =
    typeof error === 'string'
      ? error
      : isFieldError(error)
      ? parseErrorMessage(error, label)
      : ''
  if (errorString === '') return null
  return <span className="form-error">{errorString}</span>
}

export const LabelErrorRow: React.FC<{
  label: string
  error: string | FieldError | undefined
}> = ({label, error}) => {
  const classes = useClassnames({
    error: typeof error === 'string' ? error.length > 0 : error !== undefined,
  })
  return (
    <div className="form-label-row">
      <label className={classes}>{label}</label>
      <FormError error={error} label={label} />
    </div>
  )
}

const isFieldError = (v: any): v is FieldError => v !== undefined

const parseErrorMessage = (error: FieldError, label: string) => {
  if (error.message && error.message !== '') {
    return error.message
  }
  switch (error.type) {
    case 'required':
      return `${label} is required`
    case 'minSelected':
      return `Please select at least one option`
    case 'maxSelected':
      return `Too many items eselected`
    case 'minLength':
      return 'Does not meet the minimum length'
  }
  return ''
}
