import analytics from '@scavatec/timeclock-lib/analytics'
import React, {useEffect, useState} from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

type ChangelogProps = {}
export const Changelog: React.FC<ChangelogProps> = ({children}) => {
  const [content, setContent] = useState('')
  useEffect(() => {
    analytics.event('w.l.1.0-view')
    const load = async () => {
      const res = await fetch('./public-changelog.md')
      const text = await res.text()
      setContent(text)
    }
    load()
  }, [])
  return (
    <div className="page changelog">
      <div className="content">
        <h1>Changelog</h1>
        <div className="markdown">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
        </div>
      </div>
    </div>
  )
}
export default Changelog
