import React from 'react'
import Button, {ButtonProps} from './button'

type ToolbarGroupProps = {
  align?: 'left' | 'right' | 'center'
}
export const ToolbarGroup: React.FC<ToolbarGroupProps> = ({
  children,
  align = 'left',
}) => {
  return <div className={`toolbar-group ${align}`}>{children}</div>
}

type ToolbarButtonProps = {
  icon?: string
  label: string
  onClick: OnClickEvent
} & Partial<ButtonProps>
export const ToolbarButton: React.FC<ToolbarButtonProps> = ({...props}) => {
  return <Button {...props} />
}

type ToolbarProps = {
  small?: boolean
}
export const Toolbar: React.FC<ToolbarProps> = ({small = false, children}) => {
  return <div className={`toolbar ${small ? 'small' : ''}`}>{children}</div>
}

type ToolbarItemProps = {
  label: string
}
export const ToolbarItem: React.FC<ToolbarItemProps> = ({label, children}) => {
  return (
    <div className={`item`}>
      <div className="label">{label}</div>
      {children}
    </div>
  )
}

type BarsProps = {}
export const Bars: React.FC<BarsProps> = ({children}) => {
  return <div className={`toolbars`}>{children}</div>
}
type DividerProps = {
  type: 'vertical' | 'horizontal'
}
export const ToolbarDivider: React.FC<DividerProps> = ({type}) => {
  return <div className={`divider-${type}`}></div>
}
export default {
  Bars,
  Bar: Toolbar,
  Divider: ToolbarDivider,
  Group: ToolbarGroup,
  Button: ToolbarButton,
  Item: ToolbarItem,
}
