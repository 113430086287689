import analytics from '@scavatec/timeclock-lib/analytics'
import {useDeviceListQuery} from '@scavatec/timeclock-lib/api/device'
import {RemoteDevice} from '@scavatec/timeclock-lib/api/types'
import {useDevices} from '@scavatec/timeclock-lib/state/entities'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import useDataModal from 'hooks/use-data-modal'
import useFilterSet from 'hooks/use-filter-set'
import useFuse from 'hooks/use-fuse'
import React, {useEffect} from 'react'
import {useNavigate} from 'react-router'
import semver from 'semver'
import Button from 'ui/button'
import DeviceCard from 'ui/device'
import EmptyState from 'ui/empty-state'
import DeviceUpgradeModal from 'ui/modals/device-upgrade-modal'
import LegacyDeviceModal from 'ui/modals/legacy-device-modal'
import DeviceTable from 'ui/tables/device-table'
import DeviceListToolbar, {DeviceListFilters} from 'ui/toolbars/device-list-toolbar'

const fuseOptions = {
  threshold: 0.1,
  shouldSort: true,
  keys: ['name'],
}

type DeviceListProps = {}
export const DeviceList: React.FC<DeviceListProps> = ({children}) => {
  const devices = useDevices()
  const deviceListQuery = useDeviceListQuery()
  const navigate = useNavigate()
  const preferredView = useOrganizationSetting('account.preferred_view')
  const filterSet = useFilterSet<DeviceListFilters>({
    search: '',
    showInactive: false,
    view: preferredView.value,
  })
  useEffect(() => {
    deviceListQuery.refetch()
    analytics.event('w.i.1.0-view')
  }, [])
  const {search, view} = filterSet.filters
  const filtered: RemoteDevice[] = useFuse(devices, search, fuseOptions)
  const legacyModal = useDataModal<RemoteDevice | null>(() => null)
  const upgradeModal = useDataModal<RemoteDevice | null>(() => null)
  const navToDevice = (device: RemoteDevice) => {
    analytics.event('w.i.1.0-click-tile', {
      version: device.appVersion,
    })
    let ver = device.appVersion ?? ''
    if (device.appName === 'TimeClock ST' || device.appVersion?.startsWith('1.')) {
      legacyModal.open(device)
      return
    }
    if (semver.valid(ver) && semver.lt(ver, '2.15.0')) {
      upgradeModal.open(device)
      return
    }
    navigate(`/devices/${device.id}`)
  }

  return (
    <div className="page devices">
      <DeviceListToolbar filterSet={filterSet} />
      <LegacyDeviceModal modal={legacyModal} />
      <DeviceUpgradeModal modal={upgradeModal} />
      <div className="page-content">
        {devices.length <= 0 && (
          <EmptyState
            title="No Devices"
            description="No Devices have been added to your account yet. To add your first Device, click the Add Device button in the top right."
          >
            <Button
              label="Add Device"
              color="clock-in"
              icon="plus"
              onClick={() => {
                analytics.event('w.i.1.0-click-add-device-empty')
                navigate('/devices/new')
              }}
            />
          </EmptyState>
        )}
        {devices.length > 0 && filtered.length <= 0 && (
          <EmptyState
            title="No Devices Found"
            description="
              No devices were found with the selected filters. Change the filters
              above or create a new device by clicking the 'Add Device' button in the
              top right."
          >
            <Button
              label="Add Device"
              color="clock-in"
              icon="plus"
              onClick={() => {
                analytics.event('w.i.1.0-click-add-device-empty')
                navigate('/devices/new')
              }}
            />
          </EmptyState>
        )}
        {preferredView.value === 'list' ? (
          <DeviceTable analyticsId="w.i.1.0" data={filtered} onRowClick={navToDevice} />
        ) : (
          <div className="cards">
            {filtered.map((d) => {
              return <DeviceCard key={d.id} device={d} onClick={() => navToDevice(d)} />
            })}
          </div>
        )}
      </div>
    </div>
  )
}
export default DeviceList
