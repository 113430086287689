import React from 'react'
import {RefObject} from 'react'
import {ChangeHandler} from 'react-hook-form'
import useClassnames from '../../hooks/use-classnames'
import Badge from '../badge'
import {LabelErrorRow} from './common'

type RangeInputProps = {
  label: string
  error: string
  name: string
  showValue?: string
  description?: string
  min?: number | string
  max?: number | string
  step?: number | string
  onFocus?: ChangeHandler
  onBlur?: ChangeHandler
}
export const RangeInput = React.forwardRef<HTMLInputElement, RangeInputProps>(
  function RangeInput(props, ref) {
    const {
      error,
      label,
      name,
      min = 0,
      max = 20,
      step = 1,
      description = '',
      onFocus = () => null,
      showValue = '',
      ...rest
    } = props
    const classes = useClassnames({error: error.length > 0})
    return (
      <div className={`input range`}>
        <LabelErrorRow label={label} error={error} />
        {description.length > 0 && (
          <span className="description">{description}</span>
        )}
        <div className="row">
          <input
            name={name}
            type="range"
            min={min}
            max={max}
            step={step}
            className={classes}
            ref={ref}
            {...rest}
          />
          {showValue.length > 0 && <Badge label={showValue} type="value" />}
        </div>
      </div>
    )
  },
)

export default RangeInput
