import 'react-app-polyfill/stable'
import 'react-app-polyfill/ie11'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import DatabaseProvider from '@scavatec/timeclock-lib/contexts/database-context'
import {apiEnvironmentAtom, isAuthenticatedAtom} from '@scavatec/timeclock-lib/state/environment'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import 'indexeddb-getall-shim'
import {Provider as Jotai, useAtom} from 'jotai'
import 'rc-time-picker/assets/index.css'
import React, {useEffect} from 'react'
import 'react-day-picker/lib/style.css'
import 'react-phone-number-input/style.css'
import {BrowserRouter} from 'react-router-dom'
import {SurveyManager} from 'survey/survey-manager'
import SyncManager from 'sync-manager'
import CustomerMessages from 'ui/customer-messages'
import env from 'util/env'
import 'util/polyfills/global-this'
import {bootstrap} from './bootstrap'
import Drawer from './drawer'
import Providers from './providers'
import Router from './router'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import './styles/app.sass'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import {Tidio} from 'util/tidio'
import {Global} from '@emotion/react'

const stripePromise = loadStripe(env.services.stripe.key)

// Bootstrap all libraries we need to initialize for the application
const database = bootstrap()

// if (env.dev) {
//   document.title = `${import.meta.env.MODE}`
// }

// We use a separate component for this content because we need to access the auth context
function Content() {
  const [isLoggedIn] = useAtom(isAuthenticatedAtom)
  const themeSetting = useOrganizationSetting('account.theme')
  return (
    <div className={`container theme-${themeSetting.value === 'dark' ? 'dark' : 'light'}`}>
      <Global
        styles={{
          html: {
            backgroundColor: themeSetting.value === 'dark' ? '#2a2a2a' : '#fafafa',
          },
        }}
      />
      {isLoggedIn && <Drawer />}
      {isLoggedIn && <SyncManager />}
      {isLoggedIn && <SurveyManager />}
      <Tidio />
      <div className={`content ${isLoggedIn ? 'has-drawer' : 'no-drawer'}`}>
        {isLoggedIn && <CustomerMessages />}
        <Router authenticated={isLoggedIn} />
      </div>
    </div>
  )
}

function Init() {
  const [u, setApiEnv] = useAtom(apiEnvironmentAtom)
  useEffect(() => {
    setApiEnv({
      legacyBaseUrl: env.api.legacyHost,
      baseUrl: env.api.host,
      version: env.api.version,
    })
  }, [])
  return null
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Jotai>
        <BrowserRouter>
          <div className="app">
            <Init />
            <DatabaseProvider database={database}>
              <Elements stripe={stripePromise}>
                <Providers>
                  <Content />
                </Providers>
              </Elements>
            </DatabaseProvider>
          </div>
        </BrowserRouter>
      </Jotai>
    </QueryClientProvider>
  )
}

export default App
