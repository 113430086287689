import {employeeName} from '@scavatec/timeclock-lib/formatters'
import {CellProps} from 'react-table'
import {ColumnSizes} from 'ui/tables/common'
import {PreferredTime} from 'util/formatters'

export const employeeColumns = () => [
  {
    Header: 'ID',
    accessor: 'employee.identifier',
    width: ColumnSizes.identifier,
  },
  {
    Header: 'First Name',
    accessor: 'employee.firstName',
    width: 'auto',
  },
  {
    Header: 'Last Name',
    accessor: 'employee.lastName',
    width: 'auto',
  },
  {
    Header: 'Name',
    accessor: 'employee.name',
    Cell: ({row}: CellProps<any>) => employeeName(row.original.employee),
    width: 'auto',
  },
]

export const jobColumns = () => [
  {
    Header: 'ID',
    accessor: 'job.identifier',
    width: ColumnSizes.identifier,
  },
  {
    Header: 'Name',
    accessor: 'job.firstName',
    Cell: ({cell: {value}}: CellProps<any>) => {
      return value ? value : '<none>'
    },
    width: 'auto',
  },
]

export const timeColumns = () => [
  {
    Header: 'Total Time',
    accessor: 'shifts.total.seconds',
    Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
    width: ColumnSizes.time,
  },
  {
    Header: 'Total Time (Decimal)',
    accessor: 'shifts.total.decimalHours',
    width: ColumnSizes.time,
  },
  {
    Header: 'Paid Time',
    accessor: 'shifts.paid.seconds',
    Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
    width: ColumnSizes.time,
  },
  {
    Header: 'Paid Time (Decimal)',
    accessor: 'shifts.paid.decimalHours',
    width: ColumnSizes.time,
  },
]

export const breakColumns = () => [
  {
    Header: 'Break Total Time',
    accessor: 'breaks.total.seconds',
    Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
    width: ColumnSizes.time,
  },
  {
    Header: 'Break Total Time (Decimal)',
    accessor: 'breaks.total.decimalHours',
    width: ColumnSizes.time,
  },
  {
    Header: 'Break Paid Time',
    accessor: 'breaks.paid.seconds',
    Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
    width: ColumnSizes.time,
  },
  {
    Header: 'Break Paid Time (Decimal)',
    accessor: 'breaks.paid.decimalHours',
    width: ColumnSizes.time,
  },
  {
    Header: 'Break Unpaid Time',
    accessor: 'breaks.unpaid.seconds',
    Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
    width: ColumnSizes.time,
  },
  {
    Header: 'Break Unpaid Time (Decimal)',
    accessor: 'breaks.unpaid.decimalHours',
    width: ColumnSizes.time,
  },
]

export const overtimeColumns = () => [
  {
    Header: 'Overtime',
    accessor: 'overtime.total.seconds',
    Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
    width: ColumnSizes.time,
  },
  {
    Header: 'Overtime (Decimal)',
    accessor: 'overtime.total.decimalHours',
    width: ColumnSizes.time,
  },
]
