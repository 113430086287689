import {ClientError} from '@scavatec/timeclock-lib/api/query-utils'
import {DeviceFields, RemoteDevice, SettingKey} from '@scavatec/timeclock-lib/api/types'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useFeatureFlags} from '@scavatec/timeclock-lib/state/subscription'
import useClassnames from 'hooks/use-classnames'
import React, {useEffect, useMemo} from 'react'
import {Path, UseFormReturn, WatchObserver} from 'react-hook-form'
import {PlusBadge} from 'ui/badge'
import {CheckboxInput} from 'ui2/inputs/checkbox-input'
import RadioGroupInput from 'ui2/inputs/radio-group-input'
import SelectInput, {MultiSelectInput} from 'ui2/inputs/select-input'
import TextAreaInput from 'ui2/inputs/text-area-input'
import TextInput from 'ui2/inputs/text-input'
import * as Forms from '../forms'
import {update as updateSetting} from '@scavatec/timeclock-lib/api/settings'
import {ErrorCallout, RoundedErrorCallout} from 'ui/callout'
import {defaultDeviceData, DeviceFormData} from '@scavatec/timeclock-lib/forms/device'

export const deviceToFields = (d: RemoteDevice): DeviceFormData => ({
  ...defaultDeviceData,
  data: {
    appName: '',
    appVersion: '',
    identifier: '',
    name: '',
    note: '',
    osName: '',
    osVersion: '',
    apiVersion: '',
  },
})

export const updateDeviceSetting = (setting: SettingKey, value: any) => (org: string, id: string) =>
  updateSetting(org, setting, [
    {
      entityType: 'device',
      entityId: id,
      value: value,
    },
  ])

type DeviceSettingProps = {
  disabled?: boolean
  label: string
  description: string
  badge?: JSX.Element
  children: JSX.Element
  inset?: boolean
  onClick?: () => void
}
const DeviceSetting = ({
  disabled = false,
  inset = false,
  badge,
  label,
  description,
  onClick,
  children,
}: DeviceSettingProps) => {
  const classes = useClassnames({
    'device-setting': true,
    disabled,
    inset,
    clickable: !disabled && onClick !== undefined,
  })
  return (
    <div className={classes} onClick={disabled ? undefined : onClick}>
      <div>
        <div className="row">
          <div className="label">{label}</div>
          {badge}
        </div>
        <div className="description">{description}</div>
      </div>
      <div className="input-column">{children}</div>
    </div>
  )
}

type DeviceFormProps = {
  pageId?: string
  form: UseFormReturn<DeviceFormData>
  isEdit?: boolean
}
export const DeviceForm: React.FC<DeviceFormProps> = ({pageId = '', form, isEdit = false}) => {
  const {watch, control, setValue, getValues} = form
  const values = watch()
  const flags = useFeatureFlags()
  const {
    crews: {items: crews},
    employees: {items: employees},
  } = useDataContext()

  const employeeOptions = useMemo(() => {
    return employees.map((e) => ({
      value: e.uuid,
      label: e.formattedName(),
    }))
  }, [employees])

  const crewOptions = useMemo(() => {
    return crews.map((c) => ({
      value: c.uuid,
      label: c.name,
    }))
  }, [crews])

  const toggleValue = (key: Path<DeviceFormData>) => () => {
    const current = getValues(key)
    setValue(key, !current)
  }

  useEffect(() => {
    if (!values.settings.requirePin && values.settings.allowPinChange) {
      setValue('settings.allowPinChange', false)
    }
  }, [setValue, values.settings.requirePin, values.settings.allowPinChange])
  useEffect(() => {
    if (!values.settings.jobs && values.settings.requireJobSelection) {
      setValue('settings.requireJobSelection', false)
    }
  }, [setValue, values.settings.jobs, values.settings.requireJobSelection])
  useEffect(() => {
    if (!values.settings.tips && values.settings.tipsAtIn) {
      setValue('settings.tipsAtIn', false)
    }
  }, [setValue, values.settings.tipsAtIn, values.settings.tips])
  useEffect(() => {
    if (!values.settings.reports && values.settings.reportEditing) {
      setValue('settings.reportEditing', false)
    }
    if (
      (!values.settings.usePassword || !values.settings.reportsPassword || !values.settings.reports) &&
      values.settings.reportsPassword
    ) {
      setValue('settings.reportsPassword', false)
    }
  }, [setValue, values.settings.usePassword, values.settings.reportsPassword, values.settings.reports])

  const warnReports = values.settings.reports && !values.settings.reportsPassword
  const showSecurityWarning =
    (values.settings.newPassword.length <= 0 || !values.settings.usePassword || warnReports) &&
    !values.settings.hasPassword &&
    (values.settings.accountManagement || warnReports)

  return (
    <>
      <h1>{`${isEdit ? 'Edit' : 'New'} Device`}</h1>
      <Forms.Row>
        <TextInput label="Name*" placeholder="Device name" name="data.name" control={control as any} />
        <TextAreaInput
          label="Note (only visible to admin)"
          placeholder="Device details"
          name="data.note"
          control={control as any}
        />
      </Forms.Row>
      <div className="device-setting-row">
        <div className="device-setting-col">
          <div className="device-setting-group">
            <h6>Clock Mode</h6>
            <RadioGroupInput
              name="settings.employeeMode"
              control={control as any}
              options={[
                {
                  label: 'All Employees',
                  value: 'all',
                  description: 'Kiosk mode allow all employees to clock in on this device',
                },
                {
                  label: 'Single Employee',
                  value: 'single',
                  description: 'A single employee will be using this device',
                },
                {
                  label: 'Crews',
                  value: 'crews',
                  description: 'Kiosk style mode with employees assigned to specific crews',
                },
              ]}
            />
            {values.settings.employeeMode !== 'all' && <hr />}
            {values.settings.employeeMode === 'single' && (
              <SelectInput
                name="settings.singleEmployee"
                style={{paddingBottom: 10, paddingTop: 5}}
                options={employeeOptions}
                control={control as any}
                label="Single Employee"
              />
            )}
            {values.settings.employeeMode === 'crews' && (
              <MultiSelectInput
                options={crewOptions}
                style={{paddingBottom: 10, paddingTop: 5}}
                control={control as any}
                label="Crews"
                name="settings.crews"
              />
            )}
          </div>
          <div className="device-setting-group">
            <h6>Employee Features</h6>
            <DeviceSetting
              label="Jobs"
              description="Allow job selection when clocking"
              onClick={toggleValue('settings.jobs')}
            >
              <CheckboxInput control={control as any} name="settings.jobs" />
            </DeviceSetting>
            <DeviceSetting
              inset
              disabled={!values.settings.jobs}
              label="Require Job Selection"
              description="Require a job to be selected before an employee can clock"
              onClick={toggleValue('settings.requireJobSelection')}
            >
              <CheckboxInput control={control as any} name="settings.requireJobSelection" />
            </DeviceSetting>
            <DeviceSetting
              label="Notes"
              description="Allow employees to leave notes when clocking"
              onClick={toggleValue('settings.notes')}
            >
              <CheckboxInput control={control as any} name="settings.notes" />
            </DeviceSetting>
            <DeviceSetting
              label="Self Serve Reports"
              description="Allow employees to review their hours on the clock screen"
              onClick={toggleValue('settings.selfServe')}
            >
              <CheckboxInput control={control as any} name="settings.selfServe" />
            </DeviceSetting>
            <DeviceSetting
              label="Require Pin"
              description="Employees will be required to enter their pin (if set) before the clock screen"
              onClick={toggleValue('settings.requirePin')}
            >
              <CheckboxInput control={control as any} name="settings.requirePin" />
            </DeviceSetting>
            <DeviceSetting
              inset
              disabled={!values.settings.requirePin}
              label="Allow Pin Changing"
              description="Employees will be allowed to change their own pin from the clock screen"
              onClick={toggleValue('settings.allowPinChange')}
            >
              <CheckboxInput control={control as any} name="settings.allowPinChange" />
            </DeviceSetting>
            <DeviceSetting
              label="Tips"
              description="Allow employees to report tips when clocking out"
              onClick={toggleValue('settings.tips')}
            >
              <CheckboxInput control={control as any} name="settings.tips" />
            </DeviceSetting>
            <DeviceSetting
              inset
              disabled={!values.settings.tips}
              label="Tips at Clock In"
              description="Enables tip collection at clock in"
              onClick={toggleValue('settings.tipsAtIn')}
            >
              <CheckboxInput control={control as any} name="settings.tipsAtIn" />
            </DeviceSetting>
            <DeviceSetting
              label="Employee Status Message"
              badge={<PlusBadge />}
              disabled={!flags.isTrialOrPlusTier}
              description="Allow employees to update their status message when clocking"
              onClick={toggleValue('settings.statusMessage')}
            >
              <CheckboxInput control={control as any} name="settings.statusMessage" />
            </DeviceSetting>
          </div>
        </div>
        <div className="device-setting-col">
          <div className="device-setting-group">
            <h6>Admin Features</h6>
            {showSecurityWarning && (
              <ErrorCallout>
                Sensitive data is accessible or modifiable without a password set.
                <ul>
                  {values.settings.accountManagement && (
                    <li>Anyone may change account data such as employees and jobs.</li>
                  )}
                  {warnReports ||
                    (!values.settings.hasPassword && values.settings.newPassword.length <= 0 && (
                      <li>Account wide reports can be viewed by anyone.</li>
                    ))}
                </ul>
              </ErrorCallout>
            )}
            <DeviceSetting
              label="Use Device Password"
              description="Set a password specific to this device to secure administrative and sensitive account data."
              onClick={toggleValue('settings.usePassword')}
            >
              <CheckboxInput control={control as any} name="settings.usePassword" />
            </DeviceSetting>
            {values.settings.usePassword && (
              <div className="device-setting" style={{paddingLeft: 15, paddingRight: 0}}>
                <TextInput
                  label={isEdit && values.settings.hasPassword ? 'Replace Device Password' : 'Device Admin Password'}
                  control={control as any}
                  placeholder={isEdit && values.settings.hasPassword ? 'Replace password' : 'Device specific password'}
                  name="settings.newPassword"
                  rules={{required: values.settings.hasPassword}}
                  description={
                    isEdit && values.settings.hasPassword
                      ? 'Replace admin password specific to this device. Do not use the same password as your account for security.'
                      : 'Admin password specific to this device. Do not use the same password as your account for security.'
                  }
                />
              </div>
            )}
            <DeviceSetting
              label="Account Management"
              description="Allow management of account data such as employees, jobs and settings"
              onClick={toggleValue('settings.accountManagement')}
            >
              <CheckboxInput control={control as any} name="settings.accountManagement" />
            </DeviceSetting>
            <DeviceSetting
              label="Reports"
              description="Allow reporting from this device"
              onClick={toggleValue('settings.reports')}
            >
              <CheckboxInput control={control as any} name="settings.reports" />
            </DeviceSetting>
            <DeviceSetting
              inset
              disabled={!values.settings.reports || !values.settings.usePassword}
              label="Reports Require Device Admin Password"
              description="Require device's admin password for accessing reports"
              onClick={toggleValue('settings.reportsPassword')}
            >
              <CheckboxInput control={control as any} name="settings.reportsPassword" />
            </DeviceSetting>
            <DeviceSetting
              inset
              disabled={!values.settings.reports}
              label="Allow Report Editing"
              description="Allow adding/editing of employee times"
              onClick={toggleValue('settings.reportEditing')}
            >
              <CheckboxInput control={control as any} name="settings.reportEditing" />
            </DeviceSetting>
          </div>
          <div className="device-setting-group">
            <h6>Misc</h6>
            <DeviceSetting
              label="Name Avatars"
              description="Show employee name avatars on clock list"
              onClick={toggleValue('settings.avatars')}
            >
              <CheckboxInput control={control as any} name="settings.avatars" />
            </DeviceSetting>
            <DeviceSetting
              label="Toast Notification"
              description="Show toast notification when successfully clocking"
              onClick={toggleValue('settings.toastNotification')}
            >
              <CheckboxInput control={control as any} name="settings.toastNotification" />
            </DeviceSetting>
          </div>
        </div>
      </div>
    </>
  )
}
export default DeviceForm
