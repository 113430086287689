import React, {useMemo} from 'react'
import {CellProps} from 'react-table'
import Table, {TableRowClick} from 'ui/table'
import {Currency, PreferredTime} from 'util/formatters'
import EmptyState from 'ui/empty-state'
import DailyTotalCard from 'ui/daily-total'
import {FilterSet} from '../../hooks/use-filter-set'
import {EmployeesReportFilters} from 'ui/toolbars/report-toolbar'
import useFuse from '../../hooks/use-fuse'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useReportContext, transformAllDailyTotals} from '@scavatec/timeclock-lib/contexts/report-context'
import {ColumnSizes} from 'ui/tables/common'
import {useAtom} from 'jotai'
import {reportSearchAtom} from '../reports/report-state'
import Button from 'ui/button'
import {useNavigate} from 'react-router'

const analyticsId = 'w.c.1.0-daily-totals'

type DailyTotalsProps = {}
const DailyTotals: React.FC<DailyTotalsProps> = ({}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Day',
        accessor: 'day',
        width: ColumnSizes.date,
      },
      {
        Header: 'Employee',
        accessor: 'employee',
        width: ColumnSizes.name,
      },
      {
        Header: 'Job',
        accessor: 'task',
        width: ColumnSizes.name,
      },
      {
        Header: 'Time',
        accessor: 'total',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Paid Time',
        accessor: 'paid',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Regular Time',
        accessor: (row: any) => row.paid - row.overtime,
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time + 20,
      },
      {
        Header: 'Overtime',
        accessor: 'overtime',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Breaks',
        accessor: 'break',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Pay',
        accessor: 'pay',
        Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value} />,
        width: ColumnSizes.currency,
      },
      {
        Header: 'Tips',
        accessor: 'tips',
        Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value} />,
        width: ColumnSizes.currency,
      },
    ],
    [],
  )
  const {rawReport} = useReportContext()
  const {
    employees: {items: employees},
  } = useDataContext()
  let totals = useMemo(() => {
    if (rawReport) {
      let totals = transformAllDailyTotals(rawReport.dailyTotals, employees, rawReport.transactions)
      return totals
    }
    return []
  }, [rawReport])
  const navigate = useNavigate()

  // Apply filters
  const [search] = useAtom(reportSearchAtom)
  totals = useFuse(totals, search, {
    threshold: 0.1,
    shouldSort: true,
    keys: ['employee', 'task', 'day'],
  })

  if (totals.length <= 0) {
    return (
      <EmptyState
        title="No Daily Totals in Range"
        description="No daily totals were found in the selected date range. Try changing the dates or add a new time by clicking 'Add Time'."
      >
        <Button
          label="Need Help?"
          color="clock-in"
          onClick={() => {
            navigate('/help')
          }}
        />
      </EmptyState>
    )
  }
  return (
    <Table
      columns={columns}
      analyticsId={analyticsId}
      data={totals}
      noHover
      initialSortBy={[{id: 'day', desc: true}]}
      onRowClick={() => null}
      getRowProps={(row) => {
        return {
          className: row.original.invalid ? 'invalid' : '',
        }
      }}
    />
  )
}
export default DailyTotals
