import {Control, RegisterOptions, ValidationRule, useController} from 'react-hook-form'
import useClassnames from '../../hooks/use-classnames'
import {InputLabelRow, FieldError} from './common'
import CurrencyInputLib from 'react-currency-input-field'
import {currencyMap} from '@scavatec/timeclock-lib/formatters'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import TextInput from './text-input'

const decimalRegex = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/

type TextInputProps = {
  name: string
  label: string
  placeholder?: string
  control: Control<any>
  rules?: RegisterOptions
  style?: any
  defaultValue?: string | number
}

export const CurrencyInput = ({rules, ...props}: TextInputProps) => {
  const currencySetting = useOrganizationSetting('account.currency')
  return (
    <TextInput
      {...props}
      prefix={currencyMap[currencySetting.value.toUpperCase()] ?? '$'}
      rules={{
        ...(rules ? rules : ({} as any)),
        pattern: {
          value: decimalRegex,
          message: 'Must be a number with up to 2 decimal places.',
        } as ValidationRule<RegExp>,
      }}
    />
  )
}

export default CurrencyInput
