import {isAuthenticatedAtom} from '@scavatec/timeclock-lib/state/environment'
import {subscriptionAccessAtom} from '@scavatec/timeclock-lib/state/subscription'
import {useToastContext} from 'contexts/toast-context'
import {useAtom} from 'jotai'
import EmployeeIntervals from 'pages/employees/employee-intervals'
import EmployeeMap from 'pages/employees/employee-map'
import EmployeeTimeEntries from 'pages/employees/employee-time-entries'
import JobDailyTotals from 'pages/jobs/job-daily-totals'
import JobIntervals from 'pages/jobs/job-intervals'
import JobTimeEntries from 'pages/jobs/job-time-entries'
import DailyTotalsLegacy from 'pages/reports-legacy/daily-totals'
import EmployeeTotalsLegacy from 'pages/reports-legacy/employee-totals'
import IntervalsLegacy from 'pages/reports-legacy/intervals'
import JobTotalsLegacy from 'pages/reports-legacy/job-totals'
import TimeEntriesLegacy from 'pages/reports-legacy/time-entries'
import React, {useEffect} from 'react'
import {Navigate, useLocation, useNavigate, useRoutes, useSearchParams} from 'react-router-dom'
import {AccountSettings} from 'ui/settings/account'
import {BreakSettings} from 'ui/settings/breaks'
import {OvertimeSettings} from 'ui/settings/overtime'
import {ReportSettings} from 'ui/settings/report'
import SubscriptionIndex from 'ui/settings/subscription-index'
import Login from './pages/auth/login'
import Survey from './pages/auth/onboard-survey'
import Register from './pages/auth/register'
import Changelog from './pages/changelog'
import CrewEdit from './pages/crews/crew-edit'
import Crews from './pages/crews/crew-list'
import CrewNew from './pages/crews/crew-new'
import DeviceEdit from './pages/devices/device-edit'
import DeviceList from './pages/devices/device-list'
import DeviceNew from './pages/devices/device-new'
import EmployeeDailyTotals from './pages/employees/employee-daily-totals'
import EmployeeDetails from './pages/employees/employee-details'
import EmployeeEdit from './pages/employees/employee-edit'
import Employees from './pages/employees/employee-list'
import EmployeeNew from './pages/employees/employee-new'
import Help from './pages/help'
import JobDetails from './pages/jobs/job-details'
import JobEdit from './pages/jobs/job-edit'
import JobList from './pages/jobs/job-list'
import JobNew from './pages/jobs/job-new'
import Messaging, {ComposeMessage, ConversationView} from './pages/messaging/messaging'
import ReportIndexLegacy from './pages/reports-legacy/report-index'
import Settings from './pages/settings'
import TransactionEdit from './pages/transactions/transaction-edit'
import TransactionNew from './pages/transactions/transaction-new'
import TVMode from './pages/tv-mode'
import Welcome from './pages/welcome'
import ReportIndex from 'pages/reports/reports-index'
import EmployeeTotals from 'pages/reports/employee-totals'
import JobTotals from 'pages/reports/job-totals'
import DailyTotals from 'pages/reports/daily-totals'
import Intervals from 'pages/reports/intervals'
import TimeEntries from 'pages/reports/time-entries'

const needsAuthRoutes = {
  '/': () => <Login />,
  '/login': () => <Login />,
  '/register': () => <Register />,
  '/create': () => <Register />,
  '/onboard': () => <Survey />,
}

type RouterProps = {
  authenticated: boolean
}

const protect = (isLoggedIn: boolean, route: JSX.Element) => {
  return isLoggedIn ? route : <Login />
}

const routes = (isLoggedIn: boolean) => [
  {
    path: 'login',
    element: !isLoggedIn ? <Login /> : <Navigate to="/employees" />,
  },
  {path: 'create', element: <Register />},
  {path: 'onboard', element: <Survey />},
  {
    path: '/',
    element: isLoggedIn ? <Navigate to="/employees" /> : <Navigate to="/login" />,
  },
  {path: 'welcome', element: protect(isLoggedIn, <Welcome />)},
  // Employees
  {path: 'employees', element: protect(isLoggedIn, <Employees />)},
  {path: 'employees/new', element: protect(isLoggedIn, <EmployeeNew />)},
  {
    path: 'employees/:uuid',
    element: protect(isLoggedIn, <EmployeeDetails />),
    children: [
      {path: '', element: protect(isLoggedIn, <EmployeeDailyTotals />)},
      {path: 'intervals', element: protect(isLoggedIn, <EmployeeIntervals />)},
      {path: 'times', element: protect(isLoggedIn, <EmployeeTimeEntries />)},
      {path: 'map', element: protect(isLoggedIn, <EmployeeMap />)},
    ],
  },
  {
    path: 'employees/:uuid/edit',
    element: protect(isLoggedIn, <EmployeeEdit />),
  },
  // Jobs
  {path: 'jobs', element: protect(isLoggedIn, <JobList />)},
  {path: 'jobs/new', element: protect(isLoggedIn, <JobNew />)},
  {
    path: 'jobs/:uuid',
    element: protect(isLoggedIn, <JobDetails />),
    children: [
      {path: '', element: protect(isLoggedIn, <JobDailyTotals />)},
      {path: 'intervals', element: protect(isLoggedIn, <JobIntervals />)},
      {path: 'times', element: protect(isLoggedIn, <JobTimeEntries />)},
    ],
  },
  {
    path: 'jobs/:uuid/edit',
    element: protect(isLoggedIn, <JobEdit />),
  },
  // Devices
  {path: 'devices', element: protect(isLoggedIn, <DeviceList />)},
  {path: 'devices/new', element: protect(isLoggedIn, <DeviceNew />)},
  {path: 'devices/:uuid', element: protect(isLoggedIn, <DeviceEdit />)},
  // Crews
  {path: 'crews', element: protect(isLoggedIn, <Crews />)},
  {path: 'crews/new', element: protect(isLoggedIn, <CrewNew />)},
  {path: 'crews/:uuid', element: protect(isLoggedIn, <CrewEdit />)},
  // Transactions
  {path: 'transactions/new', element: protect(isLoggedIn, <TransactionNew />)},
  {
    path: 'transactions/:uuid',
    element: protect(isLoggedIn, <TransactionEdit />),
  },
  // Reports
  {
    path: 'report',
    element: protect(isLoggedIn, <ReportIndex />),
    children: [
      {path: '', element: <EmployeeTotals />},
      {path: 'jobs', element: <JobTotals />},
      {path: 'daily', element: <DailyTotals />},
      {path: 'interval', element: <Intervals />},
      {path: 'transactions', element: <TimeEntries />},
    ],
  },
  // Reports
  {
    path: 'reports',
    element: protect(isLoggedIn, <ReportIndexLegacy />),
    children: [
      {path: '', element: <EmployeeTotalsLegacy />},
      {path: 'jobs', element: <JobTotalsLegacy />},
      {path: 'daily', element: <DailyTotalsLegacy />},
      {path: 'interval', element: <IntervalsLegacy />},
      {path: 'transactions', element: <TimeEntriesLegacy />},
    ],
  },
  // Settings
  {
    path: 'settings',
    element: protect(isLoggedIn, <Settings />),
    children: [
      {path: '', element: <SubscriptionIndex />},
      {path: 'account', element: <AccountSettings />},
      {path: 'report', element: <ReportSettings />},
      {path: 'overtime', element: <OvertimeSettings />},
      {path: 'breaks', element: <BreakSettings />},
    ],
  },
  {path: 'in-out-board', element: protect(isLoggedIn, <TVMode />)},
  {path: 'help', element: protect(isLoggedIn, <Help />)},
  {path: 'changelog', element: protect(isLoggedIn, <Changelog />)},
  {
    path: 'messaging',
    element: protect(isLoggedIn, <Messaging />),
    children: [
      {path: '', element: <ConversationView />},
      {path: 'new', element: <ComposeMessage />},
      {path: ':uuid', element: <ConversationView />},
    ],
  },
]
export const Router = ({}: RouterProps) => {
  const [isLoggedIn] = useAtom(isAuthenticatedAtom)
  const location = useLocation()
  const [access] = useAtom(subscriptionAccessAtom)
  const navigate = useNavigate()
  const {addItem} = useToastContext()
  const [, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (
      location.pathname !== '/settings' &&
      !location.pathname.startsWith('/help') &&
      !access.isTrial &&
      !access.isSubscribed
    ) {
      const msg = encodeURIComponent(
        'Your account does not have an active subscription. You must subscribe to continue using Time Clock.',
      )
      addItem(
        {
          title: 'Access Restricted',
          message: 'Your subscription has expired',
        },
        4000,
      )
      navigate(`/settings?flash=${msg}`)
    }
  }, [location, access])
  const match = useRoutes(routes(isLoggedIn))
  return match
}
export default Router
