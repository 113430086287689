import Fuse from 'fuse.js'
import {useEffect, useState} from 'react'

export const useFuse = (
  data: any[],
  search: string,
  options: Fuse.FuseOptions<any>,
) => {
  const [result, setResult] = useState(data)
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (search.length === 0) {
        return setResult(data)
      }
      const fuse = new Fuse(data, options)
      setResult(fuse.search(`${search}`))
    }, 200)
    return () => {
      clearTimeout(timeout)
    }
  }, [data, search, options])
  return result
}

export default useFuse
