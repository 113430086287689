import React from 'react'
import Button from './button'

type EmptyStateProps = {
  title: string
  description: string
  hideConsult?: boolean
}
export const EmptyState: React.FC<EmptyStateProps> = ({
  children,
  title,
  description,
  hideConsult = true,
}) => {
  return (
    <div className="empty-state">
      <h1>{title}</h1>
      <p>{description}</p>
      {children}
      {!hideConsult && (
        <p>
          If you'd like to schedule a tutorial with your Account Representative,
          please tap this link:
          <Button
            label="Schedule a Meeting"
            color="clock-in"
            icon="calendar"
            onClick={() => {
              window.open(
                'https://calendly.com/toolr-success/self-serve',
                '_blank',
              )
            }}
          />
        </p>
      )}
    </div>
  )
}
export default EmptyState

type MapEmptyStateProps = {}
export const MapEmptyState: React.FC<MapEmptyStateProps> = ({children}) => {
  return (
    <div className="empty-map-state">
      <span>No Map Data</span>
      <div className="img"></div>
    </div>
  )
}
