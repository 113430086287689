import React from 'react'
import useClassnames from '../hooks/use-classnames'

type MenuProps = {}
export const Menu: React.FC<MenuProps> = ({children}) => {
  return <div className="menu">{children}</div>
}

type MenuItemProps = {
  label: string
  active?: boolean
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}
export const MenuItem: React.FC<MenuItemProps> = ({
  onClick,
  children,
  label,
  active = false,
}) => {
  const classes = useClassnames({
    active,
    'menu-item': true,
  })
  return (
    <div className={classes} onClick={onClick}>
      {label}
      {children}
    </div>
  )
}

type MenuOption<T> = {
  label: string
  value: T
}
type MenuPicker<T> = {
  options: MenuOption<T>[]
  value: T
  onValueChange: (v: T) => void
}
export const MenuPicker = <T extends number | string>({
  options,
  value,
  onValueChange,
}: MenuPicker<T>) => {
  return (
    <Menu>
      {options.map(o => {
        return (
          <MenuItem
            key={o.value}
            label={o.label}
            active={value === o.value}
            onClick={e => {
              onValueChange(o.value)
            }}
          />
        )
      })}
    </Menu>
  )
}
