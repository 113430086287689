import React, {useMemo} from 'react'

const MAX_LENGTH = 3
const getShort = (t: string) => {
  if (!t) {
    return ''
  }
  const split = t.split(' ')
  return split.reduce((acc, s, i) => {
    let space = i !== split.length - 1 ? '' : ''
    if (acc.length > MAX_LENGTH) {
      return acc
    }
    return acc + s.charAt(0) + space
  }, '')
}

const hash = (s: string) => {
  let h = 0
  let i = s.length
  while (i > 0) {
    h = h + s.charCodeAt(--i)
  }
  return h
}

const colors = [
  '#503473',
  '#CC2D6E',
  '#F84B6E',
  '#FF6B6B',
  '#FF957C',
  '#F3D8B0',
  '#ECC4A7',
  '#E6A493',
  '#D37D7D',
  '#BF5F75',
  '#994167',
  '#A24C49',
  '#CC8052',
  '#F2B87A',
  '#FCD79F',
  '#CFE6A5',
  '#A4D080',
  '#58B171',
  '#307871',
  '#3A62A5',
  '#6194DA',
  '#89C8F6',
  '#C6E7F1',
  '#D8E8E5',
  '#BCD1D3',
  '#A0B7BF',
  '#8499A5',
  '#607082',
  '#693C87',
  '#BD55BE',
  '#E766CD',
  '#FF84D0',
]
const hashedColor = (s: string) => {
  return colors[hash(s) % colors.length]
}

type AvatarProps = {
  text: string
  label?: string
  hashString?: string
}
export const Avatar: React.FC<AvatarProps> = ({
  text,
  label,
  hashString = '',
}) => {
  const content = label ? label : getShort(text)
  const style = useMemo(() => {
    if (hashString.length > 0) {
      return {
        backgroundColor: hashedColor(hashString),
      }
    }
    return {}
  }, [hashString])
  return (
    <div className="avatar" style={style}>
      {content}
    </div>
  )
}
export default Avatar
