import analytics from '@scavatec/timeclock-lib/analytics'
import {APIValidationError} from '@scavatec/timeclock-lib/api/client'
import {useJobDeleteMutation, useJobUpdateMutation} from '@scavatec/timeclock-lib/api/job'
import {JobUpdatePayload} from '@scavatec/timeclock-lib/api/types'
import {useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import Job, {transformJob} from '@scavatec/timeclock-lib/database/models/job'
import useDatabase from '@scavatec/timeclock-lib/hooks/use-database'
import {useJob} from '@scavatec/timeclock-lib/state/entities'
import {useToastContext} from 'contexts/toast-context'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router'
import Button from 'ui/button'
import {ErrorCallout} from 'ui/callout'
import {ConfirmationButton} from 'ui/confirmation-button'
import EmptyState from 'ui/empty-state'
import {Form} from 'ui/forms'
import JobForm from 'ui/forms/job-form'
import Toolbar from 'ui/toolbar'

const pageId = 'w.h.3.1'

type JobEditProps = {
  job: Job
}
export const JobEdit: React.FC<JobEditProps> = ({job}) => {
  const navigate = useNavigate()
  const {actions} = useReportContext()
  const {addItem} = useToastContext()
  const {database} = useDatabase()

  const {control, handleSubmit, setError} = useForm<JobUpdatePayload>({
    defaultValues: {
      ...job.toForm(),
    },
  })
  useEffect(() => {
    analytics.event(`${pageId}-view`)
  }, [])
  const mutation = useJobUpdateMutation(job.uuid, {
    onError: (error) => {
      if (error instanceof APIValidationError) {
        Object.keys(error.errors).forEach((key) =>
          setError(`${key}` as any, {
            type: 'validation',
            message: error.errors[key],
          }),
        )
      }
    },
    onMutate: (data) => {
      analytics.event(`${pageId}-submit-update`, {
        add_another: false,
        name: data.name.length > 0,
        id: data.identifier,
        note: data.note && data.note.length > 0,
        is_active: data.isActive,
      })
    },
    onSuccess: (data) => {
      job.userUpdate(transformJob.fromApi(data))
      addItem(
        {
          title: `Updated job ${data.name}`,
          icon: 'save',
        },
        4000,
      )
      navigate(-1)
    },
  })
  const deleteMutation = useJobDeleteMutation({
    onMutate: (data) => {
      analytics.event(`${pageId}-submit-delete`)
    },
    onSuccess: (data) => {
      database.action(async () => {
        await job.destroyPermanently()
      })
      addItem({title: `Job ${job.name} deleted`, icon: 'trash'}, 4000)
      actions.refresh()
      navigate('/jobs')
    },
  })

  const save = (values: JobUpdatePayload) => {
    mutation.mutate(values)
  }
  return (
    <div className="page job-edit">
      <Toolbar.Bars>
        <Toolbar.Bar>
          <Toolbar.Group>
            <Button
              label="Cancel"
              disabled={mutation.isLoading}
              mode="secondary"
              icon="close"
              onClick={() => {
                analytics.event(`${pageId}-click-cancel`)
                navigate('/jobs')
              }}
            />
          </Toolbar.Group>
          <Toolbar.Group>
            <ConfirmationButton
              title="Confirm Delete"
              description="Are you sure you want to remove this job? You may change a job to inactive to prevent employees from using it while retaining the data."
              renderButton={(onClick) => {
                return (
                  <Button
                    color="danger"
                    label="Delete"
                    icon="trash"
                    disabled={deleteMutation.isLoading || mutation.isLoading}
                    loading={deleteMutation.isLoading}
                    onClick={onClick}
                  />
                )
              }}
              onConfirm={() => deleteMutation.mutate(job.uuid)}
            />
            <Button
              disabled={mutation.isLoading}
              loading={mutation.isLoading}
              label={'Save'}
              icon={'plus'}
              onClick={() => handleSubmit(save)()}
            />
          </Toolbar.Group>
        </Toolbar.Bar>
      </Toolbar.Bars>
      <div className="page-content">
        {mutation.isError && <ErrorCallout error={mutation.error} />}
        {deleteMutation.isError && <ErrorCallout error={deleteMutation.error} />}
        <div className="split-header">
          <h1>Edit Job</h1>
        </div>
        <Form onSubmit={handleSubmit(save)}>
          <JobForm pageId={pageId} control={control as any} mode="update" />
        </Form>
      </div>
    </div>
  )
}

type JobEditPageProps = {}
export const JobEditPage: React.FC<JobEditPageProps> = ({}) => {
  const {uuid} = useParams()
  const job = useJob(uuid ?? '')
  useEffect(() => {
    analytics.event(`${pageId}-view`)
  }, [])
  if (!job) {
    return (
      <div className="page job-edit">
        <div className="page-content">
          <EmptyState title="Job not found" description="The job attempting to be edited could no longer be found." />
        </div>
      </div>
    )
  }
  if (job.uuid === '00000000000000000000000000000000') {
    return (
      <div className="page job-edit">
        <div className="page-content">
          <EmptyState title="Action Blocked" description="The job, 'none', is built in and cannot be modified." />
        </div>
      </div>
    )
  }
  return <JobEdit job={job} />
}

export default JobEditPage
