import analytics from '@scavatec/timeclock-lib/analytics'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import useFilterSet from 'hooks/use-filter-set'
import {useAtom} from 'jotai'
import React, {useEffect, useMemo} from 'react'
import {Outlet, useNavigate, useParams} from 'react-router'
import {NavLink} from 'react-router-dom'
import EmptyState from 'ui/empty-state'
import PayPeriodPicker from 'ui/pay-period'
import ReportDetailsToolbar from 'ui/toolbars/report-details-toolbar'
import {EmployeesReportFilters} from 'ui/toolbars/report-toolbar'
import {employeeListViewAtom} from './employee-state'

type EmployeeDetailsProps = {}
export const EmployeeDetails: React.FC<EmployeeDetailsProps> = ({}) => {
  const {uuid = ''} = useParams()
  const navigate = useNavigate()
  const [, setView] = useAtom(employeeListViewAtom)
  const {rawReport, actions} = useReportContext()
  const {
    employees: {items: employees},
  } = useDataContext()
  const employee = useMemo(() => {
    return employees.find((e) => e.uuid === uuid)
  }, [employees, uuid])
  const total = useMemo(() => {
    if (!rawReport) {
      return {
        time: 0,
        paidTime: 0,
        breakTime: 0,
        overtime: 0,
        pay: 0,
        tips: 0,
      }
    }
    return rawReport.dateRangeTotals.employees.find((e) => e.employeeUUID === uuid)
  }, [uuid, rawReport])
  const preferredView = useOrganizationSetting('account.preferred_view')
  useEffect(() => {
    actions.refresh()
    setView(preferredView.value)
    analytics.event('w.g.2.0-view')
  }, [])
  const basePath = `/employees/${uuid}`
  const filterSet = useFilterSet<EmployeesReportFilters>({
    view: preferredView.value,
    search: '',
    columns: {
      totals: ['id', 'name', 'time', 'paidTime', 'overtime', 'breakTime', 'pay', 'tips'],
      dailyTotals: ['day', 'employee', 'task', 'total', 'paid', 'overtime', 'break', 'pay', 'tips'],
      intervals: ['dIn', 'dOut', 'job', 'totalSeconds', 'payRate', 'tips'],
    },
  })
  useEffect(() => {
    if (preferredView.value !== filterSet.filters.view) {
      preferredView.update(filterSet.filters.view)
    }
  }, [preferredView.value, filterSet.filters.view])

  return (
    <div className="page employees">
      {!employee && (
        <EmptyState
          title="No Employees"
          description="No employees have been added to your account. Add your first device by clicking on the 'Add Crew' button in the top right."
        />
      )}
      <ReportDetailsToolbar
        pageId="w.g.2.0"
        uuid={uuid}
        type="Employee"
        typeLabel="Person"
        total={total}
        filterSet={filterSet}
        setView={setView}
        title={employee?.formattedName() || 'N/A'}
        transactionParams={{
          employee: uuid,
          payRate: employee?.payRate ?? 0.0,
        }}
      />
      <div className="page-content">
        <div className="tab-menu">
          <NavLink end to={`${basePath}`} onClick={() => analytics.event('w.g.2.0-click-daily-totals-tab')}>
            Daily Totals
          </NavLink>
          <NavLink to={`${basePath}/intervals`} onClick={() => analytics.event('w.g.2.0-click-intervals-tab')}>
            Intervals
          </NavLink>
          <NavLink to={`${basePath}/times`} onClick={() => analytics.event('w.g.2.0-click-time-entries-tab')}>
            Time Entries
          </NavLink>
          <NavLink to={`${basePath}/map`} onClick={() => analytics.event('w.g.2.0-click-map-tab')}>
            Map
          </NavLink>
          <div className="spacer"></div>
          <div className="metadata">
            <PayPeriodPicker
              onNextClick={() => analytics.event(`w.g.2.0-click-date-advance`)}
              onPrevClick={() => analytics.event(`w.g.2.0-click-date-previous`)}
            />
          </div>
        </div>
        {!employee ? (
          <EmptyState
            title="No Employees Found"
            description="
              No employees were found with the selected filters. Change the filters
              above or create a new job by clicking the 'Add Crew' button in the
              top right.
"
          />
        ) : null}
        <Outlet />
      </div>
    </div>
  )
}
export default EmployeeDetails
