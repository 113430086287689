import analytics from '@scavatec/timeclock-lib/analytics'
import {
  Survey,
  SurveyContent,
  SurveyPage,
  SurveyPayload,
  SurveyResponse,
  useSurveyListQuery,
  useSurveyResponseMutation,
} from '@scavatec/timeclock-lib/api/survey'
import useRequest from '@scavatec/timeclock-lib/hooks/use-request'
import logger from '@scavatec/timeclock-lib/util/logger'
import React, {useEffect, useMemo, useState} from 'react'
import {Control, useController, UseControllerProps, UseFormSetValue, useForm} from 'react-hook-form'
import {usePopper} from 'react-popper'
import Button from '../ui/button'
import Icon, {MaterialIcon} from '../ui/icon'
import CheckboxInput from '../ui/inputs/checkbox-input'
import {LabelErrorRow} from '../ui/inputs/common'
import TextAreaInput from '../ui/inputs/text-area-input'
import {mdiInformationOutline} from '@mdi/js'
import {useMutation, useQuery} from '@tanstack/react-query'
import ReactMarkdown from 'react-markdown'
import useClassnames from 'hooks/use-classnames'
import {Link} from 'react-router-dom'
import Callout from 'ui/callout'
import {getErrorMessage} from '@scavatec/timeclock-lib/api/util'

const SCREEN_ID = 'w.y.1.0'
const COMPLETE_SCREEN_ID = 'w.y.1.1'

type SurveyManagerProps = {}
export const SurveyManager = () => {
  // The SurveyManager compontent is responsible for fetching and displaying the modal if a survey is available
  const surveyQuery = useSurveyListQuery()
  const activeSurvey = surveyQuery?.data?.[0] ?? null
  const [open, setOpen] = useState(false)
  const [complete, setComplete] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const page = activeSurvey?.questionnaire?.[pageIndex] ?? null
  useEffect(() => {
    setOpen(activeSurvey !== null)
  }, [activeSurvey, setOpen])
  const {
    control,
    reset,
    formState: {isValid, errors},
    handleSubmit,
    watch,
    setValue,
  } = useForm<any>({
    mode: 'onChange',
  })
  const values = watch()
  const mutation = useSurveyResponseMutation({
    onSuccess: (res) => {
      setComplete(true)
    },
  })
  const submit = (values: StringKeyed<string>) => {
    if (!activeSurvey) {
      return
    }
    const payload: SurveyPayload = {
      questionnaire: Object.keys(values).map((k) => {
        return {
          question: k,
          answers: [values[k]],
        }
      }),
    }
    mutation.mutate({id: activeSurvey.id, payload})
  }
  return (
    <div className="survey-container">
      {open && !complete && page && activeSurvey && (
        <Page
          page={page}
          onRequestClose={() => setOpen(false)}
          error={mutation.isError && getErrorMessage(mutation.error)}
          renderSubmit={() => {
            const onLastPage = pageIndex === activeSurvey.questionnaire.length - 1
            return (
              <Button
                disabled={!isValid}
                label={onLastPage ? 'Submit' : 'Continue'}
                loading={mutation.isLoading}
                onClick={() => {
                  if (
                    (values?.question_1 === 'no' && values?.feedback.length <= 0) ||
                    pageIndex === activeSurvey.questionnaire.length - 1
                  ) {
                    handleSubmit(submit)()
                  } else {
                    setPageIndex(Math.max(pageIndex + 1, activeSurvey.questionnaire.length - 1))
                  }
                }}
              />
            )
          }}
          control={control as any}
        />
      )}
      {open && complete && (
        <div className="survey complete">
          <div className="survey-header">
            <h1></h1>
            <div
              className="close-button"
              onClick={() => {
                analytics.event(`${SCREEN_ID}-click-x-close`)
                setOpen(false)
              }}
            >
              <Icon icon="close" size={16} />
            </div>
          </div>
          <h3>Thank you for your feedback!</h3>
          <p>
            We'll be consolidating everyone's feedback over the next couple weeks, and then we will provide an update
            here in the app shortly after. So keep an eye out!
          </p>
          <div className="survey-footer">
            <Button
              label="close"
              onClick={() => {
                analytics.event(`${SCREEN_ID}-click-close`)
                setOpen(false)
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

type SurveyPageProps = {
  page: SurveyPage
  error?: any
  onRequestClose: () => void
  renderSubmit: () => React.ReactChild
  control: Control<any>
}
const Page = ({page, error, onRequestClose, control, renderSubmit}: SurveyPageProps) => {
  const content = page.content.map((item) => {
    switch (item.type) {
      case 'tile_picker':
        return <TilePicker key={item.name} options={item} control={control as any} />
      case 'textarea':
        return <SurveyTextArea key={item.name} options={item} control={control as any} />
    }
    return null
  })
  return (
    <div className="survey">
      <div className="survey-header">
        <h1>{page.title}</h1>
        <div
          className="close-button"
          onClick={() => {
            analytics.event(`${SCREEN_ID}-click-x-close`)
            onRequestClose()
          }}
        >
          <Icon icon="close" size={16} />
        </div>
      </div>
      {error && (
        <Callout
          type="error"
          title="Unable to submit"
          message={error ?? 'Unable to save survey response, please try again later'}
        />
      )}
      {content}
      <div className="survey-footer">{renderSubmit()}</div>
    </div>
  )
}

type SurveyTextAreaProps = {
  options: SurveyContent
  control: Control<any>
}
const SurveyTextArea = ({options, control}: SurveyTextAreaProps) => {
  const {field} = useController<any>({
    name: options.name,
    defaultValue: '',
    rules: options.validation,
    control,
  })
  return (
    <div>
      <ReactMarkdown className="md">{options.markdown}</ReactMarkdown>
      <div className="form-input textarea">
        <textarea className="" {...field} style={{width: '100%'}} />
      </div>
    </div>
  )
}

type TilePickerProps = {
  options: SurveyContent
  control: Control<any>
}
const TilePicker = ({options, control}: TilePickerProps) => {
  const {field} = useController<any>({
    name: options.name,
    defaultValue: null,
    rules: options.validation,
    control,
  })
  return (
    <div>
      {options.name !== 'question_2' ? (
        <ReactMarkdown className="md">{options.markdown}</ReactMarkdown>
      ) : (
        <p>
          Based on your interest in having us develop a custom form feature, woud you be willing to upgrade to the Plus
          plan in order to use it? <Link to="/settings">Learn more about the Toolr Plus plan.</Link>
        </p>
      )}
      <div className="survey-tile-picker">
        {options.options?.map((o) => {
          return (
            <Tile
              key={options.name + o.label}
              label={o.label}
              value={o.value}
              onChange={field.onChange}
              active={o.value === field.value}
            />
          )
        })}
      </div>
    </div>
  )
}

type TileProps = {
  label: string
  value: string
  active: boolean
  onChange: (value: any) => void
}
const Tile = ({label, active, value, onChange}: TileProps) => {
  const classes = useClassnames({
    active,
    tile: true,
  })
  return (
    <div className={classes} onClick={() => onChange(value)}>
      {label}
    </div>
  )
}

type HelpPopoverProps = {
  text: string
  answer: string
}
const HelpPopover = ({answer, text}: HelpPopoverProps) => {
  const [open, setOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const {styles, attributes, update} = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {name: 'arrow', options: {element: arrowElement}},
      {name: 'offset', options: {offset: [0, 8]}},
      {name: 'preventOverflow', options: {padding: 20}},
    ],
  })

  const setVisibility = (b: boolean) => {
    if (b) {
      analytics.event(`${SCREEN_ID}-show-tooltip`, {
        answer,
      })
    }
    setOpen(b)
    update && update()
  }

  return (
    <div className="popover-container">
      <div
        ref={setReferenceElement}
        className="trigger"
        onMouseOver={() => setVisibility(true)}
        onMouseOut={() => setVisibility(false)}
      >
        <MaterialIcon path={mdiInformationOutline} size={16} />
      </div>
      <div
        ref={setPopperElement}
        className="popover-content"
        style={styles.popper}
        data-open={open}
        {...attributes.popper}
      >
        {text}
        <div className="arrow" ref={setArrowElement} style={styles.arrow} />
      </div>
    </div>
  )
}
