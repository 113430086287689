import {useReducer, useCallback} from 'react'
import {assocPath} from 'rambda'

export type FieldValue = any
type Action<T extends {}> =
  | {
      type: 'set-filter'
      name: keyof T
      value: FieldValue
    }
  | {type: 'set-filters'; value: any}

type State<T> = {
  filters: T
}

type Reducer<T extends {}> = (state: State<T>, action: Action<T>) => State<T>
const reducer: Reducer<any> = (state, action) => {
  switch (action.type) {
    case 'set-filter':
      return assocPath(['filters', action.name.toString()], action.value, state)
    case 'set-filters':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      }
  }
  return state
}

const defaultState = {
  filters: {},
}
const useFilterSet = <T extends {}>(initialValues: T) => {
  const reduce = useCallback(reducer, []) as Reducer<T>
  const [state, dispatcher] = useReducer(reduce, {
    ...defaultState,
    filters: {...initialValues},
  })
  const setValue = (name: keyof T) => (value: FieldValue) => {
    dispatcher({type: 'set-filter', name, value})
  }
  const setValues = (value: T) => {
    dispatcher({type: 'set-filters', value})
  }
  const getInputProps = <B extends keyof T>(name: B) => {
    return {
      value: state.filters[name] as T[B],
      error: '',
      onValueChange: setValue(name),
    }
  }
  return {
    filters: state.filters,
    setValue,
    setValues,
    getInputProps,
    dispatcher,
  }
}

export default useFilterSet
export type FilterSet<T> = {
  getInputProps: <B extends keyof T>(
    name: B,
  ) => {
    value: T[B]
    error: string
    onValueChange: (v: T[B]) => void
  }
  setValues: (values: any) => void
  setValue: (name: keyof T) => (value: FieldValue) => void
} & State<T>
