import React from 'react'
import {Control, RegisterOptions, useController} from 'react-hook-form'
import useClassnames from '../../hooks/use-classnames'
import {InputLabelRow, FieldError} from './common'

type TextInputProps = {
  name: string
  label: string
  placeholder?: string
  control: Control<any>
  description?: string
  rules?: RegisterOptions
  prefix?: string
} & React.HTMLProps<HTMLInputElement>
export const TextInput = ({prefix, name, description = '', control, rules, label, style, ...rest}: TextInputProps) => {
  const {
    field,
    fieldState: {error},
  } = useController<any>({name, control, defaultValue: '', rules})
  const classes = useClassnames({
    error: error != undefined,
  })
  const hasPrefix = !!prefix
  return (
    <div className={`form-input text ${classes}`} style={style}>
      <InputLabelRow label={label} error={error} />
      <div style={{position: 'relative'}}>
        {hasPrefix && (
          <div className="prefix" style={{position: 'absolute', top: 9, left: 10, pointerEvents: 'none'}}>
            {prefix}
          </div>
        )}
        <input {...rest} {...field} style={{paddingLeft: hasPrefix ? 22 : 10}} />
      </div>
      <FieldError error={error} label={label} />
      {description && <div className="description">{description}</div>}
    </div>
  )
}

export default TextInput
