import React, {useMemo} from 'react'
import Badge from './badge'
import StaticMap from './maps/static-map'
import useClassnames from 'hooks/use-classnames'
import useDimensions from 'hooks/use-dimensions'
import {ClockTypeBadge, Currency, PreferredTime} from 'util/formatters'
import {DateTime} from 'luxon'
import {MapEmptyState} from './empty-state'
import {ReportDailyTotal} from '@scavatec/timeclock-lib/api/report'
import {employeeName} from '@scavatec/timeclock-lib/formatters'

type DailyTotalCardProps = {
  dailytotal: ReportDailyTotal
  onClick?: (dt: ReportDailyTotal) => void
  showName?: boolean
}
export const DailyTotalCard: React.FC<DailyTotalCardProps> = ({dailytotal, onClick, showName = true}) => {
  const classes = useClassnames({
    card: true,
    // invalid: dailytotal.invalid,
    'dailytotal-card': true,
    'report-card': true,
    'no-hover': true,
  })
  const date = DateTime.fromFormat(dailytotal.day, 'yyyy-LL-dd')
  const {
    ref,
    dimensions: {width},
  } = useDimensions()
  const locations: any[] = []
  // const locations = useMemo(() => {
  //   const locs = dailytotal.locations ?? []
  //   return locs.map((l: TransactionLocation, i: number) => ({
  //     lat: l.lat,
  //     long: l.long,
  //     color: l.type === 'in' ? '0x03C03C' : '0xED4C67',
  //     label: (i + 1).toString(),
  //   }))
  // }, [dailytotal.locations ?? []])
  return (
    <div
      className={classes}
      ref={ref}
      onClick={() => {
        onClick && onClick(dailytotal)
      }}
    >
      {locations.length > 0 ? (
        <StaticMap width={width} height={150} zoom={13} markers={locations} />
      ) : (
        <MapEmptyState />
      )}
      <div className="card-body">
        {showName && <span className="employee">{employeeName(dailytotal.employee)}</span>}
        <div className="row">
          <span className="date">{date.toFormat('LLL dd, yyyy')}</span>
          <span className="job">missing</span>
        </div>
        <div className="row">
          <span className="time">
            <PreferredTime seconds={dailytotal.shifts.total.seconds} />
          </span>
          <span className="pay">missing</span>
        </div>
      </div>
    </div>
  )
}

type DailyTotalCardLegacyProps = {
  dailytotal: DailyTotal
  onClick?: (dt: DailyTotal) => void
  showName?: boolean
}
export const DailyTotalCardLegacy: React.FC<DailyTotalCardLegacyProps> = ({dailytotal, onClick, showName = true}) => {
  const classes = useClassnames({
    card: true,
    invalid: dailytotal.invalid,
    'dailytotal-card': true,
    'report-card': true,
    'no-hover': true,
  })
  const date = DateTime.fromFormat(dailytotal.day, 'yyyy-LL-dd')
  const {
    ref,
    dimensions: {width},
  } = useDimensions()
  const locations = useMemo(() => {
    const locs = dailytotal.locations ?? []
    return locs.map((l: TransactionLocation, i: number) => ({
      lat: l.lat,
      long: l.long,
      color: l.type === 'in' ? '0x03C03C' : '0xED4C67',
      label: (i + 1).toString(),
    }))
  }, [dailytotal.locations ?? []])
  return (
    <div
      className={classes}
      ref={ref}
      onClick={() => {
        onClick && onClick(dailytotal)
      }}
    >
      {locations.length > 0 ? (
        <StaticMap width={width} height={150} zoom={13} markers={locations} />
      ) : (
        <MapEmptyState />
      )}
      <div className="card-body">
        {showName && <span className="employee">{dailytotal.employee}</span>}
        <div className="row">
          <span className="date">{date.toFormat('LLL dd, yyyy')}</span>
          <span className="job">{dailytotal.task}</span>
        </div>
        <div className="row">
          <span className="time">
            <PreferredTime seconds={dailytotal.total} />
          </span>
          <span className="pay">
            <Currency value={dailytotal.pay} />
          </span>
        </div>
      </div>
    </div>
  )
}
export default DailyTotalCardLegacy
