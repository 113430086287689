import React from 'react'
import {Device} from '@scavatec/timeclock-lib/database/models/device'
import RegistrationCode from '../ui/registration-code'
import Icon from './icon'
import useClassnames from '../hooks/use-classnames'
import {DateTime} from 'luxon'
import {isLatestMobileVersion} from 'util/versions'
import env from 'util/env'
import {RemoteDevice} from '@scavatec/timeclock-lib/api/types'

type DeviceCardProps = {
  device: RemoteDevice
  onClick?: OnClickEvent
}
export const DeviceCard: React.FC<DeviceCardProps> = ({device, onClick}) => {
  const classes = useClassnames({
    card: true,
    'device-card': true,
    [device.osName ?? '']: true,
  })
  const isRegistered = device.registrationCode && device.registrationCode.length <= 0
  return (
    <div className={classes} onClick={onClick}>
      {/* <Avatar text={device.lastName + ' ' + device.firstName} /> */}
      <div className="card-header">
        <div className="row">
          <span className="name">{device.name}</span>
          {isRegistered && device.osName !== '' && (
            <Icon icon={device.osName === 'ios' ? 'apple' : 'android'} size={24} />
          )}
        </div>
      </div>
      <div className="card-body">
        {isRegistered && (
          <div className="row">
            <span>{device.appName}</span>
            <div className="col" style={{textAlign: 'right'}}>
              <small>{device.appVersion}</small>
              {!isLatestMobileVersion(device.appVersion ?? '', env.mobile.latestVersion) && (
                <small>(Update Available)</small>
              )}
            </div>
          </div>
        )}
        {device.registrationCode ? (
          <>
            <span className="message">Awaiting code entry on this device to activate</span>
            <RegistrationCode code={device.registrationCode} />
          </>
        ) : (
          <div className="row">
            {device.appName === 'TimeClock ST' && (
              <div className="st-warn">This device is using TimeClock ST which is no longer supported.</div>
            )}
          </div>
        )}
        {device.lastHeardFromAt && (
          <div className="row">
            <small>Last synced {DateTime.fromISO(device.lastHeardFromAt).toRelative() ?? 'Never'}</small>
          </div>
        )}
      </div>
    </div>
  )
}
export default DeviceCard
