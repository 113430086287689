import React, {useMemo} from 'react'
import {hasPath} from 'rambda'
import Icon from 'ui/icon'
import {Control, RegisterOptions, useController} from 'react-hook-form'
import useClassnames from 'hooks/use-classnames'

type SelectOptions = {
  value: string
  label: string | React.ReactNode
}[]
type ListPickerProps = {
  options: SelectOptions
  name: string
  label: string
  placeholder?: string
  control: Control<any>
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}
export const ListPickerInput: React.FC<ListPickerProps> = ({options, label, name, placeholder, control, rules}) => {
  const {
    field,
    fieldState: {error},
  } = useController<any>({name, control, defaultValue: [], rules})
  const classes = useClassnames({
    error: error != undefined,
  })
  const selected = useMemo(() => {
    const v: string[] = field.value
    return v.reduce<Record<string, boolean>>((res, v: string) => {
      res[v] = true
      return res
    }, {})
  }, [field.value])
  return (
    <div className="list-picker">
      <span className="title">{label}</span>
      <ul>
        {options.map((o) => {
          const active = hasPath([o.value], selected)
          return (
            <li
              key={o.value}
              onClick={() => {
                const keys = Object.keys(selected)
                if (keys.includes(o.value)) {
                  field.onChange(keys.filter((k) => k !== o.value))
                } else {
                  field.onChange([...keys, o.value])
                }
              }}
              className={`${active ? 'active' : ''}`}
            >
              <Icon icon="check-mark-circle" size={16} />
              {o.label}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
export default ListPickerInput
