import analytics from '@scavatec/timeclock-lib/analytics'
import {updateLegacy} from '@scavatec/timeclock-lib/api/settings'
import {userAtom} from '@scavatec/timeclock-lib/state/environment'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import {capitalize} from '@scavatec/timeclock-lib/util/string'
import {useAtom} from 'jotai'
import React, {useEffect, useMemo} from 'react'
import * as yup from 'yup'
import useForm from '../../hooks/use-form'
import Button from '../button'
import Forms from '../forms'
import {TimeInputText} from '../inputs/time-input'
import Setting from '../settings'
import {ConversationListItem} from 'pages/messaging/conversation-list'
const schema = yup.object().shape({
  daily: yup.object().shape({
    half: yup
      .number()
      .min(0)
      .max(24 * 60 * 60, 'Must be less than 24'),
    double: yup
      .number()
      .min(0)
      .max(24 * 60 * 60, 'Must be less than 24'),
  }),
  weekly: yup.object().shape({
    half: yup
      .number()
      .min(0)
      .max(168 * 60 * 60, 'Must be less than 168'),
    double: yup
      .number()
      .min(0)
      .max(168 * 60 * 60, 'Must be less than 168'),
  }),
  seventhDay: yup
    .number()
    .min(0)
    .max(24 * 60 * 60, 'Must be less than 24 hours'),
})
type OvertimeFormData = {
  ruleset: string
}
const defaultValues: OvertimeFormData = {
  ruleset: '',
}
type OvertimeSettingsProps = {}
export const OvertimeSettings = (p: OvertimeSettingsProps) => {
  const [currentUser] = useAtom(userAtom)
  const overtimeSetting = useOrganizationSetting('reporting.overtime.ruleset')
  const {getInputProps, handleSubmit, setValues} = useForm<OvertimeFormData>(defaultValues, schema)
  useEffect(() => {
    analytics.event('w.e.5.0-view')
  }, [])
  // useEffect(() => {
  //   console.log('set overtime', overtimeSetting.value)
  //   setValues({ruleset: overtimeSetting.value})
  // }, [overtimeSetting.value, setValues])

  const ruleOptions = useMemo(() => {
    return Object.keys(overtimeRulesets).map((k) => {
      const label = k.split('_').map(capitalize).join(' ')
      return {
        value: k,
        label: label,
      }
    })
  }, [])
  const html = useMemo(() => {
    const rules = overtimeRulesets[overtimeSetting.value ?? 'none'] ?? overtimeRulesets['none']
    return `<ul>${rules.map((r) => `<li>${r}</li>`).join('')}</ul>`
  }, [overtimeSetting.value])

  return (
    <div className="page-content">
      <div className="setting-row">
        <div className="setting-col">
          <div className="setting-group">
            <h6>Options</h6>
            <Setting.Setting
              label="Ruleset"
              name="reporting.overtime.ruleset"
              onValueChanged={(v) => {
                updateLegacy(currentUser.organization, 'overtime', JSON.stringify(legacyRules?.[v] ?? noneLegacy))
                analytics.event('w.e.4.0-select-ruleset', {
                  ruleset: v,
                })
              }}
              renderInput={(props) => <Forms.Select options={ruleOptions} {...props} />}
            />
          </div>
        </div>
        <div className="setting-col">
          <div className="setting-group">
            <h6>Rule Details</h6>
            <span
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            ></span>
          </div>
        </div>
      </div>
      <div className="row"></div>
      {/* <Forms.Form onSubmit={() => handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <Setting.Divider label="Daily" />
            <div className="setting">
              <TimeInputText label="Time and a half after" {...getInputProps('daily.half')} />
              <span className="description">
                1.5x regular pay rate for employees after working this many hours in a day.
              </span>
            </div>
            <div className="setting">
              <TimeInputText label="Double time after" {...getInputProps('daily.double')} />
              <span className="description">
                2x regular pay rate for employees after working this many hours in a day.
              </span>
            </div>
            <Setting.Divider label="Seventh Day" />
            <div className="setting">
              <TimeInputText label="Time and a half up to" {...getInputProps('seventhDay')} />
              <span className="description">
                1.5x regular pay for any employees up to this many hours. Double time (2x regular pay) for any
                additional hours.
              </span>
            </div>
          </div>
          <div className="col">
            <Setting.Divider label="Weekly" />
            <div className="setting">
              <TimeInputText label="Time and a half after" {...getInputProps('weekly.half')} />
              <span className="description">
                1.5x regular pay rate for employees after working this many hours in a week.
              </span>
            </div>
            <div className="setting">
              <TimeInputText label="Double time after" {...getInputProps('weekly.double')} />
              <span className="description">
                2x regular pay rate for employees after working this many hours in a week.
              </span>
            </div>
            <Button label="Save" loading={overtimeSetting.updateQuery.isLoading} type="submit" />
          </div>
        </div>
        <div className="row"></div>
      </Forms.Form> */}
    </div>
  )
}

const federalDescription = ['1.5x after working more than 40 hours a week.']

const overtimeRulesets: Record<string, string[]> = {
  none: ['No overtime rule set'],
  federal: federalDescription,
  alabama: federalDescription,
  alaska: ['1.5x after working more than 8 hours per day.', '1.5x after working more than 40 hours a week.'],
  arizona: federalDescription,
  arkansas: federalDescription,
  california: [
    '1.5x after working more than 8 hours per day.',
    '2.0x after working more than 12 hours per day.',
    '1.5x after working more than 40 hours per week.',
    '1.5x when working less than 8 hours on the 7th day of work',
    '2.0x when working more than 8 hours on the 7th day of work',
  ],
  colorado: ['1.5x after working more than 12 hours per day.', '1.5x after working more than 40 hours a week.'],
  connecticut: federalDescription,
  delaware: federalDescription,
  florida: federalDescription,
  georgia: federalDescription,
  hawaii: federalDescription,
  idaho: federalDescription,
  illinois: federalDescription,
  indiana: federalDescription,
  iowa: federalDescription,
  kansas: federalDescription,
  kentucky: ['1.5x after working more than 40 hours a week.', '1.5x when working on the 7th day of work.'],
  louisiana: federalDescription,
  maine: federalDescription,
  maryland: federalDescription,
  massachusetts: federalDescription,
  michigan: federalDescription,
  minnesota: ['1.5x after working more than 48 hours a week.'],
  missouri: federalDescription,
  mississippi: federalDescription,
  montana: federalDescription,
  north_carolina: federalDescription,
  north_dakota: federalDescription,
  nebraska: federalDescription,
  new_hampshire: federalDescription,
  new_jersey: federalDescription,
  new_mexico: federalDescription,
  nevada: federalDescription,
  new_york: federalDescription,
  ohio: federalDescription,
  oklahoma: federalDescription,
  oregon: ['1.5x after working more than 10 hours per day.', '1.5x after working more than 40 hours a week.'],
  pennsylvania: federalDescription,
  rhode_island: federalDescription,
  south_carolina: federalDescription,
  south_dakota: federalDescription,
  tennessee: federalDescription,
  texas: federalDescription,
  utah: federalDescription,
  virginia: federalDescription,
  vermont: federalDescription,
  washington: federalDescription,
  wisconsin: federalDescription,
  west_virginia: federalDescription,
  wyoming: federalDescription,
  district_of_columbia: federalDescription,
  guam: federalDescription,
  puerto_rico: ['1.5x after working more than 8 hours per day.', '1.5x after working more than 40 hours a week.'],
}

const federalLegacy = {
  daily: {half: 0, double: 0},
  weekly: {half: 40 * 60 * 60, double: 0},
  seventhDay: 0,
}
const noneLegacy = {
  daily: {half: 0, double: 0},
  weekly: {half: 0, double: 0},
  seventhDay: 0,
}
const legacyRules: Record<string, any> = {
  none: noneLegacy,
  federal: federalLegacy,
  alabama: federalLegacy,
  alaska: {
    daily: {half: 8 * 60 * 60, double: 0},
    weekly: {half: 40 * 60 * 60, double: 0},
    seventhDay: 0,
  },
  arizona: federalLegacy,
  arkansas: federalLegacy,
  california: {
    daily: {half: 8 * 60 * 60, double: 12 * 60 * 60},
    weekly: {half: 40 * 60 * 60, double: 0},
    seventhDay: 8 * 40 * 40,
  },
  colorado: {
    daily: {half: 12 * 60 * 60, double: 0},
    weekly: {half: 40 * 60 * 60, double: 0},
    seventhDay: 0,
  },
  connecticut: federalLegacy,
  delaware: federalLegacy,
  florida: federalLegacy,
  georgia: federalLegacy,
  hawaii: federalLegacy,
  idaho: federalLegacy,
  illinois: federalLegacy,
  indiana: federalLegacy,
  iowa: federalLegacy,
  kansas: federalLegacy,
  kentucky: {
    daily: {half: 0, double: 0},
    weekly: {half: 40 * 60 * 60, double: 0},
    seventhDay: 24 * 60 * 60,
  },
  louisiana: federalLegacy,
  maine: federalLegacy,
  maryland: federalLegacy,
  massachusetts: federalLegacy,
  michigan: federalLegacy,
  minnesota: {
    daily: {half: 0, double: 0},
    weekly: {half: 48 * 60 * 60, double: 0},
    seventhDay: 0,
  },
  missouri: federalLegacy,
  mississippi: federalLegacy,
  montana: federalLegacy,
  north_carolina: federalLegacy,
  north_dakota: federalLegacy,
  nebraska: federalLegacy,
  new_hampshire: federalLegacy,
  new_jersey: federalLegacy,
  new_mexico: federalLegacy,
  nevada: federalLegacy,
  new_york: federalLegacy,
  ohio: federalLegacy,
  oklahoma: federalLegacy,
  oregon: {
    daily: {half: 10 * 60 * 60, double: 0},
    weekly: {half: 40 * 60 * 60, double: 0},
    seventhDay: 0,
  },
  pennsylvania: federalLegacy,
  rhode_island: federalLegacy,
  south_carolina: federalLegacy,
  south_dakota: federalLegacy,
  tennessee: federalLegacy,
  texas: federalLegacy,
  utah: federalLegacy,
  virginia: federalLegacy,
  vermont: federalLegacy,
  washington: federalLegacy,
  wisconsin: federalLegacy,
  west_virginia: federalLegacy,
  wyoming: federalLegacy,
  district_of_columbia: federalLegacy,
  guam: federalLegacy,
  puerto_rico: {
    daily: {half: 8 * 60 * 60, double: 0},
    weekly: {half: 40 * 60 * 60, double: 0},
    seventhDay: 0,
  },
}
