import analytics from '@scavatec/timeclock-lib/analytics'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import {useAtom} from 'jotai'
import React, {useCallback} from 'react'
import {useNavigate, useParams} from 'react-router'
import DailyTotalsTable from 'ui/tables/daily-totals-table'
import DailyTotalsTableLegacy from 'ui/tables/daily-totals-table-legacy'
import {jobListViewAtom, jobSearchAtom} from './job-state'

const screenID = 'w.g.2.0-daily-totals'

type JobTableProps = {}
const JobDailyTotals: React.FC<JobTableProps> = ({}) => {
  const {uuid = ''} = useParams()
  const [search] = useAtom(jobSearchAtom)
  const [view] = useAtom(jobListViewAtom)
  const navigate = useNavigate()
  const reportEngineSetting = useOrganizationSetting('reporting.engine_version')
  const onClick = useCallback(() => {
    analytics.event(`${screenID}-click-tile`)
    navigate(`/employees/${uuid}/transactions`)
  }, [])

  return reportEngineSetting.value < 2 ? (
    <DailyTotalsTableLegacy
      search={search}
      view={view}
      job={uuid}
      showJob
      hideColumns={['task']}
      analyticsId={screenID}
      onClick={onClick}
    />
  ) : (
    <DailyTotalsTable
      search={search}
      view={view}
      job={uuid}
      showEmployee
      hideColumns={['task']}
      analyticsId={screenID}
      onClick={onClick}
    />
  )
}
export default JobDailyTotals
