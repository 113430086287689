import analytics from '@scavatec/timeclock-lib/analytics'
import {useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import useFilterSet from 'hooks/use-filter-set'
import useModal from 'hooks/use-modal'
import {useAtom} from 'jotai'
import {atomWithStorage, createJSONStorage} from 'jotai/utils'
import React, {useEffect} from 'react'
import {Outlet} from 'react-router'
import {NavLink, useNavigate} from 'react-router-dom'
import Button from 'ui/button'
import ReportSettingsModal from 'ui/modals/report-settings-modal'
import {EmployeesReportFilters} from 'ui/toolbars/report-toolbar'
import LegacyReportToolbar from 'ui/toolbars/report-toolbar-legacy'
import env from 'util/env'

const storage = createJSONStorage(() => localStorage)
const hideMessageAtom = atomWithStorage('report-downgrade-hidden', false, storage)

type ReportIndexProps = {}
export const ReportIndex: React.FC<ReportIndexProps> = ({children}) => {
  const {rawReport} = useReportContext()
  const settingModal = useModal()
  const navigate = useNavigate()
  const [hidden, setHidden] = useAtom(hideMessageAtom)
  useEffect(() => {
    analytics.event('w.c.1.0-view')
  }, [])
  const reportEngineSetting = useOrganizationSetting('reporting.engine_version')

  const filterSet = useFilterSet<EmployeesReportFilters>({
    view: 'table',
    search: '',
    columns: {
      totals: ['id', 'name', 'time', 'paidTime', 'overtime', 'breakTime', 'pay', 'tips'],
      dailyTotals: ['day', 'employee', 'task', 'total', 'paid', 'overtime', 'break', 'pay', 'tips'],
      intervals: ['name', 'dIn', 'dOut', 'job', 'totalSeconds', 'payRate', 'tips'],
    },
  })
  return (
    <div className="page employees-report">
      <LegacyReportToolbar
        pageId="w.c.1.0"
        timezone={rawReport?.meta.timezone ?? ''}
        settingModal={settingModal}
        filterSet={filterSet}
      />
      <div className="page-content">
        <ReportSettingsModal
          modal={settingModal}
          initialValues={filterSet.filters.columns}
          onSave={(v) => {
            filterSet.setValue('columns')(v)
            settingModal.close()
          }}
        />
        {!hidden && env.features.reportingEngine && (
          <div className="upgrade-banner">
            <div>
              <h3>Report Engine Updates Available</h3>
              <p>
                We have released a new version of the report engine. To minimize disruption this feature is opt-in for
                the time being. To switch to the new report engine click the button below.
              </p>
            </div>
            <div className="row">
              <Button color="blue" label="Hide Message" mode="secondary" onClick={() => setHidden(true)} />
              <Button
                color="blue"
                label="Use New Report Engine"
                onClick={() => {
                  reportEngineSetting.update(2)
                  navigate('/report')
                }}
              />
            </div>
          </div>
        )}
        <div className="tab-menu">
          <NavLink end to="/reports/" onClick={() => analytics.event('w.c.1.0-click-employees-tab')}>
            Employees
          </NavLink>
          <NavLink to="/reports/jobs" onClick={() => analytics.event('w.c.1.0-click-jobs-tab')}>
            Jobs
          </NavLink>
          <NavLink to="/reports/daily" onClick={() => analytics.event('w.c.1.0-click-daily-totals-tab')}>
            Daily Totals
          </NavLink>
          <NavLink to="/reports/interval" onClick={() => analytics.event('w.c.1.0-click-intervals-tab')}>
            Intervals
          </NavLink>
          <NavLink to="/reports/transactions" onClick={() => analytics.event('w.c.1.0-click-transactions-tab')}>
            Time Entries
          </NavLink>
          <div className="spacer"></div>
          <div className="metadata"></div>
        </div>
        <Outlet />
      </div>
    </div>
  )
}
export default ReportIndex
