export const ColumnSizes = {
  identifier: 100,
  time: 100,
  date: 140,
  datetime: 170,
  clockType: 80,
  currency: 100,
  name: 'auto',
  status: 120,
}
