import {Placement} from '@popperjs/core'
import React, {useState, useCallback} from 'react'
import {Manager, Reference, Popper, PopperProps} from 'react-popper'

type PopoverProps = {
  renderTrigger: ({ref}: any) => React.ReactNode
  renderContent: ({ref, style, placement, arrowProps}: any) => React.ReactNode
  open: boolean
  onRequestClose?: () => void
  backdrop?: boolean
  placement?: Placement
}
export const Popover: React.FC<PopoverProps> = ({
  renderTrigger,
  renderContent,
  open,
  onRequestClose = () => null,
  backdrop = false,
  placement = 'bottom',
  children = [],
  ...props
}) => {
  const requestClose = useCallback(() => onRequestClose(), [onRequestClose])
  return (
    <Manager>
      <Reference>{renderTrigger}</Reference>
      {open && (
        <>
          {backdrop && (
            <div
              className="backdrop"
              onClick={(e) => {
                e.stopPropagation()
                requestClose()
              }}></div>
          )}
          <Popper {...props} placement={placement}>
            {renderContent}
          </Popper>
        </>
      )}
    </Manager>
  )
}
export default Popover
