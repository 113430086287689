import useClassnames from 'hooks/use-classnames'
import {useAtom} from 'jotai'
import {DateTime} from 'luxon'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import Avatar from 'ui/avatar'
import CheckboxInput from 'ui/inputs/checkbox-input'
import {
  ConversationItem,
  conversationItemsAtom,
  conversationsStateAtom,
  fetchConversationsAtom,
} from './messaging-state'
import useInterval from '@use-it/interval'
import {Spinner} from 'ui/activity-indicator'
import analytics from '@scavatec/timeclock-lib/analytics'

const getRelativeTime = (iso: string) => {
  const dt = DateTime.fromISO(iso)
  const diff = dt.diffNow('seconds').seconds
  if (diff > -60) {
    return 'Less than 1 minute'
  }
  return dt.toRelative() ?? ''
}

type ConversationListItemProps = {
  isActive?: boolean
  compose?: boolean
  isSelected?: boolean
  onClick: () => void
  item: ConversationItem
  analyticsId: string
}
export const ConversationListItem = ({
  item,
  compose = false,
  isSelected = false,
  isActive = false,
  onClick,
}: ConversationListItemProps) => {
  const classes = useClassnames({
    item: true,
    selected: isActive,
  })
  const [relative, setRelative] = useState('')
  useEffect(() => {
    setRelative(getRelativeTime(item.lastMessageAt))
  }, [item.lastMessageAt])
  useInterval(() => {
    setRelative(getRelativeTime(item.lastMessageAt))
  }, 60000)
  return (
    <div className={classes} onClick={onClick}>
      {compose && <CheckboxInput value={isSelected} name="" />}
      <Avatar hashString={item.employee.uuid} text={item.employee.formattedName()} />
      <div className="labels">
        <span className="title">{item.employee.formattedName()}</span>
        <span className="timestamp">{relative}</span>
      </div>
    </div>
  )
}

type ConversationListProps = {
  active?: string
  compose?: boolean
  group?: string[]
  toggleSelection?: (uuid: string) => void
  analyticsId: string
}
export const ConversationList = ({
  compose = false,
  group = [],
  active,
  toggleSelection,
  analyticsId,
}: ConversationListProps) => {
  const [items] = useAtom(conversationItemsAtom)
  const [state] = useAtom(conversationsStateAtom)
  const navigate = useNavigate()
  if (state.loading && state.timestamp == 0) {
    return (
      <div className="conversation-list">
        <Spinner contained />
      </div>
    )
  }
  return (
    <div className="conversation-list">
      <div className="scroll-container">
        {items.length <= 0 && (
          <p className="non-ideal">
            No conversations or people found. Try adjusting your search or adding a new worker to your account
          </p>
        )}
        {items.map((item) => {
          return (
            <ConversationListItem
              analyticsId={analyticsId}
              key={item.employee.uuid}
              isActive={item.employee.uuid === active}
              item={item}
              isSelected={compose && group.includes(item.employee.uuid)}
              compose={compose}
              onClick={() => {
                analytics.event(`${analyticsId}-click-tile`)
                if (compose) {
                  toggleSelection && toggleSelection(item.employee.uuid)
                } else {
                  navigate(`/messaging/${item.employee.uuid}`)
                }
              }}
            />
          )
        })}
      </div>
    </div>
  )
}
