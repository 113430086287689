import analytics from '@scavatec/timeclock-lib/analytics'
import {create as createAccount} from '@scavatec/timeclock-lib/api/account'
import {loginFirebase} from '@scavatec/timeclock-lib/api/auth'
import {client, setAuthToken} from '@scavatec/timeclock-lib/api/client'
import {useSubscriptionAccessQuery} from '@scavatec/timeclock-lib/api/subscription'
import {useAuthContext} from '@scavatec/timeclock-lib/contexts/auth-context'
import useRequest from '@scavatec/timeclock-lib/hooks/use-request'
import {userAtom} from '@scavatec/timeclock-lib/state/environment'
import {useMutation} from '@tanstack/react-query'
import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth'
import useForm from 'hooks/use-form'
import {useAtom} from 'jotai'
import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {useSearchParams} from 'react-router-dom'
import Button from 'ui/button'
import Callout from 'ui/callout'
import Forms from 'ui/forms'
import Logo from 'ui/logo'
import {SettingDivider} from 'ui/settings'
import firebase from 'util/firebase'
import * as yup from 'yup'
import env from '../../util/env'
import {firebaseAtom} from './state'

type RegisterValues = {
  email: string
  password: string
  organizationName: string
  firstName: string
}
type RegisterProps = {}
const formDefaults = env.dev
  ? {
      email: `org-${Math.floor(Math.random() * 1000)}@mytoolr.com`,
      password: 'timeclock',
      firstName: 'Tester',
      organizationName: `org-${Math.floor(Math.random() * 1000)}`,
    }
  : {
      email: '',
      firstName: '',
      password: '',
      organizationName: '',
    }
export const Register: React.FC<RegisterProps> = ({children}) => {
  const registerRequest = useRequest({
    group: 'Onboarding',
    name: 'register',
  })
  const [, setUser] = useAtom(userAtom)
  const {dispatch} = useAuthContext()
  const [params, setSearchParams] = useSearchParams()
  const accessQuery = useSubscriptionAccessQuery()
  const mode = params.get('mode')
  const [firebaseData, setFirebaseData] = useAtom(firebaseAtom)
  const [firebaseLoading, setFirebaseLoading] = useState(false)
  const usingFirebase = firebaseData.token.length > 0
  const navigate = useNavigate()
  const [flash, setFlash] = useState<undefined | {message: string; type: string}>()

  const {handleSubmit, setValues, setErrors, getInputProps, values} = useForm<RegisterValues>(
    formDefaults,
    yup.object().shape({
      email: yup.string().required().email(),
      password: yup.string().required(),
      organizationName: yup.string().required('required'),
      firstName: yup.string().required(),
    }),
  )
  useEffect(() => {
    analytics.event('w.a.2.1-view')
  }, [])
  useEffect(() => {
    if (firebaseData.email.length > 0) {
      setValues({
        firstName: firebaseData.name,
        email: firebaseData.email,
        organizationName: values.organizationName,
        password: values.password,
      })
    }
  }, [firebaseData])
  useEffect(() => {
    const flash = params.get('flash')
    const flashType = params.get('flash_type')
    if (flash) {
      setFlash({
        message: decodeURIComponent(flash),
        type: flashType ?? 'info',
      })
      setSearchParams({})
    }
  }, [params])
  const firebaseLoginMutation = useMutation(loginFirebase, {
    onSuccess: (res) => {
      analytics.setUserProperties(
        {
          organization_uuid: res.organization.id,
          organization_id: res.user.username,
          email: res.user?.email ?? '',
        },
        res.organization.id,
      )
      analytics.event('w.a.2.1-login-via-firebase-create', {
        email: res.user.email,
        org_id: res.user.username,
      })
      setAuthToken(res.token)
      setUser({
        uuid: res.user.id,
        organization: res.organization.id,
        token: res.token,
      })
      dispatch({
        type: 'login-user',
        token: res.token,
        user: {
          email: res.user.email,
          uuid: res.user.id,
          username: res.user.username,
        },
        org: {
          name: res.organization.name,
          uuid: res.organization.id,
        },
      })
      const access = res.accessList
      accessQuery.refetch()
      if (!access?.isTrial && !access?.isSubscribed) {
        const msg = encodeURIComponent(
          'Your account does not have an active subscription. You must subscribe to continue using Time Clock.',
        )
        navigate(`/settings?flash=${msg}`)
      } else {
        navigate('/employees', {replace: true})
      }
    },
    onError: (err) => {},
  })
  const onSubmit = (mode: 'email' | 'firebase') => async (values: RegisterValues) => {
    if (mode === 'firebase' && !usingFirebase) {
      registerRequest.clear()
      firebaseSignup(values)
      return
    }
    if (usingFirebase) {
      try {
        await firebaseLoginMutation.mutateAsync(firebaseData.token)
        return
      } catch (e) {
        // If the login request via firebase fails its okay to just continue with account creation
      }
    }
    analytics.event('w.a.2.1-submit-create-account', {
      email: values.email,
      type: usingFirebase ? 'google' : 'email',
      org_id: values.organizationName,
      first_name: values.firstName,
      password: values.password.length > 0,
    })
    const createData = usingFirebase
      ? {
          email: firebaseData.email,
          firstName: values.firstName,
          organizationName: values.organizationName,
          password: values.password ?? 'timeclock',
          firebaseToken: firebaseData.token,
        }
      : {
          email: values.email,
          firstName: values.firstName,
          organizationName: values.organizationName,
          password: values.password ?? 'timeclock',
        }
    try {
      const res = await registerRequest.request(createAccount(createData))
      if (res) {
        analytics.setUserProperties(
          {
            organization_uuid: res.organization.id,
            organization_id: values.organizationName,
            first_name: values.firstName,
            email: values.email,
          },
          res.organization.id,
        )
        setAuthToken(res.token)
        const event = {
          type: 'login-user',
          token: res.token,
          user: res.user,
          org: res.organization,
        }
        localStorage.setItem('first-name', values.firstName)
        localStorage.setItem('create-account-event', JSON.stringify(event))
        navigate('/onboard', {replace: true})
      }
    } catch (e) {}
  }
  const firebaseMutation = useMutation(
    (payload: {organization_name: string; first_name: string; email: string; firebase_token: string}) => {
      return client.postJson('auth/login-firebase', payload)
    },
    {
      onSuccess: () => {},
      onError: (err) => {},
    },
  )
  const firebaseSignup = useCallback(
    async (values: RegisterValues) => {
      setFirebaseLoading(true)
      const provider = new GoogleAuthProvider()
      firebase.auth.useDeviceLanguage()
      try {
        const res = await signInWithPopup(firebase.auth, provider)
        const credential = GoogleAuthProvider.credentialFromResult(res)
        if (!credential) {
          return
        }
        const user = res.user
        const token = await user.getIdToken()
        setFirebaseData({
          name: user.displayName ?? '',
          email: user.email ?? '',
          token: token ?? '',
        })
        setFirebaseLoading(false)
      } catch (e) {
        setFirebaseLoading(false)
      }
    },
    [firebaseData],
  )
  return (
    <div className="auth-container">
      <div className="register">
        <Logo size="medium" width={200} />
        {flash && <Callout type={flash.type as any} message={flash.message} />}
        <h1>Create Account</h1>
        <p>
          Let's get you set up—it only takes a few minutes. Then you never have to handle or store a paper timesheet
          again
        </p>
        {firebaseData.email.length > 0 && (
          <p>
            {`You are currently signing up using your Google account (${firebaseData.email}). `}
            <a
              href="#"
              onClick={() => {
                registerRequest.clear()
                setFirebaseData({
                  name: '',
                  email: '',
                  token: '',
                })
              }}
            >
              Switch to email/password.
            </a>
          </p>
        )}
        {registerRequest.error.length > 0 && (
          <Callout type="error" title="Create Account Failed" message={registerRequest.error}>
            <ul>
              {Object.keys(registerRequest.errors).map((k) => {
                const e = registerRequest.errors[k]
                return <li key={k}>{e}</li>
              })}
            </ul>
          </Callout>
        )}
        <Forms.Form onSubmit={() => handleSubmit(onSubmit('email'))}>
          {!usingFirebase && (
            <Button
              loading={firebaseLoading || firebaseMutation.isLoading}
              disabled={firebaseLoading || firebaseMutation.isLoading}
              color="blue"
              icon="google"
              className="google-auth"
              label={'Use Google Account'}
              onClick={() => handleSubmit(onSubmit('firebase'))}
            />
          )}
          {!usingFirebase && <SettingDivider label="or" />}
          <Forms.TextInput label="Your First Name" placeholder="Your First Name" {...getInputProps('firstName')} />
          <Forms.TextInput
            disabled={usingFirebase}
            label="Email"
            placeholder="Your Email"
            {...getInputProps('email')}
          />
          <Forms.TextInput
            label="Organization ID (used as login ID)"
            placeholder="Your Organization Name"
            {...getInputProps('organizationName')}
          />
          <Forms.TextInput label="Password" type="password" placeholder="Password" {...getInputProps('password')} />
          {!usingFirebase ? (
            <Button icon="restaurant" label="Create Account" type="submit" loading={registerRequest.loading} />
          ) : (
            <Button
              loading={firebaseLoading || firebaseMutation.isLoading}
              disabled={firebaseLoading || firebaseMutation.isLoading}
              color="blue"
              icon="google"
              label={'Continue With Google Account'}
              onClick={() => handleSubmit(onSubmit('firebase'))}
            />
          )}
        </Forms.Form>
        <div
          className="link"
          onClick={() => {
            analytics.event('w.a.2.1-click-login')
            navigate('/login')
          }}
        >
          Already have an account? Login here.
        </div>
      </div>
    </div>
  )
}
export default Register
