import React, {useMemo} from 'react'
import {CellProps} from 'react-table'
import Table, {TableRowClick} from '../table'
import {Currency, PreferredTime, AccountTime} from 'util/formatters'
import EmptyState from '../empty-state'
import IntervalCard from 'ui/interval'
import {FilterSet} from '../../hooks/use-filter-set'
import {EmployeesReportFilters} from '../toolbars/report-toolbar'
import useFuse from '../../hooks/use-fuse'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useReportContext, transformAllIntervals} from '@scavatec/timeclock-lib/contexts/report-context'
import {ColumnSizes} from './common'

type IntervalsTableProps = {
  employee?: string
  job?: string
  view?: string
  showEmployee?: boolean
  showJob?: boolean
  onClick?: (interval: ReportIntervalLegacy) => void
  search?: string
  analyticsId: string
  hideColumns?: string[]
}
const IntervalsTable: React.FC<IntervalsTableProps> = ({
  search = '',
  employee = '',
  job = '',
  analyticsId = '',
  view = 'cards',
  showJob = true,
  showEmployee = true,
  hideColumns = [],
  onClick,
}) => {
  const columns = useMemo(
    () =>
      [
        {
          Header: 'In',
          accessor: 'dIn',
          Cell: ({cell: {value}}: CellProps<any>) => <AccountTime value={value} />,
          width: ColumnSizes.datetime,
        },
        {
          Header: 'Out',
          accessor: 'dOut',
          Cell: ({cell: {value}, row: {original}}: CellProps<any>) => {
            if (original?.out?.faux) {
              return original?.out?.fauxLabel ?? 'Pending clock out'
            }
            return <AccountTime value={value} />
          },
          width: ColumnSizes.datetime,
        },
        {
          Header: 'Employee',
          accessor: 'name',
          width: ColumnSizes.name,
        },
        {
          Header: 'Job',
          accessor: 'job',
          width: ColumnSizes.name,
        },
        {
          Header: 'Duration',
          accessor: 'totalSeconds',
          Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
          width: ColumnSizes.time,
        },
        {
          Header: 'Pay Rate',
          accessor: 'payRate',
          Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value ?? 0} />,
          width: ColumnSizes.currency,
        },
        {
          Header: 'Tips',
          accessor: 'tips',
          Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value} />,
          width: ColumnSizes.currency,
        },
      ].filter((c) => !hideColumns.includes(c.accessor)),
    [hideColumns],
  )
  const {rawReport} = useReportContext()
  const {
    jobs: {items: jobs},
  } = useDataContext()
  let totals = useMemo(() => {
    const intervals: APIIntervals = rawReport?.intervals ?? {}
    let all = transformAllIntervals(intervals, jobs)
    if (employee.length > 0) {
      all = all.filter((i) => i.employeeUUID === employee)
    }
    if (job.length > 0) {
      all = all.filter((i) => i.taskUUID === job)
    }
    return all
  }, [rawReport, employee, job])

  // Apply filters
  totals = useFuse(totals, search, {
    threshold: 0.1,
    shouldSort: true,
    keys: ['name', 'job', 'dIn', 'dOut'],
  })

  if (totals.length <= 0) {
    return (
      <EmptyState
        title="No Intervals in Range"
        description="No intervals were found in the selected date range. Try changing the dates or add a new time by clicking 'Add Time'."
      />
    )
  }
  if (view === 'list') {
    return (
      <Table
        columns={columns}
        data={totals}
        analyticsId={analyticsId}
        noHover
        initialSortBy={[{id: 'dOut', desc: true}]}
        onRowClick={() => null}
        getRowProps={(row) => {
          return {
            className: row.original.invalid ? 'invalid' : '',
          }
        }}
      />
    )
  }
  return (
    <div className="cards">
      {totals.map((t, i) => (
        <IntervalCard
          showEmployee={showEmployee}
          showJob={showJob}
          key={i}
          interval={t}
          onClick={() => {
            onClick && onClick(t)
          }}
        />
      ))}
    </div>
  )
}
export default IntervalsTable
