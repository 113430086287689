import * as subscriptionClient from '@scavatec/timeclock-lib/api/subscription'
import fileDownload from 'js-file-download'
import {DateTime} from 'luxon'
import React, {useCallback, useEffect, useState} from 'react'
import {Currency} from 'util/formatters'
import {ModalHookType} from '../../hooks/use-modal'
import Button, {IconButton, MaterialIconButton} from '../button'
import Modal from './modal'
import useRequest from '@scavatec/timeclock-lib/hooks/use-request'
import analytics from '@scavatec/timeclock-lib/analytics'
import {MaterialIcon} from 'ui/icon'
import {mdiClose} from '@mdi/js'
import {useAtom} from 'jotai'
import {userAtom} from '@scavatec/timeclock-lib/state/environment'
import {InvoiceItem} from '@scavatec/timeclock-lib/api/types'
import styled from '@emotion/styled'

const SCREEN_ID = 'w.e.2.6'

type InvoiceListProps = {
  invoices: any[]
}
const InvoiceList = ({invoices}: InvoiceListProps) => {
  const [user] = useAtom(userAtom)
  return (
    <ul className="invoice-list">
      {invoices.map((invoice: any) => {
        return (
          <li key={invoice.id}>
            <span className="date">{DateTime.fromISO(invoice.date).toLocaleString(DateTime.DATE_MED)}</span>
            <span className="total">
              <Currency value={invoice.total / 100} />
            </span>
            <a
              href="#"
              onClick={async () => {
                analytics.event(`${SCREEN_ID}-click-download`)
                try {
                  const blob = await subscriptionClient.downloadInvoice(user.organization, invoice.id)
                  fileDownload(blob, `TimeClock_${invoice.date}.pdf`)
                } catch (e) {}
              }}
            >
              Download
            </a>
          </li>
        )
      })}
    </ul>
  )
}

type InvoicesModalProps = {
  modal: ModalHookType
  onSave?: (values: any) => void
  onDelete?: (values: any) => void
  onClose?: () => void
}

export const InvoicesModal: React.FC<InvoicesModalProps> = ({modal, onSave, onDelete, onClose = () => null}) => {
  const [invoices, setInvoices] = useState<InvoiceItem[]>([])
  const query = subscriptionClient.useInvoicesQuery({
    onSuccess: (data) => {
      setInvoices(data)
    },
  })
  const request = useRequest()
  return (
    <Modal.Wrapper className="invoices-modal" open={modal.isOpen}>
      <Modal.Header>
        <h1>Invoices</h1>
        <MaterialIconButton
          path={mdiClose}
          size={1}
          onClick={() => {
            analytics.event(`${SCREEN_ID}-click-close`)
            modal.close()
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <MobileInvoiceMessage>
          Subscriptions purchased via in app purchase are available through the mobile app store used to purchase.
        </MobileInvoiceMessage>
        {invoices.length > 0 ? <InvoiceList invoices={invoices} /> : <span>No invoices found.</span>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          label="Refresh"
          color="blue"
          loading={request.loading}
          icon="refresh"
          onClick={() => {
            analytics.event(`${SCREEN_ID}-submit-refresh`)
            query.refetch()
          }}
        />
      </Modal.Footer>
    </Modal.Wrapper>
  )
}
export default InvoicesModal

const MobileInvoiceMessage = styled.small({
  textAlign: 'center',
  display: 'block',
  marginTop: 20,
  marginBottom: 20,
})
