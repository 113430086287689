import React from 'react'
import useAPI from '../../hooks/use-api'
import {FilterSet} from '../../hooks/use-filter-set'
import {ModalHookType} from '../../hooks/use-modal'
import {IconButton} from '../button'
import {TextInput} from '../forms'
import {PayPeriodPicker} from '../pay-period'
import Toolbar from '../toolbar'
import ReportDownloader from './common'
import {TimezonePicker} from '../pickers/timezone-picker'
import {useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import analytics from '@scavatec/timeclock-lib/analytics'
import {useNavigate} from 'react-router'
import {useAtom} from 'jotai'
import {reportSearchAtom} from 'pages/reports/report-state'
import {useActiveReport} from '@scavatec/timeclock-lib/api/report'

export type EmployeesReportFilters = {
  view: 'cards' | 'list' | string
  search: string
  columns: {
    totals: string[]
    intervals: string[]
    dailyTotals: string[]
  }
}
type ReportToolbarProps = {
  pageId?: string
  filterSet: FilterSet<EmployeesReportFilters>
  settingModal: ModalHookType
  timezone: string
}
export const ReportToolbar: React.FC<ReportToolbarProps> = ({filterSet, settingModal, pageId = '', timezone}) => {
  const [search, setSearch] = useAtom(reportSearchAtom)
  const navigate = useNavigate()
  const {actions, payPeriod, loading} = useReportContext()
  const {query} = useActiveReport()
  return (
    <div className="toolbars">
      <Toolbar.Bar>
        <Toolbar.Group>
          <TextInput
            label="Search"
            placeholder="Search"
            onFocus={() => analytics.event(`${pageId}-focus-search`)}
            value={search}
            onValueChange={setSearch}
            error=""
            minimal
          />
        </Toolbar.Group>
        <Toolbar.Group align="center"></Toolbar.Group>
        <Toolbar.Group align="right">
          <Toolbar.Button
            icon="reload"
            mode="secondary"
            label="Refresh"
            color="blue"
            loading={loading || query.isFetching}
            onClick={() => {
              analytics.event(`${pageId}-click-refresh`)
              query.refetch()
            }}
          />
          <Toolbar.Button
            icon="plus"
            label="Add Time"
            onClick={() => {
              analytics.event(`${pageId}-click-add-time`)
              navigate('/transactions/new')
            }}
          />
        </Toolbar.Group>
      </Toolbar.Bar>
      <Toolbar.Bar small>
        <Toolbar.Group>
          <Toolbar.Item label="Export Report">
            <ReportDownloader />
          </Toolbar.Item>
          <Toolbar.Item label="Report Timezone">
            <TimezonePicker onConfirm={() => null} />
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group></Toolbar.Group>
        <Toolbar.Group align="right">
          <Toolbar.Item label="Pay Period">
            <PayPeriodPicker
              onNextClick={() => analytics.event(`${pageId}-click-date-advance`)}
              onPrevClick={() => analytics.event(`${pageId}-click-date-previous`)}
            />
          </Toolbar.Item>
          {false && <IconButton minimal icon="cog" onClick={() => settingModal.open()} />}
        </Toolbar.Group>
      </Toolbar.Bar>
    </div>
  )
}
export default ReportToolbar
