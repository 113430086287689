import React, {useCallback} from 'react'
import Icon, {MaterialIcon} from './icon'
import ActivityIndicator from './activity-indicator'
import useClassnames from 'hooks/use-classnames'
import {IconProps} from '@mdi/react/dist/IconProps'

export type ButtonProps = {
  mode?: 'primary' | 'secondary' | 'tertiary' | 'outline'
  color?:
    | 'green'
    | 'red'
    | 'blue'
    | 'clock-in'
    | 'clock-out'
    | 'primary'
    | 'danger'
    | 'secondary'
    | 'grey'
  icon?: string
  iconRight?: string
  label?: string
  loading?: boolean
  disabled?: boolean
  className?: string
  type?: 'button' | 'submit'
  size?: 'small' | 'default' | 'large'
  onClick?: (e: React.MouseEvent) => void
  iconSize?: number
  href?: string
  iconPath?: string
  target?: '_blank' | '_self'
  variant?: any
}

const Button: React.FC<ButtonProps> = ({
  mode = 'primary',
  onClick = () => null,
  loading = false,
  disabled = false,
  color = '',
  className = '',
  iconSize = 16,
  iconPath = '',
  icon = '',
  iconRight = '',
  label = '',
  type = 'button',
  size = 'default',
  children,
  href = '',
  target = '_self',
}) => {
  const classes = useClassnames({
    button: true,
    loading,
    [size]: true,
    [mode]: true,
    [className]: true,
    [color]: true,
    disabled: loading || disabled,
  })
  const wrappedOnClick = useCallback(
    (e) => {
      if (!disabled && !loading) {
        onClick(e)
      }
    },
    [onClick, disabled, loading],
  )
  if (href.length > 0) {
    return (
      <a
        type={type}
        href={href}
        onClick={wrappedOnClick}
        target={target}
        className={`button ${loading ? 'loading' : ''} ${
          disabled || loading ? 'disabled' : ''
        } ${color} ${size} ${mode} ${className}`}>
        {loading && <ActivityIndicator />}
        {icon.length > 0 && <Icon icon={icon} size={iconSize} />}
        <span className="label">{label}</span>
        {iconRight.length > 0 && <Icon icon={iconRight} size={iconSize} />}
      </a>
    )
  }
  return (
    <button
      type={type}
      disabled={loading}
      onClick={wrappedOnClick}
      className={classes}>
      {loading && <ActivityIndicator />}
      {icon.length > 0 && <Icon icon={icon} size={iconSize} />}
      {iconPath.length > 0 && <MaterialIcon path={iconPath} size={iconSize} />}
      {label.length > 0 && <span className="label">{label}</span>}
      {iconRight.length > 0 && <Icon icon={iconRight} size={iconSize} />}
      {children}
    </button>
  )
}

type ButtonGroupOption = {
  icon?: string
  label: string
  value: string | any
}
type ButtonGroupProps = {
  options: ButtonGroupOption[]
  value: string
  error: string
  onValueChange: (v: any) => void
  color?: React.ComponentProps<typeof Button>['color']
}
export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  options,
  value,
  onValueChange,
  color = 'blue',
}) => {
  return (
    <div className="button-group">
      {options.map((o) => (
        <Button
          key={o.value}
          mode="secondary"
          color={color}
          className={o.value === value ? 'active' : ''}
          icon={o.icon ? o.icon : ''}
          label={o.label}
          onClick={() => onValueChange(o.value)}
        />
      ))}
    </div>
  )
}

type IconButtonProps = ButtonProps & {
  minimal?: boolean
}
export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  minimal = false,
  ...props
}) => {
  const classes = useClassnames({
    minimal,
    'icon-button': true,
  })
  return <Button {...props} icon={icon} iconSize={24} className={classes} />
}

export default Button

type MaterialIconButtonProps = {
  path: IconProps['path']
  size?: number
  onClick: () => void
}
export const MaterialIconButton = ({
  path,
  size = 1,
  onClick,
}: MaterialIconButtonProps) => {
  return (
    <div className="material-icon-button" onClick={onClick}>
      <MaterialIcon path={path} size={size} />
    </div>
  )
}
