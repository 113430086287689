import {useFeatureFlags} from '@scavatec/timeclock-lib/state/subscription'
import React from 'react'
import {Control} from 'react-hook-form'
import CurrencyInput from 'ui2/inputs/currency-input'
import PhoneInput from 'ui2/inputs/phone-input'
import SwitchInput from 'ui2/inputs/switch-input'
import TextAreaInput from 'ui2/inputs/text-area-input'
import TextInput from 'ui2/inputs/text-input'
import {SettingDivider} from '../settings'

type EmployeeEditProps = {
  hasExistingPin?: boolean
  mode: 'create' | 'update'
  control: Control<any>
}
export const EmployeeForm: React.FC<EmployeeEditProps> = ({hasExistingPin = false, mode, control}) => {
  const flags = useFeatureFlags()
  return (
    <>
      <div className="split-header">
        <h1>{`${mode === 'update' ? 'Edit' : 'New'} Employee`}</h1>
        <SwitchInput name="isActive" control={control as any} label="Active" />
      </div>
      <SettingDivider label="Name" />
      <div className="grid">
        <TextInput name="firstName" control={control as any} placeholder="Jane" label="First Name*" />
        <TextInput name="lastName" control={control as any} label="Last Name" placeholder="Doe" />
        <TextInput name="middleName" control={control as any} label="Middle Initial" placeholder="M." />
      </div>
      <div className="row-spacer" />
      <SettingDivider label="Details" />
      <div className="grid">
        <TextInput name="identifier" control={control as any} label="ID" placeholder="1122" />
        <CurrencyInput name="payRate" control={control as any} label="Hourly Pay Rate" placeholder="0.00" />
        {flags.isTrialOrPlusTier && (
          <TextInput name="departmentName" control={control as any} label="Department" placeholder="Maintenance" />
        )}
        {flags.isTrialOrPlusTier && (
          <TextInput name="managerName" control={control as any} label="Manager Name" placeholder="Jane Doe" />
        )}
        <TextInput name="email" control={control as any} label="Email" placeholder="me@example.com" type="email" />
        <PhoneInput name="phoneNumber" control={control as any} label="Phone" placeholder="555-555-5555" type="tel" />
        <TextAreaInput
          name="note"
          control={control as any}
          label="Note (only visible to admin)"
          placeholder="Employee details"
          style={{
            gridColumn: '1 / span 2',
          }}
        />
      </div>
      <div className="row-spacer" />
      <SettingDivider label="Access Control" />
      <div className="grid">
        <TextInput
          name="options.newPin"
          control={control as any}
          label={hasExistingPin ? 'Override Pin' : 'Set Pin'}
          maxLength={16}
          rules={{
            minLength: 1,
            maxLength: 16,
            pattern: {
              value: /^\d+$/,
              message: 'Must be only numeric characters',
            },
          }}
          placeholder="1234"
        />
        {hasExistingPin && <SwitchInput name="options.resetPin" control={control as any} label="Remove Existing Pin" />}
      </div>
    </>
  )
}
export default EmployeeForm
