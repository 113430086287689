import analytics from '@scavatec/timeclock-lib/analytics'
import {APIValidationError} from '@scavatec/timeclock-lib/api/client'
import * as crewClient from '@scavatec/timeclock-lib/api/crew'
import {CrewCreatePayload} from '@scavatec/timeclock-lib/api/types'
import {applyCreate, transformCrew} from '@scavatec/timeclock-lib/database/models/crew'
import useDatabase from '@scavatec/timeclock-lib/hooks/use-database'
import {useToastContext} from 'contexts/toast-context'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router'
import Button from 'ui/button'
import {ErrorCallout} from 'ui/callout'
import {Form} from 'ui/forms'
import CrewForm from 'ui/forms/crew-form'
import Toolbar from 'ui/toolbar'

const pageId = 'w.j.2.0'
type CrewNewProps = {}
export const CrewNew: React.FC<CrewNewProps> = ({children}) => {
  const navigate = useNavigate()
  const {addItem} = useToastContext()
  const {database, collections} = useDatabase()
  useEffect(() => {
    analytics.event(`${pageId}-view`)
  }, [])
  const {reset, control, setError, handleSubmit} = useForm<CrewCreatePayload>({
    defaultValues: {
      name: '',
      note: '',
      relationships: {
        employees: {
          data: [],
        },
        jobs: {
          data: [],
        },
      },
    },
  })
  const mutation = crewClient.useCrewCreateMutation({
    onError: (error) => {
      if (error instanceof APIValidationError) {
        Object.keys(error.errors).forEach((key) =>
          setError(`${key}` as any, {
            type: 'validation',
            message: error.errors[key],
          }),
        )
      }
    },
    onSuccess: async (res, data) => {
      await database.action(async () => await collections.crews.create(applyCreate(transformCrew.fromApi(res))))
      addItem(
        {
          title: `Created crew ${data.name}`,
          icon: 'save',
        },
        4000,
      )
    },
  })

  const submit = async (values: CrewCreatePayload, another: boolean) => {
    analytics.event(`${pageId}-submit-create`, {
      add_another: true,
      name: values.name.length > 0,
      note: values.note.length > 0,
      num_employees: values.relationships.employees.data.length,
      num_jobs: values.relationships.jobs.data.length,
    })
    try {
      await mutation.mutateAsync(values)
      if (another) {
        reset()
      } else {
        navigate('/crews')
      }
    } catch (e) {}
  }

  const saveAddAnother = async (values: CrewCreatePayload) => {
    submit(values, true)
  }

  const save = async (values: CrewCreatePayload) => {
    submit(values, false)
  }

  return (
    <div className="page crew-edit">
      <Toolbar.Bars>
        <Toolbar.Bar>
          <Toolbar.Group>
            <Button
              label="Cancel"
              disabled={mutation.isLoading}
              mode="secondary"
              icon="close"
              onClick={() => {
                analytics.event(`${pageId}-click-cancel`)
                navigate('/crews')
              }}
            />
          </Toolbar.Group>
          <Toolbar.Group align="right">
            <Button
              disabled={mutation.isLoading}
              loading={mutation.isLoading}
              label="Save & Add Another"
              mode="secondary"
              icon="plus"
              onClick={() => handleSubmit(saveAddAnother)()}
            />
            <Button
              disabled={mutation.isLoading}
              loading={mutation.isLoading}
              label={'Save'}
              icon={'plus'}
              onClick={() => handleSubmit(save)()}
            />
          </Toolbar.Group>
        </Toolbar.Bar>
      </Toolbar.Bars>

      <div className="page-content">
        {mutation.isError && <ErrorCallout error={mutation.error} />}
        <div className="split-header">
          <h1>{`New Crew`}</h1>
        </div>
        <Form onSubmit={handleSubmit(save)}>
          <CrewForm pageId="w.j.2.0" mode="create" control={control as any} />
        </Form>
      </div>
    </div>
  )
}
export default CrewNew
