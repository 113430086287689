import React from 'react'
import {RefObject} from 'react'
import {ChangeHandler} from 'react-hook-form'
import useClassnames from '../../hooks/use-classnames'
import Badge from '../badge'
import {LabelErrorRow} from './common'

type SwitchInputProps = {
  label: string
  error: string
  name: string
  showValue?: string
  description?: string
  onFocus?: ChangeHandler
  onBlur?: ChangeHandler
}
export const SwitchInput = React.forwardRef<HTMLInputElement, SwitchInputProps>(
  function RangeInput(props, ref) {
    const {
      error,
      label,
      name,
      description = '',
      onFocus = () => null,
      showValue = '',
      ...rest
    } = props
    const classes = useClassnames({error: error.length > 0})
    return (
      <div className="input switch">
        <label>{label}</label>
        <div className="switch-container">
          <input
            id={name}
            type="checkbox"
            name={name}
            className="switch-input"
            ref={ref}
            {...rest}
          />
          <label htmlFor={name}></label>
        </div>
      </div>
    )
  },
)

export default SwitchInput
