import {useState} from 'react'

export type DataModal<T> = {
  open: (data: T | null) => void
  close: () => void
  isOpen: boolean
  isEdit: boolean
  data: T | null
}
export const useDataModal = <T extends {} | null>(reset: () => T): DataModal<T> => {
  const [isOpen, setOpen] = useState(false)
  const [data, setData] = useState<T>(reset())
  const [isEdit, setEdit] = useState(false)

  const openModal = (data: T | null) => {
    setEdit(data ? true : false)
    setData(data ? data : reset())
    setOpen(true)
  }

  const closeModal = () => {
    setData(reset())
    setOpen(false)
  }
  return {
    open: openModal,
    close: closeModal,
    isOpen,
    isEdit,
    data,
  }
}

export default useDataModal
