import {mdiCrown} from '@mdi/js'
import analytics from '@scavatec/timeclock-lib/analytics'
import React, {useState} from 'react'
import {usePopper} from 'react-popper'
import {useNavigate} from 'react-router-dom'
import {MaterialIcon} from './icon'

type BadgeProps = {
  label: string
  outline?: boolean
  type?: string
}
export const Badge: React.FC<BadgeProps> = ({
  outline = false,
  type,
  label = 'primary',
}) => {
  return (
    <div className={`badge ${type} ${outline ? 'outline' : ''}`}>{label}</div>
  )
}
export default Badge

export const PlusPopoverBadge = () => {
  const [open, setOpen] = useState(false)
  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  )
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const {styles, attributes, update} = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'right',
      modifiers: [
        {name: 'arrow', options: {element: arrowElement}},
        {name: 'offset', options: {offset: [0, 8]}},
        {name: 'preventOverflow', options: {padding: 20}},
      ],
    },
  )
  const setVisibility = (b: boolean) => {
    if (b) {
      // analytics.event(`drawe-show-tooltip`})
    }
    setOpen(b)
    update && update()
  }
  const navigate = useNavigate()
  return (
    <div className="popover-container">
      <div
        className={`badge plus-feature`}
        ref={setReferenceElement}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          navigate('/settings')
        }}
        onMouseOver={() => setVisibility(true)}
        onMouseOut={() => setVisibility(false)}>
        PLUS
      </div>
      <div
        className="popover-content"
        style={styles.popper}
        data-open={open}
        {...attributes.popper}
        ref={setPopperElement}>
        <div className="arrow" ref={setArrowElement} style={styles.arrow} />
        This feature requires a Plus tier subscription. Click to learn more.
      </div>
    </div>
  )
}

export const PlusBadge = () => {
  return <div className={`badge plus-feature`}>PLUS</div>
}
