import analytics from '@scavatec/timeclock-lib/analytics'
import {useAuthContext} from '@scavatec/timeclock-lib/contexts/auth-context'
import {navigate} from 'hookrouter'
import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import Button from 'ui/button'

type WelcomeProps = {}
export const Welcome: React.FC<WelcomeProps> = ({children}) => {
  const navigate = useNavigate()
  const {
    state: {user},
  } = useAuthContext()
  useEffect(() => {
    analytics.event('w.a.3.0-view')
  }, [])
  return (
    <div className="page welcome">
      <div className="page-content">
        <h1>Welcome To Toolr Time Clock</h1>
        <p>
          For future reference your login id is <strong>{user.username}</strong>
        </p>
        <p className="button-list">
          <Button
            label="Add Your First Employee"
            icon="user"
            onClick={() => {
              analytics.event('w.a.3.0-click-add-first-employee')
              navigate('/employees/new')
            }}
          />
          <Button
            label="Add Your First Mobile Device"
            icon="laptop-phone"
            color="secondary"
            onClick={() => {
              analytics.event('w.a.3.0-click-add-first-device')
              navigate('/devices/new')
            }}
          />
          <Button
            label="Check out our help documentation"
            icon="book"
            color="secondary"
            onClick={() => {
              analytics.event('w.a.3.0-click-help-documentation')
              window.open('https://timeclock.freshdesk.com', '_blank')
            }}
          />
          <Button
            label="Schedule an onboarding meeting with your personal account rep"
            color="secondary"
            icon="headphone-alt"
            onClick={() => {
              analytics.event('w.a.3.0-click-schedule')
              window.open('https://calendly.com/toolr-success/', '_blank')
            }}
          />
        </p>
        {/* <p style={{marginTop: 200}}>
          <h4 style={{marginBottom: 10}}>
            To get started you will want to add your first employee.
          </h4>
          <Button label="Add Employee" icon="user" />
        </p>
        <p>
          <h4 style={{marginBottom: 10}}>
            View our help documentation and user guides to explore TimeClock and
            its features.
          </h4>
          <Button
            label="View Documentation"
            icon="book"
            color="secondary"
            onClick={() => {
              window.open('https://timeclock.freshdesk.com', '_blank')
            }}
          />
        </p>
        <p>
          <h4 style={{marginBottom: 10}}>
            Schedule an onboarding meeting with your personal account rep.
          </h4>
          <Button label="Schedule a Meeting" icon="headphone-alt" />
        </p> */}
      </div>
    </div>
  )
}
export default Welcome
