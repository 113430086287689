import analytics from '@scavatec/timeclock-lib/analytics'
import {useFeatureFlags} from '@scavatec/timeclock-lib/state/subscription'
import React from 'react'
import {NavLink, Outlet} from 'react-router-dom'

type SettingsProps = {}
export const Settings: React.FC<SettingsProps> = ({children}) => {
  return (
    <div className="page settings">
      <div className="tab-menu">
        <NavLink
          to="/settings"
          end
          onClick={() => analytics.event('w.e.0.0-click-subscription-tab')}>
          Subscription
        </NavLink>
        <NavLink
          to="/settings/account"
          onClick={() => analytics.event('w.e.0.0-click-account-tab')}>
          Account
        </NavLink>
        <NavLink
          to="/settings/report"
          onClick={() => analytics.event('w.e.0.0-click-report-tab')}>
          Report
        </NavLink>
        <NavLink
          to="/settings/breaks"
          onClick={() => analytics.event('w.e.0.0-click-breaks-tab')}>
          Breaks
        </NavLink>
        <NavLink
          to="/settings/overtime"
          onClick={() => analytics.event('w.e.0.0-click-overtime-tab')}>
          Overtime
        </NavLink>
        <div className="spacer"></div>
      </div>
      <Outlet />
    </div>
  )
}
export default Settings
