import React, {useMemo} from 'react'
import {CellProps} from 'react-table'
import Table, {TableRowClick} from '../table'
import {Currency, PreferredTime} from 'util/formatters'
import EmptyState from '../empty-state'
import DailyTotalCard from '../daily-total'
import {FilterSet} from '../../hooks/use-filter-set'
import {EmployeesReportFilters} from '../toolbars/report-toolbar'
import useFuse from '../../hooks/use-fuse'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useReportContext, transformAllDailyTotals} from '@scavatec/timeclock-lib/contexts/report-context'
import {ColumnSizes} from './common'

type DailyTotalsTable = {
  employee?: string
  job?: string
  showJob?: boolean
  showEmployee?: boolean
  view?: string
  onClick?: (dt: DailyTotal) => void
  analyticsId: string
  search?: string
  hideColumns?: string[]
}
const DailyTotalsTableLegacy: React.FC<DailyTotalsTable> = ({
  hideColumns = [],
  employee = '',
  job = '',
  analyticsId = '',
  showJob = true,
  showEmployee = true,
  view = 'cards',
  search = '',
  onClick,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Day',
        accessor: 'day',
        width: ColumnSizes.date,
      },
      {
        Header: 'Employee',
        accessor: 'employee',
        width: ColumnSizes.name,
      },
      {
        Header: 'Job',
        accessor: 'task',
        width: ColumnSizes.name,
      },
      {
        Header: 'Time',
        accessor: 'total',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Paid Time',
        accessor: 'paid',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Regular Time',
        accessor: (row: any) => row.paid - row.overtime,
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time + 20,
      },
      {
        Header: 'Overtime',
        accessor: 'overtime',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Breaks',
        accessor: 'break',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Pay',
        accessor: 'pay',
        Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value} />,
        width: ColumnSizes.currency,
      },
      {
        Header: 'Tips',
        accessor: 'tips',
        Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value} />,
        width: ColumnSizes.currency,
      },
    ],
    [hideColumns],
  )
  const {rawReport} = useReportContext()
  const {
    employees: {items: employees},
  } = useDataContext()
  let totals = useMemo(() => {
    if (rawReport) {
      let totals = transformAllDailyTotals(rawReport.dailyTotals, employees, rawReport.transactions)
      if (employee.length > 0) {
        totals = totals.filter((t) => t.employeeUUID === employee)
      }
      if (job.length > 0) {
        totals = totals.filter((t) => t.taskUUID === job)
      }
      return totals
    }
    return []
  }, [rawReport, employee, job])

  // Apply filters
  totals = useFuse(totals, search, {
    threshold: 0.1,
    shouldSort: true,
    keys: ['employee', 'task', 'day'],
  })

  if (totals.length <= 0) {
    return (
      <EmptyState
        title="No Daily Totals in Range"
        description="No daily totals were found in the selected date range. Try changing the dates or add a new time by clicking 'Add Time'."
      />
    )
  }
  if (view === 'list') {
    return (
      <Table
        columns={columns}
        analyticsId={analyticsId}
        data={totals}
        noHover
        initialSortBy={[{id: 'day', desc: true}]}
        onRowClick={() => null}
        getRowProps={(row) => {
          return {
            className: row.original.invalid ? 'invalid' : '',
          }
        }}
      />
    )
  }
  return (
    <div className="cards">
      {totals.map((dt, i) => (
        <DailyTotalCard showName={showEmployee} dailytotal={dt} key={i} onClick={onClick} />
      ))}
    </div>
  )
}
export default DailyTotalsTableLegacy
