import React, {useMemo} from 'react'
import {CellProps} from 'react-table'
import {Currency, PreferredTime} from 'util/formatters'
import {navigate} from 'hookrouter'
import useFuse from '../../hooks/use-fuse'
import {useReportContext, transformEmployeeTotals} from '@scavatec/timeclock-lib/contexts/report-context'
import {ColumnSizes} from 'ui/tables/common'
import EmptyState from 'ui/empty-state'
import Table from 'ui/table'
import {useNavigate} from 'react-router-dom'
import {useAtom} from 'jotai'
import {reportSearchAtom} from '../reports/report-state'
import Button from 'ui/button'
import {useActiveReport, useReport} from '@scavatec/timeclock-lib/api/report'
import {employeeName} from '@scavatec/timeclock-lib/formatters'
import {breakColumns, employeeColumns, overtimeColumns, timeColumns} from './column-definitions'

const analyticsId = 'w.c.1.0-employees'

type EmployeeTotalsProps = {}

const EmployeeTotals: React.FC<EmployeeTotalsProps> = ({}) => {
  const navigate = useNavigate()
  const columns = useMemo(() => {
    const cols = [...employeeColumns(), ...timeColumns(), ...breakColumns(), ...overtimeColumns()]
    return cols
  }, [])
  const {employeeTotals} = useActiveReport()

  // Apply filters
  const [search] = useAtom(reportSearchAtom)
  const totals = useFuse(employeeTotals, search, {
    threshold: 0.1,
    shouldSort: true,
    keys: ['first', 'last'],
  })
  if (totals.length <= 0) {
    return (
      <EmptyState
        title="No Totals in Range"
        description="No totals were found in the selected date range. Try changing the dates or add a new time by clicking 'Add Time'."
      >
        <Button
          label="Need Help?"
          color="clock-in"
          onClick={() => {
            navigate('/help')
          }}
        />
      </EmptyState>
    )
  }
  return (
    <Table
      columns={columns}
      data={totals}
      initialSortBy={[{id: 'name', desc: false}]}
      getRowProps={(row) => {
        return {
          className: row.original.isInvalid ? 'invalid' : '',
        }
      }}
      onRowClick={(r) => navigate(`/employees/${r.employee.id}`)}
    />
  )
}
export default EmployeeTotals
