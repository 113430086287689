import React, {useMemo} from 'react'
import {CellProps} from 'react-table'
import Table, {TableRowClick} from 'ui/table'
import {Currency, PreferredTime, AccountTime} from 'util/formatters'
import EmptyState from 'ui/empty-state'
import IntervalCard from 'ui/interval'
import {FilterSet} from '../../hooks/use-filter-set'
import useFuse from '../../hooks/use-fuse'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useReportContext, transformAllIntervals} from '@scavatec/timeclock-lib/contexts/report-context'
import {ColumnSizes} from 'ui/tables/common'
import {useAtom} from 'jotai'
import {reportSearchAtom} from '../reports/report-state'
import Button from 'ui/button'
import {useNavigate} from 'react-router'

const analyticsId = 'w.c.1.0-intervals'
type IntervalsProps = {}
const Intervals: React.FC<IntervalsProps> = (
  {
    /* onRowClick, */
    /* data, */
  },
) => {
  const columns = useMemo(
    () => [
      {
        Header: 'In',
        accessor: 'dIn',
        Cell: ({cell: {value}}: CellProps<any>) => <AccountTime value={value} />,
        width: ColumnSizes.datetime,
      },
      {
        Header: 'Out',
        accessor: 'dOut',
        Cell: ({cell: {value}, row: {original}}: CellProps<any>) => {
          if (original?.out?.faux) {
            return original?.out?.fauxLabel ?? 'Pending clock out'
          }
          return <AccountTime value={value} />
        },
        width: ColumnSizes.datetime,
      },
      {
        Header: 'Employee',
        accessor: 'name',
        width: ColumnSizes.name,
      },
      {
        Header: 'Job',
        accessor: 'job',
        width: ColumnSizes.name,
      },
      {
        Header: 'Duration',
        accessor: 'totalSeconds',
        Cell: ({cell: {value}}: CellProps<any>) => <PreferredTime seconds={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Pay Rate',
        accessor: 'payRate',
        Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value ?? 0} />,
        width: ColumnSizes.currency,
      },
      {
        Header: 'Tips',
        accessor: 'tips',
        Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value} />,
        width: ColumnSizes.currency,
      },
    ],
    [],
  )
  const {rawReport} = useReportContext()
  const {
    jobs: {items: jobs},
  } = useDataContext()
  let totals = useMemo(() => {
    const intervals: APIIntervals = rawReport?.intervals ?? {}
    let all = transformAllIntervals(intervals, jobs)
    return all
  }, [rawReport])
  const navigate = useNavigate()

  // Apply filters
  const [search] = useAtom(reportSearchAtom)
  totals = useFuse(totals, search, {
    threshold: 0.1,
    shouldSort: true,
    keys: ['name', 'job', 'dIn', 'dOut'],
  })

  if (totals.length <= 0) {
    return (
      <EmptyState
        title="No Intervals in Range"
        description="No intervals were found in the selected date range. Try changing the dates or add a new time by clicking 'Add Time'."
      >
        <Button
          label="Need Help?"
          color="clock-in"
          onClick={() => {
            navigate('/help')
          }}
        />
      </EmptyState>
    )
  }
  return (
    <Table
      columns={columns}
      data={totals}
      analyticsId={analyticsId}
      noHover
      initialSortBy={[{id: 'dOut', desc: true}]}
      onRowClick={() => null}
      getRowProps={(row) => {
        return {
          className: row.original.invalid ? 'invalid' : '',
        }
      }}
    />
  )
}
export default Intervals
