import {mdiCheck, mdiClose} from '@mdi/js'
import Icon from '@mdi/react'
import analytics from '@scavatec/timeclock-lib/analytics'
import * as subscriptionClient from '@scavatec/timeclock-lib/api/subscription'
import {useAuthContext} from '@scavatec/timeclock-lib/contexts/auth-context'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {subscriptionStatusAtom} from '@scavatec/timeclock-lib/state/subscription'
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js'
import {DataModal} from 'hooks/use-data-modal'
import {useAtom} from 'jotai'
import {DateTime} from 'luxon'
import React, {useState} from 'react'
import {useMutation} from '@tanstack/react-query'
import * as yup from 'yup'
import useForm from '../../hooks/use-form'
import Button, {MaterialIconButton} from '../button'
import Callout, {ErrorCallout, InfoCallout, RoundedErrorCallout} from '../callout'
import * as Forms from '../forms'
import {Pricing} from '../pricing'
import Modal from './modal'
import colors from '@scavatec/timeclock-lib/theme/colors'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'

const SCREEN_ID = 'w.e.2.1'

const schema = yup.object().shape({
  name: yup.string().required('please enter the name on the card'),
  coupon: yup.string(),
})
export type FormData = yup.InferType<typeof schema>

const SubscribeForm: React.FC<any> = ({modal, ...props}) => {
  const {
    state: {user},
  } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const plan = modal.data
  const query = subscriptionClient.useSubscriptionStatusQuery()
  const accessQuery = subscriptionClient.useSubscriptionAccessQuery()
  const stripe = useStripe()
  const elements = useElements()
  const [subscription] = useAtom(subscriptionStatusAtom)
  const [stripeError, setStripeError] = useState('')
  const subscribeMutation = subscriptionClient.useSubscribeMutation({
    onMutate: () => {
      analytics.event(`${SCREEN_ID}-submit-change-plan`)
    },
    onError: () => {
      setLoading(false)
    },
    onSuccess: () => {
      query.refetch()
      accessQuery.refetch()
      setLoading(false)
    },
  })
  let firstBillDate = DateTime.fromISO(subscription.trialPeriodEnd ?? '')
  const onSubmit = async (data: FormData) => {
    setLoading(true)
    if (!stripe || !elements) {
      throw new Error('Unable to load StripeJS')
    }
    setStripeError('')
    const ele = elements.getElement('card')
    if (!ele) {
      throw new Error('Unable to retrieve card input data')
    }
    analytics.event(`${SCREEN_ID}-submit-subscribe`)
    const {paymentMethod, error} = await stripe.createPaymentMethod({
      type: 'card',
      card: ele,
      billing_details: {name: data.name},
    })
    if (!paymentMethod) {
      setStripeError(error?.message ?? 'Unable to create payment method with stripe')
      setLoading(false)
      return
    }
    subscribeMutation.mutate({
      stripePaymentIntentTokenId: paymentMethod.id,
      organizationName: user.username,
      couponCode: data.coupon,
      subscriptionPriceName: plan.price,
      subscriptionProductName: plan.product,
    })
  }
  const theme = useOrganizationSetting('account.theme')
  const {handleSubmit, getInputProps} = useForm<FormData>({name: '', coupon: ''}, schema)
  return (
    <>
      <Modal.Header>
        <h1>Subscribe - {plan.product === subscriptionClient.STARTER_TIER_NAME ? 'Starter' : 'Plus'}</h1>
        <MaterialIconButton
          path={mdiClose}
          size={1}
          onClick={() => {
            analytics.event(`${subscribeMutation.isSuccess ? 'w.e.2.2' : SCREEN_ID}-click-close`)
            modal.close()
          }}
        />
      </Modal.Header>
      {stripeError && (
        <div className="callout-container">
          <RoundedErrorCallout message={stripeError} />
        </div>
      )}
      {subscribeMutation.isError && (
        <div className="callout-container">
          <RoundedErrorCallout message={subscribeMutation.error + ''} />
        </div>
      )}
      <Forms.Form onSubmit={() => handleSubmit(onSubmit)}>
        <Modal.Body>
          {subscribeMutation.isSuccess && (
            <div className="subscription-success">
              <Icon size={1} color={colors.green} path={mdiCheck} />
              You are now subscribed to Time Clock.
            </div>
          )}
          {!subscribeMutation.isSuccess && (
            <>
              {subscription.isSubscribed && subscription.processor !== 'stripe' && (
                <ErrorCallout message="You are currently subscribed via an in-app subscription. Please make sure to cancel this subscription after you have switched to card based billing." />
              )}
              <Pricing
                product={plan.product}
                price={plan.price}
                activeEmployeeCount={subscription.activeEmployeeCount ?? 0}
              />
              <div className="first-bill">
                {firstBillDate < DateTime.local() ? (
                  <p>You will be billed upon subscribing.</p>
                ) : (
                  <p>You will be first be billed on {firstBillDate.toLocaleString(DateTime.DATE_MED)}.</p>
                )}
              </div>
              <Forms.Row>
                <Forms.TextInput label="Card Holder Name" placeholder="John Doe" {...getInputProps('name')} />
              </Forms.Row>
              <span className="form-label">Card Info</span>
              <div className="card-form">
                <CardElement
                  options={{
                    style: {
                      base: {
                        color: theme.value === 'dark' ? '#fff' : '#000',
                        '::placeholder': {
                          color: '#828282',
                          fontSize: '1.1rem',
                        },
                      },
                    },
                  }}
                />
              </div>
              <Forms.Row>
                <Forms.TextInput label="Coupon Code (optional)" placeholder="CODE15" {...getInputProps('coupon')} />
              </Forms.Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!subscribeMutation.isSuccess && (
            <>
              <Button
                type="submit"
                disabled={subscribeMutation.isLoading || loading}
                loading={subscribeMutation.isLoading || loading}
                label="Subscribe"
              />
            </>
          )}
          {subscribeMutation.isSuccess && (
            <>
              <Button
                label="Close"
                disabled={subscribeMutation.isLoading}
                onClick={() => {
                  analytics.event(`w.e.2.3-click-close`)
                  modal.close()
                }}
              />
            </>
          )}
        </Modal.Footer>
      </Forms.Form>
    </>
  )
}

type SubscribeModalProps = {
  modal: DataModal<{product: string; price: string}>
  onSave?: (values: any) => void
  onDelete?: (values: any) => void
  onClose?: () => void
}

export const SubscribeModal: React.FC<SubscribeModalProps> = ({modal, onSave, onDelete, onClose = () => null}) => {
  return (
    <Modal.Wrapper className="subscribe-modal" open={modal.isOpen}>
      <SubscribeForm modal={modal} />
    </Modal.Wrapper>
  )
}
export default SubscribeModal
