import React, {useMemo} from 'react'
import {Control, RegisterOptions, useController} from 'react-hook-form'
import useClassnames from '../../hooks/use-classnames'
import {InputLabelRow, FieldError} from './common'
import Select from 'react-select'
import ReactSelect from 'react-select'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'

export type SelectOption = {
  label: string
  value: string
  color?: string
}
type GroupedOptions = {
  label: string
  options: SelectOption[]
}[]
type SelectInputProps = {
  name: string
  label?: string
  placeholder?: string
  options: SelectOption[] | GroupedOptions
  control: Control<any>
  onChange?: (v: string) => void
  clearable?: boolean
  style?: any
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}
export const SelectInput = ({
  name,
  control,
  rules,
  placeholder = '',
  options,
  label,
  clearable,
  onChange,
  style,
  ...rest
}: SelectInputProps) => {
  const {
    field,
    fieldState: {error},
  } = useController<any>({name, control, defaultValue: null, rules})
  const classes = useClassnames({
    error: error != undefined,
  })
  const theme = useOrganizationSetting('account.theme')
  const v = useMemo(() => {
    if ((options.length > 0 && options[0].hasOwnProperty('options')) ?? undefined) {
      const opts = options as GroupedOptions
      return opts.reduce<SelectOption | undefined>((acc, sub) => {
        if (!acc) {
          return sub.options.find((o) => o.value === field.value)
        }
        return acc
      }, undefined)
    } else {
      const opts = options as SelectOption[]
      return opts.find((o) => o.value === field.value)
    }
  }, [field.value, options])
  return (
    <div className={`form-input select ${classes}`} style={style}>
      {label && <InputLabelRow label={label} error={error} />}
      <Select
        {...rest}
        {...field}
        theme={(base) => ({
          ...base,
          colors: {
            ...base.colors,
            primary25: theme.value === 'dark' ? '#828282' : base.colors.primary25,
            neutral0: theme.value === 'dark' ? '#323232' : base.colors.neutral0,
            neutral10: 'green',
          },
        })}
        styles={selectStyles}
        options={options}
        classNamePrefix="select"
        className={classes}
        isClearable={clearable}
        placeholder={placeholder}
        value={v}
        formatGroupLabel={selectGroupLabel}
        onChange={(v) => {
          onChange?.(v?.value ?? '')
          field.onChange(v?.value)
        }}
      />
      <FieldError error={error} label={label} />
    </div>
  )
}

const selectGroupLabel = (data: any) => (
  <div className="select-group-label">
    <span>{data.label}</span>
    <span>{data.options.length}</span>
  </div>
)

const selectStyles = {
  option: (styles: any, {data}: any) => {
    return {
      ...styles,
      // backgroundColor: '#fff',
      // borderBottom: '1px solid rgba(0,0,0,0.1)',
      color: data.color,
      // ...dot(data.color),
      ':hover': {
        // backgroundColor: '#ccc',
      },
    }
  },
  control: (styles: any) => ({
    ...styles,
    background: '#E3E8EC',
    border: 'none',
    borderRadius: 8,
  }),
  menuPortal: (styles: any) => ({
    backgroundColor: 'blue',
    color: 'red',
  }),
  menu: (styles: any) => ({
    ...styles,
    // background: '#fff',
  }),
  singleValue: (styles: any, {data}: any) => {
    return {
      ...styles,
      color: data.color,
      // ...dot(data.color),
    }
  },
  multiValue: (styles: any, {data}: any) => {
    return {
      ...styles,
      background: '#2c2f33',
    }
  },
  multiValueLabel: (styles: any, {data}: any) => {
    return {
      ...styles,
      color: '#fff',
    }
  },
}

export default SelectInput

type MultiSelectInputProps = Partial<JSX.LibraryManagedAttributes<typeof ReactSelect, ReactSelect['props']>> & {
  name: string
  placeholder?: string
  disabled?: boolean
  control: Control<any>
  label: string
  style?: any
  options: SelectOption[]
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}
export const MultiSelectInput = ({
  name,
  control,
  label,
  rules,
  options,
  style,
  disabled = false,
  ...props
}: MultiSelectInputProps) => {
  const {
    field: {ref, onChange, value, onBlur: controllerOnBlur, ...inputProps},
    fieldState: {error},
  } = useController<any>({name, control, defaultValue: false, rules})
  const v = useMemo(
    () =>
      options.filter((o) => {
        if (!Array.isArray(value)) {
          return null
        }
        return value.find((s: any) => s === o.value)
      }),
    [value, options],
  )
  return (
    <div className="form-input select" style={style}>
      <InputLabelRow label={label} error={error} />
      <ReactSelect
        value={v as any}
        styles={selectStyles}
        options={options as any}
        isMulti={true as any}
        {...props}
        onChange={(newValue) => {
          if (Array.isArray(newValue)) {
            const vals = newValue as SelectOption[]
            onChange(vals.map((v) => v.value))
          }
        }}
      />
      <FieldError error={error} label={label} />
    </div>
  )
}
