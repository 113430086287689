import analytics from '@scavatec/timeclock-lib/analytics'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import {useAtom} from 'jotai'
import React, {useCallback} from 'react'
import {useNavigate, useParams} from 'react-router'
import DailyTotalsTable from 'ui/tables/daily-totals-table'
import DailyTotalsTableLegacy from 'ui/tables/daily-totals-table-legacy'
import {employeeListViewAtom, employeeSearchAtom} from './employee-state'

const screenID = 'w.g.2.0-daily-totals'

type EmployeeTableProps = {}
const EmployeeDailyTotals: React.FC<EmployeeTableProps> = ({}) => {
  const {uuid = ''} = useParams()
  const [search] = useAtom(employeeSearchAtom)
  const [view] = useAtom(employeeListViewAtom)
  const navigate = useNavigate()
  const reportEngineSetting = useOrganizationSetting('reporting.engine_version')
  const onClick = useCallback(() => {
    analytics.event(`${screenID}-click-tile`)
    navigate(`/employees/${uuid}/times`)
  }, [])
  const onClickLegacy = useCallback(() => {
    analytics.event(`${screenID}-click-tile`)
    navigate(`/employees/${uuid}/times`)
  }, [])
  return reportEngineSetting.value < 2 ? (
    <DailyTotalsTableLegacy
      search={search}
      view={view}
      employee={uuid}
      showJob
      hideColumns={['employee']}
      analyticsId={screenID}
      onClick={onClick}
    />
  ) : (
    <DailyTotalsTable
      search={search}
      view={view}
      employee={uuid}
      showJob
      hideColumns={['employee']}
      analyticsId={screenID}
      onClick={onClickLegacy}
    />
  )
}
export default EmployeeDailyTotals
