import analytics from '@scavatec/timeclock-lib/analytics'
import {APIValidationError} from '@scavatec/timeclock-lib/api/client'
import * as deviceClient from '@scavatec/timeclock-lib/api/device'
import {defaultDeviceData, DeviceFormData, deviceValidationResolver} from '@scavatec/timeclock-lib/forms/device'
import {validationErrorsToHookForm} from '@scavatec/timeclock-lib/forms/errors'
import {userAtom} from '@scavatec/timeclock-lib/state/environment'
import {settingsAtom, transformRemoteSetting} from '@scavatec/timeclock-lib/state/settings'
import {hashLocalPassword} from '@scavatec/timeclock-lib/util/local-password'
import {useToastContext} from 'contexts/toast-context'
import {useAtom} from 'jotai'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router'
import Button from 'ui/button'
import {ErrorCallout} from 'ui/callout'
import * as Forms from 'ui/forms'
import DeviceForm, {updateDeviceSetting} from 'ui/forms/device-form'
import Toolbar from 'ui/toolbar'

const SCREEN_ID = 'w.i.2.0'
type DeviceNewProps = {}

export const DeviceNew: React.FC<DeviceNewProps> = ({children}) => {
  const {addItem} = useToastContext()
  const [user] = useAtom(userAtom)
  const navigate = useNavigate()
  const [, settingsDispatch] = useAtom(settingsAtom)
  const deviceListQuery = deviceClient.useDeviceListQuery()
  useEffect(() => {
    analytics.event(`${SCREEN_ID}-view`)
  }, [])

  const form = useForm<DeviceFormData>({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    resolver: deviceValidationResolver,
    defaultValues: {
      ...defaultDeviceData,
    },
  })
  const {
    watch,
    formState: {isSubmitting},
    reset,
    setError,
    handleSubmit,
  } = form
  const mutation = deviceClient.useDeviceCreateMutation({
    onError: (err) => validationErrorsToHookForm(setError as any, err, 'data.'),
  })
  const values = watch()

  const saveNewDevice = async (v: DeviceFormData) => {
    analytics.event('w.i.2.0-submit-save', {
      name: v.data.name.length > 0,
      note: v.data.note && v.data.note.length > 0,
      // device_mode: v.data.settings.syncMode,
      // features: v.features,
    })
    try {
      const res = await mutation.mutateAsync({
        name: v.data.name,
        note: v.data.note,
        appVersion: '',
        identifier: '',
        appName: '',
        osName: '',
        osVersion: '',
        apiVersion: '',
      })
      // Allow settings to silently fail
      try {
        const settingChanges = [
          updateDeviceSetting('device.clocking.employee_mode', v.settings.employeeMode),
          updateDeviceSetting('device.clocking.jobs.enabled', v.settings.jobs),
          updateDeviceSetting('device.clocking.jobs.require_selection', v.settings.requireJobSelection),
          updateDeviceSetting('device.clocking.notes.enabled', v.settings.notes),
          updateDeviceSetting('device.clocking.employee_report.enabled', v.settings.selfServe),
          updateDeviceSetting('device.clocking.require_pin', v.settings.requirePin),
          updateDeviceSetting('device.clocking.pin_changing.enabled', v.settings.allowPinChange),
          updateDeviceSetting('device.clocking.tips.enabled', v.settings.tips),
          updateDeviceSetting('device.clocking.tips.entry_at_clock_in', v.settings.tipsAtIn),
          updateDeviceSetting('device.admin.account_management', v.settings.accountManagement),
          updateDeviceSetting('device.reporting.enabled', v.settings.reports),
          updateDeviceSetting('device.reporting.allow_editing', v.settings.reportEditing),
          updateDeviceSetting('device.reporting.require_admin_password', v.settings.reportsPassword),
          updateDeviceSetting('device.clocking.employee_status_message.enabled', v.settings.statusMessage),
          updateDeviceSetting('device.clocking.crews', v.settings.crews),
          updateDeviceSetting('device.clock_list.show_avatar', v.settings.avatars),
          updateDeviceSetting('device.resource_score.enabled', v.settings.resourceScore),
          updateDeviceSetting('device.clocking.toast_notification.enabled', v.settings.toastNotification),
          updateDeviceSetting(
            'device.clocking.single_employee',
            v.settings.singleEmployee.length <= 0 ? null : v.settings.singleEmployee,
          ),
        ]
        if (v.settings.usePassword && v.settings.newPassword.length > 0) {
          settingChanges.push(
            updateDeviceSetting(
              'device.admin.local_password',
              hashLocalPassword(user.organization, v.settings.newPassword),
            ),
          )
        }
        await Promise.all(
          settingChanges.map((fn) =>
            fn(user.organization, res.id).then((res) => {
              // Update the setting locally
              settingsDispatch({type: 'update', payload: transformRemoteSetting(res)})
            }),
          ),
        )
      } catch (e) {
        console.log('setting error')
      }
      addItem(
        {
          title: `Created device ${res.name}`,
          icon: 'save',
        },
        4000,
      )
      return true
    } catch (e) {
      return false
    }
  }

  const onSubmit = (another: boolean) => async (v: DeviceFormData) => {
    const isSuccess = await saveNewDevice(v)
    if (!isSuccess) {
      return
    }
    if (another) {
      reset()
    } else {
      navigate('/devices')
      deviceListQuery.refetch()
    }
  }

  return (
    <div className="page device-edit">
      <Forms.Form onSubmit={handleSubmit(onSubmit(false))}>
        <Toolbar.Bars>
          <Toolbar.Bar>
            <Toolbar.Group>
              <Button
                label="Cancel"
                disabled={isSubmitting}
                mode="secondary"
                icon="close"
                onClick={() => {
                  analytics.event(`${SCREEN_ID}-click-cancel`)
                  navigate('/devices')
                }}
              />
            </Toolbar.Group>
            <Toolbar.Group align="right">
              <Button
                disabled={mutation.isLoading}
                loading={mutation.isLoading}
                label="Save & Add Another"
                mode="secondary"
                icon="plus"
                onClick={handleSubmit(onSubmit(true))}
              />
              <Button type="submit" disabled={isSubmitting} loading={isSubmitting} label={'Save'} icon={'plus'} />
            </Toolbar.Group>
          </Toolbar.Bar>
        </Toolbar.Bars>
        <div className="page-content">
          {mutation.isError && <ErrorCallout error={mutation.error} />}
          <DeviceForm pageId="w.i.2.0" form={form} />
        </div>
      </Forms.Form>
    </div>
  )
}
export default DeviceNew
