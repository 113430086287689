import analytics from '@scavatec/timeclock-lib/analytics'
import {useJobListQuery} from '@scavatec/timeclock-lib/api/job'
import Job, {JobDoc, NONE_UUID} from '@scavatec/timeclock-lib/database/models/job'
import {jobsAtom} from '@scavatec/timeclock-lib/state/entities'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import useFilterSet from 'hooks/use-filter-set'
import useFuse from 'hooks/use-fuse'
import {useAtom} from 'jotai'
import React, {useEffect, useMemo} from 'react'
import {useNavigate} from 'react-router'
import Button from 'ui/button'
import EmptyState from 'ui/empty-state'
import JobCard from 'ui/job'
import JobTable from 'ui/tables/job-table'
import JobListToolbar, {JobListFilters} from 'ui/toolbars/job-list-toolbar'

const fuseOptions = {
  threshold: 0.1,
  shouldSort: true,
  keys: ['name'],
}

type JobListProps = {}
export const JobList: React.FC<JobListProps> = ({children}) => {
  const navigate = useNavigate()
  const preferredView = useOrganizationSetting('account.preferred_view')
  const query = useJobListQuery({})
  const filterSet = useFilterSet<JobListFilters>({
    search: '',
    showInactive: false,
    view: preferredView.value,
  })
  useEffect(() => {
    analytics.event('w.h.1.0-view')
    query.refetch()
  }, [])
  const [jobs] = useAtom(jobsAtom)
  const {search, showInactive, view} = filterSet.filters
  let filtered = useMemo(() => {
    return jobs
      .map<JobDoc>((j) => ({
        name: j.name,
        identifier: j.identifier,
        isActive: j.isActive,
        note: j.note,
        uuid: j.uuid,
      }))
      .filter((j) => (showInactive || j.isActive) && j.uuid !== NONE_UUID)
  }, [jobs, showInactive])
  filtered = useFuse(filtered, search, fuseOptions)
  return (
    <div className="page jobs">
      <JobListToolbar filterSet={filterSet} />
      <div className="page-content">
        {jobs.length <= 0 && (
          <EmptyState title="No Jobs Found" description="No jobs have been added to your account.">
            <Button
              label="Add Job"
              color="clock-in"
              icon="plus"
              onClick={() => {
                analytics.event('w.h.1.0-click-add-job-empty')
                navigate('/jobs/new')
              }}
            />
          </EmptyState>
        )}
        {jobs.length > 0 && filtered.length <= 0 && (
          <EmptyState title="No Jobs Found" description="">
            <p>
              No jobs were found with the selected filters. Change the filters above or create a new job by clicking the
              'Add Job' button in the top right.
            </p>
            <Button
              label="Add Job"
              color="clock-in"
              icon="plus"
              onClick={() => {
                analytics.event('w.h.1.0-click-add-job-empty')
                navigate('/jobs/new')
              }}
            />
          </EmptyState>
        )}
        {preferredView.value === 'list' ? (
          <JobTable
            analyticsId="w.h.1.0"
            data={filtered}
            onRowClick={(jobDocument: Job) => {
              analytics.event('w.h.1.0-click-tile')
              navigate('/jobs/' + jobDocument.uuid)
            }}
          />
        ) : (
          <div className="cards">
            {filtered.map((j) => {
              return (
                <JobCard
                  key={j.uuid}
                  job={j}
                  onClick={() => {
                    analytics.event('w.h.1.0-click-tile')
                    navigate('/jobs/' + j.uuid)
                  }}
                />
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
export default JobList
