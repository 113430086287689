import analytics, {sanitizeProperties} from '@scavatec/timeclock-lib/analytics'
import {APIValidationError} from '@scavatec/timeclock-lib/api/client'
import * as employeeClient from '@scavatec/timeclock-lib/api/employee'
import {EmployeeCreatePayload, RemoteEmployee} from '@scavatec/timeclock-lib/api/types'
import {applyCreate, createEmployee, transformEmployee} from '@scavatec/timeclock-lib/database/models/employee'
import useDatabase from '@scavatec/timeclock-lib/hooks/use-database'
import {useCustomForm} from '@scavatec/timeclock-lib/state/form-builder'
import {useToastContext} from 'contexts/toast-context'
import {CustomUserForm} from 'custom-forms'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router'
import Button from 'ui/button'
import {ErrorCallout} from 'ui/callout'
import * as Forms from 'ui/forms'
import EmployeeForm from 'ui/forms/employee-form'
import {SettingDivider} from 'ui/settings'
import Toolbar from 'ui/toolbar'
import SwitchInput from 'ui2/inputs/switch-input'
import {employeeName} from 'util/formatters'
import env from 'util/env'
import {useMutation} from '@tanstack/react-query'
import {useAtom} from 'jotai'
import {userAtom} from '@scavatec/timeclock-lib/state/environment'
import {useDeviceListQuery} from '@scavatec/timeclock-lib/api/device'
import {ClientError} from '@scavatec/timeclock-lib/api/query-utils'
import {updateDeviceSetting} from 'ui/forms/device-form'
import {validationErrorsToHookForm} from '@scavatec/timeclock-lib/forms/errors'

const screenID = 'w.g.3.0'

type EmployeeNewProps = {}
export const EmployeeNew: React.FC<EmployeeNewProps> = ({children}) => {
  const navigate = useNavigate()
  const {database, collections} = useDatabase()
  const [user] = useAtom(userAtom)
  const {addItem} = useToastContext()
  const {control, handleSubmit, watch, setError, reset} = useForm({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      ...createEmployee({}),
      options: {
        sms: false,
        newPin: '',
        createDevice: false,
      },
    },
  })
  useEffect(() => {
    analytics.event(`${screenID}-view`)
  }, [])
  const deviceListQuery = useDeviceListQuery()
  const mutation = useMutation<{employee: RemoteEmployee; deviceCreated: boolean}, ClientError, EmployeeCreatePayload>(
    async (values) => {
      const employee = await employeeClient.create(user.organization, values)
      let deviceCreated = false
      if (values.options.createDevice) {
        const deviceName = `${employee.firstName ?? employee.lastName}'s Device`
        try {
          const device = await employeeClient.createDefaultEmployeeDevice(user.organization, employee.id, deviceName)
          deviceCreated = true
          await Promise.all(
            [
              updateDeviceSetting('device.clocking.employee_mode', 'single'),
              updateDeviceSetting('device.clocking.single_employee', employee.id),
            ].map((fn) => fn(user.organization, device.id)),
          )
          if (values.options.sms) {
            await employeeClient.sendDeviceRegistrationSMS(
              user.organization,
              employee.id,
              device?.registrationCode ?? '',
            )
          }
        } catch (e) {}
      }
      return {employee, deviceCreated}
    },
    {
      onMutate: (values) => {},
      onSuccess: async (res, values) => {
        if (!res) {
          return
        }
        if (res.deviceCreated) {
          deviceListQuery.refetch()
        } else if (values?.options.createDevice) {
          addItem(
            {
              title: `Unable to add device for employee, try from devices page.`,
              icon: 'error',
            },
            4000,
          )
        }
        addItem(
          {
            title: `Created employee ${employeeName(transformEmployee.fromApi(res.employee))}`,
            icon: 'save',
          },
          4000,
        )
      },
      onError: (error) => {
        validationErrorsToHookForm(setError as any, error)
      },
    },
  )
  const form = useCustomForm('employeeStore', {enabled: env.features.customAttributes})

  const submit = async (values: EmployeeCreatePayload, another: boolean) => {
    analytics.event(`${screenID}-submit-create`, sanitizeProperties(values))
    try {
      await mutation.mutateAsync(values)
      if (another) {
        reset({
          ...createEmployee({}),
          options: {
            sms: false,
            newPin: '',
            createDevice: false,
          },
        })
      } else {
        navigate('/employees')
      }
    } catch (e) {}
  }

  const saveAddAnother = async (values: EmployeeCreatePayload) => {
    submit(values, true)
  }

  const save = async (values: EmployeeCreatePayload) => {
    submit(values, false)
  }

  const values = watch()
  return (
    <div className="page employee-edit">
      <Toolbar.Bars>
        <Toolbar.Bar>
          <Toolbar.Group>
            <Button
              label="Cancel"
              mode="secondary"
              disabled={mutation.isLoading}
              icon="close"
              onClick={() => {
                analytics.event(`${screenID}-click-cancel`)
                navigate('/employees')
              }}
            />
          </Toolbar.Group>
          <Toolbar.Group align="right">
            <Button
              disabled={mutation.isLoading}
              loading={mutation.isLoading}
              label="Save & Add Another"
              mode="secondary"
              icon="plus"
              onClick={handleSubmit(saveAddAnother as any)}
            />
            <Button
              type="submit"
              disabled={mutation.isLoading}
              loading={mutation.isLoading}
              label="Save"
              icon="plus"
              onClick={() => {
                handleSubmit(save as any)()
              }}
            />
          </Toolbar.Group>
        </Toolbar.Bar>
      </Toolbar.Bars>

      <div className="page-content">
        {mutation.isError && <ErrorCallout error={mutation.error} />}
        <Forms.Form className="employee-form" onSubmit={handleSubmit(save as any)}>
          <EmployeeForm mode="create" control={control as any} />
          <div className="row-spacer" />
          <SettingDivider label="Device Creation (optional)" />
          <div className="grid">
            <SwitchInput name="options.createDevice" control={control as any} label="Add Device For Employee" />
            <SwitchInput
              name="options.sms"
              control={control as any}
              label="Send Code & Instructions via SMS"
              disabled={!values.options.createDevice || values.phoneNumber.length < 9}
              disabledMessage={!values.phoneNumber || values.phoneNumber.length < 9 ? 'A phone number is required' : ''}
            />
          </div>
          <div className="row-spacer" />
          <CustomUserForm control={control as any} prefix="relationships.customForm.data" formName="employeeStore">
            <SettingDivider label="Custom Properties" />
          </CustomUserForm>
        </Forms.Form>
      </div>
    </div>
  )
}
export default EmployeeNew
