import Employee from '@scavatec/timeclock-lib/database/models/employee'
import Job from '@scavatec/timeclock-lib/database/models/job'
import {activeEmployeesAtom, activeJobsAtom} from '@scavatec/timeclock-lib/state/entities'
import {useAtom} from 'jotai'
import {groupBy, sort} from 'rambda'
import React, {useMemo} from 'react'
import {Control} from 'react-hook-form'
import ListPickerInput from 'ui2/inputs/list-picker-input'
import TextAreaInput from 'ui2/inputs/text-area-input'
import TextInput from 'ui2/inputs/text-input'
import * as Forms from '../forms'

const byStatus = groupBy((item: Employee | Job) => {
  return item.isActive ? 'active' : 'inactive'
})

const sortEmployees = (a: Employee, b: Employee) => {
  return a.isActive !== b.isActive
    ? -1
    : 1 || a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)
}

const sortJobs = (a: Job, b: Job) => {
  return a.isActive !== b.isActive ? -1 : 1 || a.name.localeCompare(b.name)
}

type CrewFormProps = {
  mode: 'create' | 'update'
  pageId?: string
  control: Control<any>
}
export const CrewForm: React.FC<CrewFormProps> = ({pageId = '', mode, control}) => {
  const [employees] = useAtom(activeEmployeesAtom)
  const [jobs] = useAtom(activeJobsAtom)
  const employeeOptions = useMemo(() => {
    return sort(sortEmployees, employees).map((e) => ({
      value: e.uuid,
      label: (
        <div className="label">
          <span>{e.formattedName()}</span>
          <span className="hint">{e.isActive ? '' : 'inactive'}</span>
        </div>
      ),
    }))
  }, [employees])
  const jobOptions = useMemo(() => {
    return sort(sortJobs, jobs).map((j) => ({
      value: j.uuid,
      label: (
        <div className="label">
          <span>{j.name}</span>
          <span className="hint">{j.isActive ? '' : 'inactive'}</span>
        </div>
      ),
    }))
  }, [jobs])

  return (
    <>
      <Forms.Row>
        <TextInput label="Name*" placeholder="Managers" name="name" control={control as any} />
        <TextAreaInput label="Note" placeholder="Crew details" name="note" control={control as any} />
      </Forms.Row>
      <Forms.Row className="list-picker-row">
        {/* <MultiSelect
                options={employeeOptions}
                {...getInputProps('employees')}
              /> */}
        <ListPickerInput
          label="Employees"
          name="relationships.employees.data"
          options={employeeOptions}
          control={control as any}
        />
        <ListPickerInput label="Jobs" name="relationships.jobs.data" options={jobOptions} control={control as any} />
      </Forms.Row>
    </>
  )
}
export default CrewForm
