import analytics from '@scavatec/timeclock-lib/analytics'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {EmployeeDoc} from '@scavatec/timeclock-lib/database/models/employee'
import React from 'react'
import {useNavigate} from 'react-router'
import useAPI from '../../hooks/use-api'
import {DataModal} from '../../hooks/use-data-modal'
import {FilterSet} from '../../hooks/use-filter-set'
import Button, {ButtonGroup} from '../button'
import {Switch, TextInput} from '../forms'
import BoardOptionsModal from '../modals/board-options-modal'
import Toolbar from '../toolbar'

export type TVModeFilters = {
  view: 'cards' | 'list' | string
  showInactive: boolean
  autoScroll: boolean
  scrollSpeed: number
  scrollStride: number
  showEmployeeNote: boolean
  showLastJob: boolean
  showLastTime: boolean
  showStatus: boolean
  showEmployeeStatus: boolean
  sort: 'name' | 'status' | 'activity'
  density: number
  status: 'in' | 'out' | 'all'
  search: string
}
type TVModeToolbarProps = {
  filterSet: FilterSet<TVModeFilters>
  modal: DataModal<EmployeeDoc>
}
export const TVModeToolbar: React.FC<TVModeToolbarProps> = ({
  filterSet,
  modal,
}) => {
  const refreshRequest = useAPI()
  const navigate = useNavigate()
  const {
    employees: {requestSync},
  } = useDataContext()
  return (
    <div className="toolbars">
      <Toolbar.Bar>
        <Toolbar.Group>
          <Button
            icon="arrow-left"
            size="small"
            label="Back to App"
            className="back-button"
            mode="secondary"
            onClick={() => {
              analytics.event('w.k.1.0-click-back')
              navigate('/employees')
            }}
          />
          <TextInput
            label="Search"
            placeholder="Search"
            onFocus={() => analytics.event('w.k.1.0-focus-search')}
            {...filterSet.getInputProps('search')}
            minimal
          />
          <Toolbar.Item label="Status">
            <ButtonGroup
              {...filterSet.getInputProps('status')}
              options={[
                {label: 'All', icon: 'clipboard', value: 'all'},
                {label: 'In', icon: 'download', value: 'in'},
                {label: 'Out', icon: 'upload', value: 'out'},
              ]}
              onValueChange={(v) => {
                analytics.event('w.k.1.0-change-status-filter', {
                  status: v,
                })
                filterSet.setValue('status')(v)
              }}
            />
          </Toolbar.Item>
          <Toolbar.Item label="Sort">
            <ButtonGroup
              {...filterSet.getInputProps('sort')}
              options={[
                {label: 'Name', icon: '', value: 'name'},
                {label: 'Status', icon: '', value: 'status'},
                {label: 'Activity', icon: '', value: 'activity'},
              ]}
              onValueChange={(v) => {
                analytics.event('w.k.1.0-change-sort-filter', {
                  status: v,
                })
                filterSet.setValue('sort')(v)
              }}
            />
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group align="right">
          <Toolbar.Item label="Auto Scroll">
            <Switch
              name="auto-scroll"
              error=""
              label=""
              value={filterSet.filters.autoScroll}
              onValueChange={(v) => {
                analytics.event('w.k.1.0-change-auto-scroll', {
                  auto_scroll: v,
                })
                filterSet.setValue('autoScroll')(v)
              }}
            />
          </Toolbar.Item>
          <Button
            icon="reload"
            loading={refreshRequest.loading}
            mode="secondary"
            onClick={() => {
              analytics.event('w.k.1.0-submit-refresh')
              refreshRequest.request(requestSync())
            }}
          />
          <BoardOptionsModal
            filterSet={filterSet}
            renderTrigger={(open) => {
              return (
                <Button
                  icon="cog"
                  mode="secondary"
                  label="Configure"
                  onClick={open}
                />
              )
            }}
          />
        </Toolbar.Group>
      </Toolbar.Bar>
    </div>
  )
}
export default TVModeToolbar
