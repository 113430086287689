import {PusherProvider} from '@harelpls/use-pusher'
import {createTheme, ThemeProvider} from '@material-ui/core/styles'
import {client, setAuthToken} from '@scavatec/timeclock-lib/api/client'
import {Provider as AuthProvider} from '@scavatec/timeclock-lib/contexts/auth-context'
import DataProvider from '@scavatec/timeclock-lib/contexts/data-context'
import ReportProvider from '@scavatec/timeclock-lib/contexts/report-context'
import {userAtom} from '@scavatec/timeclock-lib/state/environment'
import ToastContext from 'contexts/toast-context'
import {useAtom} from 'jotai'
import React from 'react'
import env from 'util/env'

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#0098FE',
    },
  },
})

const STORE_KEY = 'timeclocksync'
const persist = async (s: string) => {
  window.localStorage.setItem(STORE_KEY, s)
}
const restore = async () => {}

type ProviderProps = {}
export const Providers: React.FC<ProviderProps> = ({children}) => {
  const [, setUser] = useAtom(userAtom)
  const restore = async () => {
    const json = window.localStorage.getItem(STORE_KEY)
    const state = JSON.parse(json ?? '{}')
    if (state?.user?.token) {
      setUser({
        organization: state.organization?.id ?? state.organization?.uuid,
        uuid: state.user.uuid,
        token: state.user.token,
      })
      setAuthToken(state.user.token)
    }
    return json ?? '{}'
  }
  return (
    <AuthProvider
      persist={persist}
      restore={restore}
      onAuth={(token, state) => {
        if (token.length > 0) {
          client.setToken(token)
        }
      }}
    >
      <ThemeProvider theme={muiTheme}>
        <PusherProvider clientKey={env.services.pusher.key} cluster={env.services.pusher.cluster}>
          <DataProvider>
            <ToastContext>
              <ReportProvider>{children}</ReportProvider>
            </ToastContext>
          </DataProvider>
        </PusherProvider>
      </ThemeProvider>
    </AuthProvider>
  )
}
export default Providers
