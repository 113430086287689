import {useReducer, useCallback, useEffect} from 'react'
import * as yup from 'yup'
import {assocPath, path} from 'rambda'

export type FieldValue = any
type Action<T extends {}> =
  | {type: 'set-value'; name: string; value: FieldValue}
  | {type: 'set-values'; values: any}
  | {type: 'set-errors'; errors: any}

type State<T> = {
  values: T
  errors: Partial<T> & {[s: string]: any}
  dirty: boolean
  touched: boolean
}

const reducer = <T extends {}>() => (
  state: State<T>,
  action: Action<T>,
): State<T> => {
  switch (action.type) {
    case 'set-value':
      return {
        ...state,
        values: assocPath(action.name.split('.'), action.value, state.values),
        touched: true,
      }
    case 'set-errors': {
      return {
        ...state,
        errors: action.errors,
      }
    }
    case 'set-values':
      return {
        ...state,
        values: {
          ...action.values,
        },
        touched: true,
      }
  }
}

const defaultState = {
  values: {},
  errors: {},
  dirty: false,
  touched: false,
}
type ValidatorResult = {name: string; message: string}[]
type CustomValidator<T> = (values: T) => ValidatorResult
const useForm = <T extends {}>(
  initialValues: T,
  schema: yup.ObjectSchema<any>,
  validator: CustomValidator<T> = (values) => [],
) => {
  const [state, dispatcher] = useReducer(reducer<T>(), {
    ...defaultState,
    values: {...initialValues},
  })
  useEffect(() => {
    if (!state.touched) {
      setValues(initialValues)
    }
  }, [initialValues])
  const setValue = (name: string) => (value: FieldValue) => {
    dispatcher({type: 'set-value', name, value})
  }
  const getError = (name: string): string => {
    return state.errors.hasOwnProperty(name) ? state.errors[name] : ''
  }
  const getValue = (name: string): FieldValue => {
    return path(name.split('.'), state.values)
  }
  const setValues = useCallback((values: T) => {
    dispatcher({type: 'set-values', values})
  }, [])
  const setErrors = (errors: {}) => {
    dispatcher({type: 'set-errors', errors})
  }
  const resetErrors = () => {
    dispatcher({type: 'set-errors', errors: {}})
  }
  const validate = () => {
    resetErrors()
    try {
      const values = schema.validateSync(state.values, {
        abortEarly: false,
      })
      const customErrors = validator(values)
      if (customErrors.length > 0) {
        const errors = customErrors.reduce((acc: any, err) => {
          acc[err.name] = err.message
          return acc
        }, {})
        dispatcher({type: 'set-errors', errors: errors})
        return null
      }
      return values
    } catch (e) {
      const err = e as yup.ValidationError
      const errors =
        err?.inner?.reduce((acc, err) => {
          return {
            ...acc,
            [err.path]: err.message,
          }
        }, {}) ?? {}
      dispatcher({type: 'set-errors', errors: errors})
    }
    return null
  }
  const handleSubmit = (callback: (v: T) => void) => {
    const values = validate()
    if (values) {
      callback(values)
    }
  }
  const getInputProps = (name: string) => {
    return {
      value: getValue(name),
      error: getError(name),
      onValueChange: (v: FieldValue) => setValue(name)(v),
    }
  }
  return {
    ...state,
    setValue,
    resetErrors,
    setErrors,
    getError,
    setValues,
    handleSubmit,
    getInputProps,
    dispatcher,
  }
}

export default useForm
