import {mdiClose} from '@mdi/js'
import analytics from '@scavatec/timeclock-lib/analytics'
import {useEmployee} from '@scavatec/timeclock-lib/state/entities'
import {environmentAtom} from '@scavatec/timeclock-lib/state/environment'
import {useAtom} from 'jotai'
import React, {useCallback} from 'react'
import {useQueryClient} from '@tanstack/react-query'
import {useNavigate} from 'react-router'
import Avatar from 'ui/avatar'
import {MaterialIcon} from 'ui/icon'
import {MessageComposer} from './message-composer'
import {ConversationMessages} from './messages'
import {fetchConversationsAtom} from './messaging-state'

const screenID = 'w.m.1.0'

type ChatContactProps = {
  employeeUUID: string
  onRemove: () => void
  compose?: boolean
}
export const ChatContact = ({employeeUUID, compose, onRemove}: ChatContactProps) => {
  const employee = useEmployee(employeeUUID)
  if (!employee) {
    return null
  }
  return (
    <div className="chat-contact">
      <Avatar hashString={employee.uuid} text={employee.formattedName()} />
      <span>{employee.formattedName()}</span>
      {compose && (
        <div
          className="remove"
          onClick={() => {
            analytics.event('w.n.2.0-click-remove-from-to')
            onRemove()
          }}
        >
          <MaterialIcon path={mdiClose} size={0.7} />
        </div>
      )}
    </div>
  )
}
type ConversationHeaderProps = {
  group: string[]
  compose?: boolean
  toggleSelection?: ConversationGroupToggleFn
}
export const ConversationHeader = ({group, compose = false, toggleSelection}: ConversationHeaderProps) => {
  return (
    <div className="conversation-header">
      {compose && <span>To:</span>}
      <div className="contact-list">
        {group.map((g) => (
          <ChatContact compose={compose} employeeUUID={g} onRemove={() => toggleSelection && toggleSelection(g)} />
        ))}
      </div>
    </div>
  )
}

type ComposeConversationProps = {
  group: string[]
  toggleSelection: ConversationGroupToggleFn
}
export const ComposeConversation: React.FC<ComposeConversationProps> = ({group, toggleSelection}) => {
  const navigate = useNavigate()
  const client = useQueryClient()
  const [, reloadConversations] = useAtom(fetchConversationsAtom)
  const submit = useCallback(
    (s: string) => {
      client.invalidateQueries(['messages'])
      reloadConversations()
      navigate(`/messaging/${group[0]}`)
    },
    [navigate, group],
  )

  return (
    <div className="conversation">
      <ConversationHeader compose toggleSelection={toggleSelection} group={group} />
      <div className="message-container">
        <div className="messages">
          <span className="info-message">Please use the form below to compose a new message to your recipients.</span>
        </div>
      </div>
      <MessageComposer analyticsId="w.n.2.0" group={group} onSend={submit} />
    </div>
  )
}

type ConversationProps = {
  employeeUUID: string
}
export const Conversation: React.FC<ConversationProps> = ({employeeUUID}) => {
  const [env] = useAtom(environmentAtom)
  const client = useQueryClient()
  const [, reloadConversations] = useAtom(fetchConversationsAtom)
  const onSend = () => {
    client.invalidateQueries([env.user.organization, employeeUUID, 'messages'])
    reloadConversations()
  }
  return (
    <div className="conversation">
      <div className="chat-header">
        <ConversationHeader group={[employeeUUID]} />
      </div>
      <ConversationMessages employeeUUID={employeeUUID} />
      <MessageComposer analyticsId="w.n.1.0" group={[employeeUUID]} onSend={onSend} />
    </div>
  )
}
export default Conversation
