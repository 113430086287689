import {PusherProvider, useChannel, useEvent} from '@harelpls/use-pusher'
import {data} from '@scavatec/timeclock-lib'
import {useIdentityQuery} from '@scavatec/timeclock-lib/api/auth'
import {useDeviceListQuery} from '@scavatec/timeclock-lib/api/device'
import * as employeeClient from '@scavatec/timeclock-lib/api/employee'
import {useJobListQuery} from '@scavatec/timeclock-lib/api/job'
import {PusherSync} from '@scavatec/timeclock-lib/api/pusher'
import {useSettingListQuery} from '@scavatec/timeclock-lib/api/settings'
import {useSubscriptionAccessQuery} from '@scavatec/timeclock-lib/api/subscription'
import useDatabase from '@scavatec/timeclock-lib/hooks/use-database'
import {crewsAtom, devicesAtom, employeesAtom, jobsAtom} from '@scavatec/timeclock-lib/state/entities'
import {currentUserAtom, environmentAtom, userAtom} from '@scavatec/timeclock-lib/state/environment'
import {settingsAtom, transformRemoteSettings} from '@scavatec/timeclock-lib/state/settings'
import logger from '@scavatec/timeclock-lib/util/logger'
import {useAtom} from 'jotai'
import React, {useEffect} from 'react'
import env from 'util/env'

type SyncManagerProps = {}
export const SyncManager: React.FC<SyncManagerProps> = ({children}) => {
  const {sync} = data.useDataContext()
  const {observables} = useDatabase()
  const [, setEmployees] = useAtom(employeesAtom)
  const [, setJobs] = useAtom(jobsAtom)
  const [, setDevices] = useAtom(devicesAtom)
  const [, setCrews] = useAtom(crewsAtom)
  const [, setCurrentUser] = useAtom(currentUserAtom)
  const [settings, settingsDispatch] = useAtom(settingsAtom)
  const accessQuery = useSubscriptionAccessQuery()
  const employeesQuery = employeeClient.useEmployeeListQuery()
  const jobsQuery = useJobListQuery({})
  const devicesQuery = useDeviceListQuery({
    onSuccess: (devices) => {
      setDevices(devices)
    },
  })
  const identityQuery = useIdentityQuery({
    onSuccess: (data) => {
      if (data.user) {
        setCurrentUser(data.user)
      }
    },
  })

  const [env] = useAtom(environmentAtom)
  const settingsQuery = useSettingListQuery({
    onSuccess: (res) => {
      settingsDispatch({type: 'set', payload: transformRemoteSettings(res)})
    },
    staleTime: 2 * 60000,
  })

  useEffect(() => {
    sync()
    accessQuery.refetch()
    employeesQuery.refetch()
    jobsQuery.refetch()
    logger.info('init', 'Sync Manager')
    const employeeSubscription = observables.employees$.subscribe((next) => setEmployees(next))
    const jobSubscription = observables.jobs$.subscribe((next) => setJobs(next))
    const crewSubscription = observables.crews$.subscribe((next) => setCrews(next))
    return () => {
      employeeSubscription.unsubscribe()
      jobSubscription.unsubscribe()
      crewSubscription.unsubscribe()
    }
  }, [])

  return <PrivatePusherProvider />
}
export default SyncManager

const PrivatePusherProvider: React.FC = ({children}) => {
  const [apiUser] = useAtom(userAtom)
  if (!apiUser.token) {
    return null
  }
  return (
    <PusherProvider
      clientKey={env.services.pusher.key}
      authEndpoint={`${env.api.host}/broadcasting/auth`}
      auth={{
        headers: {Authorization: `Bearer ${apiUser.token}`},
      }}
      cluster={env.services.pusher.cluster}
    >
      <PusherSync apiVersion={env.api.version} useChannel={useChannel} useEvent={useEvent} />
      {children}
    </PusherProvider>
  )
}
