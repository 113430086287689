import React, {useMemo, useState} from 'react'
import {CellProps} from 'react-table'
import {Currency, AccountTime, ClockTypeBadge} from 'util/formatters'
import Table, {TableRowClick} from 'ui/table'
import TransactionCard from 'ui/transaction'
import EmptyState from 'ui/empty-state'
import {FilterSet} from '../../hooks/use-filter-set'
import {EmployeesReportFilters} from 'ui/toolbars/report-toolbar'
import useFuse from '../../hooks/use-fuse'
import Popover from 'ui/popover'
import Button from 'ui/button'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useReportContext, transformTransactions} from '@scavatec/timeclock-lib/contexts/report-context'
import {ColumnSizes} from 'ui/tables/common'
import {useNavigate} from 'react-router'
import {useAtom} from 'jotai'
import {reportSearchAtom} from '../reports/report-state'

const analyticsId = 'w.c.1.0-transactions'

type TimeEntriesProps = {}

const NotePopover = ({note}: {note: string}) => {
  const [open, setOpen] = useState(false)
  return (
    <Popover
      open={open}
      onRequestClose={() => setOpen(false)}
      backdrop
      placement="left"
      renderTrigger={({ref}) => {
        return (
          <div className="note-popover-trigger" ref={ref}>
            <Button
              size="small"
              label="View Note"
              onClick={(e) => {
                e.stopPropagation()
                setOpen(!open)
              }}
            />
          </div>
        )
      }}
      renderContent={({ref, style}) => {
        return (
          <div className="popover note-popover" onClick={(e) => e.stopPropagation()} ref={ref} style={style}>
            <h3>Note</h3>
            {note}
          </div>
        )
      }}
    />
  )
}
const TimeEntries: React.FC<TimeEntriesProps> = ({}) => {
  const navigate = useNavigate()
  const columns = useMemo(
    () => [
      {
        Header: 'Date Time',
        accessor: 'dateUTC',
        Cell: ({cell: {value}}: CellProps<any>) => <AccountTime value={value} />,
        width: ColumnSizes.datetime,
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({cell: {value}}: CellProps<any>) => <ClockTypeBadge type={parseInt(value, 10)} />,
        width: ColumnSizes.clockType,
      },
      {
        Header: 'Employee',
        accessor: 'employee',
        width: ColumnSizes.name,
      },
      {
        Header: 'Job',
        accessor: 'job',
        Cell: ({cell: {value}}: CellProps<any>) => (value === null ? 'none' : value),
        width: ColumnSizes.name,
      },
      {
        Header: 'Device',
        accessor: 'device',
        width: ColumnSizes.name,
      },
      {
        Header: 'Pay Rate',
        accessor: 'payRate',
        Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value} />,
        width: ColumnSizes.currency,
      },
      {
        Header: 'Tips',
        accessor: 'tips',
        Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value} />,
        width: ColumnSizes.currency,
      },
      {
        Header: 'Note',
        accessor: 'note',
        Cell: ({cell: {value}}: CellProps<any>) => value.length > 0 && <NotePopover note={value} />,
      },
    ],
    [],
  )
  const {rawReport} = useReportContext()
  const {
    employees: {items: employees},
  } = useDataContext()
  let totals = useMemo(() => {
    if (rawReport) {
      let transactions = rawReport.transactions
      return transformTransactions(transactions, employees)
    }
    return []
  }, [rawReport, employees])

  // Apply filters
  const [search] = useAtom(reportSearchAtom)
  totals = useFuse(totals, search, {
    threshold: 0.1,
    shouldSort: true,
    keys: ['name', 'job', 'device', 'date'],
  })

  if (totals.length <= 0) {
    return (
      <EmptyState
        title="No Transactions in Range"
        description="No transactions were found in the selected date range. Try changing the dates or add a new time by clicking 'Add Time'."
      >
        <Button
          label="Need Help?"
          color="clock-in"
          onClick={() => {
            navigate('/help')
          }}
        />
      </EmptyState>
    )
  }
  return (
    <Table
      columns={columns}
      data={totals}
      onRowClick={(t) => {
        navigate(`/transactions/${t.transactionUUID}`, {
          state: {
            returnTo: window.location.pathname,
          },
        })
      }}
      analyticsId={analyticsId}
      initialSortBy={[{id: 'dateUTC', desc: true}]}
      getRowProps={(row) => {
        return {
          className: row.original.isInvalid === '1' ? 'invalid' : '',
        }
      }}
    />
  )
}
export default TimeEntries
