import analytics from '@scavatec/timeclock-lib/analytics'
import React, {useEffect, useState} from 'react'
import {useTable, useSortBy} from 'react-table'
import useClassnames from 'hooks/use-classnames'
import Icon from './icon'

export type TableRowClick = (v: any) => void
type TableProps = {
  columns: any
  data: any
  onRowClick: TableRowClick
  getRowProps?: (row: any) => any
  noHover?: boolean
  analyticsId?: string
  initialSortBy?: Array<{id: string; desc: boolean}>
}
export const Table: React.FC<TableProps> = ({
  onRowClick,
  initialSortBy = [],
  noHover = false,
  analyticsId = 'table',
  columns,
  data,
  getRowProps = () => {},
}) => {
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state} = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: initialSortBy,
      },
    },
    useSortBy,
  )
  const [init, setInit] = useState(false)
  useEffect(() => {
    if (!init) {
      // Hacky solution to prevent sending initial sorting on page reload
      setTimeout(() => {
        setInit(true)
      }, 500)
      return
    }
    const sort = state.sortBy.reduce((acc, s, i) => {
      return {
        ...acc,
        [s.id]: s.desc ? 'desc' : 'asc',
      }
    }, {})
    analytics.event(`${analyticsId}-click-sort`, sort)
  }, [state.sortBy])
  const classes = useClassnames({
    table: true,
    'no-hover': noHover,
  })
  if (data.length <= 0) {
    return null
  }
  return (
    <div className="table-scroll-wrapper">
      <div className={classes}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      width: column.width,
                      minWidth: column.width,
                      maxWidth: column.width,
                    }}
                  >
                    <span>
                      {column.render('Header')}
                      <div className={`sort-icons ${column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : ''}`}>
                        <div className="asc"></div>
                        <div className="desc"></div>
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr onClick={() => onRowClick(row.original)} {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default Table
