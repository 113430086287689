import React from 'react'
import {Currency, employeeName} from 'util/formatters'
import Badge from './badge'
import {ClockType} from '@scavatec/timeclock-lib/database/models/common'
import {
  Employee,
  createEmployee,
} from '@scavatec/timeclock-lib/database/models/employee'

const badgeLabel = (e: Employee) => {
  if (e.isActive) {
    return e.status === ClockType.In ? 'In' : 'Out'
  }
  return 'inactive'
}
const badgeType = (e: Employee) => {
  if (e.isActive) {
    return e.status === ClockType.In ? 'clock-in' : 'clock-out'
  }
  return 'inactive'
}
type EmployeeCardProps = {
  employee: Employee
  onClick?: OnClickEvent
}
export const EmployeeCard: React.FC<EmployeeCardProps> = ({
  employee,
  onClick,
}) => {
  return (
    <div
      className={`card employee-card ${employee.statusString()} ${
        !employee.isActive ? 'inactive' : ''
      }`}
      data-employee={employee}
      onClick={onClick}>
      {/* <Avatar text={employee.lastName + ' ' + employee.firstName} /> */}
      <div className="card-header">
        <div className="row">
          <span className="name">{employee.formattedName()}</span>
          <Badge
            label={badgeLabel(employee)}
            type={badgeType(employee)}
            outline
          />
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <span className="pay-rate">
            <Currency value={employee.payRate} suffix="/hr" />
          </span>
        </div>
      </div>
    </div>
  )
}
export default EmployeeCard
