import analytics from '@scavatec/timeclock-lib/analytics'
import {APIValidationError} from '@scavatec/timeclock-lib/api/client'
import * as crewClient from '@scavatec/timeclock-lib/api/crew'
import {CrewUpdatePayload} from '@scavatec/timeclock-lib/api/types'
import Crew, {transformCrew} from '@scavatec/timeclock-lib/database/models/crew'
import useDatabase from '@scavatec/timeclock-lib/hooks/use-database'
import {useCrew} from '@scavatec/timeclock-lib/state/entities'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router'
import Button from 'ui/button'
import {ErrorCallout} from 'ui/callout'
import {ConfirmationButton} from 'ui/confirmation-button'
import {Form} from 'ui/forms'
import CrewForm from 'ui/forms/crew-form'
import Toolbar from 'ui/toolbar'
import {useToastContext} from '../../contexts/toast-context'
import EmptyState from '../../ui/empty-state'
const pageId = 'w.j.2.1'

type CrewEditProps = {
  crew: Crew
}
const CrewEdit = ({crew}: CrewEditProps) => {
  const {addItem} = useToastContext()
  const navigate = useNavigate()
  const {database} = useDatabase()
  const {control, setError, handleSubmit} = useForm<CrewUpdatePayload>({
    defaultValues: {
      ...crew.toForm(),
    },
  })
  const mutation = crewClient.useCrewUpdateMutation(crew.uuid, {
    onError: (error) => {
      if (error instanceof APIValidationError) {
        Object.keys(error.errors).forEach((key) =>
          setError(`${key}` as any, {
            type: 'validation',
            message: error.errors[key],
          }),
        )
      }
    },
    onSuccess: async (res, data) => {
      analytics.event(`${pageId}-submit-update`, {
        add_another: false,
        name: data.name.length > 0,
        note: data.note.length > 0,
        num_employees: data.relationships.employees.data.length,
        num_jobs: data.relationships.jobs.data.length,
      })
      await crew.userUpdate(transformCrew.fromApi(res))
      addItem({title: `Updated crew ${data.name}`, icon: 'save'}, 4000)
      navigate('/crews')
    },
  })
  const deleteMutation = crewClient.useCrewDeleteMutation({
    onSuccess: (res) => {
      database.action(async () => {
        await crew.destroyPermanently()
      })
      addItem({title: `Crew ${crew.name} deleted`, icon: 'trash'}, 4000)
      navigate('/crews')
    },
  })
  const save = (values: CrewUpdatePayload) => {
    mutation.mutate(values)
  }
  return (
    <div className="page crew-edit">
      <Toolbar.Bars>
        <Toolbar.Bar>
          <Toolbar.Group>
            <Button
              label="Cancel"
              disabled={mutation.isLoading}
              mode="secondary"
              icon="close"
              onClick={() => {
                analytics.event(`${pageId}-click-cancel`)
                navigate('/crews')
              }}
            />
          </Toolbar.Group>
          <Toolbar.Group>
            <ConfirmationButton
              title="Confirm Delete"
              description="Are you sure you want to delete this crew?"
              renderButton={(onClick) => {
                return (
                  <Button
                    color="danger"
                    label="Delete"
                    icon="trash"
                    disabled={deleteMutation.isLoading || mutation.isLoading}
                    loading={deleteMutation.isLoading}
                    onClick={onClick}
                  />
                )
              }}
              onConfirm={() => deleteMutation.mutate(crew.uuid)}
            />
            <Button
              disabled={mutation.isLoading}
              loading={mutation.isLoading}
              label={'Save'}
              icon={'plus'}
              onClick={() => handleSubmit(save)()}
            />
          </Toolbar.Group>
        </Toolbar.Bar>
      </Toolbar.Bars>
      <div className="page-content">
        {mutation.isError && <ErrorCallout error={mutation.error} />}
        {deleteMutation.isError && <ErrorCallout error={deleteMutation.error} />}
        <div className="split-header">
          <h1>{`Edit Crew`}</h1>
        </div>
        <Form onSubmit={handleSubmit(save)}>
          <CrewForm pageId={pageId} control={control as any} mode="update" />
        </Form>
      </div>
    </div>
  )
}

type CrewEditPageProps = {}
export const CrewEditPage: React.FC<CrewEditPageProps> = ({}) => {
  const {uuid} = useParams()
  const crew = useCrew(uuid ?? '')
  useEffect(() => {
    analytics.event('w.j.2.1-view')
  }, [])
  if (!crew) {
    return (
      <div className="page crew-edit">
        <div className="page-content">
          <EmptyState title="Crew not found" description="The crew attempting to be edited could no longer be found." />
        </div>
      </div>
    )
  }
  return <CrewEdit crew={crew} />
}
export default CrewEditPage
