import React, {useMemo} from 'react'
import {CellProps} from 'react-table'
import Table, {TableRowClick} from '../table'
import Job, {JobDoc} from '@scavatec/timeclock-lib/database/models/job'
import {ColumnSizes} from './common'
import {EntityStatusBadge} from 'util/formatters'

type EmployeeTableProps = {
  data: Job[] | JobDoc[]
  onRowClick: TableRowClick
  analyticsId: string
}
const JobTable: React.FC<EmployeeTableProps> = ({analyticsId, onRowClick, data}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'identifier',
        width: ColumnSizes.identifier,
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: ColumnSizes.name,
      },
      {
        Header: 'Status',
        accessor: 'isActive',
        Cell: ({cell: {value}}: CellProps<any>) => <EntityStatusBadge isActive={value} />,
        width: ColumnSizes.status,
      },
    ],
    [],
  )
  return (
    <Table
      columns={columns}
      data={data}
      analyticsId={analyticsId}
      initialSortBy={[{id: 'name', desc: false}]}
      onRowClick={onRowClick}
    />
  )
}
export default JobTable
