import {useRef, useState, useLayoutEffect, RefObject} from 'react'

type Rect = {
  x: number
  y: number
  width: number
  height: number
}
export const useDimensions = (): {ref: RefObject<any>; dimensions: Rect} => {
  const ref = useRef<any>()
  const [dimensions, setDimensions] = useState<Rect>({
    x: 0,
    y: 0,
    height: 0,
    width: 0,
  })
  useLayoutEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect().toJSON()
      if (rect.height > dimensions.height) {
        setDimensions(rect)
      }
    }
  }, [ref.current])
  return {ref, dimensions}
}

export default useDimensions
