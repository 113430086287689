import useClassnames from 'hooks/use-classnames'
import React from 'react'
import {FieldError as HookFieldError} from 'react-hook-form'

const parseErrorMessage = (error: HookFieldError | undefined, label: string) => {
  if (!error) {
    return ''
  }
  if (error.message && error.message !== '') {
    return error.message
  }
  switch (error.type) {
    case 'required':
      return `${label} is required`
    case 'minLength':
      return 'Does not meet the minimum length'
  }
  return ''
}
export const FieldError: React.FC<{
  label?: string
  error?: HookFieldError
}> = ({label = '', error}) => {
  return <span className="form-error">{parseErrorMessage(error, label)}</span>
}

type InputLabelRowProps = {
  label: string
  error?: HookFieldError
  focused?: boolean
}
export const InputLabelRow: React.FC<InputLabelRowProps> = ({focused = false, label, error}) => {
  const classes = useClassnames({
    error: error !== undefined,
  })
  return (
    <div className="form-label-row">
      <label className={classes}>{label}</label>
    </div>
  )
}
