import React from 'react'

type ActivityIndicatorProps = {}
export const ActivityIndicator: React.FC<ActivityIndicatorProps> = ({
  children,
}) => {
  return (
    <div className="activity-indicator">
      <div className="indicator-0"></div>
      <div className="indicator-1"></div>
      <div className="indicator-2"></div>
    </div>
  )
}
export default ActivityIndicator

type SpinnerProps = {
  className?: string
  contained?: boolean
}
export const Spinner = ({contained, className = ''}: SpinnerProps) => {
  const spinner = <div className={`spinner ${className}`}></div>
  return !contained ? (
    spinner
  ) : (
    <div className="spinner-container">{spinner}</div>
  )
}
