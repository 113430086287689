import React, {ComponentProps, useState} from 'react'
import Button from './button'
import Popover from './popover'

type ConfirmationButtonProps = {
  title: string
  description: string
  renderButton: (onClick: () => void) => JSX.Element
  onConfirm: () => void
  placement?: ComponentProps<typeof Popover>['placement']
}
export const ConfirmationButton: React.FC<ConfirmationButtonProps> = ({
  onConfirm,
  title,
  description,
  placement = 'bottom-end',
  renderButton,
}) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  return (
    <Popover
      open={open}
      onRequestClose={() => setOpen(false)}
      backdrop
      placement={placement}
      renderTrigger={({ref}) => {
        return (
          <div className="note-popover-trigger" ref={ref}>
            {renderButton(() => {
              setOpen(true)
            })}
          </div>
        )
      }}
      renderContent={({ref, style}) => {
        return (
          <div className="popover confirmation-popover" onClick={(e) => e.stopPropagation()} ref={ref} style={style}>
            <h3>{title}</h3>
            <p>{description}</p>
            <div className="row">
              <Button label="cancel" color="secondary" onClick={() => setOpen(false)} />
              <Button
                label="Confirm"
                color="danger"
                loading={loading}
                onClick={async () => {
                  setLoading(true)
                  await onConfirm()
                  setLoading(false)
                  setOpen(false)
                }}
              />
            </div>
          </div>
        )
      }}
    />
  )
}
