import {useDevices} from '@scavatec/timeclock-lib/state/entities'
import {useMemo} from 'react'

export const isLegacyDevice = (appName: string, appVersion: string) =>
  appName.toLowerCase() === 'timeclock st' || appVersion.startsWith('1.')

export const useHasLegacyDevices = () => {
  const devices = useDevices()

  return useMemo(() => devices.find((d) => isLegacyDevice(d.appName ?? '', d.appVersion ?? '')) !== undefined, [
    devices,
  ])
}
