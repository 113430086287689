import * as userClient from '@scavatec/timeclock-lib/api/user'
import React from 'react'
import {useForm} from 'react-hook-form'
import TextInput from 'ui2/inputs/text-input'
import {ModalHookType} from '../../hooks/use-modal'
import Button from '../button'
import Callout, {ErrorCallout} from '../callout'
import * as Forms from '../forms'
import Modal from './modal'

type PasswordModalProps = {
  modal: ModalHookType
}
type PasswordFormData = {
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
}
export const PasswordModal: React.FC<PasswordModalProps> = ({modal}) => {
  const {handleSubmit, reset, control} = useForm({
    defaultValues: {currentPassword: '', newPassword: '', newPasswordConfirmation: ''},
  })
  const mutation = userClient.useUpdatePasswordMutation()

  const onSubmit = async (values: PasswordFormData) => {
    mutation.mutate(values)
  }

  return (
    <Modal.Wrapper
      open={modal.isOpen}
      onOpen={() => {
        mutation.reset()
        reset()
      }}
    >
      <Forms.Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <h1>Change Password</h1>
        </Modal.Header>
        {!mutation.isSuccess && (
          <Modal.Body>
            {mutation.isError && <ErrorCallout error={mutation.error} />}
            <Forms.Row>
              <TextInput
                label="Current Password"
                control={control as any}
                rules={{required: true}}
                type="password"
                name="currentPassword"
              />
            </Forms.Row>
            <Forms.Row>
              <TextInput
                label="New Password"
                control={control as any}
                rules={{required: true}}
                type="password"
                name="newPassword"
              />
            </Forms.Row>
            <Forms.Row>
              <TextInput
                label="Confirm New Password"
                control={control as any}
                rules={{required: true}}
                type="password"
                name="newPasswordConfirmation"
              />
            </Forms.Row>
          </Modal.Body>
        )}
        {mutation.isSuccess && (
          <Modal.Body>
            <Callout type="success" title="Password Changed" message="Your password has been changed." />
          </Modal.Body>
        )}
        <Modal.Footer>
          <div className="group"></div>
          <Button
            color="secondary"
            label={mutation.isSuccess ? 'Close' : 'Cancel'}
            disabled={mutation.isLoading}
            icon="close"
            onClick={() => modal.close()}
          />
          {!mutation.isSuccess && (
            <Button type="submit" disabled={mutation.isLoading} loading={mutation.isLoading} label="Save" icon="save" />
          )}
        </Modal.Footer>
      </Forms.Form>
    </Modal.Wrapper>
  )
}
export default PasswordModal
