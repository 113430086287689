import {css, Global} from '@emotion/react'
import {mdiTooltipEditOutline} from '@mdi/js'
import {client} from '@scavatec/timeclock-lib/api/client'
import {useTransactionDeleteMutation} from '@scavatec/timeclock-lib/api/transaction'
import {useAuthContext} from '@scavatec/timeclock-lib/contexts/auth-context'
import {userAtom} from '@scavatec/timeclock-lib/state/environment'
import {useAtom} from 'jotai'
import React, {useCallback, useEffect, useState} from 'react'
import {useQuery} from '@tanstack/react-query'

export const Tidio = () => {
  const {
    state: {
      user: {username, email},
    },
  } = useAuthContext()
  const tidio = (window as any).tidioChatApi
  const [enabled, setEnabled] = useState(false)
  const [user] = useAtom(userAtom)
  const setupTidio = (enabled: boolean, tags: string[]) => {
    if (!tidio) {
      return
    }
    tidio.setVisitorData({
      distinct_id: user.organization,
      name: username,
      email: email,
      tags: tags,
    })
    setEnabled(enabled)
    if (enabled) {
      tidio.show()
    }
  }
  const query = useQuery(
    ['tidio'],
    () => {
      if (!user.organization) {
        throw new Error('Missing request arguments')
      }
      return client.getJson(`organizations/${user.organization}/services/chat`)
    },
    {
      onSuccess: (res) => {
        const enabled = res.data?.show_service
        const tags = (res.data?.tags as string[]) ?? []
        setupTidio(enabled, tags)
      },
      staleTime: -1, // Disable the stale data timeout/refresh
      enabled: user.token?.length > 0 ?? false, // Disable query until the user has logged in
    },
  )

  const onTidioReady = useCallback(() => {
    if (query.data) {
      const enabled = query.data.data?.show_service
      const tags = (query.data.data?.tags as string[]) ?? []
      setupTidio(enabled, tags)
    } else {
      setupTidio(enabled, [])
    }
  }, [query])

  useEffect(() => {
    if (!tidio) {
      return
    }
    if (!tidio.readyEventWasFired) {
      tidio.on('ready', onTidioReady)
    } else {
      onTidioReady()
    }
  }, [tidio])
  return null
}
