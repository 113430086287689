import analytics from '@scavatec/timeclock-lib/analytics'
import * as client from '@scavatec/timeclock-lib/api/settings'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import {useAtom} from 'jotai'
import React, {useEffect, useMemo} from 'react'
import {Outlet, useNavigate, useParams} from 'react-router'
import {NavLink} from 'react-router-dom'
import useFilterSet from '../../hooks/use-filter-set'
import EmptyState from '../../ui/empty-state'
import PayPeriodPicker from '../../ui/pay-period'
import {EmployeesReportFilters} from '../../ui/toolbars/report-toolbar'
import ReportDetailsToolbar from '../../ui/toolbars/report-details-toolbar'
import {jobListViewAtom} from './job-state'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import Job, {JobDoc, NONE_UUID} from '@scavatec/timeclock-lib/database/models/job'

type JobDetailsProps = {}
export const JobDetails: React.FC<JobDetailsProps> = ({}) => {
  const {uuid = ''} = useParams()
  const navigate = useNavigate()
  const {rawReport, actions} = useReportContext()
  const [, setView] = useAtom(jobListViewAtom)
  const {
    jobs: {items: jobs},
  } = useDataContext()
  const preferredView = useOrganizationSetting('account.preferred_view')
  const job = useMemo<Job | JobDoc | undefined>(() => {
    if (uuid === 'none') {
      return {
        name: '<none>',
        identifier: 0,
        isActive: true,
        note: '',
        uuid: 'none',
      } as JobDoc
    }
    return jobs.find((e) => e.uuid === uuid)
  }, [jobs, uuid])
  const total = useMemo(() => {
    if (!rawReport) {
      return {
        time: 0,
        paidTime: 0,
        breakTime: 0,
        overtime: 0,
        pay: 0,
        tips: 0,
      }
    }
    return rawReport.dateRangeTotals.jobs.find((e) => {
      if (uuid === 'none' && (!e.taskUUID || e.taskUUID === NONE_UUID)) {
        return true
      }
      return e.taskUUID === uuid
    })
  }, [uuid, rawReport])
  useEffect(() => {
    actions.refresh()
    setView(preferredView.value)
    analytics.event('w.h.2.0-view')
  }, [])
  const basePath = `/jobs/${uuid}`
  const filterSet = useFilterSet<EmployeesReportFilters>({
    view: preferredView.value,
    search: '',
    columns: {
      totals: ['id', 'name', 'time', 'paidTime', 'overtime', 'breakTime', 'pay', 'tips'],
      dailyTotals: ['day', 'job', 'task', 'total', 'paid', 'overtime', 'break', 'pay', 'tips'],
      intervals: ['dIn', 'dOut', 'job', 'totalSeconds', 'payRate', 'tips'],
    },
  })

  useEffect(() => {
    if (preferredView.value !== filterSet.filters.view) {
      preferredView.update(filterSet.filters.view)
    }
  }, [preferredView.value, filterSet.filters.view])
  if (!job) {
    return (
      <div className="page jobs">
        <div className="page-content">
          <EmptyState title="Job Not Found" description="The job you are trying to view could not be found." />
        </div>
      </div>
    )
  }

  return (
    <div className="page jobs">
      <ReportDetailsToolbar
        pageId="w.h.2.0"
        uuid={uuid}
        type="Job"
        typeLabel="Job"
        total={total}
        setView={setView}
        filterSet={filterSet}
        title={job?.name || 'N/A'}
        transactionParams={{
          job: uuid,
        }}
      />
      <div className="page-content">
        <div className="tab-menu">
          <NavLink end to={`${basePath}`} onClick={() => analytics.event('w.h.2.0-click-daily-totals-tab')}>
            Daily Totals
          </NavLink>
          <NavLink to={`${basePath}/intervals`} onClick={() => analytics.event('w.h.2.0-click-intervals-tab')}>
            Intervals
          </NavLink>
          <NavLink to={`${basePath}/times`} onClick={() => analytics.event('w.h.2.0-click-time-entries-tab')}>
            Time Entries
          </NavLink>
          <div className="spacer"></div>
          <div className="metadata">
            <PayPeriodPicker
              onNextClick={() => analytics.event(`w.h.2.0-click-date-advance`)}
              onPrevClick={() => analytics.event(`w.h.2.0-click-date-previous`)}
            />
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  )
}
export default JobDetails
