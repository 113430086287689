import { mdiCheck, mdiClose } from '@mdi/js'
import analytics from '@scavatec/timeclock-lib/analytics'
import * as subscriptionClient from '@scavatec/timeclock-lib/api/subscription'
import { Subscription } from '@scavatec/timeclock-lib/database/models/subscription'
import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import * as yup from 'yup'
import useAPI from '../../hooks/use-api'
import useForm from '../../hooks/use-form'
import { ModalHookType } from '../../hooks/use-modal'
import Button, { MaterialIconButton } from '../button'
import Callout, { MinimalCallout, RoundedErrorCallout } from '../callout'
import * as Forms from '../forms'
import Modal from './modal'
import colors from '@scavatec/timeclock-lib/theme/colors'
import Icon from '@mdi/react'
import { useMutation } from '@tanstack/react-query'
import { ClientError } from '@scavatec/timeclock-lib/api/util'
import { SubscriptionStatus } from '@scavatec/timeclock-lib/api/types'

const SCREEN_ID = 'w.e.2.4'

type CancelSubscriptionModalProps = {
  modal: ModalHookType
  subscription: SubscriptionStatus
  onSave?: (values: any) => void
  onDelete?: (values: any) => void
}
const cancelSchema = yup.object().shape({})
export type CancelFormData = yup.InferType<typeof cancelSchema>
export const CancelSubscriptionModal: React.FC<CancelSubscriptionModalProps> = ({
  modal,
  onSave,
  onDelete,
  subscription,
}) => {
  const { handleSubmit, getInputProps } = useForm<CancelFormData>(
    { name: '', coupon: '' },
    cancelSchema,
  )
  const query = subscriptionClient.useSubscriptionStatusQuery()
  const mutation = subscriptionClient.useCancelSubscriptionMutation({
    onSuccess: () => {
      query.refetch()
    }
  })

  const onSubmit = useCallback(async (values) => {
    //
    analytics.event(`${SCREEN_ID}-submit-cancel`)
    mutation.mutate(values.email)
  }, [])
  return (
    <Modal.Wrapper className="subscribe-modal" open={modal.isOpen}>
      <Forms.Form onSubmit={() => handleSubmit(onSubmit)}>
        <Modal.Header>
          <h1>Cancel Subscription</h1>
          <MaterialIconButton
            path={mdiClose}
            size={1}
            onClick={() => {
              analytics.event(`${SCREEN_ID}-click-close`)
              modal.close()
            }}
          />
        </Modal.Header>
        <div className="callout-container">
          {mutation.error && <RoundedErrorCallout error={mutation.error} />}
        </div>
        <Modal.Body>
          {!mutation.isSuccess && (
            <p>
              Your account will remain active until the current period ends on{' '}
              {subscription.currentPeriodEnd &&
                DateTime.fromISO(subscription.currentPeriodEnd).toSQLDate()}
              .
            </p>
          )}
          {mutation.isSuccess && (
            <div className="subscription-success">
              <Icon size={1} color={colors.green} path={mdiCheck} />
              Your subscription has been canceled.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!mutation.isSuccess && (
            <>
              <Button
                mode="secondary"
                label="Cancel"
                disabled={mutation.isLoading}
                onClick={() => {
                  analytics.event(`${SCREEN_ID}-click-cancel`)
                  modal.close()
                }}
              />
              <Button
                type="submit"
                disabled={mutation.isLoading}
                loading={mutation.isLoading}
                color="danger"
                label="Cancel Subscription"
              />
            </>
          )}
          {mutation.isSuccess && (
            <Button label="Close" onClick={() => modal.close()} />
          )}
        </Modal.Footer>
      </Forms.Form>
    </Modal.Wrapper>
  )
}
export default CancelSubscriptionModal
