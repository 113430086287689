import {IconProps} from '@mdi/react/dist/IconProps'
import {ClientError} from '@scavatec/timeclock-lib/api/query-utils'
import React from 'react'
import {MaterialIcon} from './icon'

type CalloutProps = {
  title?: string
  message: string
  type: 'error' | 'success' | 'warning'
}
export const Callout: React.FC<CalloutProps> = ({title, message, children, type}) => {
  return (
    <div className={`callout ${type}`}>
      {title && <span className="title">{title}</span>}
      <span className="message">{message}</span>
      {children}
    </div>
  )
}
export default Callout

type MinimalCalloutProps = {
  iconPath?: IconProps['path']
  message: string
  type: 'error' | 'success' | 'warning'
}
export const MinimalCallout: React.FC<MinimalCalloutProps> = ({message, type, iconPath}) => {
  return (
    <div className={`minimal-callout ${type}`}>
      {iconPath !== undefined && <MaterialIcon path={iconPath} size={1} />}
      <span className="message">{message}</span>
    </div>
  )
}

const formatError = (e: any, omit: string[] = []) => {
  if (!e) {
    return
  }
  let error = e?.message ?? 'Unknown Error'
  if (e.hasOwnProperty('errors')) {
    const o = {...e.errors}
    for (let key of omit) {
      delete o[key]
    }
    Object.keys(o).forEach((k) => (error += `\n- ${o[k]}`))
  }
  return error
}
type ErrorCalloutProps = {
  iconPath?: IconProps['path']
  message?: string
  error?: ClientError | null
  omitKeys?: string[]
  children?: React.ReactNode
  style?: React.CSSProperties
}
export const ErrorCallout: React.FC<ErrorCalloutProps> = ({
  message = '',
  error,
  iconPath,
  omitKeys = [],
  children,
  style,
}) => {
  return (
    <div className={`flat-callout error`} style={style}>
      {iconPath !== undefined && <MaterialIcon path={iconPath} size={1} />}
      <pre className="message">
        {message}
        {formatError(error, omitKeys)}
        {children}
      </pre>
    </div>
  )
}

type InfoCalloutProps = {
  iconPath?: IconProps['path']
  message?: string
  error?: Error
  omitKeys?: string[]
  children?: React.ReactNode
}
export const InfoCallout: React.FC<InfoCalloutProps> = ({message = '', error, iconPath, omitKeys = []}) => {
  return (
    <div className={`flat-callout info`}>
      {iconPath !== undefined && <MaterialIcon path={iconPath} size={1} />}
      <pre className="message">
        {message}
        {formatError(error, omitKeys)}
      </pre>
    </div>
  )
}

type WarningCalloutProps = {
  iconPath?: IconProps['path']
  message?: string
  error?: Error
  omitKeys?: string[]
  children?: React.ReactNode
}
export const WarningCallout: React.FC<InfoCalloutProps> = ({
  message = '',
  error,
  iconPath,
  omitKeys = [],
  children,
}) => {
  return (
    <div className={`flat-callout warning`}>
      {iconPath !== undefined && <MaterialIcon path={iconPath} size={1} />}
      <pre className="message">
        {message}
        {formatError(error, omitKeys)}
        {children}
      </pre>
    </div>
  )
}

export const RoundedErrorCallout: React.FC<ErrorCalloutProps> = ({message = '', error, iconPath, omitKeys = []}) => {
  return (
    <div className={`rounded-callout error`}>
      {iconPath !== undefined && <MaterialIcon path={iconPath} size={1} />}
      <pre className="message">
        {message}
        {formatError(error, omitKeys)}
      </pre>
    </div>
  )
}

export const RoundedInfoCallout: React.FC<InfoCalloutProps> = ({
  message = '',
  error,
  iconPath,
  omitKeys = [],
  children,
}) => {
  return (
    <div className={`rounded-callout info`}>
      {iconPath !== undefined && <MaterialIcon path={iconPath} size={1} />}
      <pre className="message">
        {message}
        {formatError(error, omitKeys)}
        {children}
      </pre>
    </div>
  )
}
