import React, {useEffect, useState} from 'react'
import * as client from '@scavatec/timeclock-lib/api/messages'
import Button from './button'
import {CustomerMessage} from '@scavatec/timeclock-lib/api/types'
import {useHasLegacyDevices} from 'hooks/use-has-legacy-devices'
import {ErrorCallout} from './callout'

type Storage = string[]

type Props = {}
const CustomerMessages: React.FC<Props> = (props: Props) => {
  const hasLegacy = useHasLegacyDevices()
  const [banner, setBanner] = useState<CustomerMessage | undefined>(undefined)
  const [seen, setSeen] = useState<Storage>([])
  useEffect(() => {
    const load = async () => {
      const stored: Storage = JSON.parse(window.localStorage.getItem('seen-customer-messages') ?? '[]')
      setSeen(stored)
      try {
        const res = await client.customerMessages()
        const banner = res.find((v) => {
          return (
            v.relationships.type.data.name === 'banner' && v.relationships.platform.data.name === '2.0' && v.is_active
          )
        })
        setBanner(banner)
      } catch (e) {}
    }
    load()
  }, [])
  const messages = []
  if (hasLegacy) {
    messages.push(
      <ErrorCallout style={{marginBottom: 0}}>
        You have legacy devices that are no longer supported on our platform. Please upgrade any legacy devices to the
        latest version of Toolr Time Clock by March 31st to prevent any disruption to your service.{' '}
        <a href="https://mytoolr.stonly.com/kb/guide/en/frequently-asked-questions-8CDdVRVTx4/Steps/1702148">
          View Upgrade Guide
        </a>
      </ErrorCallout>,
    )
  }
  if (banner && !seen.includes(banner.message_unique_id)) {
    messages.push(
      <div className="customer-messages">
        <div className="banner">
          <span>{banner?.message}</span>
          <Button
            color="blue"
            className="action"
            label={banner?.button_text}
            onClick={() => {
              window.open(banner?.button_url, '_blank')
            }}
          />
          <Button
            color="secondary"
            icon="close"
            label=""
            onClick={() => {
              const stored = [...seen, banner.message_unique_id]
              setSeen(stored)
              window.localStorage.setItem('seen-customer-messages', JSON.stringify(stored))
            }}
          />
        </div>
      </div>,
    )
  }
  return <>{messages}</>
}

export default CustomerMessages
