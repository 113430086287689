import React, {useCallback, useEffect} from 'react'
import Icon from 'ui/icon'
import Forms from 'ui/forms'
import useForm from 'hooks/use-form'
import * as yup from 'yup'
import Button from 'ui/button'
import {ticketSchema, Ticket, createTicket} from '@scavatec/timeclock-lib/api/help'
import useRequest from 'hooks/use-request'
import Callout from 'ui/callout'
import {useAuthContext} from '@scavatec/timeclock-lib/contexts/auth-context'
import analytics from '@scavatec/timeclock-lib/analytics'
import env from 'util/env'
import {useNavigate} from 'react-router-dom'
import {useFeatureFlags} from '@scavatec/timeclock-lib/state/subscription'
import Badge, {PlusBadge} from 'ui/badge'

type HelpCardProps = {
  label: string
}
export const HelpCard: React.FC<HelpCardProps> = ({children, label}) => {
  return (
    <div className="dash-panel card">
      <h2>{label}</h2>
    </div>
  )
}

type DashboardProps = {}
export const Help: React.FC<DashboardProps> = ({children}) => {
  const {
    state: {user},
  } = useAuthContext()
  const request = useRequest()
  const flags = useFeatureFlags()
  const navigate = useNavigate()
  const {getInputProps, values, handleSubmit} = useForm<Ticket>(
    {
      email: user.email,
      app: '',
      supportEmail: 'support@mytoolr.com',
      message: '',
      appType: '',
      account: user.username,
    },
    ticketSchema,
  )
  useEffect(() => {
    analytics.event('w.e.6.0-view')
  }, [])
  const onSubmit = useCallback((values: Ticket) => {
    analytics.event('w.e.6.0-submit-create-support-ticket', {
      ...values,
    })
    request.request(
      createTicket({
        ...values,
        app: values.app.length > 0 ? values.app : 'Website',
      }),
    )
  }, [])
  return (
    <div className="page help">
      <div className="page-content">
        <div className="help-list">
          <div className="column">
            <div className="help-item">
              <div className="details">
                <div className="title-row">
                  <Icon icon="envelope" size={48} />
                  <h1>Feedback / Support</h1>
                </div>
                <div className="row support-row">
                  {!flags.phoneSupport ? (
                    <h2>Hours of Support: 9-5am PST Mon-Fri</h2>
                  ) : (
                    <>
                      <h2>Hours of Support: 24/7</h2>
                      <PlusBadge />
                    </>
                  )}
                </div>
                <p>
                  We're here to help you. Send your questions or feedback directly to our team.
                  <br />
                </p>
                {request.success && (
                  <Callout title="Ticket Submitted" message="You will hear back from us shortly." type="success" />
                )}
                {request.error.length > 0 && (
                  <Callout
                    title="Unable to submit ticket"
                    message="There was a problem submitting your ticket. Please manually email support."
                    type="success"
                  />
                )}
                {!request.success && (
                  <Forms.Form onSubmit={() => handleSubmit(onSubmit)}>
                    <Forms.Row>
                      <Forms.TextInput
                        label="Contact Email"
                        placeholder="Doe.J@my_org.com"
                        {...getInputProps('email')}
                      />
                    </Forms.Row>
                    <Forms.Row>
                      <Forms.Select
                        label="Device / Platform"
                        options={[
                          {value: 'web', label: 'Website'},
                          {value: 'mobile', label: 'Mobile'},
                        ]}
                        {...getInputProps('appType')}
                      />
                    </Forms.Row>
                    {values.appType === 'mobile' && (
                      <Forms.Row>
                        <Forms.Select
                          label="Application"
                          options={[
                            {
                              value: 'Mobile - ST',
                              label: 'TimeClock ST - iOS (legacy)',
                            },
                            {
                              value: 'Mobile - Sync iOS',
                              label: 'TimeClock Sync - iOS',
                            },
                            {
                              value: 'Mobile - Sync Android',
                              label: 'TimeClock Sync - Android',
                            },
                          ]}
                          {...getInputProps('app')}
                        />
                      </Forms.Row>
                    )}
                    <Forms.Row>
                      <Forms.TextArea
                        label="Message"
                        rows={5}
                        placeholder="Please provide a detailed description of your problem and any steps taken to recreate the issue."
                        {...getInputProps('message')}
                      />
                    </Forms.Row>
                    <Forms.Row>
                      <div className="row-button">
                        <Button type="submit" label="Create Support Ticket" />
                      </div>
                    </Forms.Row>
                  </Forms.Form>
                )}
              </div>
            </div>
          </div>
          <div className="column">
            <div className="help-item">
              <div className="details">
                <div className="title-row">
                  <Icon icon="keyword-research" size={48} />
                  <h1>Knowledge Base</h1>
                </div>
                <p>Find answers to frequently asked questions and tutorials to help you navigate Time Clock.</p>
                <div className="row-button">
                  <Button
                    label="View Knowledge Base"
                    color="primary"
                    onClick={() => {
                      analytics.event('w.e.6.0-click-view-knowledgebase')
                    }}
                    href="https://mytoolr.stonly.com/"
                    target="_blank"
                  />
                </div>
              </div>
            </div>
            {flags.phoneSupport ? (
              <div className="help-item">
                <div className="details">
                  <div className="title-row">
                    <Icon icon="headphone-alt" size={48} />
                    <h1>Phone Support</h1>
                    <PlusBadge />
                  </div>
                  <div className="row options">
                    <div>
                      <h1>Call Now</h1>
                      <span>Talk to an available rep or leave a voicemail for the next available call back time</span>
                      <p>
                        Monday - Friday
                        <br />
                        9am to 5pm PST
                        <br />
                        <span className="phone">(+1) 971-343-4278</span>
                      </p>
                    </div>
                    <div>
                      <h1>Call Later</h1>
                      <span>Schedule a call time to guarantee a time slot for a phone or Zoom meeting</span>
                      <Button
                        label="Schedule a Meeting"
                        onClick={() => analytics.event('w.e.6.0-click-schedule')}
                        href="https://calendly.com/toolr-success/self-serve"
                        target="_blank"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="help-item plus">
                <h1>Plus Plan Support</h1>
                <p>Get dedicated phone support and 24/7 email support by upgrading to our Toolr Plus plan.</p>
                <Button
                  label="upgrade"
                  className="upgrade"
                  onClick={() => {
                    analytics.event('w.e.6.0-click-upgrade')
                    navigate('/settings')
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Help
