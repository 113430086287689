import styled from '@emotion/styled'
import {Control, RegisterOptions, useController} from 'react-hook-form'

type RadioOption = {
  label: string
  description?: string
  value: string
}

type RadioGroupInputProps = {
  name: string
  placeholder?: string
  options: RadioOption[]
  disabled?: boolean
  control: Control<any>
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}
export const RadioGroupInput = ({options, name, control, rules, disabled = false}: RadioGroupInputProps) => {
  const {
    field: {ref, onChange, value, onBlur: controllerOnBlur, ...inputProps},
    fieldState: {error},
  } = useController<any>({name, control, defaultValue: false, rules})
  return (
    <div className="input radio-group">
      {options.map((o) => (
        <div key={o.value} className="radio-option" onClick={() => onChange(o.value)}>
          <div className="labels">
            <div className="label">{o.label}</div>
            <div className="description">{o.description}</div>
          </div>
          <div className="radio-input">{value === o.value && <div className="radio-selected"></div>}</div>
        </div>
      ))}
    </div>
  )
}

export default RadioGroupInput
