import analytics from '@scavatec/timeclock-lib/analytics'
import * as accountClient from '@scavatec/timeclock-lib/api/account'
import {useAuthContext} from '@scavatec/timeclock-lib/contexts/auth-context'
import {navigate} from 'hookrouter'
import React, {useEffect} from 'react'
import * as yup from 'yup'
import env from '../../util/env'
import useForm from 'hooks/use-form'
import useRequest from 'hooks/use-request'
import Button from 'ui/button'
import Callout from 'ui/callout'
import Forms from 'ui/forms'
import Logo from 'ui/logo'
import {useNavigate} from 'react-router'
import {useAtom} from 'jotai'
import {userAtom} from '@scavatec/timeclock-lib/state/environment'

const schema = yup.object().shape({
  app_source: yup.string(),
  contact_name: yup.string(),
  contact_phone: yup.string(),
  industry: yup.string().required('Industry is required'),
  employees: yup.string().required('Employees is required'),
  other_industry: yup.string().when('industry', {
    is: 'Other',
    then: yup.string().min(2).required('Industry is required'),
    otherwise: yup.string(),
  }),
})

const industries = [
  'Agriculture & Forestry/Wildlife',
  'Business & Information',
  'Construction/Utilities/Contracting',
  'Education',
  'Finance & Insurance',
  'Food & Hospitality',
  'Gaming',
  'Health Services',
  'Motor Vehicle',
  'Natural Resources/Environmental',
  'Personal Services',
  'Real Estate & Housing',
  'Safety/Security & Legal',
  'Transportation',
  'Other',
]

const employeeOptions = [
  'just me',
  '2-10 employees',
  '11-50 employees',
  '51-100 employees',
  '101+ employees',
]

const testData = {
  contact_name: 'Test',
  contact_phone: '555-555-5555',
  industry: 'Other',
  other_industry: 'Testing!',
  employees: '11-50 employees',
  app_source: 'Sync 2.0 - Web',
}

type FormData = {
  contact_name: string
  contact_phone: string
  industry: string
  other_industry: string
  employees: string
  app_source: string
}
type SurveyProps = {}
export const Survey: React.FC<SurveyProps> = ({children}) => {
  const surveyRequest = useRequest()
  const navigate = useNavigate()
  const {dispatch} = useAuthContext()
  const [, setUser] = useAtom(userAtom)
  useEffect(() => {
    analytics.event('w.a.2.0-view')
  }, [])
  const {handleSubmit, setValue, values, getInputProps} = useForm<FormData>(
    env.env === 'local'
      ? testData
      : {
          contact_name: '',
          industry: '',
          other_industry: '',
          employees: '',
          app_source: 'Sync 2.0 - Web',
          contact_phone: '',
        },
    schema,
  )
  useEffect(() => {
    const load = async () => {
      const res = (await localStorage.getItem('first-name')) ?? ''
      setValue('contact_name')(res)
    }
    load()
  }, [])
  const onSubmit = async (values: FormData) => {
    const industry =
      values.industry === 'Other' ? values.other_industry : values.industry
    analytics.event('w.a.2.0-submit-next', {
      industry: industry,
      employees: values.employees,
      contact_phone: values.contact_phone.length > 0,
    })
    analytics.setUserProperties(
      {
        survey_size_of_company: values.employees,
        survey_industry: industry,
      },
      '',
      false,
    )
    try {
      await surveyRequest.request(accountClient.onboard(values as any))
    } catch (e) {}
    // Reguardless of success or fail we should continue
    // Decode the stashed create account event
    const eventStr = await localStorage.getItem('create-account-event')
    if (eventStr) {
      const event = JSON.parse(eventStr)
      setUser({
        uuid: event.user.id,
        organization: event.org.id,
        token: event.token,
      })
      dispatch(event)
      navigate('/welcome', {replace: true})
    }
  }
  const showContact = !['', 'just me', '2-10 employees'].includes(
    values.employees,
  )
  return (
    (<div className="auth-container">
      <div className="onboard-survey">
        <Logo width={200} size="medium" forceTheme="light" />
        <p>
          In order to custom tailor your experience, please complete the
          following questions:
        </p>
        {surveyRequest.error.length > 0 && (
          <Callout
            type="error"
            title="Create Account Failed"
            message={surveyRequest.error}
          />
        )}
        <Forms.Form onSubmit={() => handleSubmit(onSubmit)}>
          <Forms.Row>
            <Forms.Select
              label="Industry*"
              options={industries.map((i) => {
                return {value: i, label: i.replace('/', ' / ')}
              })}
              {...getInputProps('industry')}
            />
            {values.industry === 'Other' && (
              <Forms.TextInput
                label="Other Industry*"
                placeholder="Timekeeping"
                {...getInputProps('other_industry')}
              />
            )}
          </Forms.Row>
          <Forms.Row>
            <Forms.Select
              label="Employees*"
              options={employeeOptions.map((i) => {
                return {
                  value: i,
                  label: i.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase(),
                  ),
                };
              })}
              {...getInputProps('employees')}
            />
          </Forms.Row>
          {showContact && (
            <p>
              Would you like to speak to your Account Manager for a demo and
              help getting set up with Toolr Time Clock? Shoot us your contact
              info below and we’ll give you a call!
            </p>
          )}
          {showContact && (
            <Forms.Row>
              <Forms.TextInput
                label="Phone"
                placeholder="+1-555-555-5555"
                type="tel"
                {...getInputProps('contact_phone')}
              />
            </Forms.Row>
          )}
          <Button
            icon="restaurant"
            label="Next"
            type="submit"
            loading={surveyRequest.loading}
          />
        </Forms.Form>
      </div>
    </div>)
  );
}
export default Survey
