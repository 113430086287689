import React, {useEffect} from 'react'
import Table, {TableRowClick} from '../table'
import {useMemo} from 'react'
import {CellProps} from 'react-table'
import {Currency, TransactionTypeBadge, AccountTime} from 'util/formatters'
import Badge from '../badge'
import {EmployeeDoc, Employee} from '@scavatec/timeclock-lib/database/models/employee'
import {ColumnSizes} from './common'
import {useCustomAttributesQuery} from '@scavatec/timeclock-lib/api/form-builder'
import capitalize from '@material-ui/utils/capitalize'
import env from 'util/env'

type EmployeeTableProps = {
  data: EmployeeDoc[]
  onRowClick: TableRowClick
  analyticsId: string
}
const EmployeeTable: React.FC<EmployeeTableProps> = ({analyticsId, onRowClick, data}) => {
  const attributesQuery = useCustomAttributesQuery({
    enabled: env.features.customAttributes,
  })
  const {data: attributes = []} = attributesQuery
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'identifier',
        width: ColumnSizes.identifier,
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        width: ColumnSizes.name,
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        width: ColumnSizes.name,
      },
      {
        Header: 'Pay Rate',
        accessor: 'payRate',
        Cell: ({cell: {value}}: CellProps<any>) => <Currency value={value} />,
        width: ColumnSizes.time,
      },
      {
        Header: 'Last Clocked',
        accessor: (d: Employee) => d.getLastClock()?.dateUtc,
        Cell: ({cell: {value}}: CellProps<any>) => (value ? <AccountTime value={value} /> : null),
        width: ColumnSizes.datetime,
      },
      {
        Header: 'Last Clock Rounded',
        accessor: (d: Employee) => d.getLastClock()?.dateUtcRounded,
        Cell: ({cell: {value}}: CellProps<any>) => (value ? <AccountTime value={value} /> : null),
        width: ColumnSizes.datetime,
      },
      {
        Header: 'Status',
        accessor: (d: Employee) => ({
          isActive: d.isActive,
          status: d.getClockStatus(),
        }),
        Cell: ({
          cell: {
            value: {status, isActive},
          },
        }: CellProps<any>) => (
          <>
            <TransactionTypeBadge type={status} />
            {!isActive && <Badge type="inactive" outline label="inactive" />}
          </>
        ),
        width: ColumnSizes.clockType,
      },
      ...attributes
        .filter((a) => a.model === 'employee')
        .map((attr) => {
          return {
            Header: capitalize(attr.name),
            accessor: (d: Employee) => d.attributes?.[attr.name],
          }
        }),
    ],
    [attributes],
  )
  return (
    <Table
      columns={columns}
      data={data}
      analyticsId={analyticsId}
      initialSortBy={[
        {id: 'lastName', desc: false},
        {id: 'firstName', desc: false},
      ]}
      onRowClick={onRowClick}
    />
  )
}
export default EmployeeTable
