import {mdiSort} from '@mdi/js'
import analytics from '@scavatec/timeclock-lib/analytics'
import React, {useState} from 'react'
import {usePopper} from 'react-popper'
import {MaterialIcon} from 'ui/icon'

type MenuItemProps = {
  icon?: string
  onClick: () => void
}
export const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  onClick,
  children,
}) => {
  return (
    <div className="menu-item" onClick={onClick}>
      {icon && <MaterialIcon path={icon} size={0.7} />}
      <span className="label">{children}</span>
    </div>
  )
}

type DropdownMenuProps = {
  children: (setOpen: (b: boolean) => void) => React.ReactNode
  renderTrigger: (open: () => void) => React.ReactNode
}
export const DropdownMenu = ({children, renderTrigger}: DropdownMenuProps) => {
  const [open, setOpen] = useState(false)
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] =
    useState<HTMLDivElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const {styles, attributes, update} = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [{name: 'arrow', options: {element: arrowElement}}],
    },
  )

  const setVisibility = (b: boolean) => {
    setOpen(b)
    update && update()
  }

  return (
    <div className="dropdown-menu">
      <div ref={setReferenceElement}>
        {renderTrigger(() => {
          setVisibility(!open)
        })}
      </div>

      <div
        ref={setPopperElement}
        className="dropdown-content"
        data-open={open}
        style={styles.popper}
        {...attributes.popper}>
        {children(setOpen)}
        <div ref={setArrowElement} className="arrow" style={styles.arrow} />
      </div>
    </div>
  )
}
