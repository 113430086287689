import analytics from '@scavatec/timeclock-lib/analytics'
import React from 'react'
import {useEffect} from 'react'
import Button from './button'

type AlertDialogProps = {
  title: string
  message: string
  open: boolean
  onDismiss: () => void
  onConfirm: () => void
  loading?: boolean
  analyticsId: string
  children?: React.ReactNode
}
export const AlertDialog = ({
  title,
  message,
  open,
  onDismiss,
  onConfirm,
  analyticsId,
  loading = false,
  children = null,
}: AlertDialogProps) => {
  useEffect(() => {
    if (open) {
      analytics.event(`${analyticsId}-view`)
    }
  }, [open])
  if (!open) {
    return null
  }
  return (
    <div className="alert-container">
      <div className="alert-dialog">
        <h1>{title}</h1>
        <p>{message}</p>
        {children}
        <div className="actions">
          <Button
            label="cancel"
            disabled={loading}
            mode="secondary"
            onClick={() => {
              analytics.event(`${analyticsId}-click-cancel`)
              onDismiss()
            }}
          />
          <Button
            label="Confirm"
            loading={loading}
            color="red"
            mode="primary"
            onClick={() => {
              analytics.event(`${analyticsId}-click-confirm`)
              onConfirm()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default AlertDialog
