import {mdiCheck, mdiFlag} from '@mdi/js'
import analytics from '@scavatec/timeclock-lib/analytics'
import {PLUS_TIER_NAME, STARTER_TIER_NAME, useSubscriptionStatusQuery} from '@scavatec/timeclock-lib/api/subscription'
import {SubscriptionStatus} from '@scavatec/timeclock-lib/api/types'
import {subscriptionStatusAtom} from '@scavatec/timeclock-lib/state/subscription'
import {queryStringToObject} from 'hookrouter'
import useClassnames from 'hooks/use-classnames'
import useDataModal, {DataModal} from 'hooks/use-data-modal'
import {useAtom} from 'jotai'
import {DateTime} from 'luxon'
import React, {useEffect, useMemo, useState} from 'react'
import {useQueryClient} from '@tanstack/react-query'
import {useSearchParams} from 'react-router-dom'
import {animated, useSpring} from 'react-spring'
import ActivityIndicator, {Spinner} from 'ui/activity-indicator'
import TrialExtension from 'ui/extend-trial'
import {MaterialIcon} from 'ui/icon'
import ChangePlanModal from 'ui/modals/change-plan-modal'
import {getBaseFee, getEmployeePrice, getPrice, isMonthlyPrice} from 'ui/pricing'
import env from 'util/env'
import {AccountTime, IntegerCurrency} from 'util/formatters'
import useModal from '../../hooks/use-modal'
import Button from '../button'
import Callout from '../callout'
import CancelSubscriptionModal from '../modals/cancel-subscription-modal'
import ChangeCardModal from '../modals/change-card-modal'
import InvoicesModal from '../modals/invoices-modal'
import {SubscribeModal} from '../modals/subscribe-modal'

const SCREEN_ID = 'w.e.2.0'

type PeriodSwitchProps = {
  value: string
  onValueChange: (s: string) => void
}
const PeriodSwitch = ({value, onValueChange}: PeriodSwitchProps) => {
  const trackStyles = useSpring({left: value === 'monthly' ? 3 : 100 - 3})
  return (
    <div
      className="subscription-header"
      onClick={() => {
        onValueChange(value === 'monthly' ? 'yearly' : 'monthly')
        analytics.event(`${SCREEN_ID}-click-yearly-toggle`)
      }}
    >
      <div className="period-switch">
        <span className={`${value === 'monthly' ? 'active' : ''}`}>Monthly</span>
        <span className={`${value === 'yearly' ? 'active' : ''}`}>Annual</span>
        <animated.div className="track" style={trackStyles} />
      </div>
      <span className={`promo ${value === 'yearly' ? 'active' : ''}`}>
        <MaterialIcon path={mdiFlag} size={1} />2 Months Free
      </span>
    </div>
  )
}

export const formatProductName = (name: string) => {
  switch (name) {
    case STARTER_TIER_NAME:
      return 'Starter'
    case PLUS_TIER_NAME:
      return 'Plus'
  }
  return name
}

type SubscriptionPeriod = 'monthly' | 'yearly'
type SubscriptionSettingsProps = {}
export const SubscriptionSettings: React.FC<SubscriptionSettingsProps> = ({}) => {
  const [subscription] = useAtom(subscriptionStatusAtom)
  const client = useQueryClient()
  const [period, setPeriod] = useState('monthly')
  const cancelModal = useModal()
  const subscribeModal = useDataModal(() => {
    return {
      product: STARTER_TIER_NAME,
      price: env.services.stripe.prices.monthly,
    }
  })
  const changePlanModal = useDataModal(() => {
    return {
      product: STARTER_TIER_NAME,
      price: env.services.stripe.prices.monthly,
    }
  })
  const subscriptionQuery = useSubscriptionStatusQuery()
  useEffect(() => {
    analytics.event('w.e.2.0-view')
    subscriptionQuery.refetch()
  }, [])
  const finalProduct = getFinalProduct(subscription.productName ?? '', subscription.priceName ?? '')
  return (
    <div className="subscription-settings">
      <PeriodSwitch value={period} onValueChange={setPeriod} />
      <div className="subscription-list">
        <SubscriptionBanner
          className="dimmed"
          modal={subscribeModal}
          changePlanModal={changePlanModal}
          name="Starter"
          loading={subscriptionQuery.isLoading}
          isUpgrade={period === 'yearly' && finalProduct !== 'plus'}
          period={period}
          product={STARTER_TIER_NAME}
          subscription={subscription}
        >
          <>
            <ul className="feature-list">
              <li>Time-tracking</li>
              <li>Time reporting & exporting</li>
              <li>GPS location tracking</li>
              <li>Live attendance status</li>
              <li className="disabled">People directory</li>
              <li className="disabled">Worker messaging</li>
              <li className="disabled">Digital in-out board</li>
              <li className="disabled">People "Roll Call"</li>
            </ul>
            <h6>Support</h6>
            <ul className="feature-list">
              <li>M-F Support</li>
              <li>9-5pm (pst) Email support</li>
              <li className="disabled">9-5pm (pst) phone support</li>
              <li className="disabled">Concierge account setup</li>
            </ul>
          </>
        </SubscriptionBanner>
        <SubscriptionBanner
          modal={subscribeModal}
          changePlanModal={changePlanModal}
          name="Plus"
          loading={subscriptionQuery.isLoading}
          period={period}
          isUpgrade={finalProduct !== 'plus' || period === 'yearly'}
          isBest
          subscription={subscription}
          product={PLUS_TIER_NAME}
        >
          <>
            <ul className="feature-list">
              <li>Time-tracking</li>
              <li>Time reporting & exporting</li>
              <li>GPS location tracking</li>
              <li>Live attendance status</li>
              <li>People directory</li>
              <li>Worker messaging</li>
              <li>Digital in-out board</li>
              <li>People "Roll Call"</li>
            </ul>
            <h6>Support</h6>
            <ul className="feature-list">
              <li>24/7 VIP support</li>
              <li>24/7 Email support</li>
              <li>9-5pm (pst) phone support</li>
              <li>Concierge account setup</li>
            </ul>
          </>
        </SubscriptionBanner>
        <SubscriptionDetails subscription={subscription} loading={subscriptionQuery.isLoading} />
        <SubscribeModal modal={subscribeModal} />
        <ChangePlanModal modal={changePlanModal} />
        <CancelSubscriptionModal modal={cancelModal} subscription={subscription} />
        {subscription.isSubscribed && subscription && !subscription.isCancelled && (
          <div className="cancel">
            Need to cancel your subscription?
            <br />
            <br />
            We know circumstances can sometimes change.
            <br />
            <br />
            {subscription.processor === 'stripe' ? (
              <a href="#" onClick={cancelModal.open}>
                Cancel My Subscription
              </a>
            ) : (
              <>
                Your subscription is currently managed through your mobile platform. You must cancel through their
                provided mechanisms. See the links below for more information.
                <br />
                <br />
                <a href="https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid">
                  Play Store - Android (Google, Samsung, etc)
                </a>
                <br />
                <a href="https://support.apple.com/en-us/HT202039">Apple App Store - iOS</a>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const statusHeader = (subscription: SubscriptionStatus) => {
  if (subscription.isDelinquent || subscription.isCancelled || subscription.isOnGracePeriod) {
    return `Your account requires attention`
  }
  if (subscription.isSubscribed) {
    return `You're in good standing`
  }
  if (subscription.isTrial) {
    return `You're on trial`
  }
  return 'Your trial is over'
}
type SubscriptionDetailsProps = {
  subscription: SubscriptionStatus
  loading: boolean
}
export const SubscriptionDetails = ({loading, subscription}: SubscriptionDetailsProps) => {
  const invoiceModal = useModal()
  const cardModal = useModal()
  const classes = useClassnames({
    standing: true,
    error:
      subscription.isDelinquent || subscription.isCancelled || (!subscription.isTrial && !subscription.isSubscribed),
  })
  const finalProduct = getFinalProduct(subscription.productName ?? '', subscription.priceName ?? '')
  const [params] = useSearchParams({flash: ''})
  const flash = decodeURIComponent(params.get('flash') ?? '')
  if (loading) {
    return (
      <div className="details">
        <Spinner contained />
      </div>
    )
  }
  return (
    <div className="details">
      {flash.length > 0 && !subscription.isSubscribed && !subscription.isTrial && (
        <Callout title="Unsubscribed" message={flash} type="error"></Callout>
      )}
      <div className={classes}>
        <h6>{statusHeader(subscription)}</h6>
        <ul>
          {subscription.isDelinquent && (
            <li>Please update your credit card information below to continue using Time Clock</li>
          )}
          {subscription.isTrial && (
            <>
              <li>
                Your trial period ends on <AccountTime value={subscription.trialPeriodEnd} />
              </li>
              <li>While in your trial period you have access to all Plus tier features</li>
            </>
          )}
          {!subscription.isSubscribed && !subscription.isTrial && (
            <>
              <li>
                Your trial period ended on <AccountTime value={subscription.trialPeriodEnd} />
              </li>
              <li>To continue using Time Clock please subscribe</li>
            </>
          )}
          {subscription.isSubscribed &&
            !subscription.isCancelled &&
            !subscription.isOnGracePeriod &&
            (subscription.isTrial ? (
              <>
                {!subscription.isCancelled && (
                  <li>
                    Once your trial period ends, your {formatProductName(finalProduct)} subscription will start on{' '}
                    <AccountTime value={subscription.currentPeriodEnd} />
                  </li>
                )}
                {!subscription.isCancelled && finalProduct !== PLUS_TIER_NAME && (
                  <li>
                    Once your {`${formatProductName(finalProduct)}`} subscription starts, you will lose access to Plus
                    features
                  </li>
                )}
              </>
            ) : (
              <>
                {subscription.processor === 'stripe' ? (
                  <li>
                    Your {`${formatProductName(finalProduct)}`} plan is active{' '}
                    {!subscription.isDelinquent && 'and in good standing'}
                  </li>
                ) : (
                  <>
                    <li>Your In App Purchase plan is active and in good standing.</li>
                    <li>
                      Apple or Android In App Purchase plans are equivalent to Starter plans. If you would like to
                      upgrade to Plus plan, you can subscribe on this page
                    </li>
                    {subscription.isSubscribed && (
                      <li>
                        Current period ends on:{' '}
                        <strong>
                          <AccountTime value={subscription.currentPeriodEnd} />
                        </strong>
                      </li>
                    )}
                  </>
                )}
              </>
            ))}
          {subscription.productName === 'plus' && subscription.priceName?.includes('trial') && (
            <li>You are currently on a Plus Trial which gives you access to all Plus tier features</li>
          )}
          {subscription.isCancelled && (
            <>
              {subscription.isOnGracePeriod ? (
                <li>
                  Your subscription will end on <AccountTime value={subscription.currentPeriodEnd} />
                </li>
              ) : (
                <li>
                  Your subscription ended on <AccountTime value={subscription.currentPeriodEnd} />
                </li>
              )}
            </>
          )}
        </ul>
      </div>
      {!subscription.isSubscribed && !subscription.isTrial && <TrialExtension />}
      {subscription.upcomingInvoice && subscription.upcomingInvoice.status !== null && (
        <>
          <div className="detail next-invoice">
            <h6>Next estimated invoice</h6>
            <span>
              <AccountTime value={subscription.upcomingInvoice.createdAt} baseFormat={DateTime.DATE_MED} />
            </span>
            <span className="divider">|</span>
            <span>
              <IntegerCurrency value={subscription.upcomingInvoice.amount ?? 0} forceCurrency="USD" />{' '}
              {/* (<a href="#">PDF</a>) */}
            </span>
          </div>
          <hr />
        </>
      )}
      {subscription.latestInvoice && subscription.latestInvoice.status !== null && (
        <>
          <div className="detail last-invoice">
            <h6>Last invoice</h6>
            <span>
              <AccountTime value={subscription.latestInvoice.createdAt} baseFormat={DateTime.DATE_MED} />
            </span>
            <span className="divider">|</span>
            <span>
              <IntegerCurrency value={subscription.latestInvoice.amount ?? 0} forceCurrency="USD" />{' '}
              {/* (<a href="#">PDF</a>) */}
            </span>
            <br />
            <a href="#" onClick={invoiceModal.open}>
              Invoice History
            </a>
          </div>
          <hr />
        </>
      )}
      {subscription.card && subscription.card.last4 !== null && (
        <div className="detail card-details">
          <h6>Payment Method</h6>
          <span className="brand">
            <strong>{subscription.card.brand}</strong>
          </span>
          <span className="cardnum">{`**** ${subscription.card.last4}`}</span>
          <br />
          <a href="#" onClick={cardModal.open}>
            Change Card
          </a>
        </div>
      )}
      <InvoicesModal modal={invoiceModal} />
      <ChangeCardModal modal={cardModal} />
    </div>
  )
}

const getFinalProduct = (product: string, price: string) => {
  if (product === 'plus' && price.includes('-trial')) {
    return 'starter'
  }
  return product
}

type SubscriptionBannerProps = {
  name: string
  loading: boolean
  modal: DataModal<{product: string; price: string}>
  changePlanModal: DataModal<{product: string; price: string}>
  period: 'monthly' | 'yearly' | string
  product: string
  subscription: SubscriptionStatus
  className?: string
  isBest?: boolean
  isUpgrade?: boolean
  children: React.ReactNode
}
const SubscriptionBanner = ({
  name,
  className = '',
  period,
  modal,
  changePlanModal,
  product,
  isUpgrade,
  isBest,
  subscription,
  loading,
  children,
}: SubscriptionBannerProps) => {
  const priceSplit = useMemo(() => {
    const p = getEmployeePrice(product, `usd-${period}`)
    return p.split('.')
  }, [product, period])
  const finalProductName = getFinalProduct(subscription.productName ?? '', subscription.priceName ?? '')
  const subscribeButton = useMemo(() => {
    if (!subscription.isSubscribed || subscription.isCancelled || subscription.processor !== 'stripe') {
      return (
        <Button
          label="Subscribe"
          loading={loading}
          onClick={() => {
            analytics.event(`${SCREEN_ID}-click-${product.toLowerCase()}-${period}`)
            modal.open({
              product: product,
              price: `usd-${period}`,
            })
          }}
        />
      )
    } else {
      if (
        finalProductName === product &&
        ((period === 'monthly' && isMonthlyPrice(subscription.priceName ?? 'monthly')) ||
          (period === 'yearly' && !isMonthlyPrice(subscription.priceName ?? 'monthly')))
      ) {
        return <Button label="Active" className="active" iconPath={mdiCheck} iconSize={1} />
      } else {
        return isUpgrade ? (
          <Button
            label="Upgrade"
            loading={loading}
            onClick={() => {
              analytics.event(`${SCREEN_ID}-click-${product.toLowerCase()}-${period}`)
              changePlanModal.open({
                product: product,
                price: `usd-${period}`,
              })
            }}
          />
        ) : (
          <Button
            label="Downgrade"
            className="downgrade"
            loading={loading}
            onClick={() => {
              analytics.event(`${SCREEN_ID}-click-${product.toLowerCase()}-${period}`)
              changePlanModal.open({
                product: product,
                price: `usd-${period}`,
              })
            }}
          />
        )
      }
    }
  }, [subscription, product, period, loading])
  return (
    <div className={`subscription-banner ${className}`}>
      {isBest && (
        <div className="best-banner">
          <span>BEST</span>
        </div>
      )}
      <h1>{name}</h1>
      <h2 className="price">
        <span className="currency">$</span>
        <span className="dollar">{priceSplit[0]}</span>
        <span className="cents">.{priceSplit[1]}</span>
      </h2>
      <h6 className="price-period">
        Per active worker
        <br />
        per month
        {period === 'yearly' && (
          <>
            <br />
            (Pre-paid annually)
          </>
        )}
      </h6>
      <h6 className="fee">{`Base Fee: $${getBaseFee(product, `usd-${period}`)}`}</h6>
      <hr />
      <h6>Features</h6>
      {children}
      {subscribeButton}
    </div>
  )
}
export default SubscriptionSettings
