import analytics from '@scavatec/timeclock-lib/analytics'
import {forgotPassword} from '@scavatec/timeclock-lib/api/auth'
import {ClientError} from '@scavatec/timeclock-lib/api/util'
import React from 'react'
import {useMutation} from '@tanstack/react-query'
import * as yup from 'yup'
import useAPI from '../../hooks/use-api'
import useForm from '../../hooks/use-form'
import {ModalHookType} from '../../hooks/use-modal'
import Button from '../button'
import {ErrorCallout, RoundedErrorCallout} from '../callout'
import * as Forms from '../forms'
import Modal from './modal'

const schema = yup.object().shape({
  email: yup.string().email(),
})
export type FormData = yup.InferType<typeof schema>
type ForgotPasswordModalProps = {
  modal: ModalHookType
  onSave?: (values: any) => void
  onDelete?: (values: any) => void
}
export const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
  modal,
  onSave,
  onDelete,
}) => {
  const {handleSubmit, getInputProps} = useForm<FormData>({email: ''}, schema)

  const mutation = useMutation<any, ClientError, string>((email) => {
    return forgotPassword(email)
  })

  const onSubmit = async (values: FormData) => {
    analytics.event('w.a.1.1-submit-send-reset-email', {
      email: values.email,
    })
    mutation.mutate(values.email)
  }

  return (
    <Modal.Wrapper className="reset-password" open={modal.isOpen}>
      <Forms.Form onSubmit={() => handleSubmit(onSubmit)}>
        <Modal.Header>
          <h1>Forgot Password</h1>
        </Modal.Header>
        <div className="callout-container">
          {mutation.error && <RoundedErrorCallout error={mutation.error} />}
        </div>
        <Modal.Body>
          {mutation.isSuccess ? (
            <>
              <h5>
                Request to send reset email successful. If there are any
                Organizations associated with this email, you will receive an
                email with further instructions within the next few minutes.
              </h5>
            </>
          ) : (
            <>
              <h5>
                Enter your email and we will send you a list of any accounts
                associated with it along with a link to reset the password if
                needed.
              </h5>
              <Forms.Row>
                <Forms.TextInput
                  label="Email"
                  placeholder="timeclock@my-company.com"
                  {...getInputProps('email')}
                />
              </Forms.Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {mutation.isSuccess ? (
            <Button
              disabled={mutation.isLoading}
              loading={mutation.isLoading}
              label="Continue"
              onClick={() => modal.close()}
            />
          ) : (
            <>
              <Button
                mode="secondary"
                label="Cancel"
                disabled={mutation.isLoading}
                onClick={() => {
                  analytics.event('w.a.1.1-click-cancel')
                  modal.close()
                }}
              />
              <Button
                type="submit"
                disabled={mutation.isLoading}
                loading={mutation.isLoading}
                label="Send Reset Email"
              />
            </>
          )}
        </Modal.Footer>
      </Forms.Form>
    </Modal.Wrapper>
  )
}
export default ForgotPasswordModal
