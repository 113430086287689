import analytics from '@scavatec/timeclock-lib/analytics'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import {useAtom} from 'jotai'
import React, {useCallback} from 'react'
import {useNavigate, useParams} from 'react-router'
import TimeEntriesTable from 'ui/tables/time-entries-table'
import TimeEntriesTableLegacy from 'ui/tables/time-entries-table-legacy'
import {jobListViewAtom, jobSearchAtom} from './job-state'

const screenID = 'w.g.2.0-daily-totals'

type JobTimeEntriesProps = {}
const JobTimeEntries: React.FC<JobTimeEntriesProps> = ({}) => {
  const {uuid = ''} = useParams()
  const [search] = useAtom(jobSearchAtom)
  const [view] = useAtom(jobListViewAtom)
  const navigate = useNavigate()
  const onClick = useCallback((t: Transaction) => {
    analytics.event(`${screenID}-click-tile`)
    navigate(`/transactions/${t.transactionUUID}`)
  }, [])

  const reportEngineSetting = useOrganizationSetting('reporting.engine_version')
  return reportEngineSetting.value < 2 ? (
    <TimeEntriesTableLegacy
      search={search}
      view={view}
      job={uuid}
      showEmployee
      hideColumns={['job']}
      analyticsId={screenID}
      onRowClick={onClick}
    />
  ) : (
    <TimeEntriesTable
      search={search}
      view={view}
      job={uuid}
      showEmployee
      hideColumns={['job']}
      analyticsId={screenID}
      onRowClick={onClick}
    />
  )
}
export default JobTimeEntries
