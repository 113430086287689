import {
  mdiAccountGroup,
  mdiAlert,
  mdiAndroidMessages,
  mdiChartBar,
  mdiContacts,
  mdiCrane,
  mdiDevices,
  mdiTelevision,
} from '@mdi/js'
import analytics from '@scavatec/timeclock-lib/analytics'
import {useIdentityQuery} from '@scavatec/timeclock-lib/api/auth'
import {useResendActivationMutation} from '@scavatec/timeclock-lib/api/user'
import {useAuthContext} from '@scavatec/timeclock-lib/contexts/auth-context'
import {useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import useDatabase from '@scavatec/timeclock-lib/hooks/use-database'
import {currentUserAtom, userAtom} from '@scavatec/timeclock-lib/state/environment'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import {useFeatureFlags} from '@scavatec/timeclock-lib/state/subscription'
import {useToastContext} from 'contexts/toast-context'
import {usePath} from 'hookrouter'
import {useHasLegacyDevices} from 'hooks/use-has-legacy-devices'
import {useAtom} from 'jotai'
import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import Button from 'ui/button'
import Avatar from './ui/avatar'
import Badge, {PlusPopoverBadge} from './ui/badge'
import {MaterialIcon} from './ui/icon'
import Logo from './ui/logo'
import env from './util/env'

type DrawerItemProps = {
  label: string
  icon: string
  path?: string
  beta?: boolean
  plus?: boolean
  right?: React.ReactNode
  onClick?: () => void
}
const DrawerItem: React.FC<DrawerItemProps> = ({
  label,
  path = '',
  beta = false,
  icon,
  plus = false,
  onClick,
  right,
}) => {
  const currPath = usePath()
  return (
    <NavLink
      to={path}
      className="item"
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
    >
      {icon.length > 0 && <MaterialIcon path={icon} size={1} />}
      {label}
      {beta && <Badge label="Beta" type="beta" />}
      {plus && <PlusPopoverBadge />}
      {right}
    </NavLink>
  )
}

type DrawerCategoryProps = {
  label: string
  icon?: string
}
const DrawerCategory: React.FC<DrawerCategoryProps> = ({label, icon = '', children}) => {
  return (
    <div className="category">
      <div className="title">
        {icon.length > 0 && <MaterialIcon path={icon} size={1} />}
        {label}
      </div>
      <div className="items">{children}</div>
    </div>
  )
}
type DrawerProps = {}
export const Drawer: React.FC<DrawerProps> = ({children}) => {
  const [, setUser] = useAtom(userAtom)
  const {logout} = useAuthContext()
  const {unsafeReset} = useDatabase()
  const {actions} = useReportContext()
  const [currentUser] = useAtom(currentUserAtom)
  const reportEngineSetting = useOrganizationSetting('reporting.engine_version')
  const flags = useFeatureFlags()
  const hasLegacy = useHasLegacyDevices()
  const {addItem} = useToastContext()
  const identity = useIdentityQuery()
  const resendMutation = useResendActivationMutation({
    onSuccess: () => {
      addItem(
        {
          title: 'Activation email sent',
          message: 'Check your email for a link to activate your email address',
        },
        5000,
      )
    },
    onError: (error) => {
      addItem(
        {
          title: 'Unable to send',
          message: error?.message ?? 'Unknown problem',
          color: 'red',
        },
        3000,
      )
    },
  })
  return (
    <div className="drawer">
      <Logo width={150} />
      <div className="profile">
        <Avatar text={identity.data?.organization?.name ?? ''} />
        <div className="label">
          <div className="org">{identity.data?.organization?.name}</div>
          <div className="user">{identity.data?.user?.email}</div>
          {currentUser && currentUser?.emailVerifiedAt == null && (
            <Button
              size="small"
              label="Resend Verification"
              onClick={() => resendMutation.mutate()}
              loading={resendMutation.isLoading}
            />
          )}
        </div>
      </div>
      {/* <DrawerItem icon="dashboard" label="Dashboard" path="/dashboard" /> */}
      <DrawerItem
        icon={mdiContacts}
        label="People"
        path="/employees"
        onClick={() => analytics.event('w.z.1.0-click-employees')}
      />
      <DrawerItem icon={mdiCrane} label="Jobs" path="/jobs" onClick={() => analytics.event('w.z.1.0-click-jobs')} />
      <DrawerItem
        icon={mdiDevices}
        label="Devices"
        path="/devices"
        right={
          hasLegacy && (
            <div
              className="legacy-device"
              style={{marginLeft: 'auto'}}
              title="Your account has mobile applications that are no longer supported"
            >
              <MaterialIcon path={mdiAlert} size={1} />
            </div>
          )
        }
        onClick={() => analytics.event('w.z.1.0-click-devices')}
      />
      <DrawerItem
        icon={mdiAccountGroup}
        label="Crews"
        path="/crews"
        onClick={() => analytics.event('w.z.1.0-click-crews')}
      />
      {reportEngineSetting.value < 2 || env.features.reportingEngine ? (
        <DrawerItem
          icon={mdiChartBar}
          label="Reports"
          path="/reports/"
          onClick={() => {
            analytics.event('w.z.1.0-click-reports')
            actions.refresh()
          }}
        />
      ) : (
        <DrawerItem
          icon={mdiChartBar}
          label="Reports"
          path="/report/"
          onClick={() => {
            analytics.event('w.z.1.0-click-reports')
          }}
        />
      )}
      <DrawerItem
        icon={mdiAndroidMessages}
        label="Messaging"
        path="/messaging"
        plus={flags.plusBadges}
        onClick={() => {
          analytics.event('w.z.1.0-click-messaging')
        }}
      />
      <DrawerItem
        icon={mdiTelevision}
        label="In-Out Board"
        plus={flags.plusBadges}
        path="/in-out-board"
        onClick={() => {
          analytics.event('w.z.1.0-click-in-out-board')
          actions.refresh()
        }}
      />
      <div className="mini-menu">
        <DrawerItem
          icon=""
          label="Settings"
          path="/settings"
          onClick={() => analytics.event('w.z.1.0-click-settings')}
        />
        <DrawerItem icon="" label="Help" path="/help" onClick={() => analytics.event('w.z.1.0-click-help')} />
        <DrawerItem
          icon=""
          label="Logout"
          path="/login"
          onClick={async () => {
            logout()
            setUser({
              organization: '',
              token: '',
              uuid: '',
            })
            analytics.event('w.z.1.0-click-logout')
            analytics.reset()
            await unsafeReset()
            window.location.reload()
          }}
        />
        <div className="metadata">
          <span className="cc">{`Toolr 2023`}</span>
          <span className="version">
            {`v${env.version}`}
            <Link to="/changelog">Changelog</Link>
            <a href="https://www.mytoolr.com/policy" target="_blank">
              Privacy Policy
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}
export default Drawer
